import { SettingOutlined } from "@ant-design/icons";
import { Avatar, Card, List, Typography } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import Flags from "../../../../../components/flags";
import BaseListPage from "../../../components/BaseListPage";

export default function Rule({stateLocation}) {

  return (
    <BaseListPage
      datas={stateLocation}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                icon={<SettingOutlined />}
              />
            }
            title={
              <div>
                <Typography.Title level={5}>
                  {item.rule_name?.toUpperCase()}
                </Typography.Title>
              </div>
            }
            description={
              <div className="rowslist-inline-flex">
                <p>Total = {item.total}</p>
              </div>
            }
          />

          <div>
            <Flags country_code={item.nationality} withCountry />
          </div>
        </List.Item>
      )}
    />
    // <List
    //   className="list-base"
    //   itemLayout="horizontal"
    //   dataSource={state}
    //   renderItem={(item) => (
    //     <List.Item>
    //       <List.Item.Meta
    //         avatar={
    //           <Avatar
    //             style={{
    //               justifyContent: "center",
    //               display: "flex",
    //               alignItems: "center",
    //             }}
    //             icon={<SettingOutlined />}
    //           />
    //         }
    //         title={
    //           <Typography.Title level={5}>
    //             {item.rule_name?.toUpperCase()}
    //           </Typography.Title>
    //         }
    //         description={
    //           <div className="rowslist-inline-flex">
    //             <p>Total = {item.total}</p>
    //           </div>
    //         }
    //       />

    //       <div>
    //         <Flags country_code={item.nationality} withCountry />
    //       </div>
    //     </List.Item>
    //   )}
    // />
  );
}
