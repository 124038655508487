import { GiAirplaneArrival, GiAirplaneDeparture, GiCommercialAirplane, GiGlobe } from "react-icons/gi";
import CFG from '../../../config/env';
const {withDomestic}=CFG;
export default [
    // {
    //     caption:'flight_master',
    //     category:'flight',
    //     group:0, 
    //     Icon:GiCommercialAirplane,
    //     linkTo:'/flight/master'
    // },
    {
        caption:'flight_schedule_arrival',
        category:'flight',
        group:0, 
        Icon:GiAirplaneArrival,
        linkTo:'/flight/schedule/arrival'
    },
    {
        caption:'flight_schedule_departure',
        category:'flight',
        group:0, 
        Icon:GiAirplaneDeparture,
        linkTo:'/flight/schedule/departure'
    },
    (!!withDomestic && {
        caption:'flight_schedule_domestic',
        category:'flight',
        group:0, 
        Icon:GiGlobe,
        linkTo:'/flight/domestic'
    }),
]