import { GiCargoShip, GiHarborDock, GiSailboat, GiSoulVessel } from "react-icons/gi";
import { RiShip2Fill } from "react-icons/ri";

export default [
    {
        caption:'ferry',
        category:'vessel',
        group:0, 
        Icon:RiShip2Fill,
        childs:[
            {
                caption:'vessel_arrival',
                category:'vessel',
                group:0, 
                Icon:GiHarborDock,
                linkTo:'/vessel/ferry/arrival'
            },
            {
                caption:'vessel_departure',
                category:'vessel',
                group:0, 
                Icon:GiSailboat,
                linkTo:'/vessel/ferry/departure'
            },
        ]
        // linkTo:'/vessel/arrival'
    },
    {
        caption:'cargo',
        category:'vessel',
        group:0, 
        Icon:GiCargoShip,
        childs:[
            {
                caption:'vessel_arrival',
                category:'vessel',
                group:0, 
                Icon:GiHarborDock,
                linkTo:'/vessel/cargo/arrival'
            },
            {
                caption:'vessel_departure',
                category:'vessel',
                group:0, 
                Icon:GiSailboat,
                linkTo:'/vessel/cargo/departure'
            },
        ]
        // linkTo:'/vessel/departure'
    },
]