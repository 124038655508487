import { green, grey, red } from '@ant-design/colors';
import { Avatar, Typography } from 'antd';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import Flags from '../../../../../../components/flags';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import './fs.css';

export default ({row, to, url, direction}) => {
    const {_id, flight_number, origin_id, dest_id, airline_id, flight_date, 
        aircraft_registration_id:aircraft_id, customs_messages, arrival_date,
        localAta, localAtd, localSta, localStd, sta, std, status} = row;
    const ta = arrival_date.substring(0,2)+' ' +( localAta || localSta || sta);
    const td = flight_date.substring(0,2)+ ' ' + ( localAtd || localStd || std);
    const airlineCode=airline_id.code || airline_id.icao;
    const [pnr, adl, prl]=useMemo(()=>{
        const p = customs_messages.filter((r)=>r.msgType==='PNL');
        const l = customs_messages.filter((r)=>r.msgType==='ADL');
        const c = customs_messages.filter((r)=>r.msgType==='PRL');
        return [p.length, l.length, c.length];
    }, [customs_messages]);
    const date=direction==='departure'?flight_date:arrival_date;
    const [,getLanguage] = useLanguage()
    return (
        <Link to={`${url}/${_id}`}>
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', flexDirection:'row', background:'#ffffffaa', padding:4}}>
                    <Typography.Text style={{width:`calc(25% + 64px)`}} title={getLanguage('flight_number')} strong>{flight_number} </Typography.Text>                    
                    <Typography.Text style={{width:'25%'}} title={getLanguage('flight_date')} strong>{date}</Typography.Text>
                    <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center', flexGrow:1, justifyContent:'end'}}>
                        {
                            !!airline_id.country && <Flags country_code={airline_id.country.toLowerCase()} />
                        }
                        <Typography.Text style={{textAlign:'right'}}>{airline_id?.name}</Typography.Text>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', background:'#ffffff77', alignItems:'center', padding:8}}>
                    <div style={{width:64}}>
                        <Avatar
                            alt={airline_id?.code}
                            size="large"
                            style={{alignSelf:'center'}}
                            className={'avatar-contain'}
                            src={`/assets/carrier/${airlineCode.toLowerCase()}.png`}
                        />
                    </div>
                    <div style={{display:'flex', width:'25%', flexDirection:'column', rowGap:4}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center', flexWrap:'wrap'}}>
                            {
                                !!origin_id.country && <Flags country_code={origin_id.country.toLowerCase()} />
                            }
                            <Typography.Text strong>{origin_id?.code}</Typography.Text>
                            <Typography.Text>({origin_id?.kota})</Typography.Text>
                            <Typography.Text>{td}</Typography.Text>
                            <GiAirplaneDeparture size={18} color='black'/>
                        </div>
                        <Typography.Text ellipsis>{origin_id?.name}</Typography.Text>
                    </div>
                    <div style={{display:'flex', width:'25%', flexDirection:'column', rowGap:4}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center', flexWrap:'wrap'}}>
                            {
                                !!dest_id.country && <Flags country_code={dest_id.country.toLowerCase()} />
                            }
                            <Typography.Text strong>{dest_id?.code}</Typography.Text>
                            <Typography.Text>({dest_id?.kota})</Typography.Text>
                            <Typography.Text>{ta}</Typography.Text>
                            <GiAirplaneArrival size={18} color='black' />
                        </div>
                        <Typography.Text ellipsis>{dest_id?.name}</Typography.Text>
                    </div>
                    <div style={{display:'flex', width:'25%', flexDirection:'column', rowGap:4, alignItems:'end'}}>
                        {
                            !aircraft_id?.aircraft_registration && (
                                <Typography.Text>{ getLanguage('no_aircraft') }</Typography.Text>
                            )
                            || (
                                <Typography.Text ellipsis>{ aircraft_id.aircraft_registration }</Typography.Text>
                            )
                        }
                        <Typography.Text ellipsis>{aircraft_id?.aircraft_type_txt}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexGrow:1, flexDirection:'column', rowGap:4, alignItems:'end'}}>
                        <div style={{display:'flex', flexDirection:'row', gap:8, alignItems:'start', justifyContent:'end', flexWrap:'wrap'}}>
                            <span title='PNL' className={`msg-wrapper ${pnr>0?'msg-exists':''}`}>
                                P:{numeral(pnr).format('00')}
                            </span>
                            <span title='ADL' className={`msg-wrapper ${adl>0?'msg-exists':''}`}>
                                A:{numeral(adl).format('00')}
                            </span>
                            <span title='PRL' className={`msg-wrapper ${prl>0?'msg-exists':''}`}>
                                R:{numeral(prl).format('00')}
                            </span>
                        </div>
                        <Typography.Text>{status}</Typography.Text>
                    </div>
                </div>
            </div>
        </Link>
    )
}