import React from 'react';
import Table from '../../lists/table';
import moment from 'moment';

export default ({data}) => {
    console.log({data});
    return (
        <Table 
                data={data || []}
                pagination={false}
                filterField={['nama_tpi', 'kode_tpi']}
                columns={[
                    {
                        title:'time',
                        dataIndex:'tanggal_perlintasan',       
                        render:(val)=>!!val?moment(val).format('DD-MMM-YYYY HH:mm'):'-'
                    },
                    {
                        title:'arah',
                        dataIndex:'arah_perlintasan',  
                    }, 
                    {
                        title:'visa',
                        dataIndex:'no_visa',  
                    }, 
                    {
                        title:'number',
                        dataIndex:'kode_alat_angkut',  
                    },                
                    {
                        title:'kode_tpi',
                        dataIndex:'kode_tpi',       
                    },
                    {
                        title:'nama_tpi',
                        dataIndex:'nama_tpi',       
                    },
                    {
                        title:'status',
                        dataIndex:'status_perlintasan',       
                    },
            ]}
        />
    )
}