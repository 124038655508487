import { Card, Tabs } from "antd";
import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { IoIosPulse, IoMdInformation, IoMdMail } from "react-icons/io";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdFlight } from "react-icons/md";
import FlightRadar from "./flight_radar";
import ProccessLogs from "./process_logs";
import ProccessStatus from "./process_status";
import TelexLogs from "./telex_logs";

const TabItems = [
  {
    key: "Flight Radar",
    label: (
      <span>
        <MdFlight style={{ marginRight: 4 }} />
        Flight Radar
      </span>
    ),
    children: <FlightRadar />,
  },
  {
    key: "Telex Logs",
    label: (
      <span>
        <HiOutlineMail style={{ marginRight: 4 }} />
        Telex Logs
      </span>
    ),
    children: <TelexLogs />,
  },
  {
    key: "Process Status",
    label: (
      <span>
        <IoInformationCircleOutline style={{ marginRight: 4 }} />
        Process Status
      </span>
    ),
    children: <ProccessStatus />,
  },
  {
    key: "Process Logs",
    label: (
      <span>
        <IoIosPulse style={{ marginRight: 4 }} />
        Process Logs
      </span>
    ),
    children: <ProccessLogs />,
  },
];

export default function Tools() {
  return (
    <div className="p-3" style={{ backgroundColor: "#F0F1F5" }}>
      <Tabs>
        {TabItems.map((item, idx) => (
          <Tabs.TabPane tab={item.label} key={idx}>
            {item.children}
          </Tabs.TabPane>
        ))}
        {/* <Tabs.TabPane tab="Flight Radar" key="item-1">
          <FlightRadar />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Telex Logs" key="item-2">
          <TelexLogs />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Process Status" key="item-3">
          <ProccessStatus />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Process Logs" key="item-4">
          <ProccessLogs />
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
}
