import { blue, green, magenta } from '@ant-design/colors';
import { Typography } from 'antd';
import numeral from 'numeral';
import React from 'react';
import { Fragment } from 'react';
import { GiExitDoor, GiHomeGarage } from 'react-icons/gi';
import { IoIosMan, IoIosWoman } from 'react-icons/io';
import Flags from '../../../../../../components/flags';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import Panel from '../../panel/panel';

export const TooltipAge = ({ active, payload, label, onTooltipHover }) => {
    const [, getLan] = useLanguage();
    if (active && payload && payload.length) {
        const [{payload:{male, female, ...rest}}] = payload;
        !!onTooltipHover && typeof onTooltipHover==='function' && onTooltipHover(rest);
        return (
            <div style={{width:180, background:'#ffffffaa'}}>
                <Panel
                    headerLeft={getLan('age')}
                    headerRight={`${label} - ${(parseInt(label)+ 9)}`}
                >
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosMan color={blue[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(male).format('0,000')}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosWoman color={magenta[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(female).format('0,000')}</Typography.Text>
                    </div>
                </Panel>
            </div>
        );
    }

    return null;
};

export const TooltipDirectionArr = ({ active, payload, label, onTooltipHover }) => {
    const [, getLan] = useLanguage();
    if (active && payload && payload.length) {
        const [{payload:{departure, arrival, ...rest}}] = payload;
        !!onTooltipHover && typeof onTooltipHover==='function' && onTooltipHover(rest);
        return (
            <div style={{width:180, background:'#ffffffaa'}}>
                <Panel
                    headerLeft={getLan('hour')}
                    headerRight={`${numeral(label).format('00')}:00 - ${numeral(label).format('00')}:59`}
                >
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center'}}>
                            <GiHomeGarage color={blue[8]}  size={24} />
                            <Typography.Text style={{fontSize:'0.9rem', textAlign:'right'}}>Arrival</Typography.Text>
                        </div>
                        
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(arrival).format('0,000')}</Typography.Text>
                    </div>
                </Panel>
            </div>
        );
    }

    return null;
};


export const TooltipDirectionDep = ({ active, payload, label, onTooltipHover }) => {
    const [, getLan] = useLanguage();
    if (active && payload && payload.length) {
        const [{payload:{departure, arrival, ...rest}}] = payload;
        !!onTooltipHover && typeof onTooltipHover==='function' && onTooltipHover(rest);
        return (
            <div style={{width:180, background:'#ffffffaa'}}>
                <Panel
                    headerLeft={getLan('hour')}
                    headerRight={`${numeral(label).format('00')}:00 - ${numeral(label).format('00')}:59`}
                >
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center'}}>
                            <GiExitDoor color={green[8]}  size={24} />
                            <Typography.Text style={{fontSize:'0.9rem', textAlign:'right'}}>Departure</Typography.Text>
                        </div>
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(departure).format('0,000')}</Typography.Text>
                    </div>
                </Panel>
            </div>
        );
    }

    return null;
};


export const TooltipCountry = ({ active, payload, label, onTooltipHover }) => {
    const [, getLan] = useLanguage();
    if (active && payload && payload.length) {
        const [{payload:{male, female, ...rest}}] = payload;
        !!onTooltipHover && typeof onTooltipHover==='function' && onTooltipHover(rest);
        return (
            <div style={{width:180, background:'#ffffffaa'}}>
                <Panel
                    headerMiddle={<Flags country_code={label} withCountry flexGrow={1} />}
                >
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosMan color={blue[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(male).format('0,000')}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosWoman color={magenta[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(female).format('0,000')}</Typography.Text>
                    </div>
                </Panel>
            </div>
        );
    }

    return null;
};

export const TooltipAirport = ({ active, payload, label, onTooltipHover }) => {
    const [, getLan] = useLanguage();
    if (active && payload && payload.length) {
        const [{payload:{male, female, code, airport, vessel}}] = payload;
        // const [{payload:{male, female, ...rest}}] = payload;
        !!onTooltipHover && typeof onTooltipHover==='function' && onTooltipHover(airport||code);
        return (
            <div style={{width:240, background:'#ffffffaa'}}>
                <Panel
                    headerMiddle={
                        <Typography.Text style={{display:'flex', flexDirection:'row', gap:4}}>
                            {
                                !vessel?(
                                    <Fragment>
                                        <Flags country_code={airport?.country} />
                                        {airport?.code} - {airport?.kota}
                                    </Fragment>    
                                ):code
                            }
                        </Typography.Text>
                    }
                >
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosMan color={blue[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(male).format('0,000')}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosWoman color={magenta[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(female).format('0,000')}</Typography.Text>
                    </div>
                </Panel>
            </div>
        );
    }

    return null;
};

export const TooltipCarrier = ({ active, payload, label, onTooltipHover }) => {
    const [, getLan] = useLanguage();
    if (active && payload && payload.length) {
        const [{payload:{male, female, code, airline, vessel}}] = payload;
        !!onTooltipHover && typeof onTooltipHover==='function' && onTooltipHover(airline||code);
        return (
            <div style={{width:240, background:'#ffffffaa'}}>
                <Panel
                    headerMiddle={
                        <Typography.Text style={{display:'flex', flexDirection:'row', gap:4}}>
                            {
                                !vessel?(
                                    <Fragment>
                                        {airline?.code} - {airline?.name}
                                    </Fragment>    
                                ):code
                            }
                        </Typography.Text>
                    }
                >
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosMan color={blue[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(male).format('0,000')}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexDirection:"row", paddingInline:16, paddingBlock:8}}>
                        <IoIosWoman color={magenta[6]}  size={24} />
                        <Typography.Text style={{flexGrow:1, fontSize:'1.2rem', textAlign:'right'}}>{numeral(female).format('0,000')}</Typography.Text>
                    </div>
                </Panel>
            </div>
        );
    }

    return null;
};