import React, { useMemo } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { components } from "react-select";
import NotFoundPage from "./NotFoundPage";

export default function SubDetailPage({
  basePath = "",
  subComponents = {},
  keyComponents = "",
}) {
  const {pathname, state} = useLocation()


  const memoizeStateLocation = useMemo(()=>{
    return state
  }, [pathname, state])



  const ComponentsRender = subComponents[`${keyComponents}`];
  return (
    // <BrowserRouter>
    <div className="my-2">
      <Switch>
        <Route
          path={`${basePath}`}
          exact
          render={(props) => {
            return !!ComponentsRender ? <ComponentsRender stateLocation={memoizeStateLocation} /> : <NotFoundPage />;
          }}
        />
      </Switch>
     </div>
    //  </BrowserRouter>
  );
}
