import { Typography } from 'antd';
import React from 'react';
import { GiMailbox } from 'react-icons/gi';
import { useFetch } from '../../../../../hooks/useFetch';
import Lists from '../lists/lists';
import Panel from '../panel/panel';
import Telex from './modal/telex_items';
export default ({ data: _id, telexes, is_arrival, is_international }) => {
    const url = _id && `api/v1/flight_schedule${is_international ? (is_arrival ? '/international/arrival' : '/international/departure') : '/domestic'}`;
    const [data] = useFetch(url && `${url}/telexes/${_id}`);
    return (
        <Panel
            headerLeft={<GiMailbox size={24} />}
            headerRight={<Typography.Text strong>Telex</Typography.Text>}
        >
            <div style={{ height: 360 }}>
                <Lists
                    data={data|| telexes || []}
                    noLimit
                    filterValues={['origin', 'destination', 'subSmi']}
                    renderItem={(row, idx) => (<Telex telex_data={row} key={idx} />)}
                />
            </div>
        </Panel>
    )
}