import { Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useMemo } from 'react';
import { IoIosPerson, IoMdPeople } from 'react-icons/io';
import Flags from '../../../../../../components/flags';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import Flight_schedule from '../../category/flight_schedule';
import FlightHistory from '../../category/citizen_history_flight_inter';
import VesselHistory from '../../category/citizen_history_vessel_inter';
import Pnr from '../../category/pnr';
import Row from '../../category/row';
import Telex from '../../category/telex';
import Panel from '../../panel/panel';
import AccompanyWith from '../../category/accompany_with';
import Originator from '../../category/originator';
import { Link } from 'react-router-dom';
import Remarks from '../../category/remarks';
import WatchFlag from '../../category/watchflag';

export default ({ data }) => {
    const { passenger_name, _id,
        passenger_type, dob, telexes, citizen_id, status, age, pnr_id, origin_id, dest_id, flight_schedule_id, ssr,
        gender, doc_number, doc_country, doc_nationality, doc_type, other_doc_type, other_doc_number, doc_expiry,
        other_expiry: other_doc_expiry, other_issuing_country: other_doc_country, watch_flag, api_message,  } = data;
    const [, getLan] = useLanguage();
    const isWNA=useMemo(()=>{
        return ['ID', 'IDN'].indexOf(`${doc_nationality}`.toUpperCase())<0;
    }, [doc_nationality]);
    const isArrival=useMemo(()=>{
        return flight_schedule_id?.is_arrival
    }, [flight_schedule_id]);
    const returnTicket=useMemo(()=>{
        if(!(isWNA && isArrival ))return [];
        if(!pnr_id)return [];
        const {itinerary} = pnr_id
        if(!itinerary)return [];
        const result=[];
        let found=false;
        for (let iii = 0; iii < itinerary.length; iii++) {
            const {origin, dest, ...rest} = itinerary[iii];
            if(!found){
                if(origin===flight_schedule_id?.origin && dest===flight_schedule_id?.dest){
                    found=true;
                }
                continue;
            }
            result.push({origin, dest, ...rest});
        }
        return result;
    }, [isWNA, isArrival, pnr_id, flight_schedule_id])
    return data && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 8, padding: 16, flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, flexGrow: 1, }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, width: '50%' }}>
                        <Panel
                            headerLeft={<IoIosPerson size={24} />}
                            headerRight={<Typography.Text>{getLan('passengers')}</Typography.Text>}
                        >
                            <Row name={'passenger_type'} value={passenger_type} />
                            <Row name={'passenger_name'} value={
                                citizen_id && (<Link to={`/passengers/citizen/detail/${citizen_id?._id}`} >{passenger_name}</Link>)
                                ||
                                <Typography.Text title='No data citizen' >{passenger_name}</Typography.Text>
                            } />
                            <Row name={'origin'} value={
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                                    <Flags country_code={origin_id?.country} />
                                    <Typography.Text>
                                        {origin_id?.code} - {origin_id?.kota}
                                    </Typography.Text>
                                </div>
                            } />
                            <Row name={'dest'} value={
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                                    <Flags country_code={dest_id?.country} />
                                    <Typography.Text>
                                        {dest_id?.code} - {dest_id?.kota}
                                    </Typography.Text>
                                </div>
                            } />
                            <Row name={'gender'} value={gender} />
                            <Row name={'nationality'} valueEl={() => doc_nationality && <Flags country_code={doc_nationality} withCountry />} />
                            <Row name={'dob'} value={dob && (moment(dob).format('DD-MMM-YYYY') + '(' + age + ')')} />
                            <Row name={'passport'} value={
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                    <Typography.Text style={{ width: '33%', paddingInline: 4 }}>
                                        {doc_number}
                                    </Typography.Text>
                                    <Typography.Text style={{ width: '33%', paddingInline: 4 }}>
                                        {doc_expiry && moment(doc_expiry).format('DD-MMM-YYYY')}
                                    </Typography.Text>
                                    {doc_country && <Flags country_code={doc_country} withCountry countryFormat={"alpha3"} width={'33%'} />}

                                </div>
                            } />
                            {
                                !!other_doc_type && <Row name={'visa'} value={
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <Typography.Text style={{ width: '33%', paddingInline: 4 }}>
                                            {other_doc_number}
                                        </Typography.Text>
                                        <Typography.Text style={{ width: '33%', paddingInline: 4 }}>
                                            {other_doc_expiry && moment(other_doc_expiry).format('DD-MMM-YYYY')}
                                        </Typography.Text>
                                        {other_doc_country && <Flags country_code={other_doc_country} withCountry countryFormat={"alpha3"} width={'33%'} />}
                                    </div>
                                } />
                            }
                            {/* <Row name={'doc_number'} value={doc_number} /> */}
                            {/* <Row name={'doc_country'} valueEl={() => doc_country && <Flags country_code={doc_country} withCountry />} /> */}
                            <Row name={'status'} value={status} valueStyle={{textTransform:"capitalize"}} />
                            {
                                isWNA && isArrival && (
                                    <Row name={"next_flight"} value={
                                        
                                        <Fragment>
                                            {
                                                returnTicket.length>0 && (
                                                    <ul>
                                                        {
                                                            returnTicket.map(({origin, dest, airline, std}, idx)=>{
                                                                return <li key={idx}>{airline} : {origin} {dest} @{moment.unix(Math.ceil(std/1000)).format('DD-MMM-YYYY')}</li>
                                                            })
                                                        }
                                                    </ul>    
                                                ) || '-'
                                            }
                                            
                                        </Fragment>
                                    } />
                                )
                            }
                            <Row name={'watchlist'} last value={<WatchFlag flag={watch_flag} row={data} message={api_message} />} />
                        </Panel>
                        {!!ssr?.other_ssr && <Remarks data={ssr?.other_ssr} />}
                        {pnr_id && <Pnr data={pnr_id} />}
                        {
                            pnr_id?.originator && <Originator originator={pnr_id.originator} />
                        }
                        {citizen_id && <FlightHistory _id={citizen_id?._id} />}
                        {citizen_id && <VesselHistory _id={citizen_id?._id} />}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, width: '50%' }}>
                        {flight_schedule_id && <Flight_schedule flight_schedule_id={flight_schedule_id} />}
                        <Telex telexes={telexes || []} width="100%" />
                        {pnr_id && <AccompanyWith pnr_id={pnr_id._id} pax_id={_id} />}
                    </div>
                </div>
            </div>
        </div>
    )
}