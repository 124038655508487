
import { BrowserRouter, Route, Redirect } from "react-router-dom"
import { CacheRoute } from 'react-router-cache-route'
import Dashboard from './dashboard';
import Interactive from './interactive';
import AppsV2 from './appsv2'
import ExecutiveSummary from './executive_summary';
import { useState, useEffect } from 'react';
import InteractiveMobile from "./interactive_mobile";
import Geojs from "./maps";
import Documents from './document'

export default () => {
    const MAX_RES_MOBILE = 1200

    const [sizeWindow, setSizeWindow] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            setSizeWindow(window.innerWidth)
        })
    }, [window.innerWidth])

    return (
        <BrowserRouter>
            <Route path="/" exact>
                {sizeWindow < MAX_RES_MOBILE ? <Redirect to="/m" /> : <Redirect to="/apps" />}
            </Route>
            <CacheRoute path='/apps'>
                {sizeWindow < MAX_RES_MOBILE ? <Redirect to="/m" /> : <Interactive />}
            </CacheRoute>
            <CacheRoute path='/m'>
                {sizeWindow < MAX_RES_MOBILE ? <InteractiveMobile /> : <Redirect to="/apps" />}
            </CacheRoute>
            <Route path='/appsv2'>
                <AppsV2 />
            </Route>
            <Route path='/dashboard'>
                <Dashboard />
            </Route>
            <Route path='/bod/dashboard'>
                <ExecutiveSummary />
            </Route>
            <Route path='/maps'>
                <Geojs />
            </Route>
            <Route path="/docs">
                <Documents />
            </Route>
        </BrowserRouter>
    )
}