import { Button, Form, Pagination, Radio } from 'antd';
import Input from 'antd/lib/input/Input';
import React from 'react';
import { useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import { RiSearch2Line } from 'react-icons/ri';
import { useLanguage } from '../../../../hooks/useLanguage';
import Page from '../components/pages/page';
import {SearchOutlined} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { GetAPI, PostAPI } from '../../../../redux';
import DataContainer from './data';
import Scrollbars from 'react-custom-scrollbars';
import CFG from '../../../../config/env';
const {withDomestic} = CFG;
export default () => {
    const [,getLan]=useLanguage();
    const [model, setModel]=useState({database:'pax', name:''});
    const [database, setDatabase] = useState('pax');
    const [page, setPage]=useState(1);
    const [total, setTotal]=useState(0);
    const [data, setData] = useState([]);
    const dispatch=useDispatch();
    const query=(val, pg)=>{
        const newModel={...val, database, page:pg};
        // setPage(1);
        dispatch(PostAPI({url:'api/v1/search/v2', data:newModel})).then((payload)=>{
            if(!!payload?.payload){
                const dt=payload.payload;
                setData(dt.data || []);
                setTotal(dt.total);
                setModel({...newModel});
            }
        })
    }
    return (
        <Page
            bgColor={'#ffffff55'}
            title={'search'}
            icon={RiSearch2Line}
            breadcrumbs={[
                {
                    href:'/home',
                    icon:<GiHouse color='white' />,
                    item:'home'
                },
                {
                    item:'search'
                }
            ]}
        >
            <div style={{display:'flex', flexDirection:'column', rowGap:8, paddingInline:32, paddingTop:16}}>
                <Form
                    layout='horizontal'
                    initialValues={model}
                    size='small'
                    onFinish={(val)=>{
                        // const newModel={...val, database, page:1};
                        setPage(1);
                        query(val, 1);
                        // dispatch(PostAPI({url:'api/v1/search', data:newModel})).then((payload)=>{
                        //     if(!!payload?.payload){
                        //         const dt=payload.payload;
                        //         setData(dt.data || []);
                        //         setTotal(dt.total);
                        //         setModel({...newModel});
                        //     }
                        // })
                    }}
                >
                    <div style={{display:'flex', flexDirection:'row', gap:32, alignItems:'center', justifyContent:'center'}}> 
                        <Form.Item
                            name={'name'}
                            style={{width:'80%'}}
                        >
                            <Input autoFocus autoCapitalize='uppercase' placeholder='Search Name or Flight Number' />
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Form.Item>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', gap:32, alignItems:'center', justifyContent:'center'}}> 
                        <Form.Item name={'database'}>
                            <Radio.Group onChange={(e)=>setDatabase(e.target.value)}>
                                <Radio value={'pax'}>{getLan('passenger_or_passport')}</Radio>
                                { !!withDomestic && <Radio value={'domestic'}>{getLan('domestic_pax')}</Radio>}
                                <Radio value={'flight_number'}>{getLan('flight_number')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', gap:8, justifyContent:'flex-end', margin:8}}>
                        <Pagination 
                            total={total}
                            showTotal={tot=>`Total ${tot}`}
                            defaultPageSize={30}
                            current={page}
                            onChange={pg=>{
                                setPage(pg);
                                query(model, pg);
                            }}
                        />
                    </div>
                </Form>
            </div>
            <div style={{display:'flex', flexDirection:'column', flexGrow:1, 
                justifyContent:'center', alignItems:'center', border:'1px solid gray', 
                marginInline:32, marginBottom:16}}>
                <Scrollbars>
                    <DataContainer 
                        data={data}
                        database={model.database}
                    />
                </Scrollbars>
            </div>

        </Page>
    )
}