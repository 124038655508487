import React from 'react';
import {  GiHouse } from 'react-icons/gi';
import {  IoIosPerson } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/vessel/passenger';
export default () => {
    const {id} = useParams();
    
    const Icon=IoIosPerson;
    return (
        <Detail 
            title={`vessel_passenger`} 
            icon={Icon} 
            id={id}
            url={`api/v1/vessel/passenger/detail`}
            getBreadcumbValue={({passenger_name})=>passenger_name}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'vessel'
                    }
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{id}}
        />
    )
}