import { Card } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import RowList from "../../../components/RowList";

export default function Ssr() {
  const { state } = useLocation();
  console.log(state);

  const isObjectData = (data) => {
    return (
      <ul>
        {typeof data === "object" ? (
          Object.keys(data).map((item, idx) => <li key={idx}>{item} : {data[item]}</li>)
        ) : (
          <li>{data}</li>
        )}
      </ul>
    );
  };

  return (
    <Card>
      {Object.keys(state || {}).map((property, idx) => (
        <RowList
          key={idx}
          linkTo={"#"}
          title={property?.replace("_", " ").toUpperCase()}
          content={
            <div>
              {isObjectData(state[property])}
              {/* {typeof state[property] === "object" ? (
                <div>
                  <ul>
                    {Object.keys(state[property] || {}).map((sub, idx1) => (
                      <li key={idx1}>{typeof sub === "object" ?<div>
                        {sub?.map((item, index)=> <div key={index}>{item}</div>)}
                      </div> : <div>{sub}</div> }</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>{state[property]}</div>
              )} */}
            </div>
          }
          dividerBottom={idx + 1 !== Object.keys(state).length}
        />
      ))}
    </Card>
  );
}
