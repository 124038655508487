import XLS from 'exceljs';
import {saveAs} from 'file-saver';
import moment from 'moment';

/**
 *  
 * @param {XLS.Workbook} wb 
 * @param {String} airline 
 * @returns {XLS.Worksheet}
 */
const getSheet=(wb, airline)=>{
    let sheet=wb.getWorksheet(airline);
    if(!sheet){
        sheet = wb.addWorksheet(airline);
        sheet.getCell('1', '1').value="Airline";
        sheet.getCell('1', '2').value="Flight Number";
        sheet.getCell('1', '3').value="ACReg";
        sheet.getCell('1', '4').value="Origin";
        sheet.getCell('1', '5').value="Dest";
        sheet.getCell('1', '6').value="Time";
        sheet.getCell('1', '7').value="APIS";
        sheet.getCell('1', '8').value="APIS CREW";
        sheet.getCell('1', '9').value="PNR72";
        sheet.getCell('1', '10').value="PNR24";
        sheet.getCell('1', '11').value="PNR2";
        sheet.getCell('1', '12').value="PNR1";
    }
    return sheet;
}

const getUtcTimeFormUnix=(unixTime)=>{
    if(!unixTime)return '';
    return moment.unix(unixTime).utc(false).format('DD-MMM HH:mm');
}

/**
 * 
 * @param {XLS.Workbook} wb 
 * @param {Array} records 
 * @param {String} direction 
 */
const ProcessExcel=(wb, records, direction)=>{
    const lastRow={};
    const rows=records.sort((a, b)=>{        
        return direction==='departure'?(a.unixStd - b.unixStd):(a.unixSta - b.unixSta);
    })

    for (let iii = 0; iii < rows.length; iii++) {
        const {airline_id, timed, flight_number, flight_date, arrival_date, unixStd, unixSta, unixAtd, unixAta, std, sta, origin, dest, acreg} = rows[iii];
        const name=direction==='departure'?origin : dest;
        const sheet=getSheet(wb, name || "XXX");
        const last=lastRow[name] || 1;
        sheet.getCell(last+1, '1').value=airline_id?.name;
        sheet.getCell(last+1, '2').value=flight_number;
        sheet.getCell(last+1, '3').value=acreg;
        sheet.getCell(last+1, '4').value=origin;
        sheet.getCell(last+1, '5').value=dest;
        sheet.getCell(last+1, '6').value=direction==='departure'?flight_date+" "+std:arrival_date+' '+sta;
        sheet.getCell(last+1, '7').value=!!timed?.PAXLST?"OK":"-";
        sheet.getCell(last+1, '8').value=!!timed?.CRWLST?"OK":"-";
        sheet.getCell(last+1, '9').value=!!timed?.PNR_72?"OK":"-";
        sheet.getCell(last+1, '10').value=!!timed?.PNR_24?"OK":"-";
        sheet.getCell(last+1, '11').value=!!timed?.PNR_2?"OK":"-";
        sheet.getCell(last+1, '12').value=!!timed?.PNR_1?"OK":"-";
        lastRow[name]=last+1;
    }
}

/**
 *  
 * @param {XLS.Workbook} wb 
 * @param {String} airline 
 * @returns {XLS.Worksheet}
 */
const getSheetPax=(wb, airline)=>{
    let sheet=wb.getWorksheet(airline);
    if(!sheet){
        sheet = wb.addWorksheet(airline);
        sheet.getCell('1', '1').value="Name";
        sheet.getCell('1', '2').value="Operator/Agent";
        sheet.getCell('1', '3').value="Origin";
        sheet.getCell('1', '4').value="Dest";
        sheet.getCell('1', '5').value="Time";
        sheet.getCell('1', '6').value="Source";
        sheet.getCell('1', '7').value="WNI";
        sheet.getCell('1', '8').value="WNA";        
    }
    return sheet;
}

/**
 * 
 * @param {XLS.Workbook} wb 
 * @param {Array} records 
 * @param {String} direction 
 */
const ProcessPaxExcel=(wb, records, direction)=>{
    const lastRow={};
    for (let iii = 0; iii < records.length; iii++) {
        const {ferry_key, source, operator_id, vessel_name, attr, std, sta, origin:org, origin_id, destination, destination_id} = records[iii];
        const dest=source==='LNSW'?destination_id?.name:destination;
        const origin=source==='LNSW'?origin_id?.name:org;
        const name=direction==='departure'?origin : dest;
        const sheet=getSheetPax(wb, name);
        const last=lastRow[name] || 1;
        sheet.getCell(last+1, '1').value= source==='LNSW'?vessel_name:ferry_key;
        sheet.getCell(last+1, '2').value=operator_id;
        sheet.getCell(last+1, '3').value=origin;
        sheet.getCell(last+1, '4').value=dest;
        sheet.getCell(last+1, '5').value=direction.toLowerCase()==='arrival'? sta : std;
        sheet.getCell(last+1, '6').value=source?source:'VesselXml';
        sheet.getCell(last+1, '7').value=attr.wni;
        sheet.getCell(last+1, '8').value=attr.wna;
        lastRow[name]=last+1;
    }
}

export const DownloadFlightRecords=async(records, direction)=>{
    const wb=new XLS.Workbook();
    wb.title="PNRAPIS_Message_Monitoring_"+direction;
    wb.subject="Message Monitoring";
    wb.creator="ESI - EDIFly Solusi Indonesia ~~~HK~~~"
    wb.created=new Date();
    ProcessExcel(wb, records, direction)
    const buff=await wb.xlsx.writeBuffer();
    saveAs(new Blob([buff], {type:"application/octet-stream"}), 'PNRAPIS_Message_Monitoring_'+direction+'.xlsx');
    return true;
}

export const DownloadPaxRecords=async(records, direction)=>{
    const wb=new XLS.Workbook();
    wb.title="Passengers_Report_"+direction;
    wb.subject="Passengers Report";
    wb.creator="ESI - EDIFly Solusi Indonesia ~~~HK~~~"
    wb.created=new Date();
    ProcessPaxExcel(wb, records, direction)
    const buff=await wb.xlsx.writeBuffer();
    saveAs(new Blob([buff], {type:"application/octet-stream"}), 'Passengers_Report_'+direction+'.xlsx');
    return true;
}