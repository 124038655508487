import { Breadcrumb, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useAos } from '../../../../../hooks/useAos';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { getLanguage } from '../../../../../languages/utils';

export default ({children, title, icon, breadcrumbs, bgColor}) => {
    const {language} = useSelector(state=>state.apps);
    const [Lang] = useLanguage(language);
    const aos=useAos();
    const Icon=icon;
    return (
        <div style={{display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>                        
            <div data-aos={aos} style={{display:'flex', flexDirection:'row', width:'100%', paddingLeft:12, alignItems:'center', gap:16}}>
                <Icon size={32} color={'white'} />
                <Typography.Text style={{color:'white', fontSize:'1.5rem'}}>{getLanguage(title, Lang)}</Typography.Text>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'end', flexGrow:1}}>
                        <Breadcrumb separator="/" style={{color:'white', justifySelf:'end', paddingInline:32, paddingTop:8}}>
                        {
                            breadcrumbs && Array.isArray(breadcrumbs) && breadcrumbs.length>0 && breadcrumbs.map((br, idx)=>(
                                <Breadcrumb.Item key={idx}>
                                    {
                                        !!br.href && (
                                            <Link to={br.href}>
                                                {
                                                    br.icon
                                                }
                                                <Typography.Text style={{color:'white', marginInline:4}} >{getLanguage(br.item, Lang)}</Typography.Text>
                                            </Link>
                                        ) || (
                                            <React.Fragment>
                                                {
                                                    br.icon
                                                }
                                                <Typography.Text style={{color:'white', marginInline:4}} >{getLanguage(br.item, Lang)}</Typography.Text>
                                            </React.Fragment>
                                        )
                                    }
                                </Breadcrumb.Item>
                            ))
                        }
                    </Breadcrumb>
                </div>
            </div>
            <div style={{flexGrow:1, display:'flex', flexDirection:'column', paddingInline:4, background:bgColor}}>
                {children}
            </div>
        </div>
    )
}