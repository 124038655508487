import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import {GiJourney } from 'react-icons/gi';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import './table.css';
export default ({itinerary,})=>{
    const [,getLan] = useLanguage();
    return (
        <Panel
            headerLeft={<GiJourney size={24} color="black"/>}
            headerRight={<Typography.Text>{getLan('itinerary')}</Typography.Text>}
        >
            <table className='my-table'>
                <thead>
                    <tr>
                        <th>{getLan('pnr')}</th>
                        <th>{getLan('airline')}</th>
                        <th>{getLan('origin')}</th>
                        <th>{getLan('dest')}</th>
                        <th>{getLan('std')}</th>
                        <th>{getLan('sta')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !!itinerary && Array.isArray(itinerary) && itinerary.map((iti, idx)=>{
                            const {airline, origin, dest, sta, std, passenger_record_locator} = iti;
                            return (
                                <tr key={idx}>
                                    <td>{passenger_record_locator?.pnr}</td>
                                    <td>{airline}</td>
                                    <td>{origin}</td>
                                    <td>{dest}</td>
                                    <td>{moment.unix(Math.floor(std/1000)).utc().format('DD-MMM-YYYY HH:mm')}</td>
                                    <td>{moment.unix(Math.floor(sta/1000)).utc().format('DD-MMM-YYYY HH:mm')}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Panel>
    )
}