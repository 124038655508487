import { blue, cyan, geekblue, gold, magenta, red } from "@ant-design/colors";
import { Avatar, Card, Col, DatePicker, Row, Typography } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { IoWarning } from "react-icons/io5";
import {
  MdEmojiPeople,
  MdFlightLand,
  MdFlightTakeoff,
  MdPeople,
} from "react-icons/md";
import { RiShip2Fill, RiShipFill } from "react-icons/ri";
import { useFetch } from "../../../../hooks/useFetch";
import RowList from "../../components/RowList";
import CardSummary from "./CardSummary";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

export default function Home() {
  const [date, setDate] = useState(moment());
  const [timestamp, setTimestamp] = useState(moment(new Date()).unix());
  const [filter, setFilter] = useState({});

  const formatedDate = useMemo(() => {
    return moment(date).format("DD-MMM-YYYY");
  }, [date]);

  const defUrl = useMemo(() => {
    return `api/v1/summary/landing/${formatedDate}?filter=${JSON.stringify(
      filter
    )}&${timestamp}`;
  }, [timestamp, formatedDate]);

  const [summLanding, setSummLanding] = useFetch(defUrl);
  const { detail_cekal, cekal, flights, hourly, passengers, vessels } =
    summLanding || {
      detail_cekal: [],
      cekal: 0,
      flights: [],
      hourly: [],
      passengers: [],
      vessels: [],
    };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const dataChart = {
    labels: hourly?.map((item, idx) => `${item.hour} h`),
    datasets: [
      {
        fill:true,
        label: "Arrival",
        data: hourly?.map((item) => item.arrival),
        borderColor: geekblue[4],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        fill:true,
        label: "Departure",
        data: hourly?.map((item) => item.departure),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <Fragment>
      <div className="p-4">
        <Row className="home-filter" style={{ justifyContent: "flex-end" }}>
          <Col xs={10} sm={8} md={6} lg={6}>
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MMM-YYYY"}
              value={date}
              onChange={(val, valStr) => {
                const formatedDate = moment(val).format("DD-MMM-YYYY");
                setDate(val);
              }}
              size="large"
            />
          </Col>
        </Row>
        <div className="my-4">
          <Row className="home-summary-card" gutter={[8, 8]}>
           
            <Col xs={12} sm={6} md={6} lg={6}>
              <CardSummary
                href={"/flight/schedule/arrival"}
                color={cyan}
                icon={<MdFlightLand size={"1.4rem"} color={cyan[7]} />}
                content={flights?.arrival || 0}
                subTitle={"Arrival "}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <CardSummary
                href={"/flight/schedule/departure"}
                color={cyan}
                icon={<MdFlightTakeoff size={"1.4rem"} color={cyan[7]} />}
                content={flights?.departure || 0}
                subTitle={"Departure "}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <CardSummary
                href={"/vessel/arrival"}
                color={blue}
                icon={<RiShipFill size={"1.4rem"} color={blue[7]} />}
                content={vessels?.arrival || 0}
                subTitle={"Arrival "}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <CardSummary
                href={"/vessel/departure"}
                color={blue}
                icon={<RiShip2Fill size={"1.4rem"} color={blue[7]} />}
                content={vessels?.departure || 0}
                subTitle={"Departure "}
              />
            </Col>
            
            <Col xs={12} sm={8} md={8} lg={8}>
              <CardSummary
                href={"/"}
                color={gold}
                icon={<MdPeople size={"1.4rem"} color={gold[7]} />}
                content={passengers?.arrival || 0}
                subTitle={" In"}
              />
            </Col>
            <Col xs={12} sm={8} md={8} lg={8}>
              <CardSummary
                href={"/"}
                color={gold}
                icon={<MdEmojiPeople size={"1.4rem"} color={gold[7]} />}
                content={passengers?.departure || 0}
                subTitle={" Out"}
              />
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
              <CardSummary
                href={"/passengers/matched"}
                color={red}
                icon={<IoWarning size={"1.4rem"} color={red[7]} />}
                content={cekal}
                subTitle={"Matched"}
              />
            </Col>
            
          </Row>
          <Row className="my-4" gutter={[8, 8]}>
            <Col xs={24} sm={24} md={14} lg={14}>
              <Card style={{height:'100%'}}>
                <Line data={dataChart} options={options} />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}>
              <Card title={"Matched Detail"} style={{height:'100%'}}>
                {detail_cekal?.map((item, idx) => (
                  <RowList key={idx} title={item._id?.command} content={item?.jml} />
                ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ;
    </Fragment>
  );
}
