import { Button, Form, Input } from 'antd';
import React from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import Panel from '../components/panel/panel';
import {SaveOutlined, UserAddOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default ({onSearchCallback}) =>{
    const [,getLan]=useLanguage();
    const history=useHistory();
    return (
        <div style={{width:320}}>

            <Panel 
                headerLeft={getLan("search")}
                
            >
                <div style={{padding:16}}>
                    <Form
                        autoComplete='off'

                        onFinish={val=>{
                            onSearchCallback && typeof onSearchCallback==='function' && onSearchCallback(val);
                        }}
                    >
                        <Form.Item label={getLan("name")} name={'name'}>
                            <Input autoFocus />
                        </Form.Item>
                        <div style={{display:'flex', flexDirection:'row-reverse', gap:8, }}>
                            <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                                Search
                            </Button>
                            <Button icon={<UserAddOutlined />} onClick={()=>{
                                history.push('/passengers/rules/create');
                            }} >
                                Add
                            </Button>
                        </div>
                    </Form>
                </div>
            </Panel>
        </div>
    )
}