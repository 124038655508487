import React from 'react';
import { GiHouse } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/passengers/detail_domestic';
import { IoMdPeople } from 'react-icons/io';
export default () => {
    const {id} = useParams();
    
    return (
        <Detail 
            title={`passengers`} 
            icon={IoMdPeople} 
            id={id}
            url={`api/v1/passengers/domestic/detail`}
            getBreadcumbValue={({passenger_name})=>passenger_name}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'passengers'
                    },
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{ id}}
        />
    )
}