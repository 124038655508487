import { Avatar, Card, Typography } from "antd";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Flags from "../../../../../components/flags";
import RowList from "../../../components/RowList";
import { getLogoAirline } from "../../../library";

export default function Airline({stateLocation}) {
  const { name, country, code, icao } = stateLocation || { name:'', country:'', code:'', icao:'' };

  return (
    <Card>
      <RowList
        title={"Name"}
        content={
          <div className="rowslist-inline-flex">
            <Avatar
              alt={code}
              size="default"
              style={{ alignSelf: "center" }}
              className={"avatar-contain"}
              src={`${getLogoAirline(code)}`}
            />
            <Typography.Title level={5}>{name}</Typography.Title>
          </div>
        }
        dividerBottom
      />
      <RowList title={"IATA Code"} content={code} dividerBottom />
      <RowList title={"ICAO Code"} content={icao} dividerBottom />
      <RowList
        title={"Country"}
        content={
          <div className="rowslist-inline-flex">
            <Flags country_code={country} withCountry countryWidth />
          </div>
        }
        // dividerBottom
      />
    </Card>
  );
}
