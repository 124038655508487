import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useLanguage } from '../../../../../hooks/useLanguage';
export default ({onSearchCallback, initValue}) => {
    const [state, setState]=useState(initValue);
    const [,getLang] = useLanguage();
    return (
        <div style={{padding:12,  width:320,background:'#ffffff88', display:'flex', flexDirection:'column', overflow:'auto'}}>
            <Typography.Text strong>{getLang('search')}</Typography.Text>
                <Form 
                    layout='vertical'
                    initialValues={state}
                    onFinish={onSearchCallback}
                    autoComplete="off"
                    
                >
                    <Form.Item
                        style={{marginBottom:16}}
                        label={getLang('smi')}
                        name="subSmi"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom:16}}
                        label={getLang('status')}
                        name="status"
                    >
                        <Select>
                            <Select.Option value={10}>ALL</Select.Option>
                            <Select.Option value={0}>Pending</Select.Option>
                            <Select.Option value={1}>Success</Select.Option>
                            <Select.Option value={2}>Error</Select.Option>
                            <Select.Option value={3}>Ignored</Select.Option>
                            <Select.Option value={4}>Processing</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button block type='primary' htmlType='submit'>Search</Button>
                </Form>
        </div>    
    )
}