import React from 'react'
import FormReport from '../../../../../components/pages/FormReport'
import {useFetch} from '../../../../../hooks/useFetch'
import {Typography} from 'antd'
export default () => { 
    return (
        <FormReport 
            title={'Report'}
            repType={'daily'}
            url="api/v1/report/outgoing/details"
            FetchComponent={useFetch}
            // onDataChange={data=>setData(data)}
            // onFilterChange={f=>setFilter(f)}
            printHeader={
                <>
                    <Typography.Title level={3}>Ini HEader</Typography.Title>
                    Filter Empu = SN
                </>
            }

            printFooter={
                <>
                    <Typography.Title level={3}>Ini Footer Dan Tanda tangan</Typography.Title>
                </>
            }
        />
    )
}
