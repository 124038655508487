import { Typography } from 'antd';
import React from 'react';
import Flags from '../../../../../components/flags';
import Row from './row';
import ctyData from 'country-data';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
export default ({originator, width}) => {
    const {country, system_location, travel_agent_iata_number, origin, currency, language} = originator;
    const countryName= (country && ctyData.countries[country].name) || ''
    const [,getLan] = useLanguage();
    return (
        <Panel
            headerLeft={<Typography.Text>{getLan('originator')}</Typography.Text>}
            headerRight={
                <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'baseline'}}>
                    <Flags country_code={country} />
                    <Typography.Text>{countryName}</Typography.Text>
                </div>
            }
        >
            <Row name={'origin'} value={origin?.code} />
            <Row name={'system_location'} value={`${system_location?.airline}/${system_location?.locationCode}`} />
            <Row name={'travel_agent_iata_number'} value={travel_agent_iata_number} />
            <Row name={'currency'} value={currency || '-'} />
            <Row last name={'language'} value={language || '-'} />     
        </Panel>
    )
}