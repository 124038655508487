import { Table, Typography } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { GiCalendar } from 'react-icons/gi';
import { useLanguage } from '../../../../../../../hooks/useLanguage';
import Panel from '../../../../components/panel/panel';
import Row from '../../../../components/category/row';
import '../../../../../../../components/pages/reports/component/table.css';
export default ({data})=>{
    const [,getLan] = useLanguage();
    
    return (
        <Panel 
            headerLeft={<GiCalendar />}
            headerRight={<Typography.Text>Status</Typography.Text>}            
        >
            <div style={{height:'100%', paddingInline:64, paddingBlock:32}}>

                <table className='table-0 big'>
                    <thead>
                        <th>{getLan('process_name')}</th>
                        <th>{getLan('status')}</th>
                        <th>{getLan('process_time')}</th>
                        <th>{getLan('last_active')}</th>
                    </thead>
                    <tbody>
                        {
                            !!data && Array.isArray(data) && data.map(({process_name, status, process_times, last_active}, idx)=>(
                                <tr>
                                    <td style={{textAlign:'center'}}>{process_name}</td>
                                    <td style={{textAlign:'center'}}>{status}</td>
                                    <td style={{textAlign:'center'}}>{process_times}</td>
                                    <td style={{textAlign:'center'}}>{moment.duration(moment(last_active).diff(moment())).humanize(true)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </Panel>
    )
}