import React, { useMemo } from 'react';

import Table from '../../lists/table';
import { ImMan, ImWoman, } from 'react-icons/im';
import {FaBaby} from 'react-icons/fa';
import { Typography } from 'antd';

export default ({data}) => {
    const [sortedData, prlExists]=useMemo(()=>{
        if(!data)return [];
        if(!Array.isArray(data))return [];
        const [exist]=data.filter(({smi})=>smi==='PRL');
        const sorted=data.sort((a, b)=>`${a.passenger_name}`.localeCompare(`${b.passenger_name}`));
        return [sorted, exist];
    },[data])
    const getSmi = (smi)=>{
        switch (smi) {
            case 'PNL':
            case 'ADL':
                if(prlExists)return "No-Show";
                return "Booked";
            case 'PRL':
                return 'Boarding'
            default:
                return '-';
        }
    }
    const renderGender=(gender, pax_type)=>{
        if(pax_type!=='IN'){
            switch (gender?.toLowerCase()) {
                case 'female':
                    return <ImWoman size={24} color="#ff6e87" />
                case 'male':
                    return <ImMan size={24} color="blue"/>
                default:
                    return <ImMan size={24} color="blue"/>
            }
        }
        return <FaBaby size={24} color="green" />
    }
    return (
        <Table
            data={sortedData}
            filterField={['passenger_name', 'doc_number', 'pnr']}
            url="/passengers/domestic/detail"
            columns={[
                {
                    title:'passenger_name',
                    dataIndex:'passenger_name',       
                    render:(val, rec)=>{
                        const {smi} = rec;
                        const status=getSmi(smi);
                        const textDecoration=status==='No-Show'?'line-through':'none';
                        return (<Typography.Text style={{textDecoration}}>{val}</Typography.Text>)
                    }
                },
                {
                    title:'pnr',
                    dataIndex:'pnr',
                },
                {
                    title:'gender',
                    dataIndex:'gender',
                    render:(val, rec)=>renderGender(val)
                },
                {
                    title:'origin',
                    dataIndex:'origin',
                },
                {
                    title:'dest',
                    dataIndex:'dest',
                },      
                {
                    title:'ticket_number',
                    dataIndex:'ticket_number',
                },
                {
                    title:'status',
                    dataIndex:'smi',
                    render:(val)=>getSmi(val)
                },   
            ]}
        />
    )
}   