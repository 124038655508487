import { Table, Typography } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { GiCalendar } from 'react-icons/gi';
import { useLanguage } from '../../../../../../../hooks/useLanguage';
import Panel from '../../../../components/panel/panel';
import Row from '../../../../components/category/row';
export default ({data})=>{
    const [,getLan] = useLanguage();
    
    return (
        <Panel 
            headerLeft={<GiCalendar />}
            headerRight={<Typography.Text>Last Parser</Typography.Text>}            
        >
            <Row name={'last_request'} value={!!data?.last_parser && moment.unix(data.last_parser).format('DD-MMM-YYYY HH:mm:ss')} />
            {
                !!data?.queue && Array.isArray(data.queue) && data.queue.map(({origin, total}, idx)=>(
                    <Row name={origin} value={`${total}`} key={idx} last={idx===data.queue.length-1} />
                ))
            }
        </Panel>
    )
}