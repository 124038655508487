import { blue, magenta } from "@ant-design/colors";
import {
  EllipsisOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Image,
  List,
  Menu,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Fragment } from "react";
import { MdPeopleOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PostAPI } from "../../../../../redux";

import BaseListPage from "../../../components/BaseListPage";
import RowList from "../../../components/RowList";
import ModalFilterWatch from "./ModalFilterWatch";
import { DonwloadTemplateWatchlist, UploadWatchlist } from "./xls_modal";
import ENV from "../../../../../config/env";
import ModalImages from "./ModalImages";
import { useFontSize } from "../../../../../hooks/useDimension";

const { ImageUrl } = ENV;
const imgUri = ImageUrl || "/images/";

export default function Watchlist() {
  const history = useHistory();
  const fontSize = useFontSize()
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    name: "",
    timestamp: moment().unix(),
  });
  const [dataModal, setDataModal] = useState(null);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const handleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const [isModalImages, setIsModalImages] = useState(false);
  const handleModalImages = (data) => {
    setDataModal(data);
    setIsModalImages(!isModalImages);
  };

  const OverlayMenu = ({ data }) => {
    const {_id} = data
    return (
      <Menu
        items={[
          {
            label: (
              <Button
                type="link"
                onClick={() =>
                  history.push(`/passengers/watchlist/edit/${_id}`)
                }
              >
                edit
              </Button>
            ),
            key: "edit_watchlist",
          },
        ]}
      />
    );
  };

  return (
    <Fragment>
      <BaseListPage
        paginationUrl={"api/v1/watchlist/pagination"}
        fixedPagination
        searchApiProps={search}
        FilterComponent={() => {
          return (
            <div style={{ padding: "16px 8px" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 8,
                }}
              >
                <DonwloadTemplateWatchlist />
                <UploadWatchlist
                  onFormSubmit={(rows) => {
                    const data = rows.map(({ dob, ...rest }) => ({
                      ...rest,
                      dob: moment(dob).format("DD-MMM-YYYY"),
                    }));
                    dispatch(
                      PostAPI({ data, url: "api/v1/watchlist/batch" })
                    ).then((r) => {
                      if (!!r.payload) {
                        setSearch({ ...search, timestamp: moment().unix() });
                      }
                    });
                  }}
                />
                <Button
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    history.push("/passengers/watchlist/create");
                  }}
                >
                  Add
                </Button>
              </div>
              <Divider className="my-2 " />
              <div className="mt-2 text-right">
                <Button
                  //   style={{ width: 42 }}
                  type="primary"
                  icon={<FilterOutlined />}
                  size={24}
                  onClick={handleModal}
                >
                  Filter
                </Button>
                {isModalFilter && (
                  <ModalFilterWatch
                    state={search}
                    setState={setSearch}
                    handleSearch={(value) => {
                      setSearch({
                        ...search,
                        ...value,
                        timestamp: moment().unix(),
                      });
                      setIsModalFilter(!isModalFilter);
                    }}
                    // title={direction}
                    open={isModalFilter}
                    handleModal={handleModal}
                  />
                )}
              </div>
            </div>
          );
        }}
        renderItem={(item) => {
          const {
            _id,
            name,
            createdBy,
            createdAt,
            notes,
            gender,
            nationality,
            dob,
            images,
            passport_number,
          } = item || {
            _id: "",
            name: "",
            createdBy: "",
            createdAt: "",
            notes: "",
            gender: "",
            nationality: "",
            dob: "",
            images: "",
            passport_number: "",
          };
          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: `${
                        gender?.toLowerCase() === "m" ||
                        gender?.toLowerCase() === "male"
                          ? blue[4]
                          : magenta[4]
                      }`,
                    }}
                    icon={<MdPeopleOutline />}
                  />
                }
                title={
                  <Row>
                    <Col xs={20} sm={20} md={22}>
                      <Typography.Title style={{fontSize:fontSize.md}}>
                        {name?.toUpperCase()}
                      </Typography.Title>
                    </Col>
                    <Col xs={4} sm={4} md={2} className="text-right" style={{display:"flex", justifyContent: "center"}}>
                      <div id="action-container">
                        <Dropdown
                          placement="bottomRight"
                          arrow={{ pointAtCenter: true }}
                          overlayStyle={{
                            position: "relative",
                            zIndex: 50,
                            width: "max-content",
                          }}
                          getPopupContainer={() =>
                            document.getElementById("action-container")
                          }
                          overlay={<OverlayMenu data={item} />}
                          trigger={["click"]}
                        >
                          <Button
                            type="link
                    "
                            icon={<EllipsisOutlined />}
                          />
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                }
                description={
                  <div>
                    <ul>
                      <li>Passport : {passport_number}</li>
                      <li>Dob : {dob}</li>
                      <li>Nationality : {nationality}</li>
                      <li>
                        Notes :{" "}
                        <div
                          style={{
                            borderRadius: 4,
                            padding: "4px 8px",
                            margin: "8px 0",
                            backgroundColor: "#f6f6f6",
                          }}
                        >
                          {notes?.toString()}
                        </div>
                      </li>
                    </ul>
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {!!images && !!images?.length && (
                        <Button
                          type="link"
                          onClick={() => handleModalImages(images)}
                        >
                          show image
                        </Button>
                      )}
                    </div>
                    {/* <Divider className="my-1" />
                    {createdBy?.username} -{" "}
                    {moment(createdAt).format("DD-MMM-YYYY HH:mm")} */}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      {isModalImages && (
        <ModalImages
          handleModal={handleModalImages}
          open={isModalImages}
          state={dataModal}
        />
      )}
    </Fragment>
  );
}
