import Page from '../../../../../components/pages/TablePage';
import React from 'react';
import { useParams } from 'react-router-dom';

export default () =>{
    const {direction} = useParams();
    const cols=[
        {
            selector:(row)=>row?.flight_number,
            name:"Flight Number"
        },
        {
            selector:(row)=>row?.flight_date,
            name:"Flight Date"
        },
        {
            selector:(row)=>row?.origin+ '' +row?.dest,
            name:"Route"
        },
        {
            selector:(row)=>row?.std,
            name:"STD"
        },
        {
            selector:(row)=>row?.sta,
            name:"STA"
        },
        {
            selector:({customs_messages})=>{
                if(!customs_messages)return 'NA';
                const tot=customs_messages.filter(({msgType})=>msgType==='PNRGOV');
                return `${tot.length} Messages`
            },
            name:"PNRGOV"
        },
        {
            selector:({customs_messages})=>{
                if(!customs_messages)return 'NA';
                const tot=customs_messages.filter(({msgType})=>msgType==='PAXLST');
                return `${tot.length} Messages`
            },
            name:"PAXLST"
        },
    ]
    return (
        <Page 
            title={'flight_schedule_'+direction}
            url={"api/v1/flight_schedule/international/"+direction}
            columns={cols}
        />
    )
}