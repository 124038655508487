import { grey } from "@ant-design/colors";
import { BarsOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Dropdown, Layout, Menu, Typography, Input, Button } from "antd";
import React, { useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { doLogout, setOpenKeys, setSelectedMenu } from "../../../redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useHistory } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import Languages from "../../../components/layouts/languages";
import { MdArrowBack, MdArrowBackIos, MdSearch } from "react-icons/md";

const {Search} = Input;


const { Header, Content, Footer, Sider } = Layout;

export default function DashboardLayout({ children, menus }) {
  const history = useHistory()
  const { selectedMenu, openKeys } = useSelector((state) => state.nav);
  const { userdata } = useSelector((state) => state.auth);
  // const [isSearchPage, setIsSearchPage] = useState(false)
  const dispatch = useDispatch();
  const [colaps, setColaps] = useState(true);
  const rootSubmenuKeys = useMemo(() => {
    return menus.map(({ key }) => key);
  }, [menus]);
  const renderThumb = ({ style }) => {
    return <div style={{ ...style, backgroundColor: "#eecccc6e" }} />;
  };

  const OverlayMenu = () => {
    return (
      <Menu
        items={[
          {
            label: (
              <div style={{ textAlign: "right" }}>
                <Typography.Title level={5}>
                  {userdata?.username}
                </Typography.Title>
                <Typography.Text>{userdata?.levelStr}</Typography.Text>
                <Divider className="my-1" />
              </div>
            ),
            key: "Profile",
          },
          {
            label: (
              <Link onClick={() => dispatch(doLogout())}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RiLogoutCircleRLine /> Logout
                </div>
              </Link>
            ),
            key: "Logout",
          },
        ]}
      />
    );
  };
  return (
    <Layout hasSider>
      <Sider
        collapsed={colaps}
        trigger={null}
        style={{
          height: "100vh",
          position: "fixed",
          top: 64,
          bottom: 0,
          left: 0,
          zIndex: 99999,
        }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        {/* <div className="logo" /> */}
        <Scrollbars renderThumbVertical={renderThumb}>
          <Menu
            style={{ padding: 8 }}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["4"]}
            items={menus || []}
            onClick={(e) => {
              dispatch(setSelectedMenu(e.key));
              setColaps(!colaps)
            }}
            openKeys={openKeys}
            inlineIndent={8}
            onOpenChange={(keys) => {
              const latestOpenKey = keys.find(
                (key) => openKeys.indexOf(key) === -1
              );
              if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                dispatch(setOpenKeys(keys));
              } else {
                dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
              }
            }}
          />
        </Scrollbars>
      </Sider>
      <Layout>
        <Header
            className="base-shadow"
          style={{
            padding: 4,
            backgroundColor: "white",
            position: "sticky",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div style={{ display: "inline-flex", gap: 4, alignItems: 'center' }}>
         
            
          {/* <Dropdown>
          </Dropdown> */}
          {colaps ? (
            <GiHamburgerMenu
              color={grey[4]}
              size={24}
              className="trigger"
              onClick={() => setColaps(!colaps)}
            />
          ) : (
            <GiHamburgerMenu
              color={grey[4]}
              size={32}

              className="trigger"
              onClick={() => setColaps(!colaps)}
            />
          )}
          {/* {isSearchPage && <Button icon={<MdArrowBackIos />} type='link' onClick={()=> history.push('/')} />} */}
          <Button icon={<MdSearch size={24}  color={grey[1]}/>} type="link" onClick={()=>{
            history.push('/search')
          }} />
          
          </div>
          <div style={{ display: "inline-flex", gap: 8, paddingRight: 16 }}>
            <Languages />
            <Dropdown
              placement="bottomLeft"
              overlay={OverlayMenu}
              trigger={["click"]}
            >
              <Avatar size={"large"} icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>

        <Content>
        <Scrollbars  style={{ minHeight: "calc(100vh - 64px)", width:'100%'}}>
          <div
            //   className="site-layout-background"
            style={{
              // padding: 4,
              margin: 0,
             
            }}
          >
            {children}
          </div>
        </Scrollbars>
        </Content>
      </Layout>
    </Layout>
  );
}
