import { Button, Divider, Input, Typography } from 'antd'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import {FaEdit} from 'react-icons/fa'
import {RiRefreshLine, RiAddCircleLine} from 'react-icons/ri'
import Table from 'react-data-table-component'
import { green, grey, yellow} from '@ant-design/colors'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {useFetch} from '../../hooks/useFetch'
import CONFIG from '../../config/env';
import Page from './Page'
const {Title} = CONFIG;

export default ({title, columns, actionPosition, url, editPath, createPath, HeaderComponent, otherAction, refresh, conditionalRows, 
    ExpandComponent, 
}) =>{
    const [qry, setQry]=useState({});
    const history=useHistory();
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)

    const [search, setSearch]=useState('');
    const [localRefresh, setLocalRefresh]=useState(moment().unix())
    const cols=useMemo(()=>{
        if(!!editPath){
            const action={
                selector:(row)=>{
                    return (
                        <Fragment>
                            <Button 
                                shape='circle'
                                icon={<FaEdit color={grey.primary} />}
                                type="link"
                                title='Edit Data'
                                onClick={()=>{
                                    history.push({pathname:editPath, state:{...row, isCreate:false}});
                                }}
                            /> 
                            {
                                !!otherAction && typeof otherAction === 'function' && otherAction(row)
                            }
                        </Fragment>
                    )
                },
                id:'_action',
                name:"Action",
                center:true,
                compact:true
            }
            return actionPosition==='first'?[action, ...columns]:[...columns, action];
        }
        else if (!!otherAction && typeof otherAction === 'function'){
            const action={
                selector:otherAction,
                id:'_action',
                name:"Action",
                center:true,
                compact:true
            }
            return actionPosition==='first'?[action, ...columns]:[...columns, action];

        }
        return columns;
    }, [columns, editPath, actionPosition, otherAction]);
    const uri = useMemo(()=>{
        const parentRefresh=parseInt(refresh) || 0;
        const r=localRefresh>parentRefresh?localRefresh:parentRefresh;
        return `${url}?page=${page}&perPage=${perPage}&search=${search}&search2=${JSON.stringify(qry)}&timestamp=${r}`;
    }, [url, refresh, localRefresh, qry, search, page, perPage])
    useEffect(()=>{
        document.title=`${Title} (${title})`;
    }, [])
    const [data, loading] = useFetch(uri);

    return (
        <Page title={title}>
            {
                !!HeaderComponent && <HeaderComponent onSubmitSearch={aQry=>setQry(aQry)} />
            }
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', marginBlock:16}}>
                {
                    !HeaderComponent && <Input.Search allowClear onSearch={(str)=>setSearch(str)} style={{width:240, paddingInline:8}} />
                }
                <Button 
                    icon={<RiRefreshLine size={20} color={yellow[7]} />}
                    shape={'round'}
                    style={{paddingInline:8}}
                    type="link"
                    onClick={()=>setLocalRefresh(moment().unix())}
                />
                {
                    !!createPath && <Button 
                        icon={<RiAddCircleLine size={20} color={green[7]} />}
                        style={{paddingInline:8}}
                        shape={'round'}
                        type="link"
                        onClick={()=>history.push({pathname:createPath, state:{isCreate:true}})}
                    />
                }
            </div>
            <Table 
                columns={cols}
                data={data?.data || []}
                noHeader
                dense
                striped
                persistTableHead
                progressPending={loading}
                pagination
                highlightOnHover
                expandableRowsComponent={ExpandComponent}
                expandableRows={!!ExpandComponent}
                expandOnRowClicked={!!ExpandComponent}                
                paginationServer={true}
                paginationTotalRows={data?.total || 0}
                onChangeRowsPerPage = {(pp, pg) => {
                    setPerPage(pp)
                    setPage(pg)
                }}
                onChangePage={(pg) => setPage(pg)}
                conditionalRowStyles={conditionalRows}
            />
        </Page>
    )
}