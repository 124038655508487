import { Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import {countries} from 'country-data';
import QRCode from 'react-qr-code'

export default ()=>{
    const {id}=useParams();
    const [data, loading] = useFetch(`/lnsw/clearence/${id}`);
    const [vessel, summary] = useMemo(()=>{
        if(!data)return [{}, {wna_crew:0, wni_crew:0, wna_pax:0, wni_pax:0}];
        const {vessel, summary} = data;
        const {is_arrival, destination_id, destination, origin_id, origin} = vessel;
        const direction=is_arrival?"ARRIVAL":"DEPARTURE";
        const arrival=!!destination_id && `${destination_id?.name}, ${destination_id?.country && countries[destination_id?.country].name}` || destination;
        const departure=!!origin_id && `${origin_id?.name}, ${origin_id?.country && countries[origin_id?.country].name}` || origin;
        return  [{...vessel, direction, arrival, departure}, summary];
    }, [data]);
    const Row=({name, value})=>{
        return (
            <div style={{display:'flex', flexDirection:'row', gap:'1mm', alignItems:'center', justifyContent:'flex-start', 
                width:'95%', }}>
                <Typography.Text style={{width:'45mm'}} strong>{name}</Typography.Text>
                <span>:</span>
                <Typography.Text style={{flexGrow:1, borderBottom:'1px dotted gray', textTransform:'uppercase'}}>{value}</Typography.Text>
            </div>
        )
    }
    const RowPax=({name, wna, wni})=>{
        return (
            <div style={{display:'flex', flexDirection:'row', gap:'1mm', alignItems:'center', justifyContent:'flex-start', 
                width:'95%', }}>
                <Typography.Text style={{width:'45mm'}} strong>{name}</Typography.Text>
                <span>:</span>
                <div style={{display:'flex', flexDirection:'row', flexGrow:1}}>
                    <Typography.Text style={{flexGrow:1, borderBottom:'1px dotted gray', paddingRight:'2mm', textAlign:'right'}}>{wna}</Typography.Text>
                    <Typography.Text>WNA</Typography.Text>
                    <Typography.Text style={{flexGrow:1, borderBottom:'1px dotted gray', paddingRight:'2mm', textAlign:'right'}}>{wni}</Typography.Text>
                    <Typography.Text>WNI</Typography.Text>
                </div>
            </div>
        )
    }
    return (
        <div style={{display:'flex', flexDirection:'column', justifyContent:'start', alignItems:'center'}}>
            <div style={{height:'210mm', width:'148mm', padding:'3mm'}}>
                <div style={{display:'flex', flexDirection:'column', border:'1px solid black', flexGrow:1}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', 
                        paddingBlock:'0.8mm', borderBottom:'1px solid black'}}>
                        <Typography.Text strong >
                            INDONESIA IMMIGRATION CLEARANCE
                        </Typography.Text>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', rowGap:'1.5mm', alignItems:'center', padding:'2mm'}}>
                        <Typography.Text>{vessel?.direction} CHECKED</Typography.Text>
                        <Row name={'SHIP'} value={vessel?.vessel_name || '-'} />
                        <Row name={'FLAG'} value={vessel?.flag || '-'} />
                        <Row name={'ARRIVAL'} value={vessel?.arrival} />
                        <Row name={'DEPARTURE FROM'} value={vessel?.departure} />
                        <RowPax name={"CREW"} wna={summary.wna_crew} wni={summary.wni_crew} />
                        <RowPax name={"PASSENGER"} wna={summary.wna_pax} wni={summary.wni_pax} />
                        <Row name={'AGENT'} value={vessel?.operator_id} />
                        <Row name={'DATE'} value={vessel?.schedule_date} />
                        <Row name={'TIME'} value={vessel?.schedule_time} />
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center' }}>
                    <Typography.Text strong>IMMIGRATION OFFICER</Typography.Text>
                    <QRCode value={`http://sipp.imigrasi.go.id/docs/clearance/${vessel._id}`} style={{width:'35mm', height:'35mm'}} />
                    <Typography.Text strong>{vessel?.officer || '-'}</Typography.Text>
                    <Typography.Text strong>NIP {vessel?.nip || '-'}</Typography.Text>
                </div>
            </div>
        </div>
    );
}