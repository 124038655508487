import moment from 'moment';
import React, { useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import { RiUserFollowLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Paging from '../components/pages/paging';
import SearchComp from './search';
const getBaseUrl=(category, id)=>{
    switch (category) {
        case 'flight-pax-inter':
            return `/passengers/detail/${id}`;
        case 'vessel-pax-inter':
            return `/vessel/passengers/detail/${id}`;
        case 'flight':
            return `/flight/schedule/match/${id}`;
        default:
            return `/404`;
    }
}
const Columns=[
    {
        title:'name',
        dataIndex:'passenger_name',
        render:(val, {obj_id, db_name})=><Link to={getBaseUrl(db_name, obj_id)}>{val}</Link>
    },
    {
        title:'screen_name',
        dataIndex:'screen_name',
    },
    {
        title:'db_name',
        dataIndex:'db_name',
    },
    {
        title:'station',
        dataIndex:'related_station',
        render:(val, rec)=>val?.code || rec.vessel_station
    },
    {
        title:'time',
        dataIndex:'createdAt',
        render:(val)=>moment(val).format('DD-MMM-YYYY HH:mm')
    },
];

export default () => {
    const [search, setSearch] = useState({});
    return (
        <Paging 
            url={'api/v1/matched'}
            searchValue={search}
            title={'matched'}
            icon={RiUserFollowLine}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'matched'
                    }
                ]
            }
            columns={Columns}
            searchComponent={
                <SearchComp 
                    onSearchCallback={(searchValue)=>{
                        console.log({searchValue});
                        setSearch({...searchValue, timestamp:moment().unix()});
                    }}
                />
            }
        />
    )
}