import { Button, Typography } from 'antd';
import React, { useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {CopyOutlined, CopyFilled} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import { red } from '@ant-design/colors';
export default ({data})=>{
    const {subSmi, message, headers, error_message} = data;
    const msg=useMemo(()=>{
        if(['PNRGOV', 'PAXLST'].indexOf(subSmi)>=0){
            const m = message.join('');
            const segmentTerminator = headers?.una?.segmentTerminator || `'`;
            return m.split(segmentTerminator).join(`${segmentTerminator}\n`);
        }
        return Array.isArray(message) && message.join('\n') || '';
    }, [message, subSmi, headers]);
    const copyLog=(textToCopy)=>{
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }
    const [,getLan]=useLanguage();
    return (
        <div style={{height:'75vh', display:'flex', flexDirection:'column', rowGap:8, alignItems:'center'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignSelf:'end'}}>
                <Button onClick={()=>{
                    copyLog(msg);
                    toast.success('Message Copied!');
                }} type='link' icon={<CopyOutlined />}>{getLan('copy')}</Button>
                <Button onClick={()=>{
                    copyLog(message);
                    toast.success('Message Copied!');
                }} type='link' icon={<CopyFilled />}>{getLan('copy_original')}</Button>
            </div>            
            <Scrollbars>
                <pre>
                    {msg}
                </pre>
            </Scrollbars>
            {
                error_message && (
                    <div style={{background:red[3], color:'white', padding:12, width:'100%', borderRadius:8}}>
                        <Typography.Text style={{color:'white'}}>{error_message}</Typography.Text>
                    </div>
                )
            }
        </div>
    )
}