import { GiChart, GiHouse, GiMailbox, GiPieChart } from "react-icons/gi";
import { RiSearch2Line } from "react-icons/ri";

export default [
    {
        caption:'home',
        category:'dashboard',
        group:0, 
        Icon:GiHouse,
        linkTo:'/home'
    },
    {
        caption:'inbound',
        category:'dashboard',
        group:0, 
        Icon:GiChart,
        linkTo:'/dashboard/inbound'
    },
    {
        caption:'outbound',
        category:'dashboard',
        group:0, 
        Icon:GiPieChart,
        linkTo:'/dashboard/outbound'
    },
    {
        caption:'search',
        category:'search',
        group:0, 
        Icon:RiSearch2Line,
        linkTo:'/search'
    },
]