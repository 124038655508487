import { blue } from "@ant-design/colors";
import { Avatar, Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { RiShipLine } from "react-icons/ri";
import { Link, useLocation, useParams } from "react-router-dom";
import CardListPage from "../../components/CardListPage";
import { getFormatedTime } from "../../library";
import PaxMessange from "./PaxMessange";

export default function Vessel() {
  const { direction } = useParams();
  const { pathname } = useLocation();

  const [searchApi, setSearchApi] = useState({
    schedule_date: [moment(new Date()), moment(new Date()).add(1, "days")],
  });

  const formatedSearch = useMemo(() => {
    const { schedule_date, ...rest } = searchApi;
    const [day1, day2] = schedule_date || ["", ""];
    return {
      ...rest,
      schedule_date: [
        moment(day1).format("YYYYMMDD"),
        moment(day2).format("YYYYMMDD"),
      ],
    };
  }, [searchApi, direction]);

  return (
    <CardListPage
      title={`Vessel ${direction}`}
      fixedPagination
      searchApiProps={formatedSearch}
      onSortData={(data) => {
        if (!!data && Array.isArray(data)) {
          const mData = data.map((row, idx) => {
            const currentTime = moment().unix();
            const scheduleTime = row?.is_arrival ? row.unixSta : row.unixStd;
            const diff = Math.abs(
              currentTime - (scheduleTime || row?.unix_time || 0)
            );
            return { ...row, number: idx + 1, diff };
          });
          return mData.sort((a, b) => a.diff - b.diff);
        }
        return [];
      }}
      searchProps={["origin", "destination", "ferry_key"]}
      url={`api/v1/vessel/${direction}/inquiry`}
      datePickerChange={(val, valStr) => {
        setSearchApi({ ...searchApi, schedule_date: val });
      }}
      CardContent={({ item }) => {
        const {
          _id,
          vessel_id,
          ferry_key,
          schedule_date,
          operator_id,
          origin,
          destination,
          callsign,
          schedule_id,
          is_arrival,
          sta,
          std,
        } = item || {
          _id: "",
          vessel_id: "",
          ferry_key: "",
          schedule_date: "",
          operator_id: "",
          origin: "",
          destination: "",
          callsign: "",
          schedule_id: "",
          is_arrival: false,
          sta: "",
          std: "",
        };

        return (
          <Fragment>
            <Link to={`${pathname}/${_id}`}>
              <Row>
                <Col span={8}>
                  {/* {flight_rule_affected.length > 0 && <Badge color={'error'} text={`Rule ${flight_rule_affected.length}`} />} */}

                  <Typography.Title level={5} style={{}}>
                    {vessel_id || "-"}
                  </Typography.Title>
                </Col>
                <Col span={16}>
                  <Row gutter={8} justify="end">
                    <Col className="text-right" span={20}>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: 4,
                          alignItems: "center",
                        }}
                      >
                        {/* <Flags country_code={airline_id?.country} /> */}
                        <Typography.Title level={5} style={{ marginBottom: 0 }}>
                          {operator_id}
                        </Typography.Title>
                      </div>
                      <div>
                        <Typography.Text className="text-sub-title" level={5}>
                          {moment(schedule_date, "YYYYMMDD").format(
                            "DD-MMM-YYYY"
                          )}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={4}>
                      <Avatar
                        // alt={airline_id?.code}
                        size="large"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: blue[5],
                        }}
                        className={"avatar-contain"}
                        icon={<RiShipLine />}
                        // src={`${getLogoAirline(airline_id?.code)}`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className="my-2" />
              <Row gutter={[16,8]}>
                <Col xs={5} sm={4} className="text-center">
                  <Typography.Title level={5} className="Text-bold">
                    {is_arrival
                      ? getFormatedTime({
                          st: moment(sta, "DD-MMM-YYYY HH;mm").format("HH:mm"),
                        })
                      : getFormatedTime({
                          st: moment(std, "DD-MMM-YYYY HH;mm").format("HH:mm"),
                        })}
                  </Typography.Title>
                  <Typography.Text className="text-sub-title">
                    {origin}
                  </Typography.Text>
                </Col>
                <Col xs={12} sm={16} className="text-center">
                  <Divider dashed className="my-2">
                    {schedule_id || "Not Scheduled"}
                  </Divider>
                  <Typography.Text className="text-sub-title">
                    {ferry_key}
                  </Typography.Text>
                </Col>
                <Col xs={5} sm={4} className="text-center">
                  <Typography.Title level={5} className="Text-bold">
                    {is_arrival
                      ? getFormatedTime({
                          st: moment(std, "DD-MMM-YYYY HH;mm").format("HH:mm"),
                        })
                      : getFormatedTime({
                          st: moment(sta, "DD-MMM-YYYY HH;mm").format("HH:mm"),
                        })}
                  </Typography.Title>
                  <Typography.Text className="text-sub-title">
                    {destination}
                  </Typography.Text>
                </Col>
              </Row>
              <Divider className="my-4" />
              
                <PaxMessange id={_id} />
              
            </Link>
          </Fragment>
        );
      }}
    />
  );
}
