import React from 'react';
import { useLanguage } from '../../../../../hooks/useLanguage';
import ctyData from 'country-data';
import { Avatar, Typography } from 'antd';
import Row from './row';
import Flags from '../../../../../components/flags';
import { GiCommercialAirplane } from 'react-icons/gi';
import Panel from '../panel/panel';
import { Link } from 'react-router-dom';

export default ({ flight_schedule_id, width }) => {
    const [, getLan] = useLanguage();
    const { origin_id, dest_id, is_arrival, is_international, source, airline_id, flight_date, apis_info,
        flight_number, sta, std, aircraft_id, _id } = flight_schedule_id;
    const airlineCode = airline_id?.code || airline_id?.icao;
    const image = `/assets/carrier/${airlineCode.toLowerCase()}.png`;
    const url = !is_international ? `/flight/domestic/${_id}` : `/flight/schedule/${is_arrival ? 'arrival' : 'departure'}/${_id}`;
    return !!flight_schedule_id && (
        <Panel
            headerLeft={
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                    <GiCommercialAirplane size={24} color="black" />
                    <Typography.Text>
                        <Link to={url}>
                            {flight_number} / {flight_date}
                        </Link>
                    </Typography.Text>
                </div>
            }
            headerRight={
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                    <Avatar src={image} className="avatar-contain" size="small" />
                    <Typography.Text ellipsis strong>{airline_id?.name}</Typography.Text>
                    <Typography.Text strong>({airlineCode})</Typography.Text>
                </div>
            }
        >
            <Row name={"aircraft_reg"} value={aircraft_id?.aircraft_registration || getLan("not_found")} />
            <Row name={"origin"} value={`${origin_id?.kota} (${origin_id?.code || origin_id?.icao}) `} />
            <Row name={"dest"} value={`${dest_id?.kota} (${dest_id?.code || dest_id?.icao})`} />
            <Row name={"std"} value={std} />
            <Row name={"sta"} value={sta} />
            <Row name={"total_pax"} value={apis_info?.total_passengers || '0'} />
            <Row name={"total_crew"} value={apis_info?.total_crew || '0'} />
            <Row last name={"source"} value={source || "Telex"} />
        </Panel>
    )
}