import { Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useMemo } from 'react';

export default ({data, onClick}) => {
    const {origin, destination, subSmi, headers, _id, createdAt, processedAt, reff, message} = data;
    const [id, ref, status, subset]=useMemo(()=>{
        if(!headers)return [false, false, false, false];
        if(!headers?.unh)return [false, false, false, false];
        const {msgIdentifier, msgRefNumber, statusOfTransfer, msgSubsetIdentification} = headers.unh;
        return [msgIdentifier, msgRefNumber, statusOfTransfer, msgSubsetIdentification]
    }, [headers])
    const ttl=`Time @ ${moment(createdAt).format('DD-MMM-YYYY HH:mm')} Process @ ${moment(processedAt).format('DD-MMM-YYYY HH:mm')}`;
    return (
        <div 
            title={ttl} 
            onClick={onClick} style={{display:'flex', flexDirection:'column', rowGap:2, background:'#ffffff99', cursor:'pointer' }}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', 
                textAlign:'center', background:'#ffffffbb', paddingInline:4}}>
                <Typography.Text>{origin}</Typography.Text>
                <Typography.Text>{destination.join(', ')}</Typography.Text>
                <Typography.Text strong>{subSmi} {id && `Ver. ${id?.msgVersionNumber}.${id?.msgReleaseNumber}`}</Typography.Text>
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', 
                textAlign:'center', background:'#ffffffbb', paddingInline:4}}>
                <Typography.Text title='Sequence'>{status?.seqOfTransfer || `${reff}${moment(createdAt).format('HHmm')}`}</Typography.Text>
                <div style={{display:'flex', flexDirection:'row', gap:8}}>
                    <Typography.Text title='Reference'>{ref}</Typography.Text>
                    <Typography.Text title='Subset'>{subset?`${subset?.msgSubsetIdentification} ${subset?.msgSubsetVersionNumber}`:(message && message[1] || '')}</Typography.Text>
                </div>
            </div>
        </div>
    )
}