import PaxDetailPage from '../pages/passengers/detail';
import PaxDetailDomesticPage from '../pages/passengers/domestic';
import PaxPage from '../pages/passengers';
import WatchlistPage from '../pages/watchlist';
import WatchlistForm from '../pages/watchlist/form';
import PaxRulesPage from '../pages/pax_config';
import PaxRulesForm from '../pages/pax_config/form';
import MatchedPage from '../pages/matched';
import DualCitizenPage from '../pages/dual_citizen';
export default [
    
    {
        path:'/passengers/detail/:id',
        exact:true,
        Component:PaxDetailPage
    },
    {
        path:'/passengers/domestic/detail/:id',
        exact:true,
        Component:PaxDetailDomesticPage
    },
    {
        path:'/passengers/list',
        exact:true,
        Component:PaxPage,
        cache:true
    },
    {
        path:'/passengers/rules',
        exact:true,
        Component:PaxRulesPage,
        cache:true
    },
    {
        path:'/passengers/rules/create',
        exact:true,
        Component:PaxRulesForm,
        cache:true
    },
    {
        path:'/passengers/rules/edit/:id',
        exact:true,
        Component:PaxRulesForm,
        cache:true
    },
    {
        path:'/passengers/watchlist',
        exact:true,
        Component:WatchlistPage,
    },
    {
        path:'/passengers/watchlist/create',
        exact:true,
        Component:WatchlistForm,
    },
    {
        path:'/passengers/watchlist/edit/:id',
        Component:WatchlistForm,
    },    
    {
        path:'/passengers/matched',
        exact:true,
        Component:MatchedPage,
    },
    {
        path:'/passengers/dual_citizenship',
        exact:true,
        Component:DualCitizenPage,
    },
];