import { Form, Input, InputNumber } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Offline'
import AutoCompleteOnline from '../../../../../components/autocomplete/Online'
import {useSelector} from 'react-redux'
import moment from 'moment-timezone';
const DefModel={
    code:'',
    icao_code:'',
    name:'',
    kota:'',
    country:'',
    timezone:'',
    unix_offset:0,
    kanim_id:null
}

export default ()=>{
    const [model, setModel]=useState(DefModel);
    const tzs=useMemo(()=>moment.tz.names(), []);

    return (
        <FormPage 
            url={'api/v1/airport'}
            callbackPath={'/master/airport'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{span:8}}
            labelCol={{span:3}}
            title={"Airport"}            
            emptyModel={DefModel}
        >
            <Form.Item name={'code'} label="IATA Code" rules={[{required:true}]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item
                label={'Kanim'}
                name="kanim_id"
            >
                <AutoCompleteOnline 
                    getKey={(opt)=>opt._id}
                    getSelectedOptions={(opt, val)=>opt._id===val._id}
                    getValues={(val)=>`${val.name}`}
                    uri="api/v1/kanim"
                    onChange={(val)=>setModel({...model, kanim_id:val})}
                    value={model.kanim_id}
                />
            </Form.Item>
            <Form.Item name={'icao_code'} label="ICAO Code" >
                <Input  />
            </Form.Item>
            <Form.Item name={'name'} label="Name" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'kota'} label="City">
                <Input  />
            </Form.Item>
            <Form.Item name={'country'} label="Country" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'timezone'} label="Timezone" rules={[{required:true}]}>
                <AutoComplete 
                    data={tzs}
                    value={model.timezone}
                    getKey={(tz)=>tz}
                    getSelectedOptions={(opt, val)=>opt===val}
                    getValues={(opt)=>opt}
                    onChange={(val)=>{
                        const offset=moment().tz(val)._offset;
                        const unix_offset=(offset && offset * 60)||0;
                        setModel({...model, timezone:val, unix_offset})
                    }}
                />
            </Form.Item>
        </FormPage>
    )
}