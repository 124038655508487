
import CardListSearch from "./subSearch/CardListSearch"
import ListSearch from "./subSearch/ListSearch"

export const renderSubSearch = (key, resultData) =>{
switch(key){
    case 'flight_number':
    return <CardListSearch dataSource={resultData} />
    case 'pax':
        return <ListSearch dataSource={resultData} />
    default:
        return

}
}