import { gold } from "@ant-design/colors";
import { Avatar, Col, Divider, Row, Select, Typography } from "antd";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useState } from "react";
import { MdAirplaneTicket, MdOutlineAirplaneTicket } from "react-icons/md";
import { useFontSize } from "../../../../../hooks/useDimension";
import CardListPage from "../../../components/CardListPage";
import RowList from "../../../components/RowList";

export default function Checker() {
  const fontSize = useFontSize();
  const [search, setSearch] = useState({
    msg_type: "",
  });

  return (
    <CardListPage
      paginationUrl={"api/v1/message/checker/pagination"}
      searchApiProps={search}
      fixedPagination
      FilterComponent={() => {
        return (
          <div  style={{ padding: "16px", display:"flex", justifyContent: "flex-end" }}>
            <Select
              style={{ width: 100 }}
              value={search.msg_type}
              onChange={(val) => {
                setSearch({...search, msg_type: val})
              }}
            >
              <Select.Option value="">ALL</Select.Option>
              <Select.Option value="PNRGOV">PNRGOV</Select.Option>
              <Select.Option value="APIS">APIS</Select.Option>
            </Select>
          </div>
        );
      }}
      CardContent={({ item }) => {
        const { createdAt, flight_schedule_id, msg_type, time_missing } =
          item || {
            createdAt: "",
            flight_schedule_id: "",
            msg_type: "",
            time_missing: "",
          };
        return (
          <div>
            <Row>
              <Col
                span={18}
                style={{ display: "flex", alignItems: "center", gap: 8 }}
              >
                <Avatar
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: gold[7],
                  }}
                  icon={<MdOutlineAirplaneTicket />}
                />
                <Typography.Title
                  style={{ fontSize: fontSize.lg, fontWeight: "bold" }}
                >
                  {flight_schedule_id?.flight_number}
                </Typography.Title>
              </Col>
              <Col span={6}>{/* {getServiceStatus(log)} */}</Col>
            </Row>
            <Divider className="my-1" />
            <div className="p-2">
              <RowList
                title={"Flight Date"}
                content={flight_schedule_id?.flight_date}
                dividerBottom
              />

              <RowList
                title={"Message Type"}
                content={msg_type}
                dividerBottom
              />
              <RowList
                title={"Missing Time"}
                content={`${numeral(time_missing).format("0,000")} Minutes`}
              />
              <RowList
                title={"Created At"}
                content={moment(createdAt).format("DD-MMM-YYYY HH:mm")}
              />
            </div>
          </div>
        );
      }}
    />
  );
}
