import { Button, DatePicker, Form, Input, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import AutoComplete from '../../../../components/autocomplete/Online';
import { RiArrowLeftFill, RiSearchLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
export default ({onSearchCallback, initValue}) => {
    const [state, setState]=useState(initValue);
    const {userdata} = useSelector(state=>state.auth);
    const [colapsed, setColapsed]=useState(true);
    const tpi_active=(userdata.level & 0x1ffe) > 0;
    const [,getLang] = useLanguage();
    return (
        <div style={{padding:12, background:'#ffffff88', display:'flex', flexDirection:'column', rowGap:8}}>
            {
                !colapsed && (
                    <Fragment>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <Typography.Text strong>{getLang('search')}</Typography.Text>
                            <Button icon={<RiArrowLeftFill />} onClick={()=>setColapsed(true)} />                            
                        </div>
                        <Form 
                            layout='vertical'
                            initialValues={state}
                            onFinish={onSearchCallback}
                            autoComplete="off"
                        >
                            <Form.Item
                                label={getLang("sail_date")}
                                name="schedule_date"
                                rules={[{
                                    validator:async(rule, value)=>{
                                        // console.log({rule, value})
                                        const [from, to]=value;
                                        const diff=moment.duration(to.diff(from)).days();
                                        if(diff>7){
                                            throw new Error('Cannot more than 7 days');
                                        }
                                        return true;
                                    }
                                }]}
                            >
                                <DatePicker.RangePicker 
                                    showTime={false}
                                    value={state.schedule_date}
                                    format={'DD-MMM-YYYY'}
                                    onChange={(val, strVal)=>{
                                        setState({...state, schedule_date:val});
                                    }}
                                />
                            </Form.Item>                
                            {
                                tpi_active && (
                                    <Form.Item
                                        label={getLang('tpi')}
                                        style={{marginBottom:4}}
                                        name="tpi"
                                    >
                                        <AutoComplete 
                                            getKey={(opt)=>opt._id}
                                            getSelectedOptions={(opt, val)=>opt._id===val._id}
                                            getValues={(val)=>`${val.code} - ${val.name}`}
                                            uri="api/v1/tpi/vessel"
                                            onChange={(val)=>setState({...state, tpi:val})}
                                            value={state.tpi}
                                        />
                                    </Form.Item>
                                )
                            }
                            <Button block type='primary' htmlType='submit'>Search</Button>
                        </Form>
                    </Fragment>
                ) || (
                    <Button icon={<RiSearchLine />} onClick={()=>setColapsed(false)} />
                )
            }
            
        </div>    
    )
}