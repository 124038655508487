import React, { Fragment } from 'react';
import { Button } from 'antd';
import { CopyFilled, CopyOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import { green, red } from '@ant-design/colors';
import { PostAPI } from '../../../../../../redux';
import { useDispatch } from 'react-redux';

export default ({telex_data, onNeedRefresh}) => {
    const { parsed_data, error, error_stack_trace, msg_id, status, _id} = telex_data;
    const copyLog=(textToCopy)=>{
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }
    const [,getLan] = useLanguage();
    const dispatch=useDispatch();
    return (
        <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', rowGap:4, gap:4, maxHeight:120, alignItems:'start'}}>
            <Button onClick={()=>{
                copyLog(JSON.stringify(parsed_data, null, 2));
                toast.success('Content Message Copied!');
            }} type='link' icon={<CopyOutlined />}>{getLan('copy_data')}</Button>
            {
                status===2 && (
                        <Button onClick={()=>{
                            copyLog(`Error = ${error}\nStack Trace = ${error_stack_trace}`);
                            toast.success('Error Message Copied!');
                        }} type='link' icon={<CopyFilled />}>{getLan('copy_error')}</Button>
                )
            }
            {
                msg_id?._id &&  (
                    <Button onClick={()=>{
                        copyLog(msg_id?.message?.join(`\n`));
                        toast.success('Error Message Copied!');
                    }} type='link' icon={<CopyFilled />}>{getLan('copy_telex')}</Button>
                )
            }
            {
                status===2 && (
                    <Button onClick={()=>{
                        dispatch(PostAPI({url:'api/v1/buffer/delete', data:{_id}})).then(resp=>{
                            typeof onNeedRefresh==='function' && onNeedRefresh(resp);
                        })
                    }} type='link' style={{color:red[6]}} icon={<DeleteOutlined />}>{getLan('delete_process')}</Button>
                )
            }
            {
                status===2 && (
                    <Button onClick={()=>{
                        dispatch(PostAPI({url:'api/v1/buffer/reprocess', data:{_id}})).then(resp=>{
                            typeof onNeedRefresh==='function' && onNeedRefresh(resp);
                        })
                    }} type='link' style={{color:green[6]}} icon={<ReloadOutlined />}>{getLan('reprocess')}</Button>
                )
            }
        </div>
    )
}