import { Button, Form, Image, Input, Modal, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLanguage } from "../../../../../hooks/useLanguage";
import ENV from "../../../../../config/env";

const { ImageUrl } = ENV;
const imgUri = ImageUrl || "/images/";

export default function ModalImages({
  open = false,
  state = null,
  handleModal = null,
}) {
  const [, getLang] = useLanguage();
  console.log(state);

  return (
    <Modal
      title={`Images`}
      visible={open}
      onCancel={handleModal}
      footer={null}
      width={400}
      bodyStyle={{maxHeight: 400, overflow: "auto"}}
    >
      <Image.PreviewGroup>
        {state?.map((item, idx) => (
          <Image
            key={idx}
            width={"100%"}
            style={{ margin: 4 }}
            src={`${imgUri}${item}`}
          />
        ))}
      </Image.PreviewGroup>
    </Modal>
  );
}
