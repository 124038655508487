import { Avatar, List, Typography } from "antd";
import Badge from "../../components/Badge";
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import BaseListPage from "../../components/BaseListPage";
import moment from "moment";
import { getDomHeader, getHeaders, onLimitChar } from "../../library";


export default function TelexList({stateLocation}) {

  console.log(stateLocation);

  return (
    <BaseListPage
      fixedPagination
      searchProps={['origin', 'destination']}
      datas={stateLocation}
      renderItem={(item) => {
        const { _id, origin, destination, headers,message, processedAt } = item || {
          origin: "",
          destination: "",
          processedAt:''
        };
        const { unh } = headers || { unh: "" };
        const { msgIdentifier, statusOfTransfer } = unh || {
          msgIdentifier: "",
          statusOfTransfer: "",
        };
        const { msgType, msgVersionNumber, msgReleaseNumber } =
          msgIdentifier || {
            msgTyp: "",
            msgVersionNumber: "",
            msgReleaseNumber: "",
          };
        const { seqOfTransfer } = statusOfTransfer || { seqOfTransfer: "" };
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  // className={"avatar-contain"}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  icon={<IoMdMail size={20} />}
                />
              }
              title={
                <Link to={{pathname: `/telex/detail/${_id}`, state: item}}> 
                <div className="cursor-pointer">
                  <Typography.Title level={5}>
                    {origin} - {destination}
                  </Typography.Title>
                </div>
                </Link>
              }
              // description={`${onLimitChar(seqOfTransfer, 16)}` || ""}
            description={`${getHeaders(headers) || getDomHeader(message) || "-"} `}

            />
            <div>
              {!!msgType || !!msgVersionNumber || msgReleaseNumber ? (
                <Badge
                  color={"def"}
                  text={`${
                    msgType || ""
                  } v.${msgVersionNumber}.${msgReleaseNumber}`}
                />
              ) : (
                ""
              )}

              {!!processedAt ? <Typography.Text className="text-sub-title">{moment(processedAt).format('DD-MMM-YYYY HH:mm')}</Typography.Text> : ''}
            </div>
          </List.Item>
        );
      }}
    />

    // <BasePage datas={state} getFetchedData={(row) => setRows(row)}>
    //   <List
    //     className="list-base"
    //     itemLayout="horizontal"
    //     dataSource={rows}
    //     renderItem={(item) => {
    //       const { origin, destination, headers } = item || {
    //         origin: "",
    //         destination: "",
    //       };
    //       const { unh } = headers || { unh: "" };
    //       const { msgIdentifier, statusOfTransfer } = unh || {
    //         msgIdentifier: "",
    //         statusOfTransfer: "",
    //       };
    //       const { msgType, msgVersionNumber, msgReleaseNumber } =
    //         msgIdentifier || {
    //           msgTyp: "",
    //           msgVersionNumber: "",
    //           msgReleaseNumber: "",
    //         };
    //       const { seqOfTransfer } = statusOfTransfer || { seqOfTransfer: "" };
    //       return (
    //         <List.Item>
    //           <List.Item.Meta
    //             avatar={
    //               <Avatar
    //                 // className={"avatar-contain"}
    //                 style={{ justifyContent: "center", display: "flex", alignItems:'center' }}
    //                 icon={<IoMdMail size={20} />}
    //               />
    //             }
    //             title={
    //               <div>
    //                 <Typography.Title level={5}>
    //                   {origin} - {destination}
    //                 </Typography.Title>
    //               </div>
    //             }
    //             description={seqOfTransfer}
    //           />
    //           <div>
    //             <Badge
    //               color={"def"}
    //               text={`${msgType} v.${msgVersionNumber}.${msgReleaseNumber}`}
    //             />
    //           </div>
    //         </List.Item>
    //       );
    //     }}
    //   />
    // </BasePage>
  );
}
