import { red } from "@ant-design/colors";
import { FilterOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { MdFilter } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { PostAPI } from "../../../../redux";
import SubDetailPage from "../../components/SubDetailPage";
import { renderSubSearch } from "./renderSubSearch";
import Tets from "./subSearch/CardListSearch";

// import Search from "antd/lib/transfer/search";
const { Search } = Input;

const opts = [
  {
    label: "Passenger Name or Passport ",
    value: "pax",
  },
  {
    label: "Flight Number",
    value: "flight_number",
  },
];

const ModalFilter = ({ open, model, handleSave, handleModal }) => {
  const [state, setState] = useState(model);
  return (
    <Modal
      title={"Search By"}
      visible={open}
      onCancel={handleModal}
      width={"75%"}
      footer={null}
    >
      <div className="mb-4">
        <Select
          style={{ width: "100%" }}
          value={state?.database}
          onChange={(val) => {
            setState({ ...state, database: val });
          }}
        >
          {opts.map((item, idx) => (
            <Select.Option key={idx} value={item?.value}>
              {item?.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Button
        className="my-4"
        block
        type="primary"
        htmlType="submit"
        onClick={() => handleSave(state)}
      >
        Save
      </Button>
    </Modal>
  );
};

const defValue = {
  name: "",
  database: "pax",
};

export default function SearchPage() {
  const history = useHistory();
  const { pathname, state } = useLocation();
  const { searchby } = useParams();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(state?.model);

  const [resultData, setResultData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSave = (value) => {
    setSearchValue(value);
    setIsModalOpen(false);
    history.push(`/search`);
  };

  const currenData = useMemo(()=>{
    return !!state?.data ? state?.data : resultData
  },[state, resultData])

  const selectedOptIndex = useMemo(() => {
    return opts?.map((opt) => opt?.value).indexOf(searchValue?.database);
  }, [searchValue]);

  const onSearch = (params) => {
    dispatch(PostAPI({ url: "api/v1/search", data: params })).then(
      (payload) => {
        if (!!payload?.payload) {
          setResultData(payload.payload || []);
          history.push({
            pathname: `/search`,
            state: {data: payload.payload || [],
            model: params,}
          });
          // setModel({...newModel});
        }
      }
    );
  };

  useEffect(() => {
    !searchValue?.database && setSearchValue(defValue);
    return ()=> setResultData([])
  }, [searchValue]);
  return (
    <Fragment>
      {!!isModalOpen && (
        <ModalFilter
          open={isModalOpen}
          model={searchValue}
          handleModal={handleModal}
          handleSave={handleSave}
        />
      )}
      <div className="p-4">
        <div className="text-left p-4">
          <Typography.Title className="base-text-color" level={3}>{`Search`}</Typography.Title>
          <p className="text-sub-title">
            Current you can search by {opts[selectedOptIndex]?.label}
          </p>
          {/* <p className="text-sub-title">*) to change select in search by</p> */}
        </div>
        <Row gutter={[8, 8]}>
          <Col xs={16} sm={16} md={20} lg={20}>
            <Search
              placeholder={`${opts[selectedOptIndex]?.label}`}
              autoFocus
              value={searchValue?.name}
              onChange={(e) =>
                setSearchValue({ ...searchValue, name: e.target.value })
              }
              onSearch={() => onSearch(searchValue)}
              allowClear
            />
          </Col>
          <Col xs={8} sm={8} md={4} lg={4}>
            <Button
              icon={<FilterOutlined />}
              type="primary"
              onClick={handleModal}
            >
              Search By
            </Button>
          </Col>
        </Row>
      </div>
        <div className="my-4">

        {renderSubSearch(searchValue?.database, currenData)}
        </div>
    </Fragment>
  );
}
