import React, { useMemo } from 'react';
import Table from '../../lists/table';
import Flags from '../../../../../../components/flags';
import { Typography } from 'antd';

export default ({data}) => {
    const cdata=useMemo(()=>{
        if(!data)return[];
        if(!Array.isArray(data))return [];
        return data.sort((a, b)=>{
            const {total_pax:at, passengers:ap} = a;
            const {total_pax:bt, passengers:bp} = b;

            const total_a = (!at)?ap.length:at;
            const total_b = (!bt)?bp.length:bt;
            return total_b - total_a
        }).map((row, idx)=>({...row, number:idx+1}));
    }, [data])
    return (
        <Table
            data={cdata}
            filterField={['pnr']}
            url={"/pnr/detail"}
            columns={[
                {
                    title:'#',
                    dataIndex:'number',
                    width:'90px'
                },
                {
                    title:'pnr',
                    dataIndex:'pnr',          
                    render:(val, rec)=>{
                        const {total_pax, passengers} = rec;
                        const tot=total_pax || passengers.length;
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4}}>
                                <Typography.Text>{val}</Typography.Text>
                                {
                                    tot > 1 && (
                                        <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                            {tot}
                                        </span>
                                    )
                                }
                            </div>
                        )
                    }          
                },
                {
                    title:'booking_date',
                    dataIndex:'booking_date',
                }, 
                {
                    title:'itinerary',
                    dataIndex:'itinerary',
                    ellipsis:true,
                    render:(val, rec)=>!!val && Array.isArray(val) && val.map(({dest, origin, airline})=>(`${airline}/${origin}/${dest}`)).join(',') || '-'
                }, 
                {
                    title:'originator',
                    dataIndex:'originator',
                    render:(val, rec)=>val?.origin?.code && `${val.origin.code}/${val?.system_location?.airline||''}/${val?.system_location?.locationCode||''}`                     
                },                
                {
                    title:'travel_agent_iata_number',
                    dataIndex:'originator',
                    render:(val, rec)=>val?.travel_agent_iata_number || '-'
                },
                {
                    title:'country',
                    dataIndex:'originator',
                    render:(val, rec)=>val?.country && <Flags country_code={val.country} withCountry /> || '-'
                },
                {
                    title:'language',
                    dataIndex:'originator',
                    render:(val, rec)=>val?.language && <Flags country_code={val.language} withCountry /> || '-'
                },
            ]}
            scroll={{y:320}}
        />
    )
}   