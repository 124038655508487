import { Typography } from 'antd';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import {useLanguage} from '../../../../../hooks/useLanguage';
export default ({name, size, value, isNumber, numberFormat, last, valueStyle,
    valueEl, labelWidth})=>{
    const [v, vStyle] = useMemo(()=>{
        if(!value)return ['',false];
        if(isNumber) return [numeral(value).format(numberFormat || '0,000'), {textAlign:'right'}];
        return [value, {}]
    }, [value, isNumber]);
    const [,getLan] = useLanguage();
    const fontSize=size==='large'?'1.4rem':size==='medium'?'1.1rem':'0.9rem';
    return (
        <div style={{display:'flex', flexDirection:'row', 
            gap:8, padding:8, borderBottom:!last && '0.05rem solid black'}}>
            <Typography.Text ellipsis style={{fontWeight:'400', width:labelWidth || `calc(100% / 2.7)`, fontSize}}>{getLan(name)}</Typography.Text>
            {
                typeof valueEl ==='function' && valueEl() || <Typography.Text ellipsis style={{fontWeight:'500', flexGrow:1, fontSize, ...vStyle, ...valueStyle}}>{v}</Typography.Text>
            }
        </div>
    )
}