import { blue } from "@ant-design/colors";
import { Avatar, Card, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Fragment } from "react";
import { RiShipFill } from "react-icons/ri";
import { useLocation, useParams } from "react-router-dom";
import Flags from "../../../../components/flags";
import { useFontSize } from "../../../../hooks/useDimension";
import { useFetch } from "../../../../hooks/useFetch";
import BaseDetailPage from "../../components/BaseDetailPage";
import RowList from "../../components/RowList";

export default function VesselDetail() {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();
  const fontSize  = useFontSize()

  const [detail, setDetail] = useState({})

  const urlPassengers = useMemo(()=>{
    return !!detail &&  `api/v1/vessel_pax/detail/vessel_id/${id}`
  },[detail])

  const [passengers, loadingPassengers] = useFetch(urlPassengers)



  return (
    <BaseDetailPage
    url={ `api/v1/vessel/departure/detail/${id}`}
    getData={(data)=>{
      setDetail(data)
    }}
      renderContent={(item) => {
        const {
          _id,
          vessel_id,
          ferry_key,
          schedule_date,
          operator_id,
          origin,
          destination,
          callsign,
          schedule_id,
          is_arrival,
          sta,
          std,
          source,
          origin_id,
          destination_id,
        } = item || {
          _id: "",
          vessel_id: "",
          ferry_key: "",
          schedule_date: "",
          operator_id: "",
          origin: "",
          destination: "",
          callsign: "",
          schedule_id: "",
          is_arrival: false,
          sta: "",
          std: "",
          source: "",
          origin_id: "",
          destination_id: "",
        };
        return(
        <Fragment>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} className="p-2">
              <Card className="bg-base-theme " style={{ height: "100%" }}>
                {/* <div style={{height: '100%'}}> */}

                <Row gutter={[8, 32]}>
                  <Col span={12}>
                    <Typography.Title className="text-white" style={{fontSize: fontSize.lg}}>
                      {!!is_arrival ? "Arrival" : "Departure"}
                    </Typography.Title>
                  </Col>
                  <Col span={12} className="text-right">
                    <Typography.Text className="text-white ">
                      {moment(schedule_date, "YYYYMMDD").format("DD-MMM-YYYY")}
                    </Typography.Text>
                  </Col>

                  <Col span={6} className="text-center">
                    <Avatar
                      alt={""}
                      size="large"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "6rem",
                        height: "6rem",
                        backgroundColor: blue[6],
                      }}
                      className={"avatar-contain mb-4"}
                      icon={<RiShipFill size={"3rem"} />}
                      // src={`${getLogoAirline(airline_id?.code)}`}
                    />
                  </Col>
                  <Col span={18}>
                    {/* <RowList
              // title={}
              content={ */}
                    <div className="text-right">
                      <Typography.Title className="text-white" style={{fontSize: fontSize.lg}}>
                        {schedule_id}
                      </Typography.Title>
                      <div className="rowslist-inline-flex ">
                        <Typography.Text className="text-white" style={{fontSize: fontSize.md}}>
                          {operator_id}
                        </Typography.Text>
                        {/* <Flags
                      country_code={airline_id?.country}
                      // withCountry
                      countryWidth
                      // countryFormat={"alpha3"}
                    /> */}
                      </div>
                      <p className="text-white" style={{fontSize: fontSize.md}}>
                        {!!ferry_key ? `${ferry_key}` : "Not Registered"}
                      </p>
                    </div>
                    {/* }
              // dividerBottom
            /> */}
                  </Col>
                </Row>
                {/* </div> */}
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} className="p-2">
              <Card style={{ height: "100%" }}>
                <RowList
                  title={"STD"}
                  content={`${moment(std).format('DD/MMM/YY HH:mm') || "-"} LT`}
                  dividerBottom
                />
                <RowList
                  title={"STA"}
                  content={`${moment(sta).format('DD/MMM/YY HH:mm') || "-"} LT`}
                  dividerBottom
                />
                <RowList
                  title={"Origin"}
                  content={
                    <div className="rowslist-inline-flex " style={{justifyContent:'flex-end'}}>
                      <Flags
                        country_code={origin_id?.country}
                        withCountry
                        countryWidth
                        countryFormat={"alpha3"}
                      />
                      {`  ${!!origin && origin}`}
                    </div>
                  }
                  dividerBottom
                />
                <RowList
                  title={"Destination"}
                  content={
                    <div className="rowslist-inline-flex" style={{justifyContent:'flex-end'}}>
                      <Flags
                        country_code={destination_id?.country}
                        withCountry
                        countryWidth
                        countryFormat={"alpha3"}
                      />
                      {`  ${!!destination && destination}`}
                    </div>
                  }
                  dividerBottom
                />
                <RowList title={"Source"} content={source} />
              </Card>
            </Col>
          </Row>
          <Row style={{ gap: 16 }} className="my-4">
            {/* <Col span={24}>
          <Card>
            <RowList
              title={"ID Operator"}
              content={operator_id}
              dividerBottom
            />
            <RowList title={"Ferry Key"} content={ferry_key} dividerBottom />
            <RowList
              title={"Schedule ID"}
              content={schedule_id}
              dividerBottom
            />
            
          </Card>
        </Col> */}
            <Col span={24}>
              <Card>
                <RowList
                  linkTo={{
                    pathname: `${pathname}/passengers`,
                    state: passengers,
                  }}
                  title={"Passenger List"}
                  content={`${passengers?.length || 0} passengers`}
                />
              </Card>
            </Col>
          </Row>
        </Fragment>
        )
      }}
    />
  );
}
