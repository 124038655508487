import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {GiCommercialAirplane, GiHouse } from 'react-icons/gi';
import Page from '../components/pages/paging';
import Search from './search';

export default () => {
    const Icon=GiCommercialAirplane;
    const uri=`api/v1/flight_master`;
    const [searchValue, setSearchValue]=useState({flight_number:'', airline:null, origin_id:null, dest_id:null, is_international:true});
    const formatedSearch=useMemo(()=>{
        const {airline, origin_id, dest_id, ...rest} = searchValue;
        return {...rest, airline_id:airline?._id, origin_id:origin_id?._id, dest_id:dest_id?._id};
    }, [searchValue])
    return (
        <Page 
            url={uri}
            searchValue={formatedSearch}
            title={`master`} 
            icon={Icon} 
            columns={[
                {
                    title:'flight_number',
                    dataIndex:'flight_number'
                },
                {
                    title:'origin',
                    dataIndex:'origin_id',
                    render:(val)=>`${val.code} - ${val.kota}`
                },
                {
                    title:'destination',
                    dataIndex:'dest_id',
                    render:(val)=>`${val.code} - ${val.kota}`
                },
                {
                    title:'std',
                    dataIndex:'std',
                },
                {
                    title:'sta',
                    dataIndex:'sta',
                },
            ]}
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'master'
                    }
                ]
            }
            onRowClick={(row)=>alert(row.flight_number)}
        />
    )
}