import React, { Fragment, useState } from 'react';
import MyModal from './index';
import TelexDetail from '../../details/telex';
import { Button } from 'antd';

const TelexBtn=({data, onClick})=>{
    return <Button type='link'  onClick={onClick}>{data?.corid || data?.msgid}</Button>
}

export default ({telex_data}) => {
    return (
        <MyModal
            Trigger={TelexBtn}
            title={'telex'}
            data={telex_data}
            width={'75%'}
        >
            <TelexDetail data={telex_data} />
        </MyModal>
    )
}