import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  InputNumber,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { GiCctvCamera, GiHouse } from "react-icons/gi";
import Scrollbars from "react-custom-scrollbars";
import { PlusOutlined } from "@ant-design/icons";
import Country from "country-data";
import moment from "moment";

import AutoComplete from '../../../../../components/autocomplete/Online';

import { useLanguage } from "../../../../../hooks/useLanguage";
import { useDispatch } from "react-redux";
import { PostAPI } from "../../../../../redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ENV from "../../../../../config/env";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useFetch } from "../../../../../hooks/useFetch";

const { ImageUrl } = ENV;
const imgUri = ImageUrl || "/images/";
export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [, getLan] = useLanguage();

  const url = useMemo(() => {
    return !!id && `api/v1/rules/detail/${id}`;
  }, [id]);

  const [editData] = useFetch(url);
  console.log();

  const [model, setModel]=useState({name:'', rule_type:'passenger', nationality:'', origin:null, dest:null,
        gender:'', first_age:0, last_age:0, expired_date:0, min_value:0, max_value:0
    });

  const handleSave = async (value) => {
    const newModel = {...model, value}
    await dispatch(PostAPI({ data: newModel, url: "api/v1/rules" }));
    history.goBack();
  };

  useEffect(() => {
    if (!!id) {
      form.setFieldsValue({...editData,});
    } else {
      form.setFieldsValue({ ...model });
    }
  }, [id, editData]);
  return (
    // <Scrollbars>
    <div style={{ padding: 24 }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={model}
        onFinish={handleSave}
        autoComplete="off"
      >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width: "50%",
              rowGap: 8,
            }}
          >
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Form.Item label={getLan("name")} name="name" required>
                  <Input autoFocus autoComplete="off" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  label={getLan("rule_type")}
                  name="rule_type"
                  required
                >
                  <Select size="large">
                    <Select.Option value="passenger">Passenger</Select.Option>
                    <Select.Option value="passport">Passport</Select.Option>
                    <Select.Option value="pnr">PNR</Select.Option>
                    <Select.Option value="flight">Flight</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label={getLan("gender")} name="gender">
                  <Select size="large">
                    <Select.Option value="female">Female</Select.Option>
                    <Select.Option value="male">Male</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label={getLan("origin")} name="origin">
                  <AutoComplete
                    getKey={(opt) => opt._id}
                    getSelectedOptions={(opt, val) => opt._id === val._id}
                    getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
                    uri="api/v1/airport"
                    onChange={(val) => setModel({ ...model, origin: val })}
                    value={model.origin}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label={getLan("dest")} name="dest">
                  <AutoComplete
                    getKey={(opt) => opt._id}
                    getSelectedOptions={(opt, val) => opt._id === val._id}
                    getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
                    uri="api/v1/airport"
                    onChange={(val) => setModel({ ...model, dest: val })}
                    value={model.dest}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Item label={getLan("first_age")} name="first_age">
                  <InputNumber size="large" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Item label={getLan("last_age")} name="last_age">
                  <InputNumber size="large" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Item label={getLan("min_value")} name="min_value">
                  <InputNumber size="large" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Item label={getLan("max_value")} name="max_value">
                  <InputNumber size="large" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={6}></Col>
            </Row>
          </div>
        <Button size="large" className="mt-3" block type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </div>

    // </Scrollbars>
  );
};
