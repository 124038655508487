import { green, grey, red } from "@ant-design/colors";
import {
  FilterOutlined,
  EllipsisOutlined,
  CopyOutlined,
  CopyFilled,
  ReloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Fragment } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Collapse } from "antd";

import { useFontSize } from "../../../../../hooks/useDimension";
import Queue_row_buttons from "../../../../interactive/pages/components/category/modal/queue_row_buttons";
import CardListPage from "../../../components/CardListPage";
import RowList from "../../../components/RowList";
import { getBadgeStatus } from "../../../library";
import BtnAction from "./BtnAction";
import ModalFilterQueue from "./ModalFilterQueue";
import { useDispatch } from "react-redux";
import { useLanguage } from "../../../../../hooks/useLanguage";
import { toast } from "react-toastify";
import { PostAPI } from "../../../../../redux";

const { Panel } = Collapse;

export default function TelexQueue() {
  const fontSize = useFontSize();

  const [search, setSearch] = useState({
    subSmi: "",
    status: 10,
    refresh: moment().unix(),
  });

  const formatedSearch = useMemo(() => {
    const { status, ...rest } = search;
    const s = status === 10 ? undefined : status;
    return { ...rest, status: s };
  }, [search]);

  const [isModalFilter, setIsModalFilter] = useState(false);
  const handleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleNeedRefresh = (resp) => {
    setSearch({ ...search, refresh: moment().unix() });
  };

  const OverlayMenu = ({ telex_data, onNeedRefresh }) => {
    const { parsed_data, error, error_stack_trace, msg_id, status, _id } =
      telex_data;
    const copyLog = (textToCopy) => {
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    };
    const [, getLan] = useLanguage();
    const dispatch = useDispatch();

    return (
      <Menu
        items={[
          {
            label: (
              <Button
                onClick={() => {
                  copyLog(JSON.stringify(parsed_data, null, 2));
                  toast.success("Content Message Copied!");
                }}
                type="link"
                icon={<CopyOutlined />}
              >
                {getLan("copy_data")}
              </Button>
            ),
            key: "copy_data",
          },
          ...(status === 2
            ? [
                {
                  label: (
                    <Button
                      onClick={() => {
                        copyLog(
                          `Error = ${error}\nStack Trace = ${error_stack_trace}`
                        );
                        toast.success("Error Message Copied!");
                      }}
                      type="link"
                      icon={<CopyFilled />}
                    >
                      {getLan("copy_error")}
                    </Button>
                  ),
                  key: "copy_error",
                },
              ]
            : []),
          ...(msg_id?._id
            ? [
                {
                  label: (
                    <Button
                      onClick={() => {
                        copyLog(msg_id?.message?.join(`\n`));
                        toast.success("Error Message Copied!");
                      }}
                      type="link"
                      icon={<CopyFilled />}
                    >
                      {getLan("copy_telex")}
                    </Button>
                  ),
                  key: "copy_telex",
                },
              ]
            : []),
          ...(status === 2
            ? [
                {
                  label: (
                    <Button
                      onClick={() => {
                        dispatch(
                          PostAPI({
                            url: "api/v1/buffer/reprocess",
                            data: { _id },
                          })
                        ).then((resp) => {
                          typeof onNeedRefresh === "function" &&
                            onNeedRefresh(resp);
                        });
                      }}
                      type="link"
                      style={{ color: green[6] }}
                      icon={<ReloadOutlined />}
                    >
                      {getLan("reprocess")}
                    </Button>
                  ),
                  key: "reprocess",
                },
                {
                  label: (
                    <Button
                      onClick={() => {
                        dispatch(
                          PostAPI({
                            url: "api/v1/buffer/delete",
                            data: { _id },
                          })
                        ).then((resp) => {
                          typeof onNeedRefresh === "function" &&
                            onNeedRefresh(resp);
                        });
                      }}
                      type="link"
                      style={{ color: red[6] }}
                      icon={<DeleteOutlined />}
                    >
                      {getLan("delete_process")}
                    </Button>
                  ),
                  key: "delete_process",
                },
              ]
            : []),
        ]}
      />
    );
  };


  

  return (
    <CardListPage
      paginationUrl={"api/v1/buffer/pagination"}
      searchApiProps={formatedSearch}
      fixedPagination
      FilterComponent={() => {
        return (
          <div className='text-right' style={{ padding: "16px 8px" }}>
            <Button
              //   style={{ width: 42 }}
              type="primary"
              icon={<FilterOutlined />}
              size={24}
              onClick={handleModal}
            >
              Filter
            </Button>
            {isModalFilter && (
              <ModalFilterQueue
                state={search}
                setState={setSearch}
                handleSearch={(value) => {
                  setSearch({
                    ...search,
                    ...value,
                    timestamp: moment().unix(),
                  });
                  setIsModalFilter(!isModalFilter);
                }}
                // title={direction}
                open={isModalFilter}
                handleModal={handleModal}
              />
            )}
          </div>
        );
      }}
      CardContent={({ item }) => {
        const { _id, handle_by, createdAt, sub_smi, status, error, retries } =
          item || {
            _id: "",
            handle_by: "",
            createdAt: "",
            sub_smi: "",
            status: "",
            error: "",
            retries: "",
          };

        return (
          <Fragment>
            <Row>
              <Col span={6}>
                {/* {flight_rule_affected.length > 0 && <Badge color={'error'} text={`Rule ${flight_rule_affected.length}`} />} */}

                <Typography.Title
                  style={{
                    fontSize: fontSize.lg,
                  }}
                >
                  {sub_smi}
                </Typography.Title>
              </Col>
              <Col span={18} className="text-right " 
              
              >
                {/* <Typography.Text
                  style={{
                    fontSize: fontSize.md,
                  }}
                >
                  {moment(createdAt).format("DD-MMM-YYYY HH:mm")}
                </Typography.Text> */}
                <div
                  id="action-container"

                >
                  <Dropdown
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                    overlayStyle={{position: "relative", zIndex:50, width: "max-content"}}
                    getPopupContainer={() =>
                      document.getElementById("action-container")
                    }
                    overlay={
                      <OverlayMenu
                        telex_data={item}
                        onNeedRefresh={handleNeedRefresh}
                      />
                    }
                    trigger={["click"]}
                  >
                    <Button type="link
                    " icon={<EllipsisOutlined />}  />
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Divider className="my-2" />

            <RowList
              title={
                <div>
                  <Typography.Title
                    style={{
                      fontSize: fontSize.md,
                    }}
                  >
                    {handle_by}
                  </Typography.Title>
                  <Typography.Text
                    className="text-sub-title"
                    style={{
                      fontSize: fontSize.md,
                    }}
                  >
                    {moment(createdAt).format("DD-MMM-YYYY HH:mm")}
                  </Typography.Text>
                </div>
              }
              dividerBottom
              content={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ width: "max-content" }}>
                    {getBadgeStatus(status, retries)}
                  </div>
                </div>
              }
            />

            {error && (
              <Fragment>
                <Collapse accordion>
                  <Panel header="Error Message" key="1">
                    <p>{error}</p>
                  </Panel>
                </Collapse>
                {/* <Typography.Text className="mt-2">
                  Error Message
                </Typography.Text>
                <div
                  style={{
                    borderRadius: 4,
                    padding: 16,
                    margin: "8px 0",
                    backgroundColor: "#f6f6f6",
                  }}
                >
                  {error}
                </div> */}
              </Fragment>
            )}
            {/* <BtnAction telex_data={item} onNeedRefresh={handleNeedRefresh} />  */}
          </Fragment>
        );
      }}
    />
  );
}
