import React from 'react';
import {AppVersion, getUpdateLogs} from '../../../../../config/appVersion';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../hooks/useDimension';
const update=getUpdateLogs();

export default ({data})=>{
    const {userdata:{be_version}} = useSelector(state=>state.auth);
    const {language} = useSelector(state=>state.apps);
    const {width, height} = useWindowDimensions();
    return (
        <div style={{display:'flex', flexDirection:'row', gap:48, padding:16, flexWrap:'wrap', justifyContent:'space-evenly', 
            position:'relative'}}>
            <div style={{display:'flex', flexDirection:'column', rowGap:16, width:480}}>
                <div style={{display:'flex', flexDirection:'row', gap:16, alignItems:'baseline', borderBottom:'1px solid black'}}>
                    <Typography.Text style={{fontSize:24}}>Frontend Version</Typography.Text>
                    <Typography.Text style={{fontSize:18}}>{AppVersion}</Typography.Text>
                </div>
                {
                    Array.isArray(update) && update.map((u, idx)=>{
                        const {version, update} = u;
                        const updates=update[language.toLowerCase()];
                        return (
                            <div style={{display:'flex', flexDirection:'column', rowGap:8}} key={idx}>
                                <Typography.Text strong>{version}</Typography.Text>
                                <ul style={{paddingInline:20}}>
                                    {
                                        updates.map((up, iiix)=><li key={iiix}>{up}</li>)
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{display:'flex', flexDirection:'column', rowGap:16, width:480}}>
                <div style={{display:'flex', flexDirection:'row', gap:16, alignItems:'baseline', borderBottom:'1px solid black'}}>
                    <Typography.Text style={{fontSize:24}}>Backend Version</Typography.Text>
                    <Typography.Text style={{fontSize:18}}>{data?.version}</Typography.Text>
                </div>
                {
                    data?.history?.map((u, idx)=>{
                        const {version, update} = u;
                        const updates=update[language.toLowerCase()];
                        return (
                            <div style={{display:'flex', flexDirection:'column', rowGap:8}} key={idx}>
                                <Typography.Text strong>{version}</Typography.Text>
                                <ul style={{paddingInline:20}}>
                                    {
                                        Array.isArray(updates) && updates.map((up, iiix)=><li key={iiix}>{up}</li>)
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{position:'absolute', right:15, top:5}}>
                <Typography.Text>Display: {width}x{height}</Typography.Text>
            </div>
        </div>
    )
}