import { Table, Typography } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { GiCalendar } from 'react-icons/gi';
import { useLanguage } from '../../../../../../../hooks/useLanguage';
import Panel from '../../../../components/panel/panel';
// import { useLanguage } from '../../../../../../../hooks/useLanguage';
export default ({data})=>{
    const [,getLan] = useLanguage();
    const datas=useMemo(()=>{
        if(!data)return [];
        const result=[];
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const d = data[key];
                const t = moment.unix(d).format('DD-MMM-YYYY HH:mm');
                result.push({airport:key, last_request:t});
            }
        }
        return result;
    }, [data]);
    return (
        <Panel 
            headerLeft={<GiCalendar />}
            headerRight={<Typography.Text>FR Bot</Typography.Text>}            
        >
            <div style={{padding:16}}>
                <Table 
                    pagination={false}
                    dataSource={datas}
                    columns={[
                        {
                            title:getLan('airport'),
                            dataIndex:'airport'
                        },
                        {
                            title:getLan('last_request'),
                            dataIndex:'last_request'
                        }
                    ]}
                />
            </div>
        </Panel>
    )
}