import { Typography } from 'antd'
import React from 'react'

export default function NoResultData() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px'}}>
        <div className='text-center'>
        <Typography.Title level={3} className='base-text-color'>No Result Data</Typography.Title>
        <Typography.Text className="text-sub-title">
          No data items found 
        </Typography.Text>
      </div>
    </div>
  )
}
