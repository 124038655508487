import { blue, magenta } from "@ant-design/colors";
import { Avatar, List, Select, Typography } from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { MdPeopleOutline } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import Flags from "../../../../../components/flags";
import { exportManifest } from "../../../../interactive/pages/components/details/passengers/manifest";
import Badge from "../../../components/Badge";
import BaseListPage from "../../../components/BaseListPage";
import { getPaxType } from "../../../library";
import Passengerlist from "./Passengerlist";

export default function PassengersFlight({ stateLocation }) {
  const history = useHistory();
  const [passengersModel, setPassengersModel] = useState({
    message: "apis",
    watchlist: "all",
  });
  const handleExport = (data) => {
    if (data.length > 0) {
      const [{ flight_schedule_id }] = data;
      return exportManifest(data, flight_schedule_id);
    }
  };

  const onSortData = (data) => {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    const filteredData = data.filter((row) => {
      const { message, watchlist } = passengersModel;
      if (watchlist !== "all") {
        if (row?.watch_flag && row?.watch_flag > 0) {
          const filtered =
            watchlist === "orange"
              ? (row.watch_flag & 24) > 0
              : (row.watch_flag & 7) > 0;
          return filtered;
        }
        return false;
      }
      if (message === "apis") {
        return row?.status === "boarded";
      }
      return true;
    });
    return filteredData
      .sort((a, b) =>
        `${a.passenger_name}`.localeCompare(`${b.passenger_name}`)
      )
      .map((row, idx) => ({ ...row, number: idx + 1 }));
  };

  const filterComponent = () => {
    return (
      <Fragment>
        {/* <Button onClick={handleExport}  style={{background:green[8], borderRadius:99}} icon={<FileExcelOutlined style={{color:'white'}} />} type="text" /> */}
        <Select
          style={{ width: 120 }}
          value={passengersModel.message}
          onChange={(val) =>
            setPassengersModel({ ...passengersModel, message: val })
          }
        >
          <Select.Option value="apis">APIS</Select.Option>
          <Select.Option value="pnr_apis">APIS+PNR</Select.Option>
        </Select>
        <Select
          style={{ width: 120 }}
          value={passengersModel.watchlist}
          onChange={(val) =>
            setPassengersModel({ ...passengersModel, watchlist: val })
          }
        >
          <Select.Option value="all">ALL</Select.Option>
          <Select.Option value="orange">PPT/VIT</Select.Option>
          <Select.Option value="red">ITP/CKL</Select.Option>
        </Select>
      </Fragment>
    );
  };
  return (
    <BaseListPage
      datas={stateLocation}
      fixedPagination
      sortDependency={passengersModel}
      onSortData={onSortData}
      FilterComponent={filterComponent}
      // url={url}
      searchProps={["passenger_name"]}
      onExportExcel={handleExport}
      renderItem={(item) => {
        // const {
        //   _id,
        //   passenger_name,
        //   gender,
        //   dob,
        //   pnr,
        //   doc_number,
        //   pnr_id,
        //   doc_nationality,
        //   doc_expiration,
        //   watch_flag,
        //   doc_expiry,
        //   age,
        //   doc_country,
        //   pax_type,
        // } = item || {
        //   _id: "",
        //   passenger_name: "",
        //   dob: "",
        //   doc_country: "",
        //   doc_number: "",
        //   pnr_id: "",
        //   gender: "",
        //   pnr: "",
        //   doc_expiration: "",
        //   doc_expiry: "",
        //   doc_nationality: "",
        //   watch_flag: "",
        //   age: "",
        //   pax_type: "",
        // };
        return (
          <Link to={`/passengers/detail/${item?._id}`}>
            <Passengerlist dataSource={item}/>
            {/* <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: `${
                        gender?.toLowerCase() === "m" ||
                        gender?.toLowerCase() === "male"
                          ? blue[4]
                          : magenta[4]
                      }`,
                    }}
                    icon={<MdPeopleOutline />}
                  />
                }
                title={
                  <div>
                    <Typography.Title level={5}>
                      {passenger_name?.toUpperCase()}
                    </Typography.Title>
                  </div>
                }
                description={
                  <div>
                    <p className="mb-1">
                      {moment(dob).format("DD-MMM-YYYY")} ({age} year){" "}
                    </p>
                    <p className="mb-1">
                      PNR : {pnr} / {pnr_id?.total_pax} pax{" "}
                    </p>
                    <div className="rowslist-inline-flex">
                      <p className="mb-1">
                        Passport : {doc_number} /{" "}
                        {moment(doc_expiry).format("DD-MMM-YYYY")}
                      </p>
                      {!!doc_country &&
                      !!doc_nationality &&
                      doc_country !== doc_nationality ? (
                        <Flags
                          country_code={doc_country}
                          // withCountry
                          countryWidth={50}
                          // countryFormat={"alpha3"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                }
              />

              <div>
                <div className="mb-4">
                  <Badge color={"def"} text={getPaxType(pax_type)} />
                </div>
                <Flags
                  country_code={doc_nationality}
                  withCountry
                  countryWidth={50}
                  countryFormat={"alpha3"}
                />
                {watch_flag !== 0 ? (
                  <div className="mt-4">
                    <Badge color={"error"} text={"WATCHLIST"} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </List.Item> */}
          </Link>
        );
      }}
    />
  );
}
