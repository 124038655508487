import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import Page from '../../components/pages/paging';
import Search from './search';
import { MdQueue } from 'react-icons/md';
import Queue_row_buttons from '../../components/category/modal/queue_row_buttons';
import { blue, green, orange, purple, red, yellow, lime } from '@ant-design/colors';
const getStatusTxt=(status, retry)=>{
    switch (status) {
        case 0:
            return ["Pending", yellow[7]];
        case 1:
            return ["Success", green[8]];
        case 2:
            return ["Error", red[7]];
        case 3:
            return ["Ignored", orange[5]];
        case 4:
            return ["Processing", blue[6]];
        case 5:
            return ["Retrying "+retry, yellow[6]];
        case 77:
            return ["Reserved ", lime[6]];
        default:
            return ["Unknown ", purple[6]];
            break;
    }
}

export default () => {
    const Icon=MdQueue;
    const [searchValue, setSearchValue]=useState({ subSmi:'', status:10, refresh:moment().unix()});    
    const uri=`api/v1/buffer`;
    const formatedSearchVal=useMemo(()=>{
        const { status, ...others} = searchValue;
        const s=status===10?undefined:status;
        return {...others, status:s}
    }, [searchValue]);
    const handleNeedRefresh=(resp)=>{
        setSearchValue({...searchValue, refresh:moment().unix()})
    }
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            columns={[
                {
                    title:'id',
                    dataIndex:"_id",
                    width:'320px',
                    render:(val, row)=><Queue_row_buttons onNeedRefresh={handleNeedRefresh} telex_data={row} />
                },
                {
                    title:'smi',
                    dataIndex:"sub_smi",
                    ellipsis:true,
                    width:'120px'
                },
                {
                    title:'error',
                    dataIndex:"error",
                    ellipsis:true,
                    width:'120px'
                },
                {
                    render:(val, row)=>{
                        const {retries}=row;
                        const [txt, color] = getStatusTxt(val, retries);
                        return (
                            <span style={{background:color, padding:4, color:'white', borderRadius:16}}>
                                {txt}
                            </span>
                        )
                    },
                    title:'status',
                    dataIndex:'status',
                },               
                {
                    title:'handle_by',
                    dataIndex:"handle_by",
                    ellipsis:true
                },                
                {
                    render:(val, row)=>row.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm'),
                    title:'created_at'
                },
            ]}
            title={`queue`} 
            icon={Icon} 
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'telex'
                    },
                    {
                        item:'queue'
                    }
                ]
            }
        />
    )
}