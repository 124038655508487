import { Form, Input } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Online';
const DefModel = {
    name: '',
    kanwil_id: null
}

export default () => {
    const [model, setModel] = useState(DefModel);

    return (
        <FormPage
            url={'api/v1/kanim'}
            callbackPath={'/master/kanim'}
            formValid={false}
            model={{
                ...model,
                coordinate: JSON.stringify(model.coordinate)
            }}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"Kanim"}
            emptyModel={DefModel}
            onSaveEvent={(row) => {
                const { coordinate } = row
                return { ...row, coordinate: JSON.parse(coordinate) }
            }}

        >
            <Form.Item name={'name'} label="Name" rules={[{ required: true }]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item
                label={'Kanwil'}
                style={{ marginBottom: 20 }}
                name="kanwil_id"
            >
                <AutoComplete
                    getKey={(opt) => opt._id}
                    getSelectedOptions={(opt, val) => opt._id === val._id}
                    getValues={(val) => `${val.name}`}
                    uri="api/v1/kanwil"
                    onChange={(val) => setModel({ ...model, kanwil_id: val })}
                    value={model.kanwil_id}
                />
            </Form.Item>
            <Form.Item name={'coordinate'} label="Coordinate" >
                <Input value={`${model.coordinate}`} placeholder="latxxx, longxxx" />
            </Form.Item>
        </FormPage>
    )
}