import { blue } from "@ant-design/colors";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { useFetch } from "../../../../../../hooks/useFetch";
import RowList from "../../../../components/RowList";


export default function TelexLogs() {
    const [data] = useFetch("api/v1/bot/typeb_parser");
    const {last_parser, queue} = data || {last_parser:"", queue:[]}
    return (
      <Fragment>
          {!!last_parser && <RowList title={<div style={{fontWeight: "bold", color:blue[6]}}>LAST PROCESS</div>} content={<div style={{fontWeight: "bold", color:blue[6]}}>{moment.unix(last_parser).format("DD/MMM/YY HH:mm")}</div>} dividerBottom />}
  
        {queue.map((item, idx) => (
          <RowList
            key={idx}
            title={item.origin || "-"}
            content={item.total || "-"}
            dividerBottom={idx < queue.length}
          />
        ))}
      </Fragment>
    );
}
