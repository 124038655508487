import { TbArrowBarToDown, TbArrowBarToUp } from 'react-icons/tb';
import { GiCalendar, GiCommercialAirplane, GiHouse, GiLargeDress, GiSailboat, GiSleevelessJacket } from 'react-icons/gi';
import { useMemo, useState } from 'react';
import moment from 'moment';
import Page from './parent';
import Panel from './components/panel';
import numeral from 'numeral';
import { Badge, Button, DatePicker, Input, Select, Typography } from 'antd';
import OnlineComplete from '../../../../components/autocomplete/Online';
import OfflineComplete from '../../../../components/autocomplete/Offline';
import { useLanguage } from '../../../../hooks/useLanguage';
import { ResponsiveContainer, Bar, Tooltip, BarChart, YAxis, XAxis, Legend, CartesianGrid, Brush } from 'recharts';
import { TooltipAge, TooltipAirport, TooltipCarrier, TooltipCountry } from '../components/category/charts/tooltip';
import LegendFormatter from '../components/category/charts/legend';
import './tabs.css';
import { blue, magenta } from '@ant-design/colors';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { MonthlyDailyTab, PassengerChartTab } from './components/tab';
import { useEffect } from 'react';
import cty from 'country-data';
import Scrollbars from 'react-custom-scrollbars';

const FilterValue = ({ filterName, onFilterChange }) => {
    const [filterValue, setFilterValue] = useState(null);

    useEffect(() => {
        setFilterValue(null);
    }, [filterName]);

    const handleFilterValue = (value) => {
        setFilterValue(value);
        !!onFilterChange && typeof onFilterChange === 'function' && onFilterChange(value);
    }

    const countries = cty.countries.all;

    switch (filterName) {
        case 'all':
            return null;
        case 'origin':
        case 'dest':
            return (
                <OnlineComplete
                    style={{ width: 120 }}
                    key={"airport"}
                    uri={'api/v1/airport'}
                    getKey={(opt) => opt._id}
                    getSelectedOptions={(opt, val) => val?._id === opt?._id}
                    getValues={opt => opt?.code}
                    onChange={handleFilterValue}
                    value={filterValue}
                />
            )
        case 'carrier':
            return (
                <OnlineComplete
                    style={{ width: 120 }}
                    key={"airline"}
                    uri={'api/v1/airlines'}
                    getKey={(opt) => opt._id}
                    getSelectedOptions={(opt, val) => val?._id === opt?._id}
                    getValues={opt => opt?.code}
                    value={filterValue}
                    onChange={handleFilterValue}
                />
            )
        case 'age':
            return (
                <Select value={filterValue} style={{ width: 150 }} onChange={handleFilterValue}>
                    <Select.Option value={"0"}>0</Select.Option>
                    <Select.Option value={"10"}>10</Select.Option>
                    <Select.Option value={"20"}>20</Select.Option>
                    <Select.Option value={"30"}>30</Select.Option>
                    <Select.Option value={"40"}>40</Select.Option>
                    <Select.Option value={"50"}>50</Select.Option>
                    <Select.Option value={"60"}>60</Select.Option>
                    <Select.Option value={"70"}>70</Select.Option>
                    <Select.Option value={"80"}>80</Select.Option>
                    <Select.Option value={"90"}>90</Select.Option>
                </Select>
            )
        case 'nationality':
            return (
                <OfflineComplete
                    data={countries}
                    getKey={val => val.alpha2}
                    getSelectedOptions={(opt, val) => opt.alpha2 === val.alpha2}
                    getValues={(opt) => opt.name}
                    onChange={handleFilterValue}
                    value={filterValue}
                />
            )
        default:
            return null;
    }
}

export default () => {
    const { direction } = useParams()
    const [date, setDate] = useState(moment());
    const [timestamp, setTimestamp] = useState(moment().unix());
    const [response, setResponse] = useState(null);
    const [tabActive, setTabActive] = useState('1');
    const [tab2Active, setTab2Active] = useState('1');
    const [filterVal, setFilterVal] = useState('');
    const [flight, vessel] = useMemo(() => {
        if (!response) return [false, false,]
        const { flight: [fl], vessel: [vs] } = response;
        return [fl, vs];
    }, [response]);

    const [chartData, xAxisDataKey, SelectedTooltip, filterName] = useMemo(() => {
        if (!response) return [[], 'age', TooltipAge];
        const { ages, nations, origin, dest, carrier } = response;
        switch (tabActive) {
            case '1':
                return [ages, 'age', TooltipAge, 'age'];
            case '2':
                return [nations, 'nationality', TooltipCountry, 'nationality'];
            case '3':
                return [origin, 'code', TooltipAirport, 'origin'];
            case '4':
                return [dest, 'code', TooltipAirport, 'dest'];
            case '5':
                return [carrier, 'code', TooltipCarrier, 'carrier'];
        }
    }, [response, tabActive]);
    const daily = useMemo(() => {
        return (tab2Active === '1') ? '' : "monthly/";
    }, [tab2Active]);
    const [, getLang] = useLanguage();
    const Icon = direction === 'inbound' ? TbArrowBarToDown : TbArrowBarToUp;
    
    const [filter, setFilter] = useState([]);
    const filterData=useMemo(()=>{
        if(!Array.isArray(chartData))return false;
        if(!filterVal)return Array.isArray(chartData) && chartData.slice(0, 10) || [];
        const f = chartData.filter(dt=> !!dt[xAxisDataKey] && `${dt[xAxisDataKey]}`.toUpperCase().indexOf(filterVal.toUpperCase())>=0);
        return f.slice(0, 10) ;
    }, [chartData, filterVal, xAxisDataKey]);
    console.log({filter});
    return (
        <Page
            title={direction}
            icon={Icon}
            filters={filter}
            id={tab2Active === '1' ? date.format('DD-MMM-YYYY') : date.format('MMM-YYYY')}
            timestamp={timestamp}
            url={"api/v1/summary/dashboard/" + daily + direction}
            onDataSuccess={(data) => {
                setResponse(data);
            }}
            bcumb={[
                {
                    href: '/home',
                    icon: <GiHouse color='white' />,
                    item: 'home'
                },
                {
                    item: direction
                },
            ]}
        >
            <div style={{ display: 'flex', flexDirection: "row", gap: 16, paddingInline: 16, paddingBlock: 8, width: '100%', 
                height: '100%' }}>
                <div style={{
                    width: '20%', height: '100%', display: 'flex', flexDirection: 'column', rowGap: 8,
                    alignItems: 'center', justifyContent: 'center', padding: 8
                }}>
                    <Panel
                        Icon={GiSleevelessJacket}
                        bgColor={'linear-gradient(146deg, rgb(10 19 201) 0%, rgb(68 86 185) 35%, rgb(0 69 255) 100%)'}
                        title="flight"
                        BgIcon={GiCommercialAirplane}
                        bgPos={{ top: -4, bottom: 4, right: 0 }}
                        color={'white'}
                        value={numeral(flight?.male || 0).format('0,000')}
                    />
                    <Panel
                        Icon={GiLargeDress}
                        bgColor={'linear-gradient(146deg, rgba(201,10,146,1) 0%, rgba(185,68,143,1) 35%, rgba(230,0,255,1) 100%)'}
                        title="vessel"
                        color={'white'}
                        BgIcon={GiCommercialAirplane}
                        bgPos={{ top: -4, bottom: 4, right: 0 }}
                        value={numeral(flight?.female || 0).format('0,000')}
                    />
                    <Panel
                        Icon={GiSleevelessJacket}
                        bgColor={'linear-gradient(146deg, rgb(10 19 201) 0%, rgb(68 86 185) 35%, rgb(0 69 255) 100%)'}
                        title="flight"
                        bgPos={{ right: 0 }}
                        BgIcon={GiSailboat}
                        color={'white'}
                        value={numeral(vessel?.male || 0).format('0,000')}
                    />
                    <Panel
                        Icon={GiLargeDress}
                        bgColor={'linear-gradient(146deg, rgba(201,10,146,1) 0%, rgba(185,68,143,1) 35%, rgba(230,0,255,1) 100%)'}
                        title="vessel"
                        color={'white'}
                        bgPos={{ right: 0 }}
                        BgIcon={GiSailboat}
                        value={numeral(vessel?.female || 0).format('0,000')}
                    />

                </div>
                <div style={{ flexGrow: 1, height:'100%', width:'65%', display: 'flex', flexDirection: 'column', rowGap: 4, }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16, alignItems: 'center' }}>
                        <PassengerChartTab
                            activeTab={tabActive}
                            onTabClick={(index) => setTabActive(index)}
                        />
                        <MonthlyDailyTab
                            activeTab={tab2Active}
                            onTabClick={idx => setTab2Active(idx)}
                        />
                    </div>
                    <div style={{ flexGrow: 1, display:'flex', flexDirection:'column', paddingBlock: 16, 
                        backgroundColor: '#ffffffaa', paddingRight: 24 }}>
                        {
                            filter.length>0 && (
                                <div style={{display:'flex', justifyContent:'end', gap:4}}>
                                    { filter.map((fltr, idx) =>(
                                            <Button 
                                                key={idx}
                                                icon={<DeleteOutlined />} 
                                                onClick={()=>{
                                                    const f=filter.filter((f, i)=>i!==idx);
                                                    setFilter([...f]);
                                                }} 
                                                type='default' 
                                            >
                                                {fltr.name} : {fltr.value}
                                            </Button>
                                    ))}
                                </div>
                            )
                        }
                        <ResponsiveContainer >
                            <BarChart
                                data={chartData}
                            >
                                <YAxis />
                                <XAxis dataKey={xAxisDataKey} type="category" />
                                <Tooltip content={SelectedTooltip} />
                                <Legend verticalAlign='top' formatter={LegendFormatter} />
                                {
                                    Array.isArray(chartData) && chartData.length > 15 && (
                                        <Brush startIndex={0} endIndex={10} dataKey={xAxisDataKey} height={30} stroke="#8884d8" />
                                    )
                                }
                                <Bar stackId={'a'} dataKey={"male"} stroke={blue[4]} fill={'rgb(10 19 201)'} />
                                <Bar stackId={'a'} dataKey={"female"} stroke={magenta[4]} fill={'rgba(201,10,146,1)'} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{height:16}}></div>
                </div>
                <div style={{ display: 'flex', height:'100%', flexDirection: 'column', rowGap: 4, width:'15%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 16, 
                        alignItems: 'center', height:48, width: '100%' }}>
                        {
                            tab2Active === '1' ? <DatePicker
                                value={date}
                                allowClear={false}
                                format="DD-MMM-YYYY"
                                autoFocus
                                onChange={dt => setDate(dt)}
                                disabledDate={curr => curr > moment()}
                            /> : <DatePicker.MonthPicker
                                value={date}
                                allowClear={false}
                                format="MMM-YYYY"
                                autoFocus
                                onChange={dt => setDate(dt)}
                                disabledDate={curr => curr > moment()}
                            />
                        }
                        <Button onClick={() => setTimestamp(moment().unix())} icon={<ReloadOutlined />} type={'text'} />
                    </div>
                    <div style={{display:'flex', flexGrow:1, flexDirection:'column', rowGap:4, background:'#ffffff99'}}>
                        <div style={{background:'black'}}>
                            <Typography.Text style={{color:'white'}}>Filter : {filterName}</Typography.Text>
                        </div>
                        <div style={{padding:4}}>
                            <Input.Search autoFocus value={filterVal} onChange={(e)=>setFilterVal(e.target.value)}  />
                        </div>
                        <Scrollbars>
                            <div style={{display:'flex', flexDirection:'column', rowGap:2}}>
                                {
                                    filterData.map((f, idx)=>!!f[xAxisDataKey] && (
                                        <Button 
                                            key={idx} 
                                            type="default" 
                                            onClick={()=>{
                                                const fl=filter.filter(({name})=>name!==filterName);
                                                setFilter([...fl, {name:filterName, value:f[xAxisDataKey]}])
                                            }}
                                        >
                                            {f[xAxisDataKey]}
                                        </Button>
                                    ))
                                }
                            </div>
                        </Scrollbars>
                    </div>
                    <div style={{height:16}}></div>
                </div>
            </div>
        </Page>
    )
}