import { Typography } from 'antd';
import React from 'react';
import { useLanguage } from '../../../../../../../hooks/useLanguage';
import Row from '../../../category/row';
import SSR from '../../../category/ssr';
import OriginSec from '../../../category/originator';
import Flight_schedule from '../../../category/flight_schedule';
import Osi from '../../../category/osi';
import Itinerary from '../../../category/itinerary';
import Contact from '../../../category/contact';
import Pnr from '../../../category/pnr';
export default ({data})=>{
    const {pnr, itinerary, originator, booking_date, flight_schedules_id, osi, ssr, 
        contact_information, total_pax} = data;
    const [,getLan] = useLanguage();
    return (
        <div style={{display:'flex', flexDirection:'row', gap:32, padding:16, justifyContent:'space-between', flexWrap:'wrap'}}>
            <div style={{display:'flex', flexDirection:'column', rowGap:16, flexGrow:1}}>
                <div style={{display:'flex', width:'100%', flexDirection:'row', gap:16}}>
                    <div style={{display:'flex', flexDirection:'column', rowGap:16, width:'50%'}}>                        
                        {data && <Pnr data={data} />}
                        { originator && <OriginSec originator={originator}  />}
                    </div>
                    <div style={{display:'flex', flexDirection:'column', rowGap:8, width:'50%'}}>
                        {flight_schedules_id && <Flight_schedule flight_schedule_id={flight_schedules_id} total_pax={total_pax} />}
                    </div>
                </div>
                <div style={{width:'100%'}}>
                    { contact_information && <Contact data={contact_information}  />}
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'column', rowGap:16, flexGrow:1}}>
                {
                    itinerary && <Itinerary itinerary={itinerary} />
                }
                { ssr && <SSR data={ssr} /> }
                { osi && <Osi osi={osi} />}
            </div>
        </div>
    )
}