import { Typography } from 'antd';
import React from 'react';
import { GiTicket } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Row from './row';

export default ({ data }) => {
    const { pnr, booking_date, total_pax, passengers, _id } = data;
    const [, getLan] = useLanguage();
    return (
        <Panel
            headerLeft={<GiTicket size={24} />}
            headerRight={<Typography.Text>{getLan('pnr')}</Typography.Text>}
        >
            <Row name={'pnr'} value={
                <Link to={`/pnr/detail/${_id}`}>
                    {pnr}
                </Link>
            } />
            <Row name={'booking_date'} value={booking_date} />
            <Row last name={'total_pax'} value={total_pax || passengers?.length || '-'} />

        </Panel>
    )
}