import { grey } from '@ant-design/colors';
import { Content } from 'antd/lib/layout/layout';
import Footer from './layouts/footer';
import Header from './layouts/header2';
import React, { useEffect } from 'react';

import Routes from './routing';
import Background from '../../assets/images/background-app.png';
import Menu from './layouts/menu';
import { LightenDarkenColor } from './utils';
import CFG from '../../config/env';
import { useDispatch } from 'react-redux';
import { loadRules } from '../../redux';
const { BgColor, backgroundGradient } = CFG;

export default () => {
    // const loc=useLocation();
    const menuBg=LightenDarkenColor(BgColor, -25)
    const defBackground=`linear-gradient(0deg, ${grey[5]} 0%, ${grey[4]} 51%, ${grey[6]} 100%)`;
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(loadRules());
    }, [])
    return (
        <div className='apps' style={{
            display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', position: 'relative',
            background: backgroundGradient || defBackground, 
        }}>
            <Header />
            <Content style={{ position: 'relative' }}>
                <div style={{position:'absolute', inset:0, 
                    // backgroundImage: `url(${Background})`, 
                    backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', 
                        backgroundBlendMode: 'exclusion', filter:'opacity(0.3)'}} 
                />
                {/* <div style={{position:'absolute', left:0, top:0, bottom:0, width:48, background:menuBg}}>

                </div> */}
                <div style={{ position: 'absolute', left:8, insetBlock: 8, right:8, overflow: 'hidden' }}>
                    <Routes />
                </div>
            </Content>
            <Footer />
        </div>
    )
}