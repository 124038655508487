import { grey } from '@ant-design/colors'
import { Button, Divider, Form, Image, Input, Typography,   } from 'antd'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import {RiLoginCircleLine, RiUser3Line, RiLockLine} from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import {login} from '../../redux/reducer/auth';
// import CFG from '../../config/env';
import { useState } from 'react';
import { GetAPI } from '../../redux';
import {ReloadOutlined} from '@ant-design/icons';
import { CreateRandomString } from './utils';
// const {BaseUrl} = CFG;
export default ({apps}) => {
    const dispatch=useDispatch();
    const [image, setImage]=useState('');
    const createRandomStr=()=>{
        const timestamps=(new Date()).getTime();
        return timestamps+'_'+CreateRandomString(16);
    }
    const [refresh, setRefresh]=useState(createRandomStr());
    const loadImage=()=>{
        dispatch(GetAPI({url:'auth/captcha/'+refresh})).then((resp)=>{
            if(!!resp?.payload){
                setImage(resp.payload.data);
            }
        })
    }
    useEffect(()=>{
        loadImage();
    }, [refresh]);
    const onFinished=useCallback((values)=>{
        console.log({values});
        dispatch(login({...values, app:apps, token:refresh}));
    }, [refresh])
    return (
        <Fragment>
            <div style={{display:'flex', flexDirection:'row', height:'100vh'}}>
                <div style={{display:'flex', flexGrow:1, position:'relative'}}>
                    <div className={`loginBackground login-dashboard`} />
                </div>

                <div style={{display:'flex',  flexDirection:'row', width:'40vw', height:'100vh', padding:16,
                    justifyContent:'center', alignItems:'center'}}>
                    
                    <Form 
                        title={`Login To ${apps}`} 
                        layout='horizontal' 
                        style={{width:480, border:`solid 1px ${grey[1]}`, paddingInline:16, paddingBlock:24, background:'white'}} 
                        labelAlign='left'
                        onFinish={onFinished}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    >
                        <Typography.Title type='secondary' level={2} style={{textAlign:'center'}}>Login To {apps}</Typography.Title>
                        <Divider style={{margin:'8px 0'}} />
                        <div style={{paddingInline:24}}>
                            <Form.Item name={'user'} label="Username" rules={[{required:true, message:'Username Required'}]}>
                                <Input autoFocus placeholder='Username' prefix={<RiUser3Line  color={grey[2]} />} />
                            </Form.Item>
                            <Form.Item name={'password'} label="Password" rules={[{required:true, message:'Password Required'}]}>
                                <Input.Password placeholder='Password' prefix={<RiLockLine color={grey[2]} />} />
                            </Form.Item>
                            
                            <div style={{display:'flex', flexDirection:'row-reverse', padding:8, gap:8, alignItems:'center'}}>
                                <Button icon={<ReloadOutlined />} type="ghost" onClick={()=>setRefresh(createRandomStr())} />
                                <Image  
                                    src={image}
                                    height={64}
                                />
                            </div>
                            <Form.Item name="captcha" label="Captcha Result" required>
                                <Input />
                            </Form.Item>
                            <Button 
                                icon={<RiLoginCircleLine style={{marginInline:5}}/>}
                                block
                                htmlType="submit"
                            >
                                Login
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Fragment>
    )
}