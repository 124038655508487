import { GiCctvCamera } from "react-icons/gi";
import { MdOutlineRuleFolder } from "react-icons/md";
import CFG from '../../../config/env';
import { 
    // RiContactsBook2Line, 
    // RiUserSearchLine,
    RiUserFollowLine, RiUserSearchLine, 
} from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
const {withDomestic} = CFG;

const menu = [
    // {
    //     caption:'citizen',
    //     category:'poi',
    //     group:1, 
    //     Icon:RiContactsBook2Line,
    //     linkTo:'/passengers/citizen'
    // },
    withDomestic && {
        caption:'list',
        category:'poi',
        group:1, 
        Icon:RiUserSearchLine,
        linkTo:'/passengers/list'
    },
    {
        caption:'matched',
        category:'poi',
        group:1, 
        Icon:RiUserFollowLine,
        linkTo:'/passengers/matched'
    },
    {
        caption:'watchlist',
        category:'poi',
        group:1, 
        level:0x1ff4,
        Icon:GiCctvCamera,
        linkTo:'/passengers/watchlist'
    },
    {
        caption:'dual_citizenship',
        category:'poi',
        group:1, 
        level:0x1ff0,
        Icon:IoIosPeople,
        linkTo:'/passengers/dual_citizenship'
    },
    {
        caption:'rules',
        category:'poi',
        group:1, 
        level:0x1ff0,
        Icon:MdOutlineRuleFolder,
        linkTo:'/passengers/rules'
    },
];

export default menu;