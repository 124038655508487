import { blue, gold, grey } from "@ant-design/colors";
import { Avatar, Card, Col, Row, Typography } from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { MdOutlineAirplaneTicket, MdPeople } from "react-icons/md";
import { Link, useLocation, useParams } from "react-router-dom";
import Flags from "../../../../../components/flags";
import { useFontSize } from "../../../../../hooks/useDimension";
import { useFetch } from "../../../../../hooks/useFetch";
import Badge from "../../../components/Badge";
import BaseDetailPage from "../../../components/BaseDetailPage";
import RowList from "../../../components/RowList";
// import Flags from "../../../../components/flags";
// import { useFetch } from "../../../../hooks/useFetch";
// import Badge from "../../components/Badge";
// import BaseDetailPage from "../../components/BaseDetailPage";
// import RowList from "../../components/RowList";
import { getLogoAirline, getPaxType, getPaxTypePassenger, onLimitChar, renderGender } from "../../../library";

export default function Detail() {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();
  const fontSize = useFontSize()

  const [detail, setDetail] = useState();

  const urlAccompany = useMemo(() => {
    return (
      !!detail?.pnr_id?._id &&
      `api/v1/passengers/detail/pnr_id/${detail?.pnr_id?._id}`
    );
  }, [detail]);

  const [accompany, loadingAccompany] = useFetch(urlAccompany);

  const accompanyData = useMemo(() => {
    return !!accompany?.length
      ? accompany.filter((item) => item._id !== id)
      : [];
  }, [accompany]);

  return (
    // <BasePage title="Detail Passenger">
    <BaseDetailPage
      url={`api/v1/passengers/detail/${id}`}
      getData={(data) => setDetail(data)}
      renderContent={(data) => {
        const {
          watch_flag,
          passenger_type,
          passenger_name,
          dob,
          age,
          gender,
          doc_number,
          doc_expiry,
          doc_nationality,
          status,
          flight_schedule_id,
          flight_number,
          flight_date,
          pnr_id,
          pnr,
          telexes,
          dest_id,
          origin_id,
          pax_type,
          citizen_id,
          reference
        } = data || {
          watch_flag: "",
          passenger_type: "",
          passenger_name: "",
          doc_number: "",
          doc_expiry: "",
          dob: "",
          age: "",
          gender: "",
          doc_nationality: "",
          status: "",
          flight_schedule_id: "",
          flight_number: "",
          flight_date: "",
          pnr_id: "",
          pnr: "",
          telexes: "",
          dest_id: "",
          origin_id: "",
          pax_type: "",
          citizen_id: "",
          reference:""
        };

        const {booking_code, seat_number} = reference || {booking_code:"", seat_number:""}

        const {
          _id: idFlight,
          is_arrival,
          airline_id,
        } = flight_schedule_id || {
          idFlight: "",
          is_arrival: false,
          airline_id: null,
          total_pax: 0,
        };

        return (
          <Row gutter={[8,8]} className="my-4">
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card style={{ height: "100%" }}>
                <Row style={{gap:8}}>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      alt={airline_id?.code}
                      size="large"
                      style={{
                        backgroundColor: blue[7],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "6rem",
                        height: "6rem",
                      }}
                      className={"avatar-contain"}
                      icon={<MdPeople size={"4rem"} />}
                      // src={`${getLogoAirline(airline_id?.code)}`}
                    />
                    {!!watch_flag ? (
                      <div>
                        <Badge color={"error"} text={"WATCHLIST"} />
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={24} className="text-center">
                    <Link to={`/passengers/citizen/detail/${citizen_id?._id}`}>
                      <Typography.Title level={4} style={{ color: blue[6], fontSize: fontSize.lg }}>
                        {passenger_name} {renderGender(gender)}
                      </Typography.Title>
                    </Link>
                    <Typography.Text className="text-sub-title">
                        {" "}
                        {moment(dob).format("DD-MMM-YYYY")} ({age} year)
                      </Typography.Text>
                    <div
                    className="my-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 24,
                      }}
                    >
                     
                      <Flags
                        country_code={doc_nationality}
                        withCountry
                        countryFormat={"alpha3"}
                        countryWidth
                      />
                      {!!passenger_type ? (
                        <Badge
                          color={
                            passenger_type?.toLowerCase() === "passenger"
                              ? "def"
                              : "success"
                          }
                          text={passenger_type}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div> */}

                    {/* </div> */}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Row  style={{ height: "100%", gap: 8 }}>
                <Col span={24}>
                  <Card style={{ height: "100%" }}>
                    {/* <RowList
                  title={"Fullname"}
                  content={
                    <Link to={`/passengers/citizen/detail/${citizen_id?._id}`}>
                      {passenger_name}
                    </Link>
                  }
                  dividerBottom
                />
                <RowList
                  title={"Type"}
                  content={passenger_type}
                  dividerBottom
                />

                <RowList
                  title={"Birthday"}
                  content={
                    <div>
                      <Typography.Title level={5}>
                        {moment(dob).format("DD-MMM-YYYY")}({age})
                      </Typography.Title>
                    </div>
                  }
                  dividerBottom
                />
                <RowList
                  title={"Gender"}
                  content={
                    <div className="rowslist-inline-flex">
                      {renderGender(gender)} {gender}
                    </div>
                  }
                  dividerBottom
                />
                <RowList
                  title={"Nationality"}
                  content={
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Flags
                        country_code={doc_nationality}
                        withCountry
                        countryWidth
                      />
                    </div>
                  }
                  dividerBottom
                /> */}
                    <RowList
                      title={"Pax Type"}
                      content={getPaxTypePassenger(pax_type)}
                      dividerBottom
                    />
                     <RowList
                      title={"Code Booking"}
                      content={booking_code}
                      dividerBottom
                    />
                     <RowList
                      title={"Seat Number"}
                      content={seat_number}
                      dividerBottom
                    />

                    <RowList title={"Status"} content={status} />
                  </Card>
                </Col>
                <Col span={24}>
                  <Card style={{ height: "100%" }}>
                    <RowList
                      linkTo={"#"}
                      title={"Passport"}
                      content={
                        <div
                          className="rowslist-inline-flex"
                          style={{ flexWrap: "nowrap" }}
                        >
                          {`${doc_number} ${
                            !!doc_expiry
                              ? `(${moment(doc_expiry).format("DD-MMM-YYYY")})`
                              : ""
                          }`}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Flags
                              className="text-right"
                              country_code={doc_nationality}
                              withCountry
                              countryWidth
                              countryFormat={"alpha3"}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={12}>
              
            </Col> */}
            <Col span={24}>
              <Card>
                <Row gutter={[8,8]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <RowList
                      linkTo={"#"}
                      title={"Origin"}
                      content={
                        <div
                          className="rowslist-inline-flex"
                          style={{ flexWrap: "nowrap" }}
                        >
                          {onLimitChar(origin_id?.name, 16)}
                          {origin_id?.code ? ` (${origin_id?.code})` : ""}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Flags
                              className="text-right"
                              country_code={origin_id?.country}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <RowList
                      linkTo={"#"}
                      title={"Destination"}
                      content={
                        <div
                          className="rowslist-inline-flex"
                          style={{ flexWrap: "nowrap" }}
                        >
                          {onLimitChar(dest_id?.name, 16)}
                          {dest_id?.code ? ` (${dest_id?.code})` : ""}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Flags
                              className="text-right"
                              country_code={dest_id?.country}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <RowList
                  linkTo={
                    !!idFlight
                      ? `/flight/schedule/${
                          !!is_arrival ? "arrival" : "departure"
                        }/${idFlight}`
                      : "#"
                  }
                  title={"Flight"}
                  content={
                    <div className="rowslist-inline-flex">
                      <p style={{ marginBottom: 0 }}>
                        {flight_number} / {flight_date} /
                      </p>
                      <div className="rowslist-inline-flex">
                        <p style={{ marginBottom: 0 }}>{onLimitChar(airline_id?.name, 6)}</p>
                        {!!airline_id?.code ? (
                          <Avatar
                            alt={airline_id?.code}
                            size="default"
                            //   style={{ alignSelf: "center" }}
                            className={"avatar-contain"}
                            src={`${getLogoAirline(airline_id?.code)}`}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  }
                  dividerBottom
                />
                <RowList
                  linkTo={
                    !!pnr_id?._id
                      ? {
                          pathname: `/pnr/detail/${pnr_id?._id}`,
                          state: pnr_id,
                        }
                      : "#"
                  }
                  title={"PNR"}
                  content={`${pnr} (${pnr_id?.total_pax || 0} pax total) `}
                  dividerBottom
                />
                <RowList
                  linkTo={{ pathname: `${pathname}/telexes`, state: telexes }}
                  title={"Telexes"}
                  content={`${telexes?.length || 0} telex`}
                  dividerBottom
                />

                <RowList
                  linkTo={{
                    pathname: `${pathname}/accompany`,
                    state: accompanyData,
                  }}
                  title={"Accompany"}
                  content={`${accompanyData?.length || 0} accompany`}
                />
              </Card>
            </Col>
          </Row>
        );
      }}
    />

    // </BasePage>
  );
}
