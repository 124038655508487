import React from 'react';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { Typography } from 'antd';
import Row from './row';
import Panel from '../panel/panel';
import { GiAirplaneArrival, GiAirplaneDeparture } from 'react-icons/gi';

export default ({ direction, data }) => {
    const [,getLang]= useLanguage();
    const title=getLang(direction);
    const {flight_number, flight_date, dest, class:kelas, origin} = data;
    const Icon=direction==='inbound'?GiAirplaneArrival:GiAirplaneDeparture;
    return (
        <Panel
            headerLeft={<Icon size={18} />}
            headerRight={
                <Typography.Text>{title}</Typography.Text>
            }
        >
            <Row name={'flight_number'} value={flight_number}/>
            <Row name={'flight_date'} value={flight_date}/>
            <Row name={'origin'} value={origin}/>
            <Row name={'dest'} value={dest}/>
            <Row last={true} name={'class'} value={kelas}/>
        </Panel>
    )
}