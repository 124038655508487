import { ReloadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useFetch } from '../../../../../hooks/useFetch';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Page from './page';

export default ({title, id, url, icon, breadcrumbs, children, onDataFound, getBreadcumbValue, DetailComponent, additionalData,})=>{
    const [timestamp, setTimestamp]=useState(moment().unix());
    const uri=useMemo(()=>{
        return !!id?url+'/'+id+'?timestamp='+timestamp:url+'?timestamp='+timestamp;
    }, [url, id, timestamp])
    const [data, loading] = useFetch(uri);
    useEffect(()=>{
        if(!!data){
            onDataFound && typeof onDataFound==='function' && onDataFound(data);
        }
    }, [data]);
    const [,getLang] = useLanguage()
    const bcumb=useMemo(()=>{
        if(!breadcrumbs) return [];
        if(!data)return breadcrumbs;
        if(typeof getBreadcumbValue!=='function')return breadcrumbs;
        const value=getBreadcumbValue(data);
        if(!value)return breadcrumbs;
        return [...breadcrumbs, {item:value}];
    }, [breadcrumbs, data]);
    const loadingSec=()=>{
        return (
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%'}}>
                <Typography.Title>  {!!loading?getLang('loading'):getLang('data_not_found')}</Typography.Title>
            </div>
        )
    }
    return (
        <Page title={title} icon={icon} breadcrumbs={bcumb}>
            <div style={{display:'flex', flexDirection:'column', padding:8, background:'#ffffff88', height:'100%'}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'end'}}>
                    <Button icon={<ReloadOutlined />} type="text" onClick={()=>setTimestamp(moment().unix())} />
                </div>
                { !loading && !!data && (
                        <Scrollbars>
                            <div style={{paddingInline:24}}>
                                { !!DetailComponent && <DetailComponent  {...additionalData} data={data}/>}
                            </div>
                        </Scrollbars>
                    ) || loadingSec()
                }
            </div>
        </Page>
    )
}