import React, { useMemo } from 'react';
import {Dropdown, Menu, Typography} from 'antd';
import {languages} from '../../languages/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../redux';
import { Link } from 'react-router-dom';
import Flags from '../flags';
// const Items=

export default ({withCountry}) => {
    const {language, name} = useSelector(state=>state.apps);
    const dispatch = useDispatch();
    const OverlayMenu=()=>{
        return <Menu items={languages.map(({id, name}, idx)=>(
            {
                label:<Link to={'#'} onClick={()=>dispatch(setLanguage({language:id.toUpperCase(), name}))}>{name}</Link>,
                key:name,
                icon:<Flags country_code={id} />
            }
        ))} />
    }
    return (
        <Dropdown placement='bottomLeft' overlay={OverlayMenu} trigger={['click']} className=''>
            <div style={{cursor:'pointer', display:'flex', flexDirection:'row', gap:8}}>
                <Flags country_code={language} />
                {!!withCountry && <Typography.Text ellipsis style={{color:'white'}}>{name}</Typography.Text>}
                
            </div>
        </Dropdown>
    )
}