import React from 'react';
import {RiHome2Line, RiUser3Line, RiUser2Line, RiDatabase2Line, RiFlightTakeoffLine, RiBuildingLine,
    RiMoneyDollarCircleLine, RiListOrdered, RiStore2Line, RiContactsBookLine, RiMailUnreadLine, RiUserAddLine, RiUserSearchFill, RiUserFollowLine, RiCalendar2Line, RiGovernmentLine    
} from 'react-icons/ri';
import {GiAirplaneArrival, GiAirplaneDeparture, GiCargoCrate, GiCommercialAirplane, GiHarborDock, GiPlaneWing, GiSecurityGate, GiSwitzerland} from 'react-icons/gi'
import {Link} from 'react-router-dom';
import {getLanguage} from '../../../languages/utils';

const SIZE=20;
export default (languages)=> [
    {
        key:'/home',
        icon:<RiHome2Line size={SIZE}/>,
        label:<Link to={'/home'}>{getLanguage('home', languages)}</Link>,
        level:0x1fff
    },
    {
        key:'/master',
        icon:<RiDatabase2Line size={SIZE}/>,
        label:getLanguage('master', languages),
        level:0x1ff0,
        children:[
            {
                key:'/master/airline',
                icon:<RiFlightTakeoffLine size={SIZE}/>,
                label:<Link to={'/master/airline'}>{getLanguage('airline', languages)}</Link>,
                level:0x1ff0,
            },
            {
                key:'/master/kanwil',
                icon:<GiSwitzerland size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/kanwil'}>{getLanguage('kanwil', languages)}</Link>,
            },
            {
                key:'/master/kanim',
                icon:<RiGovernmentLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/kanim'}>{getLanguage('kanim', languages)}</Link>,
            },
            {
                key:'/master/tpi',
                icon:<GiSecurityGate size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/tpi'}>{getLanguage('tpi', languages)}</Link>,
            },
            {
                key:'/master/airport',
                icon:<RiBuildingLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/airport'}>{getLanguage('airport', languages)}</Link>,
            },
            {
                key:'/master/seaport',
                icon:<GiHarborDock size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/seaport'}>{getLanguage('seaport', languages)}</Link>,
            },
            {
                key:'/master/users',
                icon:<RiUserAddLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/users'}>{getLanguage('users', languages)}</Link>,
            }
        ]
    },
    {
        key:'/flight',
        icon:<GiCommercialAirplane size={SIZE}/>,
        level:0x1ff0,
        label:getLanguage('flight', languages),
        children:[
            {
                key:'/flight/master',
                icon:<GiPlaneWing size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/flight/master'}>{getLanguage('flight_master', languages)}</Link>,
            },
            {
                key:'/flight/schedule',
                icon:<RiCalendar2Line size={SIZE}/>,
                level:0x1ff0,
                label:getLanguage('flight_schedule', languages),
                children:[
                    {
                        key:'/flight/schedule/arrival',
                        icon:<GiAirplaneArrival size={SIZE}/>,
                        level:0x1ff0,
                        label:<Link to={'/flight/schedule/arrival'}>{getLanguage('flight_schedule_arrival', languages)}</Link>,
                    },   
                    {
                        key:'/flight/schedule/departure',
                        icon:<GiAirplaneDeparture size={SIZE}/>,
                        level:0x1ff0,
                        label:<Link to={'/flight/schedule/departure'}>{getLanguage('flight_schedule_departure', languages)}</Link>,
                    },                             
                ]
            },
        ]
    },
    {
        key:'/passengers',
        icon:<RiUser3Line size={SIZE}/>,
        label:getLanguage('passengers', languages),
        level:0x1ff0,
        children:[
            {
                key:'/passengers/citizen',
                icon:<RiContactsBookLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/passengers/citizen'}>{getLanguage('citizen', languages)}</Link>,
            },
            {
                key:'/passengers/lists',
                icon:<RiUserSearchFill size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/passengers/lists'}>{getLanguage('list', languages)}</Link>
            },
            {
                key:'/passengers/matched',
                icon:<RiUserFollowLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/passengers/matched'}>{getLanguage('matched', languages)}</Link>
            },
        ]
    },
    
    {
        key:'/telex',
        icon:<RiMailUnreadLine size={SIZE}/>,
        level:0x1ff0,
        label:<Link to={'/telex'}>{getLanguage('telex', languages)}</Link>,
    }
]