import { Col, Row } from "antd";
import numeral from "numeral";
import React, { Fragment, useMemo } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import Badge from "../../components/Badge";

export default function PaxMessage({ id }) {
  const url = useMemo(() => {
    return `api/v1/flight_schedule/pax_total/${id}`;
  }, [id]);

  const [paxMsg, loading] = useFetch(url);
  const { pnr, manifest, interpol, imigrasi, visa, passport, poi, crew } =
    paxMsg || {
      pnr: 0,
      manifest: 0,
      interpol: 0,
      imigrasi: 0,
      pantau: 0,
      passport: 0,
      poi: 0,
      crew: 0,
    };

  return (
    <Fragment>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Badge
            text={`PNR : ${numeral(pnr).format("000")}`}
            color={`${pnr > 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={6}>
          <Badge
            text={`API : ${numeral(manifest).format("000")}`}
            color={`${manifest > 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={6}>
          <Badge
            text={`CRW : ${numeral(crew).format("000")}`}
            color={`${crew > 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={6}>
          <Badge
            className={`${passport > 0 ? "blink_me" : ""}`}
            text={`PPT : ${numeral(passport).format("000")}`}
            color={`${passport > 0 ? "error" : "success"}`}
            block={passport > 0}
          />
        </Col>
        <Col span={6}>
          <Badge
            className={`${interpol > 0 ? "blink_me" : ""}`}
            text={`ITP : ${numeral(interpol).format("000")}`}
            color={`${interpol > 0 ? "error" : "success"}`}
            block={interpol > 0}
          />
        </Col>
        <Col span={6}>
          <Badge
            className={`${imigrasi > 0 ? "blink_me" : ""}`}
            text={`CKL : ${numeral(imigrasi).format("000")}`}
            color={`${imigrasi > 0 ? "error" : "success"}`}
            block={interpol > 0}
          />
        </Col>
        <Col span={6}>
          <Badge
            className={`${poi > 0 ? "blink_me" : ""}`}
            text={`POI : ${numeral(poi).format("000")}`}
            color={`${poi > 0 ? "error" : "success"}`}
            block={poi > 0}
          />
        </Col>
        <Col span={6}>
          <Badge
            className={`${visa > 0 ? "blink_me" : ""}`}
            text={`VIT : ${numeral(visa).format("000")}`}
            color={`${visa > 0 ? "error" : "success"}`}
            block={visa > 0}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
