import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BasePage from "../../../components/BasePage";
import SubDetailPage from "../../../components/SubDetailPage";
import FlightHistory from "./FlightHistory";
import VesselHistory from "./VesselHistory";

export default () => {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();

  const components = {
    vesel_history: VesselHistory,
    flight_history: FlightHistory,
  };

  return (
    <BasePage goBack title={`Citizen ${subdetail.replace('_',' ')}`}>
      <SubDetailPage
        basePath={pathname}
        subComponents={components}
        keyComponents={subdetail}
      />
    </BasePage>
  );
};
