import React from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiHouse } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/pnr';
export default () => {
    const {direction, id} = useParams();
    
    const Icon=direction==='arrival'?GiAirplaneArrival:GiAirplaneDeparture;
    return (
        <Detail 
            title={`flight_schedule_${direction}`} 
            icon={Icon} 
            id={id}
            url={`api/v1/pnr/detail/flight_schedules_id`}
            getBreadcumbValue={(data)=>data[0]?.flight_number}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'flight_schedule'
                    },                    
                    {
                        item:'flight_schedule_'+direction,
                        href:'/flight/schedule/'+direction
                    },
                    {
                        item:'pnr'
                    },
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{direction, id}}
        />
    )
}