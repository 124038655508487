import { Avatar, Typography } from 'antd';
import React from 'react';
import Flags from '../../../../../components/flags';
import Row from './row';
import ctyData from 'country-data';
import {GiCommercialAirplane} from 'react-icons/gi';
import Panel from '../panel/panel';
export default ({airline_id, width}) => {
    const airlineCode=airline_id?.code || airline_id?.icao || "-";
    const images=`/assets/carrier/${airlineCode.toLowerCase()}.png`;
    return (
        <Panel 
            headerLeft={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <GiCommercialAirplane size={24} />
                </div>
            }
            headerRight={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Avatar size={'small'} className="avatar-contain" src={images} />
                    <Typography.Text strong>{airline_id?.name} ({airlineCode})</Typography.Text>
                </div>
            }
            
        >
            <Row name={'name'} value={airline_id?.name}/>
            <Row name={'iata_code'} value={airline_id?.code}/>
            <Row name={'icao_code'} value={airline_id?.icao}/>
            <Row name={'country'} last value={
                <React.Fragment>
                    <Flags country_code={airline_id?.country || ''} withCountry />                             
                </React.Fragment>
            }/>
        </Panel>
    )
    
}