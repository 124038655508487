import { Avatar, Card, Col, Row, Typography } from "antd";
import numeral from "numeral";
import React from "react";
import { Link } from "react-router-dom";

export default function CardSummary({ icon, color, subTitle, content, href }) {
  return (
    <div>
      <Card className="base-shadow card-summary bg-base-theme">
        <Link to={href}>
          <Row
            className="text-center"
            style={{ alignItems: "center", justifyContent: "center" }}
            gutter={[8, 8]}
          >
            <Col
              span={8}
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
            >
              <Avatar
                style={{
                  width: "2rem",
                  height: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: color[1],
                }}
                icon={icon}
              />
            </Col>

            <Col span={16}>
              <Typography.Title
                className=" text-bold text-right"
                style={{ marginBottom: 0, color: 'white' }}
                level={4}
              >
                {numeral(content).format("0,000")}
              </Typography.Title>
              <div className="text-sub-title text-right">
                <span>{subTitle}</span>
              </div>
            </Col>
          </Row>
        </Link>
      </Card>
    </div>
  );
}
