import { Typography } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { GiHarborDock, GiHouse, GiSailboat } from 'react-icons/gi';
import { Link, useParams } from 'react-router-dom';
import Page from '../components/pages/table';
import Item from '../components/category/items/vessel';
import Search from './search';
import {ExcelExporter} from './excel_button';

export default () => {
    const {direction, type} = useParams();
    const [vessels, setVessels]=useState([]);
    const Icon=useMemo(()=>{
        return direction==='arrival'?GiHarborDock:GiSailboat;
    }, [direction])
    const [searchValue, setSearchValue]=useState({schedule_date:[moment(), moment().add(1, 'day')], tpi:null});
    const formatedSearchVal=useMemo(()=>{
        const {schedule_date:[from, to], tpi} = searchValue;
        let qry=!!tpi?( direction==='arrival'?{destination_id:tpi?.station_id?._id}:{origin_id:tpi?.station_id?._id}):{};
        return {schedule_date:[from.format('YYYYMMDD'), to.format("YYYYMMDD")], timestamp:moment().unix(), ...qry, vessel_type:type};
    }, [searchValue, type]);
    const uri=`api/v1/vessel/${direction}/inquiry`;
    const url=`/vessel/${type}/${direction}`;
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            title={`vessel_${direction}`} 
            OtherComponent={<ExcelExporter flight_records={vessels} direction={direction} />}
            icon={Icon} 
            filterValues={['vessel_name', 'ferry_key', 'origin', 'dest', 'operator_id']}
            onDataEvent={(data)=>{
                if(!!data && Array.isArray(data)){
                    setVessels(data);
                    const mData=data.map((row, idx)=>{
                        const currentTime=moment().unix();
                        const scheduleTime=row?.is_arrival?row.unixSta:row.unixStd;
                        const diff=Math.abs(currentTime - (scheduleTime || row?.unix_time || 0));
                        return {...row, number:idx+1, diff};
                    });
                    return mData.sort((a, b)=>a.diff - b.diff);
                }
                return [];
            }}
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'vessel'
                    },
                    {
                        item:'vessel'+direction
                    }
                ]
            }
            renderItem={(row, idx)=>row && (<Item row={row} key={idx} direction={direction}  url={url}/>)}
        />
    )
}