import React, { useMemo } from 'react';
import {red, green, orange} from '@ant-design/colors';
import { Typography } from 'antd';

export default ({flag, message, row})=>{

    const [redFlag, msg]=useMemo(()=>{
        if(!row)return [false,''];
        const { cekal_online_resp, interpol_tdawn, interpol_nominal, interpol_sltd } = row;
        const msgs=[];
        const c=Array.isArray(cekal_online_resp?.response) && cekal_online_resp.response[0].response_code==='00';
        if(c)msgs.push('CKL');
        const dawn_resp=interpol_tdawn?.response && `${interpol_tdawn?.response}`.indexOf('SearchResult i:nil="true"')<0;
        if(dawn_resp)msgs.push('DWN');
        const nmnl_resp=!!interpol_nominal?.response?.SearchPassportSplitNamesResult?.Records;
        if(nmnl_resp)msgs.push('NOM');
        const sltd_resp=interpol_sltd?.response && `${interpol_sltd?.response}`.indexOf('SearchResult i:nil="true"')<0;
        if(sltd_resp)msgs.push('SLT');
        
        return [c || dawn_resp || nmnl_resp || sltd_resp, msgs.join(',')];
    }, [row]);
    const orangeFlag=(flag & 24)>0;
    const color=redFlag?red[5]:(orangeFlag?orange[5]:green[5]);
    const borderColor=redFlag?red[8]:(orangeFlag?orange[8]:green[8]);
    return (
        <div style={{display:'flex', flexDirection:'row', gap:8}}>
            <div style={{width:24, height:24, background:color, borderRadius:24, border:`${borderColor} 2px solid`}} />
            {!!msg &&  <Typography.Text ellipsis title={msg}>{msg}</Typography.Text>}
        </div>
    )
}