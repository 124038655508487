import { blue, geekblue, green, purple } from "@ant-design/colors";
import { Avatar, Card, Col, Row, Tabs, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { FaPassport } from "react-icons/fa";
import { TbCalendarTime } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Flags from "../../../../components/flags";
import { useFontSize } from "../../../../hooks/useDimension";
import { useFetch } from "../../../../hooks/useFetch";
import Badge from "../../components/Badge";
import BaseDetailPage from "../../components/BaseDetailPage";
import BasePage from "../../components/BasePage";
import RowList from "../../components/RowList";
import { getTypeDocument, renderGender } from "../../library";
import FlightHistory from "./subdetail/FlightHistory";
import VesselHistory from "./subdetail/VesselHistory";

export default function Detail() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const fontSize = useFontSize()
  const [flightHistori, loadingFlightHistory] = useFetch(
    `api/v1/passengers/detail/citizen_id/${id}`
  );
  const [vesselHistori, loadingVesselHistory] = useFetch(
    `api/v1/vessel_pax/detail/citizen_id/${id}`
  );

  return (
    // <BasePage title="Detail Citizen">
    <BaseDetailPage
      url={`api/v1/citizen/detail/${id}`}
      renderContent={(data) => {
        const {
          fullname,
          gender,
          dob,
          nationality,
          jenis_doc,
          no_id,
          issued_id_country,
          last_active,
          id_expiry
        } = data || {
          fullname: "",
          gender: "",
          dob: "",
          nationality: "",
          jenis_doc: "",
          no_id: "",
          issued_id_country: "",
          id_expiration: "",
          last_active: "",
          id_expiry:''
        };

       
        return (
          <Fragment>
            <Row gutter={[8, 8]} className="my-4">
              <Col xs={24} sm={24} md={12} lg={12}>
                <Card style={{ height: "100%" }}>
                  <Row gutter={[8, 8]}>
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Avatar
                        alt={""}
                        size="large"
                        style={{
                          backgroundColor: geekblue[7],
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "6rem",
                          height: "6rem",
                        }}
                        className={"avatar-contain"}
                        icon={<FaPassport size={"3rem"} />}
                        // src={`${getLogoAirline(airline_id?.code)}`}
                      />
                      {/* {!!watch_flag ? (
                          <Badge color={"error"} text={"WATCHLIST"} />
                        ) : (
                          ""
                        )} */}
                    </Col>
                    <Col span={24} className="text-center">
                      <Typography.Title className="base-text-color" style={{fontSize: fontSize.lg}}>
                        {fullname} {renderGender(gender)}
                      </Typography.Title>

                      <Typography.Text className="text-sub-title ">
                        {" "}
                        {moment(dob).format("DD-MMM-YYYY")}
                      </Typography.Text>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 24,
                          marginTop: 8,
                        }}
                      >
                        <Flags
                          country_code={nationality}
                          withCountry
                          countryFormat={"alpha3"}
                          countryWidth
                        />

                        <Badge
                          color={"success"}
                          text={
                            <div>
                              <TbCalendarTime />
                              {` ${moment(id_expiry).format("DD-MMM-YYYY")}`}
                            </div>
                          }
                        />
                      </div>
                      {/* <div> */}

                      {/* </div> */}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Card title={"Citizen Document"} style={{ height: "100%" }}>
                  {/* <RowList title={"Name"} content={fullname} dividerBottom />
                <RowList
                  title={"Gender"}
                  content={
                    <div className="rowslist-inline-flex">
                      {renderGender(gender)} {gender}
                    </div>
                  }
                  dividerBottom
                />
                <RowList title={"Birthday"} content={dob} dividerBottom />
                <RowList
                  title={"Nationality"}
                  content={
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Flags
                        country_code={nationality}
                        withCountry
                        countryWidth
                        countryFormat={"alpha3"}
                      />
                    </div>
                  }
                  dividerBottom
                /> */}
                  <RowList title={"Doc Type"} content={getTypeDocument(jenis_doc)} />
                  <RowList title={"Doc Number"} content={no_id} />
                  <RowList
                    title={"Issued Country"}
                    content={
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Flags
                          country_code={issued_id_country}
                          withCountry
                          countryWidth
                          countryFormat={"alpha3"}
                        />
                      </div>
                    }
                  />
                  <RowList
                    title={"Last active"}
                    content={moment(last_active).format("DD-MMM-YYYY")}
                    //   dividerBottom
                  />
                </Card>
              </Col>
            </Row>
            <Card className="my-4">
            <Tabs >
              <Tabs.TabPane tab="Flight History" key="item-1">
                <FlightHistory dataSource={flightHistori} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Vessel History" key="item-2">
                <VesselHistory dataSource={vesselHistori} />
              </Tabs.TabPane>
            </Tabs>
            </Card>
          </Fragment>
        );
      }}
    />
    // </BasePage>
  );
}
