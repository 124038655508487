import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.code,
            name:"IATA",
            id:'code'
        },
        {
            selector:(row)=>row?.icao,
            name:"ICAO",
            id:'icao'
        },
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name'
        },
        {
            selector:(row)=>row?.country,
            name:"Country",
            id:'country'
        },
        {
            selector:(row)=>row?.email,
            name:"Email",
            id:'email'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'created_at'
        },
    ]
    return (
        <TablePage
            title={"Master Airline"}
            url="api/v1/airlines"
            actionPosition={'first'}
            createPath="/master/airline/create"
            editPath={"/master/airline/edit"}
            columns={columns}            
        />
    )
}