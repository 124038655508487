import { Avatar, Typography } from 'antd';
import React, { useMemo } from 'react';
import { IoIosPerson, IoMdPeople } from 'react-icons/io';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Table from '../lists/table';
import { useFetch } from '../../../../../hooks/useFetch';
import { Link } from 'react-router-dom';
import { GiCommercialAirplane } from 'react-icons/gi';
import WatchFlag from './watchflag';

export default ({_id,}) => {
    const [data, loading] = useFetch('api/v1/passengers/detail/citizen_id/'+_id);

    const [,getLan]=useLanguage();
    return (
        <Panel
            headerLeft={<GiCommercialAirplane size={24}/>}
            headerRight={<Typography.Text>{getLan('flight_history')}</Typography.Text>}
        >
            <Table 
                data={data || []}
                pagination={false}
                filterField={['flight_number', 'origin', 'dest']}
                columns={[
                {
                    title:'airline',
                    dataIndex:'flight_schedule_id',  
                    width:'140px',     
                    render:(val, rec)=>{
                        const {airline_id} = val;
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:12, alignItems:'center'}}>
                                <Link to={"/passengers/detail/"+rec._id}><IoIosPerson size={24} /></Link>
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                    <Avatar 
                                        className={'avatar-contain'}
                                        src={`/assets/carrier/${airline_id?.code?.toLowerCase()}.png`}
                                        size="large"
                                        alt={airline_id?.code}                                
                                    />
                                    <Typography.Text>{airline_id?.name}</Typography.Text>
                                </div>
                            </div>
                        )
                    }             
                },                
                {
                    title:'flight_number',
                    dataIndex:'flight_schedule_id',       
                    width:'120px',     
                    render:(val)=>val?.flight_number
                },
                {
                    title:'flight_date',
                    dataIndex:'flight_schedule_id',       
                    width:'120px',     
                    render:(val)=>{
                        const {is_arrival, _id} = val;
                        const direction=is_arrival?'arrival':'departure';
                        return <Link to={`/flight/schedule/${direction}/${_id}`}> {val?.flight_date} </Link>
                    }
                },
                {
                    title:'pnr',
                    dataIndex:'pnr',       
                    width:'120px',     
                    render:(val, rec)=>{
                        const {pnr_id} = rec;
                        return <Link to={`/pnr/detail/${pnr_id?._id}`}> {val} </Link>
                    }
                },
                {
                    title:'origin',
                    dataIndex:'flight_schedule_id',       
                    width:'120px',     
                    render:(val)=>val?.origin_id?.code + ' - '+ val?.origin_id?.kota
                },
                {
                    title:'dest',
                    dataIndex:'flight_schedule_id',       
                    width:'120px',     
                    render:(val)=>val?.dest_id?.code+ ' - '+ val?.dest_id?.kota
                },
                {
                    title:'status',
                    width:'40px',     
                    dataIndex:'watch_flag',       
                    render:(val, rec)=><WatchFlag flag={val} row={rec} />
                },
            ]}
            />
        </Panel>
    )
}