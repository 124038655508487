import { Button, Form, Input, Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLanguage } from "../../../../hooks/useLanguage";
import AutoComplete from "../../../../components/autocomplete/Online";

export default function ModalFilterFlight({
  open=false,
  state=null,
  setState=null,
  handleModal=null,
  title='',
  handleSearch=null
}) {
  const [, getLang] = useLanguage();
  const [model, setModel] = useState(state)

  return (
    <Modal
      title={`Filter flight ${title}`}
      visible={open}
      onCancel={handleModal}
      footer={null}
      width={400}
    >
      <Form
        layout="vertical"
        initialValues={state}
        onFinish={handleSearch}
        autoComplete="off"
      >
        {/* <Form.Item
                    label={getLang("flight_date")}
                    style={{marginBottom:4}}
                    name="flight_date"
                    rules={[{
                        validator:async(rule, value)=>{
                            // console.log({rule, value})
                            const [from, to]=value;
                            const diff=moment.duration(to.diff(from)).days();
                            if(diff>7){
                                throw new Error('Cannot more than 7 days');
                            }
                            return true;
                        }
                    }]}
                >
                    <DatePicker.RangePicker 
                        showTime={false}
                        value={state.flight_date}
                        format={'DD-MMM-YYYY'}
                        onChange={(val, strVal)=>{
                            setState({...state, flight_date:val});
                        }}
                    />
                </Form.Item> */}
        <Form.Item
          label={getLang("airline")}
          style={{ marginBottom: 4 }}
          name="airline"
        >
          <AutoComplete
            getKey={(opt) => opt._id}
            getSelectedOptions={(opt, val) => opt._id === val._id}
            getValues={(val) => `${val.code || val.icao} - ${val.name}`}
            uri="api/v1/airlines"
            onChange={(val) => setModel({ ...model, airline: val })}
            value={model?.airline}
          />
        </Form.Item>
        <Form.Item
          label={getLang("origin")}
          style={{ marginBottom: 4 }}
          name="origin"
        >
          <AutoComplete
            getKey={(opt) => opt._id}
            getSelectedOptions={(opt, val) => opt._id === val._id}
            getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
            uri="api/v1/airport"
            onChange={(val) => setModel({ ...model, origin: val })}
            value={model?.origin}
          />
        </Form.Item>
        <Form.Item
          label={getLang("dest")}
          style={{ marginBottom: 4 }}
          name="dest"
        >
          <AutoComplete
            getKey={(opt) => opt._id}
            getSelectedOptions={(opt, val) => opt._id === val._id}
            getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
            uri="api/v1/airport"
            onChange={(val) => setModel({ ...model, dest: val })}
            value={model?.dest}
          />
        </Form.Item>

        <Form.Item label={getLang("flight_number")} name="flight_number">
          <Input />
        </Form.Item>

        <Button block type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Modal>
  );
}
