import { GiAirplane } from "react-icons/gi";
import {IoIosPeople} from 'react-icons/io';
import {BsMailbox} from 'react-icons/bs';
import { RiDashboard3Line, RiSailboatLine, RiSearchEyeLine } from "react-icons/ri";
import { FaCogs } from "react-icons/fa";
import DashboardMenu from './menus/dashboard';
import FlightMenu from './menus/flight';
import VesselMenu from './menus/vessel';
import PaxMenu from './menus/pax';
import TelexMenu from './menus/telex';
import OtherMenu from './menus/others';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMemo } from "react";
const AllCategory=[
    {
        caption:'dashboard',
        Icon:RiDashboard3Line
    },
    {
        caption:'search',
        Icon:RiSearchEyeLine
    },
    {
        caption:"flight",
        Icon:GiAirplane
    },
    {
        caption:'vessel',
        Icon:RiSailboatLine
    },
    {
        caption:'poi',
        Icon:IoIosPeople
    },
    {
        caption:"telex",
        Icon:BsMailbox
    },
    {
        caption:'others',
        Icon:FaCogs
    }
]

const FlightCategory=[
    {
        caption:'dashboard',
        Icon:RiDashboard3Line
    },
    {
        caption:'search',
        Icon:RiSearchEyeLine
    },
    {
        caption:"flight",
        Icon:GiAirplane
    },
    {
        caption:'poi',
        Icon:IoIosPeople
    },
    {
        caption:"telex",
        Icon:BsMailbox
    },
    {
        caption:'others',
        Icon:FaCogs
    }
]

const VesselCategory=[
    {
        caption:'dashboard',
        Icon:RiDashboard3Line
    },
    {
        caption:'search',
        Icon:RiSearchEyeLine
    },
    {
        caption:'vessel',
        Icon:RiSailboatLine
    },
    {
        caption:'poi',
        Icon:IoIosPeople
    },
    {
        caption:"telex",
        Icon:BsMailbox
    },
    {
        caption:'others',
        Icon:FaCogs
    }
]

const useMenu=()=>{
    const {userdata:{level, tpis}} = useSelector(state=>state.auth);
    const [seaport, airport] = useMemo(()=>{
        const [seaport_exists]=tpis.filter(({station_type})=>station_type==='seaport');
        const [airport_exists]=tpis.filter(({station_type})=>station_type==='airport');
        return [seaport_exists, airport_exists]
    }, [tpis])
    const [menu, setMenu]=useState([]);
    const [category, setCategory]=useState([]);
    useEffect(()=>{
        if((level&0x1fffe)>0){
            setMenu([
                ...DashboardMenu,
                ...FlightMenu,
                ...VesselMenu,
                ...PaxMenu,
                ...TelexMenu,
                ...OtherMenu
            ])
            setCategory(AllCategory);
        }else if(!!seaport){
            setMenu([
                ...DashboardMenu,
                ...VesselMenu,
                ...PaxMenu,
                ...TelexMenu,
                ...OtherMenu
            ])
            setCategory(VesselCategory);
        }else if(!!airport){
            setMenu([
                ...DashboardMenu,
                ...FlightMenu,
                ...PaxMenu,
                ...TelexMenu,
                ...OtherMenu
            ])
            setCategory(FlightCategory);
        }
    }, [level, seaport, airport]);
    return [menu, category];
}

export default useMenu;