import { blue, magenta } from '@ant-design/colors'
import { Avatar, List, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { MdChair, MdPeopleOutline } from 'react-icons/md'
import Flags from '../../../../../components/flags'
import Badge from '../../../components/Badge'
import { getPaxType } from '../../../library'

export default function Passengerlist({dataSource}) {
    const {
        _id,
        passenger_name,
        gender,
        dob,
        pnr,
        doc_number,
        pnr_id,
        doc_nationality,
        doc_expiration,
        watch_flag,
        doc_expiry,
        age,
        doc_country,
        pax_type,
        reference,
      } = dataSource || {
        _id: "",
        passenger_name: "",
        dob: "",
        doc_country: "",
        doc_number: "",
        pnr_id: "",
        gender: "",
        pnr: "",
        doc_expiration: "",
        doc_expiry: "",
        doc_nationality: "",
        watch_flag: "",
        age: "",
        pax_type: "",
        reference: ""
      };
      const {booking_code, seat_number} = reference || {booking_code:"", seat_number:""}

  return (
    <List.Item>
    <List.Item.Meta
      avatar={
        <Avatar
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            backgroundColor: `${
              gender?.toLowerCase() === "m" ||
              gender?.toLowerCase() === "male"
                ? blue[4]
                : magenta[4]
            }`,
          }}
          icon={<MdPeopleOutline />}
        />
      }
      title={
        <div>
          <Typography.Title level={5}>
            {passenger_name?.toUpperCase()}
          </Typography.Title>
        </div>
      }
      description={
        <div>
          <p className="mb-1">
            {moment(dob).format("DD-MMM-YYYY")} ({age} year){" "}
          </p>
          <p className="mb-1">
            PNR : {pnr} / {pnr_id?.total_pax} pax{" "}
          </p>
          <div className="rowslist-inline-flex">
            <p className="mb-1">
              Passport : {doc_number} /{" "}
              {moment(doc_expiry).format("DD-MMM-YYYY")}
            </p>
            {/* {!!doc_country &&
            !!doc_nationality &&
            doc_country !== doc_nationality ? (
              <Flags
                country_code={doc_country}
                // withCountry
                countryWidth={50}
                // countryFormat={"alpha3"}
              />
            ) : (
              ""
            )} */}
          </div>
        </div>
      }
    />

    <div>
      {!!seat_number && <div className="mb-4">
        <Badge color={"def"} text={<div className='rowslist-inline-flex'>
          <MdChair />
          {seat_number}
        </div>} />
      </div>}
      <Flags
        country_code={doc_nationality}
        withCountry
        countryWidth={50}
        countryFormat={"alpha3"}
      />
      {watch_flag !== 0 ? (
        <div className="mt-4">
          <Badge color={"error"} text={"WATCHLIST"} />
        </div>
      ) : (
        ""
      )}
    </div>
  </List.Item>
  )
}
