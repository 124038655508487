import { Button, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useLanguage } from "../../../../../../hooks/useLanguage";

export default function ModalFilterLogs({
  open = false,
  state = null,
  setState = null,
  handleModal = null,
  title = "",
  handleSearch = null,
}) {
  const [, getLang] = useLanguage();
  const [model, setModel] = useState(state);

  return (
    <Modal
      title={`Filter Queue`}
      visible={open}
      onCancel={handleModal}
      footer={null}
      width={400}
    >
      <Form
        layout="vertical"
        initialValues={state}
        onFinish={handleSearch}
        autoComplete="off"
      >


        <Form.Item
          label={getLang("process_name")}
          style={{ marginBottom: 4 }}
          name="process_name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={getLang("command")}
          style={{ marginBottom: 4 }}
          name="command"
        >
          <Input />
        </Form.Item>


        <Button className="mt-3" block type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Modal>
  );
}
