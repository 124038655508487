import React from 'react';
import { useLanguage } from '../../../../../hooks/useLanguage';
import ctyData from 'country-data';
import { Typography } from 'antd';
import Row from './row';
import Flags from '../../../../../components/flags';
import Panel from '../panel/panel';

export default ({ direction, data, width }) => {
    const [,getLang]= useLanguage();
    const title=getLang(direction);
    const airlineCode=data?.code || data?.icao_code || "-";
    const timeoffset=Math.ceil(data?.unix_offset / 3600);
    return (
        <Panel
            headerLeft={<Typography.Text>{title}</Typography.Text>}
            headerRight={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography.Text ellipsis strong>{data?.kota}</Typography.Text>
                    <Typography.Text strong>({airlineCode})</Typography.Text>
                </div>
            }
        >
            <Row name={'name'} value={data?.name}/>
            <Row name={'city'} value={data?.kota}/>
            <Row name={'iata_code'} value={data?.code}/>
            <Row name={'icao_code'} value={data?.icao_code}/>
            <Row name={'country_code'} value={
                <React.Fragment>
                    <Flags country_code={data?.country || ''} withCountry />
                </React.Fragment>
            }/>
            <Row last={true} name={'timezone'} value={`${data?.timezone} (${timeoffset>0?'+':''}${timeoffset})`}/>
        </Panel>
    )
}