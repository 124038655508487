import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { useState } from 'react';
import { GiLockedDoor } from 'react-icons/gi';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLanguage } from '../../../../hooks/useLanguage';
import { PostAPI } from '../../../../redux';
import Panel from '../components/panel/panel';

export default () =>{
    const [,getLan] = useLanguage();
    // const [model, setModel] = useState({current_password:'', new_password:'', confirm_password:''});
    const dispatch=useDispatch();
    return (
        <div style={{display:'flex', flexDirection:'column', height:'100%', width:'100%', alignItems:'center', justifyContent:'start' }}>
            <div style={{width:560}}>
                <Panel headerLeft={<GiLockedDoor size={24} />} headerRight={getLan('change_password')}>
                    <div style={{paddingInline:32, paddingBlock:16}}>

                        <Form 
                            layout='horizontal'
                            labelCol={{span:8}}
                            onFinish={(value)=>{
                                const {current_password, new_password} = value;
                                dispatch(
                                    PostAPI({url:"auth/changePassword", data:{current:current_password, password:new_password, }})
                                ).then((r)=>{
                                    // console.log(r);
                                    if(!!r.payload) toast.success("Success Update Password");
                                })
                            }}
                        >
                            <Form.Item
                                label={getLan('current_password')}
                                name={'current_password'}
                                rules={[{required:true, message:getLan('curr_passw_message')}]}
                                hasFeedback
                            >
                                <Input.Password autoFocus />
                            </Form.Item>
                            <Form.Item
                                label={getLan('new_password')}
                                name={'new_password'}
                                rules={[{required:true, message:getLan('new_passw_message')}]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label={getLan('confirm_password')}
                                name={'confirm_password'}
                                dependencies={['new_password']}
                                hasFeedback
                                rules={[
                                    {
                                        required:true,
                                        message:getLan("conf_passw_message")                                        
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value){
                                            if(!value || (getFieldValue('new_password') === value)){
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(getLan('passw_not_match'));
                                        }
                                    })
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'end'}}>
                                    <Button icon={<SaveOutlined />} type='primary' htmlType='submit'>{getLan('Save')}</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </Panel>
            </div>
        </div>
    )
}