import CitizenPage from '../pages/citizen';
import CitizenDetailPage from '../pages/citizen/detail';
export default [    
    {
        path:'/passengers/citizen',
        exact:true,
        Component:CitizenPage
    },
    {
        path:'/passengers/citizen/detail/:id',
        exact:true,
        Component:CitizenDetailPage
    },
];