import React, { useState } from 'react';
import Tabs from '../../../components/tabs';
import Detail from './details/typeb';
export default ({data}) => {
    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            <Tabs 
                selTab={4}
                tabs={[
                    {
                        id:0,
                        caption:'FRScheduler',
                        href:`/others/bot/fr`,
                    },
                    {
                        id:4,
                        caption:'telex',
                        href:`/others/bot/telex`,
                    },
                    {
                        id:1,
                        caption:'process_status',
                        href:`/others/bot/process/status`,
                    },
                    {
                        id:2,
                        caption:'process_logs',
                        href:`/others/bot/process/logs`,
                    },
                ]}
            />
            <Detail data={data} />
        </div>
    )
}