import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { GiNotebook } from 'react-icons/gi';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
export default ({data, width}) => {
    const [,getLan] = useLanguage();
    const ssr=useMemo(()=>{
        if(!data)return [];
        const {other_ssr, ...rest} = data;
        const result=[];
        for (const key in rest) {
            if (Object.hasOwnProperty.call(rest, key)) {
                const ssr = rest[key];
                result.push({name:key, value:ssr});
            }
        }
        for (const kk in other_ssr) {
            if (Object.hasOwnProperty.call(other_ssr, kk)) {
                const ss = other_ssr[kk];
                result.push({name:kk, value:ss});
            }
        }
        return result;
    }, [data]);

    return (
        <Panel
            headerLeft={<GiNotebook size={24} />}
            headerRight={<Typography.Text>{getLan('ssr')}</Typography.Text>}
        >
            <ul>
                {
                    ssr.map((msg, idx)=>(
                        <li key={idx}>
                            <Typography.Text strong>{getLan(msg.name).toUpperCase()}</Typography.Text> - {msg.value}
                        </li>
                    ))
                }
            </ul>
        </Panel>
    )
}