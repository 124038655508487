import { Button, Card, Col, Row, Typography } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import BasePage from "./BasePage";

export default function CardListPage({
  goBack,
  datas ,
  url ,
  title,
  paginationUrl,
  searchApiProps,
  onSortData,
  fixedPagination,
  CardContent,
  searchProps ,
  datePickerChange,
  FilterComponent,
  sortDependency,
  onExportExcel,
}) {
  const [fetchedData, setFetchedData] = useState([]);
  // const sortedData = useMemo(()=>{
  //   const sortData =
  //     (!!onSortData &&
  //       typeof onSortData === "function" &&
  //       onSortData(fetchedData)) ||
  //     fetchedData;
  //   return sortData;

  // },[onSortData, fetchedData, sortDependency])

  

  return (
    <BasePage
      title={title}
      goBack={goBack}
      onExportExcel={onExportExcel}
      datas={datas}
      url={url}
      paginationUrl={paginationUrl}
      searchProps={searchProps}
      sortDependency={sortDependency}
      searchApiProps={searchApiProps}
      getData={(data)=>{
        setFetchedData(data)
      }}
      onSortData={onSortData}
      // infinitScroll={infinitScroll}
      datePickerChange={datePickerChange}
      FilterComponent={FilterComponent}
      fixedPagination={fixedPagination}
    >
      <div className="my-3 p-4">
        {!!fetchedData && !!fetchedData?.length  && (
          <Fragment>
            <Row gutter={[16, 16]}>
              {fetchedData.map((item, idx) => (
                <Col key={idx} xs={24} sm={24} md={12} lg={12}>
                  <Card className="card-list" style={{height: '100%'}}>
                    {!!CardContent && CardContent({ item })}
                  </Card>
                </Col>
              ))}
            </Row>
          </Fragment>
        ) }
      </div>
    </BasePage>
  );
}
