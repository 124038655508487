import { Fragment, useEffect} from 'react';
import MainRouter from './content/MainRouter';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import CFG from './config/env';

const {Title} = CFG;

LoadingOverlay.propTypes=undefined;

function App() {
  const {loading}  = useSelector(state=>state.apps);
  Aos.init({
    duration:1500,
    mirror:true,
    anchorPlacement:'top-top'
  })
  useEffect(()=>{
    document.title=Title;
  },[])
  return (
    <Fragment>
      <LoadingOverlay 
        active={loading}
        spinner
        text="Loading"        
      >
        <MainRouter />
      </LoadingOverlay>
    </Fragment>
  );
}

export default App;
