import { grey } from "@ant-design/colors";
import { Col, Divider, Row, Typography } from "antd";
import React, { Fragment } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useFontSize } from "../../../hooks/useDimension";
import ConditionalLink from "./ConditionalLink";

const RowsListLink = ({ title, content, dividerBottom, linkTo, ...props }) => {
  const fontSize = useFontSize()
  return (
    <div>
      <Row style={{ alignItems: "center" }}>
        <Col span={22}>
          <Typography.Title level={5} style={{ marginBottom: 2,fontSize:fontSize.md }}>
            {title}
          </Typography.Title>

          {!!content && typeof content === "object" ? (
            <div className="text-sub-title">{content}</div>
          ) : (
            <Typography.Text className="text-sub-title">
              {content}
            </Typography.Text>
          )}
        </Col>
        <Col className="text-right" span={2}>
          {linkTo !== "#" ? (
            <MdOutlineArrowForwardIos color={grey[4]} size={18} />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

export default function RowList({
  title,
  content,
  linkTo,
  dividerBottom,
  ...props
}) {
  const fontSize = useFontSize()

  return (
    <Fragment {...props}>
      <ConditionalLink to={linkTo} condition={!!linkTo} {...props}>
        {!!linkTo ? (
          <RowsListLink
            title={title}
            content={content}
            linkTo={linkTo}
            dividerBottom={dividerBottom}
          />
        ) : (
          <Row style={{ alignItems: "center" }}>
            <Col span={12}>
              <Typography.Text>{title}</Typography.Text>
            </Col>
            <Col span={12}>
              <div className="text-right">
                <Typography.Title style={{fontSize:fontSize.md}}>{content}</Typography.Title>
              </div>
            </Col>
          </Row>
        )}
      </ConditionalLink>
      {!!dividerBottom ? <Divider className="my-1" /> : ""}
    </Fragment>
  );
}
