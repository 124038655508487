import { Button, Form, Input } from 'antd';
import React from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import Panel from '../components/panel/panel';
import {SaveOutlined, UserAddOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DonwloadTemplateWatchlist, UploadWatchlist } from './xls_modal';
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../redux';
import moment from 'moment';

export default ({onSearchCallback, onFormRefresh}) =>{
    const [,getLan]=useLanguage();
    const history=useHistory();
    const dispatch=useDispatch();
    return (
        <div style={{width:320}}>

            <Panel 
                headerLeft={getLan("search")}
                
            >
                <div style={{padding:16}}>
                    <Form
                        autoComplete='off'

                        onFinish={val=>{
                            onSearchCallback && typeof onSearchCallback==='function' && onSearchCallback(val);
                        }}
                    >
                        <div style={{display:'flex', flexDirection:'column', rowGap:8}}>
                            <Form.Item label={getLan("name")} name={'name'}>
                                <Input autoFocus />
                            </Form.Item>
                            <div style={{display:'flex', flexDirection:'row-reverse', gap:8, }}>
                                <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                                    Search
                                </Button>
                                <Button icon={<UserAddOutlined />} onClick={()=>{
                                    history.push('/passengers/watchlist/create');
                                }} >
                                    Add
                                </Button>
                                <UploadWatchlist onFormSubmit={(rows)=>{
                                    const data=rows.map(({dob, ...rest})=>({...rest, dob:moment(dob).format('DD-MMM-YYYY')}))
                                    dispatch(PostAPI({data, url:'api/v1/watchlist/batch'})).then(r=>{
                                        if(!!r.payload){
                                            onFormRefresh();
                                        }
                                    })
                                }} />
                            </div>
                            <DonwloadTemplateWatchlist />
                        </div>
                    </Form>
                </div>
            </Panel>
        </div>
    )
}