import { Form, Input } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Offline'
import {useSelector} from 'react-redux'

const DefModel={
    code:'',
    icao:'',
    name:'',
    country:'',
    prefix:'',    
    email:"",
    airport_id:null
}

export default ()=>{
    const [model, setModel]=useState(DefModel);
    const {airportCache:airport_cache} = useSelector(state=>state.apps)
    const valid=useMemo(()=>{
        return true;
    }, [model]);
    return (
        <FormPage 
            url={'api/v1/airlines'}
            callbackPath={'/master/airline'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{span:8}}
            labelCol={{span:3}}
            title={"Airline"}            
            emptyModel={DefModel}
        >
            <Form.Item name={'code'} label="IATA Code" rules={[{required:true}]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item name={'icao'} label="ICAO Code" >
                <Input  />
            </Form.Item>
            <Form.Item name={'name'} label="Name" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'prefix'} label="Prefix">
                <Input  />
            </Form.Item>
            <Form.Item name={'email'}  label="email">
                <Input type='email' />
            </Form.Item>
            <Form.Item name={'country'} label="Country" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
        </FormPage>
    )
}