import React from 'react';
import Table from '../../lists/table';
import moment from 'moment';

export default ({data}) => {
    console.log({data});
    return (
        <Table 
                data={data || []}
                pagination={false}
                filterField={['no_permohonan', 'nama_satker']}
                columns={[
                    
                    {
                        title:'doc_type',
                        dataIndex:'jenis_paspor',  
                    }, 
                    {
                        title:'no_permohonan',
                        dataIndex:'no_permohonan',       
                    },
                    {
                        title:'gender',
                        dataIndex:'jenis_kelamin',  
                    }, 
                    {
                        title:'nama_satker',
                        dataIndex:'nama_satker',  
                    },                
                    {
                        title:'doc_expiry',
                        dataIndex:'tanggal_masa_berlaku_paspor',       
                        render:(val)=>!!val?moment(val).format('DD-MMM-YYYY'):'-'
                    },
            ]}
        />
    )
}