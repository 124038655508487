import React, { useMemo } from "react";
import { Avatar, Dropdown, Typography, Menu, Button } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { doLogout } from "../../../../redux";
import { UserOutlined } from "@ant-design/icons";
import { MdAccountCircle, MdKeyboardArrowDown, MdOutlineAccountCircle } from "react-icons/md";
import Languages from "./languages";
import { createUseStyles } from "react-jss";
import { IoMdLogOut } from "react-icons/io";
import { RiAccountCircleFill, RiLogoutCircleRFill } from "react-icons/ri";

const useStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 8,
   
  },
  btnLanguage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnAvatar: {
    cursor: "pointer",
    display:"flex",
    // gap:12,
    alignItems: "center",
  },
}));

export const UserProfile = ({
  username,
  level,
  isColapsed,
  noMargin,
  noGrow,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const OverlayMenu = () => {
    return (
      <Menu
        items={[
          {
            label: <Link to={"/profile"}>Profile</Link>,
            key: "Profile",
            icon: <MdAccountCircle size={20} />
          },
          {
            label: <Link onClick={() => dispatch(doLogout())}>Logout</Link>,
            key: "Logout",
            icon: <RiLogoutCircleRFill size={20} />
          },
        ]}
      />
    );
  };

  // const margin=useMemo(()=>{
  //     if(noMargin===true)return {};
  //     return {marginRight:isColapsed?128:248};
  // }, [noMargin, isColapsed])

  return (
    // <div className='user-profile-wrapper' style={{ flexGrow:noGrow?'unset':1}}>
    //     <div className='user-profile-text-wrapper'>
    //         <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', gap:8, height:24, alignItems:'center'}}>
    //             <Typography.Text strong type='success' className='user-profile-text'>
    //                 {username}
    //             </Typography.Text>
    //             <Language />
    //         </div>
    //         <Typography.Text ellipsis type='success' className='user-profile-text user-profile-status'>
    //             {level}
    //         </Typography.Text>
    //     </div>
    //     <Dropdown placement='bottomLeft' overlay={OverlayMenu} trigger={['click']}>
    //         <Avatar size={'large'} icon={<UserOutlined />} />
    //     </Dropdown>
    // </div>
    <div className={classes.root}>
      <Button
        className={classes.btnLanguage}
        type="text"
        shape="circle"
        size="large"
        icon={<Languages />}
      />
      <Typography.Text>{username}</Typography.Text>
      <Dropdown
        className={classes.btnAvatar}
        placement="bottomRight"
        overlay={OverlayMenu}
        arrow
        trigger={["click"]}
      >
        <div>
          <Avatar size={"large"} icon={<UserOutlined />} />
          <MdKeyboardArrowDown size={20} />
        </div>
      </Dropdown>
    </div>
  );
};
