import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { GiCalendar, GiCarrier, GiDirectionSigns, GiFamilyHouse, GiWorld } from 'react-icons/gi';
import { FaBirthdayCake } from 'react-icons/fa'
import { IoCalendarNumberSharp } from 'react-icons/io5'
import CFG from '../../../../../config/env';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { LightenDarkenColor } from '../../../utils';
import { Typography } from 'antd';
import { blue, green } from '@ant-design/colors';

const {BgColor} = CFG;

export const BtnTab=({Icon, color, bgColor, hoverBgColor, title, hoverColor, onClick, tabIndex, tabActive, last, first})=>{
    const [hover, setHover]=useState(false);
    const [style, warna] = useMemo(()=>{
        const borderInline=(!last)?(first?{}:{borderLeft:`0.001rem solid ${bgColor}`}):{borderInline:`0.001rem solid ${bgColor}`};
        if(!hover){
            if(tabIndex===tabActive){
                return [{background:bgColor, ...borderInline}, color]
            }
            return [{background:color, ...borderInline}, bgColor];
        }
        return [{background:hoverBgColor, ...borderInline}, hoverColor];
    }, [tabIndex, tabActive, hover, hoverBgColor, hoverColor, last, bgColor, color, first]);
    return (
        <div
            style={{...style, borderBlock:`0.001rem solid ${bgColor}`, width:32, height:32, 
            display:'flex', flexDirection:'column', 
            justifyContent:'center', alignItems:'center', cursor:'pointer'}}
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
            title={title}
            onClick={()=>{
                !!onClick && typeof onClick==='function' && onClick(tabIndex);
            }}
        >
            <Icon color={warna} size="1.5rem"/>
        </div>
    )
}

export const PassengerChartTab=({onTabClick, activeTab})=>{
    const [,getLan]=useLanguage();
    const bgColor=LightenDarkenColor(BgColor,-10);
    const hoverBgColor=LightenDarkenColor(bgColor, -20);
    const title=useMemo(()=>{
        switch (activeTab) {
            case '1':
                return 'age';
            case '2':
                return 'nationality';
            case '3':
                return 'origin';
            case '4':
                return 'dest';
            case '5':
                return 'carrier';
            default:
                return 'age';
        }
    }, [activeTab]);

    return (
        <div style={{display:'flex', flexDirection:'column', background:bgColor, justifyContent:'center', alignItems:'center',
            borderTop:`0.01rem solid ${bgColor}`, borderInline:`0.01rem solid ${bgColor}`}}>
            <Typography.Text style={{color:'white', fontSize:'0.6rem', fontFamily:"calibri"}}>{getLan(title)}</Typography.Text>
        <div style={{display:'flex', flexDirection:'row'}}>
            <BtnTab 
                Icon={FaBirthdayCake}
                bgColor={bgColor}
                hoverBgColor={hoverBgColor}
                first
                color={'white'}
                hoverColor={'white'}
                tabActive={activeTab}
                tabIndex={'1'}
                title={getLan('age')}
                onClick={onTabClick}
            />
            <BtnTab 
                Icon={GiWorld}
                bgColor={bgColor}
                hoverBgColor={hoverBgColor}
                color={'white'}
                hoverColor={'white'}
                tabActive={activeTab}
                tabIndex={'2'}
                title={getLan('nationality')}
                onClick={onTabClick}
            />
            <BtnTab 
                Icon={GiFamilyHouse}
                bgColor={bgColor}
                hoverBgColor={hoverBgColor}
                color={'white'}
                hoverColor={'white'}
                tabActive={activeTab}
                tabIndex={'3'}
                title={getLan('origin')}
                onClick={onTabClick}
            />
            <BtnTab 
                Icon={GiDirectionSigns}
                bgColor={bgColor}
                hoverBgColor={hoverBgColor}
                color={'white'}
                hoverColor={'white'}
                tabActive={activeTab}
                tabIndex={'4'}
                title={getLan('dest')}
                onClick={onTabClick}
            />
            <BtnTab 
                Icon={GiCarrier}
                bgColor={bgColor}
                hoverBgColor={hoverBgColor}
                color={'white'}
                hoverColor={'white'}
                tabActive={activeTab}
                tabIndex={'5'}
                title={getLan('carrier')}
                onClick={onTabClick}
            />
        </div>
        </div>
    )
}

export const MonthlyDailyTab=({onTabClick, activeTab})=>{
    const [,getLan]=useLanguage();
    const bgColor=LightenDarkenColor(BgColor, -30);
    const hoverBgColor=LightenDarkenColor(bgColor, -20);
    const title=useMemo(()=>{
        switch (activeTab) {
            case '1':
                return 'daily';
            case '2':
                return 'monthly';
            default:
                return 'daily';
        }
    }, [activeTab]);

    return (
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
            borderTop:`0.01rem solid ${bgColor}`, borderInline:`0.01rem solid ${bgColor}`, background:bgColor}}>
            <Typography.Text style={{color:'white', fontWeight:'bolder', fontSize:'0.6rem', fontFamily:"calibri"}}>{getLan(title)}</Typography.Text>
            <div style={{display:'flex', flexDirection:'row'}}>
                <BtnTab 
                    Icon={IoCalendarNumberSharp}
                    bgColor={bgColor}
                    hoverBgColor={hoverBgColor}
                    color={'white'}
                    hoverColor={'white'}
                    first
                    tabActive={activeTab}
                    tabIndex={'1'}
                    title={getLan('daily')}
                    onClick={onTabClick}
                />
                <BtnTab 
                    Icon={GiCalendar}
                    bgColor={bgColor}
                    hoverBgColor={hoverBgColor}
                    color={'white'}
                    hoverColor={'white'}
                    tabActive={activeTab}
                    tabIndex={'2'}
                    title={getLan('monthly')}
                    onClick={onTabClick}
                />
            </div>
        </div>
    )
}