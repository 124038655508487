import React, { Fragment, useState } from 'react';
import MyModal from './index';
import ItemTelex from '../items/telex';
import TelexDetail from '../../details/telex';
export default ({telex_data}) => {
    return (
        <MyModal
            Trigger={ItemTelex}
            title={'telex'}
            data={telex_data}
            width={'75%'}
        >
            <TelexDetail data={telex_data} />
        </MyModal>
    )
}