import { Button, DatePicker, Form, Input, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import AutoComplete from '../../../../components/autocomplete/Online';
export default ({onSearchCallback, initValue}) => {
    const [state, setState]=useState(initValue);
    const [,getLang] = useLanguage();
    return (
        <div style={{padding:12, background:'#ffffff88', display:'flex', flexDirection:'column', rowGap:8, minWidth:320, flexGrow:1}}>
            <Typography.Text strong>{getLang('search')}</Typography.Text>
            <Form 
                layout='vertical'
                initialValues={state}
                onFinish={onSearchCallback}
                autoComplete="off"
            >
                <Form.Item
                    label={getLang('airline')}
                    name="airline"
                >
                    <AutoComplete 
                        getKey={(opt)=>opt._id}
                        getSelectedOptions={(opt, val)=>opt._id===val._id}
                        getValues={(val)=>`${val.code || val.icao} - ${val.name}`}
                        uri="api/v1/airlines"
                        onChange={(val)=>setState({...state, airline:val})}
                        value={state.airline}
                    />
                </Form.Item>
                
                <Form.Item
                    label={getLang('origin')}
                    name="origin_id"
                >
                    <AutoComplete 
                        getKey={(opt)=>opt._id}
                        getSelectedOptions={(opt, val)=>opt._id===val._id}
                        getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                        uri="api/v1/airport"
                        onChange={(val)=>setState({...state, origin_id:val})}
                        value={state.origin_id}
                    />
                </Form.Item> 
                <Form.Item
                    label={getLang('dest')}
                    name="dest_id"
                >
                    <AutoComplete 
                        getKey={(opt)=>opt._id}
                        getSelectedOptions={(opt, val)=>opt._id===val._id}
                        getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                        uri="api/v1/airport"
                        onChange={(val)=>setState({...state, dest_id:val})}
                        value={state.dest_id}
                    />
                </Form.Item>            
                <Form.Item
                    label={getLang('flight_number')}
                    name="flight_number"
                >
                    <Input />
                </Form.Item>   
                <Button block type='primary' htmlType='submit'>Search</Button>
            </Form>
        </div>    
    )
}