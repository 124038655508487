import VesselPage from '../pages/vessel';
import VesselDetailPage from '../pages/vessel/detail';
import VesselPaxPage from '../pages/vessel/passenger';
export default [
    {
        path:'/vessel/:type/:direction',
        exact:true,
        Component:VesselPage
    },
    {
        path:'/vessel/passengers/detail/:id',
        exact:true,
        Component:VesselPaxPage
    },
    {
        path:'/vessel/:type/:direction/:id',
        exact:true,
        Component:VesselDetailPage
    },
    
];