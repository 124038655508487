import { Typography } from 'antd';
import React from 'react';
import { IoIosPerson } from 'react-icons/io';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import Flight_schedule from '../../category/flight_schedule';
import Pnr from '../../category/pnr';
import Row from '../../category/row';
import Panel from '../../panel/panel';
import AccompanyWith from '../../category/dom_accompany_with';
import Remarks from '../../category/remarks';
import In_out_bound from '../../category/in_out_bound';
export default ({data})=>{
    const { passenger_name, _id, pnr_id, flight_schedule_id, gender, origin_id, dest_id, ticket_number, remarks, class:kelas, inbound, outbound } = data;
    const [,getLan] = useLanguage();
    return (
        <div style={{display:'flex', flexDirection:'row', gap:32, padding:16, flexWrap:'wrap'}}>
            <div style={{display:'flex', flexDirection:'column', rowGap:8, flexGrow:1}}>
                <div style={{display:'flex', flexDirection:'row', gap:16, alignItems:'flex-start', flexWrap:'wrap'}}>
                    <div style={{display:'flex', flexDirection:'column', rowGap:16, minWidth:360}}>
                        <Panel
                            headerLeft={<IoIosPerson size={24} />}
                            headerRight={<Typography.Text>{getLan('passengers')}</Typography.Text>}
                        >
                            <Row name={'passenger_name'} value={passenger_name} />
                            <Row name={'gender'} value={gender} />
                            <Row name={'origin'} value={`${origin_id?.code} - ${origin_id?.kota}`} />
                            <Row name={'dest'} value={`${dest_id?.code} - ${dest_id?.kota}`} />
                            <Row name={'class'} value={kelas} />
                            <Row last name={'ticket_number'} value={ticket_number?.split('HK1').join('').trim()} />
                        </Panel>
                        { pnr_id && <Pnr data={pnr_id}/>}

                    </div>
                    <div style={{display:'flex', flexDirection:'column', rowGap:16, minWidth:360, flexGrow:1}}>
                        { flight_schedule_id && <Flight_schedule flight_schedule_id={flight_schedule_id}/> }
                    </div>
                    <div style={{display:'flex', flexDirection:'column', rowGap:16, minWidth:360}}>
                        { !!remarks && <Remarks data={remarks} /> }
                        {
                            !!inbound?.flight_number && <In_out_bound data={inbound} direction="dom_inbound" />
                        }
                        {
                            !!outbound?.flight_number && <In_out_bound data={outbound} direction="dom_outbound" />
                        }
                    </div>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'column', rowGap:8, flexGrow:1}}>
                { pnr_id && <AccompanyWith isDomestic={true} pnr_id={pnr_id._id} pax_id={_id} />}
            </div>
        </div>
    )
}