import { Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useAos } from '../../../../../hooks/useAos';
import { useLanguage } from '../../../../../hooks/useLanguage';

export default ({BgIcon, color, value, Icon, bgColor, bgPos}) =>{
    const [,getLang]=useLanguage();
    const [hover, setHover]=useState(false);
    const hoverStyle=useMemo(()=>{
        if(hover)return {transform:'translateY(-2px)', transitionTimingFunction:'ease', transitionDuration:'0s'}
        return {};
    }, [hover])
    const aos=useAos();
    return (
        <div data-aos={aos} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}
            style={{display:'flex', flexDirection:'row', gap:8, alignItems:'center', borderRadius:16, overflow:'hidden', 
                position:'relative', background:bgColor, width:200, height:100, ...hoverStyle}}>
            <div style={{position:'absolute', filter:'opacity(0.3)', overflow:'hidden', zIndex:9, ...bgPos}}>
                <BgIcon size={140} color={'black'}   />
            </div>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                width:100, padding:8}}>
                <Icon size={56} color={color} />
            </div>            
            <div style={{display:'flex', flexDirection:'column', flexGrow:1, paddingInline:8, height:'100%',  zIndex:99,
                justifyContent:'center', alignItems:'center'}}>
                <Typography.Text style={{fontSize:28, color}}>{value}</Typography.Text>
            </div>
        </div>
    )
}