import AsyncSelect from 'react-select/async';
import React, { useCallback, useMemo } from 'react';

export default ({data, getValues, onChange,value, getSelectedOptions, getKey, width})=>{
    const mapper=(row, idx)=>{
        const value=(!!getValues && getValues(row)) || row;
        const key=(!!getKey && getKey(row)) || idx;
        return ({label:value, value:value, key, row});
    }

    const [defaultOptions, val]=useMemo(()=>{
        if(!data)return [[], {label:'', value:'', key:'', row:null}]
        const defOpt=data.slice(0, 10).map(mapper);
        if(!!value){
            const [sel] = data.filter((row)=>getSelectedOptions(value, row));
            if(!sel){
                return [defOpt, {label:'', value:'', key:'', row:null}]
            }
            return [[sel, defOpt], mapper(sel, -1)];
        }
        return [defOpt, null]
    },[data, value, getValues, getSelectedOptions, mapper]);

    const loadOptions=useCallback((val)=>{
        return new Promise((resolve)=>{
            const result=[]
            for (let iii = 0; iii < data.length; iii++) {
                const d = data[iii];
                const v = getValues(d) || d;
                if(`${v}`.toLowerCase().indexOf(`${val}`.toLowerCase())>=0){
                    result.push(d);
                }
                if(result.length===10)break;
            }
            resolve(result.map(mapper))
        })
    },[data, mapper]);

    return (
        <AsyncSelect 
            cacheOptions={true}
            style={{width}}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions||[]}
            value={val}
            onChange={(val)=>!!onChange && typeof onChange==='function' && onChange(val.row)}
        />
    )
}