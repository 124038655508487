import { Typography } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React, { useMemo, useState } from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiHouse } from 'react-icons/gi';
import { ImMan, ImWoman } from 'react-icons/im';
import { IoIosPeople } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import Flags from '../../../../components/flags';
import Page from '../components/pages/paging';
import Search from './search';

// const ColumnsInternational=

export default () => {
    const Icon = IoIosPeople;
    const [searchValue, setSearchValue] = useState({
        fullname: '', doc_number: ''
    });

    const formatedSearchVal = useMemo(() => {
        return searchValue;
    }, [searchValue]);

    const calcAge = (dob) => {
        let m = false;
        if(!dob)return [0,'-'];
        if (dob.indexOf('/') >= 0) {
            m = moment(dob, 'M/D/YYYY');
            if (!m.isValid()) {
                m = moment(dob, 'D/M/YYYY')
            }
        } else {
            m = moment(dob, 'DD-MMM-YYYY');
            if (!m.isValid()) {
                m = moment(dob, 'DDMMMYY');
                if (m > moment()) {
                    m.subtract(100, 'years');
                }
            }
        }
        return [moment.duration(moment().diff(m)).asYears(), m.format('DD-MMM-YYYY')];
    }
    const renderGender = (gender) => {

        switch (gender.toLowerCase()) {
            case 'female':
            case 'f':
                return <ImWoman size={24} color="#ff6e87" />
            case 'm':
            case 'male':
                return <ImMan size={24} color="blue" />
            default:
                return <ImMan size={24} color="blue" />
        }
    }
    return (
        <Page
            url={'api/v1/citizen'}
            searchValue={formatedSearchVal}
            columns={[
                {
                    title: 'citizen_name',
                    dataIndex: 'fullname',
                    width: '140px',
                    render: (val, { _id }) => (
                        <Typography.Text title={val} style={{ width: 140 }} ellipsis>
                            <Link to={`/passengers/citizen/detail/${_id}`}>
                                {val}
                            </Link>
                        </Typography.Text>
                    )
                },
                {
                    title: 'doc_number',
                    dataIndex: 'no_id',
                    width: '120px',
                    render: (val, rec) => (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 4, width:120 }}>
                            <span style={{ color: 'white', textTransform: 'uppercase', paddingInline: 2, background: '#525050', borderRadius: 2 }}>
                                {rec?.jenis_doc?.substring(0, 1)}
                            </span>
                            <Typography.Text>{val}</Typography.Text>
                        </div>
                    )
                },
                {
                    title: 'gender',
                    dataIndex: 'gender',
                    width: '80px',
                    render: (val, rec) => {
                        const [age] = calcAge(rec.dob);
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 4, width:80 }}>
                                {renderGender(val)}
                                <span style={{ color: 'white', textTransform: 'uppercase', paddingInline: 2, background: '#525050', borderRadius: 2 }}>
                                    {numeral(age).format('00')}
                                </span>
                            </div>
                        )
                    }
                },
                {
                    title: 'dob',
                    dataIndex: 'dob',
                    width: '90px',
                    render: (val) => {
                        const [, dob] = calcAge(val);
                        return dob
                    }
                },
                {
                    title: 'doc_expiry',
                    dataIndex: 'id_expiry',
                    width: '90px',
                },
                {
                    title: 'nationality',
                    dataIndex: 'nationality',
                    width: 60,
                    ellipsis:true,
                    render: (val) => <Flags width={60} countryFormat={'alpha3'} country_code={val} withCountry />
                },
                {
                    title: 'doc_country',
                    dataIndex: 'issued_id_country',
                    ellipsis:true,
                    width: 60,
                    render: (val) => <Flags width={60} countryFormat={'alpha3'} country_code={val} withCountry />
                },
                
            ]}
            title={`citizen`}
            icon={Icon}
            searchComponent={
                <Search
                    onSearchCallback={(value) => {
                        setSearchValue({ ...value, timestamp: moment().unix() });
                    }}
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href: '/home',
                        icon: <GiHouse color='white' />,
                        item: 'home'
                    },
                    {
                        item: 'list'
                    }
                ]
            }
        />
    )
}