import { Button, Form, Input, Modal, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLanguage } from "../../../../../hooks/useLanguage";

export default function ModalFilterWatch({
  open = false,
  state = null,
  setState = null,
  handleModal = null,
  title = "",
  handleSearch = null,
}) {
  const [, getLang] = useLanguage();
  const [model, setModel] = useState(state);

  return (
    <Modal
      title={`Filter Queue`}
      visible={open}
      onCancel={handleModal}
      footer={null}
      width={400}
    >
      <Form
        layout="vertical"
        initialValues={state}
        onFinish={handleSearch}
        autoComplete="off"
      >
        {/* <Form.Item
                    label={getLang("flight_date")}
                    style={{marginBottom:4}}
                    name="flight_date"
                    rules={[{
                        validator:async(rule, value)=>{
                            // console.log({rule, value})
                            const [from, to]=value;
                            const diff=moment.duration(to.diff(from)).days();
                            if(diff>7){
                                throw new Error('Cannot more than 7 days');
                            }
                            return true;
                        }
                    }]}
                >
                    <DatePicker.RangePicker 
                        showTime={false}
                        value={state.flight_date}
                        format={'DD-MMM-YYYY'}
                        onChange={(val, strVal)=>{
                            setState({...state, flight_date:val});
                        }}
                    />
                </Form.Item> */}

        

        <Form.Item
          label={getLang("name")}
          style={{ marginBottom: 4 }}
          name="name"
        >
          <Input />
        </Form.Item>

        <Button className="mt-3" block type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Modal>
  );
}
