import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../../hooks/useLanguage';

const Tab=({id, caption, icon, href, selTab})=>{
    const [,getLang]=useLanguage();
    return (
        <Link 
            to={href}
            icon={icon}
            style={{fontWeight:(selTab===id && 'bold') || 'normal', color:'black'}}
        >
            {getLang(caption)}
        </Link>
    )
}

export default ({tabs, selTab}) => {
    return (
        <div style={{display:'flex', flexDirection:'row', gap:16}}>
            {!!tabs && Array.isArray(tabs) && tabs.map((tab, idx)=><Tab {...tab} key={idx} selTab={selTab} />)}
        </div>
    )
}