import AirlinePage from './master/airlines'
import AirlineForm from './master/airlines/form'
import AirportPage from './master/airports'
import AirportForm from './master/airports/form'
import CustomerListPage from './customer/list'
import CustomerTarifPage from './customer/tarif'
import CustomerTrxPage from './customer/transaction'
import UsersPage from './master/users';
import UsersForm from './master/users/form';
import FlightMasterPage from './flights/master';
import FlightSchedulePage from './flights/schedule';
import SeaportPage from './master/seaport';
import SeaportForm from './master/seaport/form';
import KanwilPage from './master/kanwil';
import KanwilForm from './master/kanwil/form';
import KanimPage from './master/kanim';
import KanimForm from './master/kanim/form';
import TPIPage from './master/tpi';
import TPIForm from './master/tpi/form';
export const Routing = [
    {
        to:'/master/tpi',
        level:0x1ff0,
        component: <TPIPage />
    },
    {
        to:'/master/tpi/create',
        level:0x1ff0,
        component: <TPIForm />
    },
    {
        to:'/master/tpi/edit',
        level:0x1ff0,
        component: <TPIForm />
    },
    {
        to:'/master/kanim',
        level:0x1ff0,
        component: <KanimPage />
    },
    {
        to:'/master/kanim/create',
        level:0x1ff0,
        component: <KanimForm />
    },
    {
        to:'/master/kanim/edit',
        level:0x1ff0,
        component: <KanimForm />
    },
    {
        to:'/master/kanwil',
        level:0x1ff0,
        component: <KanwilPage />
    },
    {
        to:'/master/kanwil/create',
        level:0x1ff0,
        component: <KanwilForm />
    },
    {
        to:'/master/kanwil/edit',
        level:0x1ff0,
        component: <KanwilForm />
    },
    {
        to:'/flight/master',
        level:0x1ff0,
        component: <FlightMasterPage />
    },
    {
        to:'/flight/schedule/:direction',
        level:0x1ff0,
        component: <FlightSchedulePage />
    },
    {
        to:'/customer/list',
        level:0x1ff0,
        component: <CustomerListPage />
    },
    {
        to:'/master/users',
        level:0x1ff0,
        component: <UsersPage />
    },
    {
        to:'/master/users/create',
        level:0x1ff0,
        component: <UsersForm />
    },
    {
        to:'/master/users/edit',
        level:0x1ff0,
        component: <UsersForm />
    },
    {
        to:'/customer/rates',
        level:0x1ff0,
        component: <CustomerTarifPage />
    },
    {
        to:'/customer/transaction',
        level:0x1ff0,
        component: <CustomerTrxPage />
    },
    {
        to:'/master/airline',
        level:0x1ff0,
        component: <AirlinePage />
    },
    {
        to:'/master/airline/create',
        level:0x1ff0,
        component: <AirlineForm />
    },
    {
        to:'/master/airline/edit',
        level:0x1ff0,
        component: <AirlineForm />
    },
    {
        to:'/master/seaport',
        level:0x1ff0,
        component: <SeaportPage />
    },
    {
        to:'/master/seaport/create',
        level:0x1ff0,
        component: <SeaportForm />
    },
    {
        to:'/master/seaport/edit',
        level:0x1ff0,
        component: <SeaportForm />
    },
    {
        to:'/master/airport',
        level:0x1ff0,
        component: <AirportPage />
    },
    {
        to:'/master/airport/create',
        level:0x1ff0,
        component: <AirportForm />
    },
    {
        to:'/master/airport/edit',
        level:0x1ff0,
        component: <AirportForm />
    },
]