import moment from 'moment';
import React, { useState } from 'react';
import { GiCctvCamera, GiHouse } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import Table from '../components/pages/paging';
import SearchComp from './search';

const Columns=[
    {
        title:'name',
        dataIndex:'name',
        width:'320px',
        render:(val, {_id})=><Link to={`/passengers/watchlist/edit/${_id}`}>{val}</Link>
    },
    {
        title:'notes',
        dataIndex:'notes',
        render:(val)=>val.join(', ')
    },
    {
        title:'created_by',
        dataIndex:'createdBy',
        render:(val)=>val?.username
    },
    {
        title:'created_at',
        dataIndex:'createdAt',
        render:(val)=>moment(val).format('DD-MMM-YYYY HH:mm')
    },
];

export default () => {
    const [search, setSearch] = useState({name:''});
    return (
        <Table 
            url={'api/v1/watchlist'}
            searchValue={search}
            title={'watchlist'}
            icon={GiCctvCamera}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'watchlist'
                    }
                ]
            }
            columns={Columns}
            searchComponent={
                <SearchComp 
                    onFormRefresh={()=>{
                        setSearch({...search, timestamp:moment().unix()});
                    }}
                    onSearchCallback={(searchValue)=>{
                        setSearch({...searchValue, timestamp:moment().unix()});
                    }}
                />
            }
        />
    )
}