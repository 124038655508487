import { Typography } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiHouse } from 'react-icons/gi';
import { Link, useParams } from 'react-router-dom';
import Page from '../components/pages/table';
import Item from '../components/category/items/domestic';
import Search from './search';
import CFG from '../../../../config/env';
const {defaultOriginDom} = CFG;

export default () => {
    const {direction} = useParams();
    const Icon=useMemo(()=>{
        return direction==='arrival'?GiAirplaneArrival:GiAirplaneDeparture;
    }, [direction])
    const [searchValue, setSearchValue]=useState({
        flight_date:[moment(), moment().add(1, 'day')], flight_number:'', airline:null, 
        origin:defaultOriginDom, dest:null
    });
    const formatedSearchVal=useMemo(()=>{
        const {flight_date, airline, ...others} = searchValue;
        const [from, to] = flight_date;
        const airline_id=airline?airline._id:undefined;
        return {...others, from:from.format('DD-MMM-YYYY'), to:to.format('DD-MMM-YYYY'), airline_id}
    }, [searchValue]);
    const uri=`api/v1/flight_schedule/domestic/inquiry`;
    const url=`/flight/domestic`;
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            title={`flight_schedule_domestic`} 
            icon={Icon} 
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'flight_schedule'
                    },
                    {
                        item:'flight_schedule_domestic'
                    }
                ]
            }
            renderItem={(row, idx)=>row && (<Item row={row} key={idx}  url={url}/>)}
        />
    )
}