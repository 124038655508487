import React from 'react';
import { GiHouse} from 'react-icons/gi';
import { FaMailBulk} from 'react-icons/fa';
import Detail from '../../components/pages/detail';
import StatusPage from './pages/process_status';
export default () => {
    return (
        <Detail 
            title={`telex`} 
            icon={FaMailBulk} 
            id={'status'}
            url={`api/v1/bot/process`}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'bot'
                    },
                    {
                        item:'process_status'
                    },
                ]
            }
            DetailComponent={StatusPage}
        />
    )
}