import { Avatar, List, Typography } from "antd";
import moment from "moment";
import React from "react";
import { MdOutlineAirplaneTicket } from "react-icons/md";
import { useLocation } from "react-router-dom";
import BaseListPage from "../../../components/BaseListPage";
import { getLogoAirline } from "../../../library";

export default function Itinerary() {
  const { state } = useLocation();

  return (
    <BaseListPage
      datas={state}
      renderItem={(item) => {
        const {
          airline,
          booking_status,
          dest,
          origin,
          sta,
          std,
          passenger_record_locator,
        } = item || {
          airline: "",
          booking_status: "",
          dest: "",
          origin: "",
          sta: "",
          std: "",
          passenger_record_locator: null,
        };
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  // className={"avatar-contain"}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  icon={<MdOutlineAirplaneTicket size={20} />}
                />
              }
              title={
                <div>
                  <Typography.Title level={5}>
                    {passenger_record_locator?.pnr || ""}
                  </Typography.Title>
                </div>
              }
              description={
                <div>
                  <p style={{ marginBottom: 0 }}>
                    Origin : {origin} ({moment(std).format("DD-MMM-YYYY HH:mm")}
                    )
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Destination : {dest} (
                    {moment(sta).format("DD-MMM-YYYY HH:mm")})
                  </p>
                </div>
              }
            />

            <div className="rowslist-inline-flex">
              <Typography.Text>{airline}</Typography.Text>
              <Avatar
                alt={airline}
                size="large"
                style={{ alignSelf: "center" }}
                className={"avatar-contain"}
                src={`${getLogoAirline(airline)}`}
              />
            </div>
          </List.Item>
        );
      }}
    />
  );
}
