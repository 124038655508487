import { List } from "antd";
import React, { Fragment, useMemo, useState } from "react";
import BasePage from "./BasePage";

export default function BaseListPage({
  goBack,
  datas,
  paginationUrl,
  url,
  title,
  searchApiProps,
  onSortData,
  renderItem,
  searchProps,
  datePickerChange,
  FilterComponent,
  sortDependency,
  onExportExcel,
  fixedPagination
}) {
  const [rows, setRows] = useState([]);

  return (
    <BasePage
      datas={datas}
      getData={(data) => {
        setRows(data);
      }}
      paginationUrl={paginationUrl}
      title={title}
      goBack={goBack}
      url={url}
      searchProps={searchProps}
      searchApiProps={searchApiProps}
      onSortData={(data) => {
        return !!onSortData && onSortData(data);
      }}
      // infinitScroll={infinitScroll}
      datePickerChange={datePickerChange}
      FilterComponent={FilterComponent}
      sortDependency={sortDependency}
      onExportExcel={onExportExcel}
      fixedPagination={fixedPagination}
    >
      <Fragment>
        {!!rows && !!rows?.length && (
          <div>
            <List
              className="list-base"
              itemLayout="horizontal"
              dataSource={rows}
              renderItem={(item) => {
                return !!renderItem && renderItem(item);
              }}
            />
          </div>
        )}
      </Fragment>
    </BasePage>
  );
}
