import { blue, magenta } from "@ant-design/colors";
import { Avatar, List, Select, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { MdPeopleOutline } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import Flags from "../../../../../components/flags";
import { exportManifestVessel } from "../../../../interactive/pages/components/details/passengers/manifest";
import Badge from "../../../components/Badge";
import BaseListPage from "../../../components/BaseListPage";
import { getPaxType } from "../../../library";

export default function PassengersVessel({ stateLocation }) {
  const history = useHistory();
  const [passengersModel, setPassengersModel] = useState({
    watchlist: "all",
  });

  const handleExport = (nData) => {
    if (nData.length > 0) {
      const [{ vessel_id }] = nData;
      return exportManifestVessel(nData, vessel_id);
    }
  };

  const onSortData = (data) => {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    const fData = data.filter(({ watch_flag }) => {
      const { watchlist } = passengersModel;
      if (watchlist !== "all") {
        if (watch_flag && watch_flag > 0) {
          const filtered =
            watchlist === "orange"
              ? (watch_flag & 24) > 0
              : (watch_flag & 7) > 0;
          return filtered;
        }
        return false;
      }
      return true;
    });
    const sData = fData
      .sort((a, b) =>
        `${a?.passenger_name}`.localeCompare(`${b?.passenger_name}`)
      )
      .map((row, idx) => ({ ...row, number: idx + 1 }));
    return sData;
  };

  const filterComponent = () => {
    return (
      <Fragment>
        {/* <Button onClick={handleExport}  style={{background:green[8], borderRadius:99}} icon={<FileExcelOutlined style={{color:'white'}} />} type="text" /> */}
        <Select
          style={{ width: 120 }}
          value={passengersModel.watchlist}
          onChange={(val) =>
            setPassengersModel({ ...passengersModel, watchlist: val })
          }
        >
          <Select.Option value="all">ALL</Select.Option>
          <Select.Option value="orange">PPT/VIT</Select.Option>
          <Select.Option value="red">ITP/CKL</Select.Option>
        </Select>
      </Fragment>
    );
  };

  return (
    <BaseListPage
      datas={stateLocation}
      fixedPagination
      sortDependency={passengersModel}
      onSortData={onSortData}
      FilterComponent={filterComponent}
      // url={url}
      searchProps={["passenger_name"]}
      onExportExcel={handleExport}
      renderItem={(item) => {
        const {
          _id,
          passenger_name,
          gender,
          dob,
          pnr,
          doc_number,
          pnr_id,
          boarding_number,
          doc_nationality,
          doc_expiration,
          watch_flag,
          doc_expiry,
          nationality,
          age,
          doc_country,
          pax_type,
          unclode_id,
        } = item || {
          _id: "",
          passenger_name: "",
          boarding_number: "",
          dob: "",
          doc_country: "",
          nationality: "",
          doc_number: "",
          pnr_id: "",
          gender: "",
          pnr: "",
          doc_expiration: "",
          doc_expiry: "",
          doc_nationality: "",
          watch_flag: "",
          age: "",
          pax_type: "",
          unclode_id: "",
        };
        return (
          <Link to={`/vessel/passengers/detail/${_id}`}>
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: `${
                        gender?.toLowerCase() === "m" ||
                        gender?.toLowerCase() === "male"
                          ? blue[4]
                          : magenta[4]
                      }`,
                    }}
                    icon={<MdPeopleOutline />}
                  />
                }
                title={
                  <div>
                    {/* <Typography.Title style={{color: `${!!watch_flag ? grey[0]: ''}`}} level={5}> */}
                    <Typography.Title level={5}>
                      {passenger_name?.toUpperCase()}
                    </Typography.Title>
                  </div>
                }
                description={
                  <div>
                    <p className="mb-1">
                      {moment(dob).format("DD-MMM-YYYY")} ({age} year){" "}
                    </p>
                    <p className="mb-1">Boarding : {boarding_number}</p>
                    <div className="rowslist-inline-flex">
                      <p className="mb-1">
                        Passport : {doc_number} /{" "}
                        {moment(doc_expiration).format("DD-MMM-YYYY")}
                      </p>
                      {!!nationality &&
                      !!unclode_id &&
                      nationality !== nationality ? (
                        <Flags
                          country_code={unclode_id}
                          // withCountry
                          countryWidth={50}
                          // countryFormat={"alpha3"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                }
              />

              <div>
                <div className="mb-4">
                  <Badge color={"def"} text={getPaxType(pax_type)} />
                </div>
                <Flags
                  country_code={nationality}
                  withCountry
                  countryWidth={50}
                  countryFormat={"alpha3"}
                />
                {watch_flag !== 0 ? (
                  <div className="mt-4">
                    <Badge color={"error"} text={"WATCHLIST"} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </List.Item>
          </Link>
        );
      }}
    />
  );
}
