import React, { useMemo, useState } from "react";
import { Layout, Menu, Typography } from "antd";
import "./layout.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu, setOpenKeys } from "../../../../redux";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../../../../assets/images/logo-dashboard.png";
import { UserProfile } from "./userProfile";
import { Scrollbars } from "react-custom-scrollbars";
import { createUseStyles } from "react-jss";
const { Header, Content, Footer, Sider } = Layout;

const useStyles = createUseStyles((theme) => ({
  root: {
    "& .ant-layout-sider": {
      backgroundColor: theme.colorPrimary,
    },
    "& .ant-menu": {
      background: "none",
      margin: "12px 0px",
      padding: "4px 12px",
      "& .ant-menu-item": {
        display: "flex",
        alignItems: "center",
      },
      "&.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected":
        {
          borderRadius: theme.borderRadius,
          background: "rgba(255,255,255,0.2)",
        },
    },
  },
  sider: {
    height: "100vh",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 999,
  },
  header: {
    backgroundColor: ({ headerBgColor }) => headerBgColor,
    width: "100%",
    // zIndex: 99,
    // position: "fixed",
    color: ({ headerColor }) => headerColor,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px",

  },
  headerLeft:{
    display: "flex",
    justifyContent: "flex-start",
    alignItems:"center",
    gap: 8
  },
  headerRight:{
    display: "flex",
    justifyContent: "flex-end",
    alignItems:"center",
    gap: 8
  },
  content:{
    padding: 24,
  }
}));

export const SiderLayout = ({
  children,
  menus,
  title,
  theme,
  headerBgColor,
  headerColor,
}) => {
  const classes = useStyles({ headerBgColor, headerColor });
  const { selectedMenu, openKeys } = useSelector((state) => state.nav);
  const { userdata } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [colapsed, setColapsed] = useState(true);
  const rootSubmenuKeys = useMemo(() => {
    return menus.map(({ key }) => key);
  }, [menus]);
  const renderThumb = ({ style }) => {
    return <div style={{ ...style, backgroundColor: "#eecccc6e" }} />;
  };
  return (
    <Layout hasSider className={classes.root}>
      <Sider
        className={classes.sider}
        breakpoint="xs"
        theme={theme || "dark"}
        trigger={null}
        collapsed={colapsed}
        collapsedWidth="0"
        collapsible
        width={260}
      >
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <Scrollbars renderThumbVertical={renderThumb}>
          <Menu
            mode="inline"
            selectedKeys={[selectedMenu]}
            theme={theme || "dark"}
            items={menus || []}
            onClick={(e) => {
              dispatch(setSelectedMenu(e.key));
            }}
            openKeys={openKeys}
            inlineIndent={8}
            onOpenChange={(keys) => {
              const latestOpenKey = keys.find(
                (key) => openKeys.indexOf(key) === -1
              );
              if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                dispatch(setOpenKeys(keys));
              } else {
                dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
              }
            }}
          />
        </Scrollbars>
      </Sider>
      <Layout style={{ marginLeft: !!colapsed ? 0 : 260, minHeight: "100vh" }}>
        <Header className={classes.header}>
          <div className={classes.headerLeft}>
            {colapsed ? (
              <GiHamburgerMenu
                className="trigger"
                onClick={() => setColapsed(!colapsed)}
              />
            ) : (
              <GiHamburgerMenu
                className="trigger"
                onClick={() => setColapsed(!colapsed)}
              />
            )}
            <Typography.Title level={4} style={{ color: headerColor }}>
              {title || "LDC APPS"}
            </Typography.Title>
          </div>
          <div className={classes.headerRight}>
            <UserProfile
              isColapsed={colapsed}
              username={userdata?.username}
              level={userdata?.levelStr}
            />
          </div>
        </Header>
        <Scrollbars renderThumbVertical={renderThumb}>
          <Content
            className={classes.content}
          >
            {/* <div
              className="site-layout-background"
              style={{ padding: 24, flexGrow: 1 }}
            > */}
              {children}
            {/* </div> */}
          </Content>
        </Scrollbars>
        <Footer style={{ textAlign: "center", height: 64 }}>
          COPYRIGHT © 2022{" "}
          <a target={"_blank"} href="https://edifly-si.com/">
            Edifly Solusi Indonesia
          </a>
          , All rights Reserved
        </Footer>
      </Layout>
    </Layout>
  );
};
