
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SubDetailPage from "../../../../components/SubDetailPage";
import TelexList from "../../../telexes/TelexList";
import PassengersFlight from "../PassengersFlight";


export default () => {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();

  const components = {
    telexes:TelexList,
    accompany:PassengersFlight,
  };


  return (
    // <BasePage goBack title={`Passengers ${subdetail}`}>
      <SubDetailPage
        basePath={pathname}
        subComponents={components}
        keyComponents={subdetail}
      />
    // </BasePage>
  );
};
