import { blue, cyan, gold } from "@ant-design/colors";
import { Avatar, Card, Col, Collapse, Row, Typography } from "antd";
import moment from "moment";
import React, { Fragment } from "react";
import { useState } from "react";
import { MdAirplaneTicket, MdOutlineAirplaneTicket } from "react-icons/md";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useFontSize } from "../../../../hooks/useDimension";
import { useFetch } from "../../../../hooks/useFetch";
import Badge from "../../components/Badge";
import BaseDetailPage from "../../components/BaseDetailPage";
import RowList from "../../components/RowList";
import { getLogoAirline } from "../../library";

export default function Detail() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const fontSize = useFontSize()
  const [detail, setDetail] = useState();

  const [passengerList, loadingPassenger] = useFetch(
    `api/v1/passengers/detail/pnr_id/${id}`
  );

  return (
    <BaseDetailPage
      url={`api/v1/pnr/detail/${id}`}
      dataSource={detail}
      renderContent={(data) => {
        const {
          _id,
          pnr,
          booking_date,
          flight_date,
          flight_number,
          total_pax,
          contact_information,
          passengers,
          originator,
          flight_schedules_id,
          itinerary,
          osi,
          ssr,
        } = data || {
          _id: "",
          pnr: "",
          contact_information: [],
          booking_date: "",
          flight_date: "",
          flight_number: "",
          passengers: [],
          originator: null,
          flight_schedules_id: null,
          total_pax: "",
          itinerary: [],
          osi: [],
          ssr: {},
          total_pax: 0,

        };

        const {
          _id: idFlight,
          is_arrival,
          airline_id,

        } = flight_schedules_id || {
          idFlight: "",
          is_arrival: false,
          airline_id: null,
        };
        return (
          <Row >
            <Col xs={24} sm={24} md={12} lg={12} className="p-3">
              <Card style={{height: '100%' }}>
                <Row gutter={[8,32]} >
                  <Col
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                    
                  >
                    <Avatar
                      alt={airline_id?.code}
                      size="large"
                      style={{
                        backgroundColor: gold[1],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "5rem",
                        height: "5rem",
                      }}
                      className={"avatar-contain"}
                      icon={<MdAirplaneTicket size={"4rem"} color={gold[7]} />}
                      // src={`${getLogoAirline(airline_id?.code)}`}
                    />
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} 
                  
                  >
                    <Typography.Title className="base-text-color" style={{fontSize:fontSize.lg}}>{pnr}</Typography.Title>
                    <Typography.Text className="text-sub-title">
                      Booked {moment(booking_date).format("DD-MMM-YYYY HH:mm")}
                    </Typography.Text>
                    
                      <div style={{width: 'max-content', margin: '8px 0'}}>  
                        <Badge  color={'success'} text={`${total_pax || 0} Total Pax`}  />
                      </div>
                    
                    {/* <RowList title={"PNR"} content={pnr} />
                    <RowList
                      title={"Booking Date"}
                      content={moment(booking_date).format("DD-MMM-YYYY HH:mm")}
                    />
                    <RowList title={"Total Pax"} content={total_pax || 0} /> */}
                  </Col>
                </Row>

                {/* <RowList title={"PNR"} content={pnr} dividerBottom />
                <RowList
                  title={"Booking Date"}
                  content={moment(booking_date).format("DD-MMM-YYYY HH:mm")}
                  dividerBottom
                />
                <RowList title={"Total Pax"} content={total_pax || 0} /> */}
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} className="p-3">
              <Card style={{height: '100%' }}>
                <RowList
                  linkTo={{
                    pathname: `${pathname}/passengers`,
                    state: passengerList,
                  }}
                  title={"Passengers"}
                  content={`${passengerList?.length} Passengers`}
                  dividerBottom
                />

                <RowList
                  linkTo={`/flight/schedule/${
                    !!is_arrival ? "arrival" : "departure"
                  }/${idFlight}`}
                  title={"Flight"}
                  content={
                    <div className="rowslist-inline-flex">
                      <p style={{ marginBottom: 0 }}>
                        {flight_number} / {flight_date} /
                      </p>
                      <div className="rowslist-inline-flex">
                        <p style={{ marginBottom: 0 }}>{airline_id?.name}</p>
                        <Avatar
                          alt={airline_id?.code}
                          size="large"
                          //   style={{ alignSelf: "center" }}
                          className={"avatar-contain"}
                          src={`${getLogoAirline(airline_id?.code)}`}
                        />
                      </div>
                    </div>
                  }
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <RowList
                  linkTo={{
                    pathname: `${pathname}/originator`,
                    state: originator,
                  }}
                  title={"Originator"}
                  content={
                    <Fragment>
                      {!!originator?.origin?.code ||
                      !!originator?.system_location?.airline ||
                      originator?.system_location?.locationCode ? (
                        <div>
                          <Typography.Text>
                            {originator?.origin?.code || ""}
                          </Typography.Text>
                          {!!originator?.system_location?.airline ? (
                            <Typography.Text>
                              /{originator?.system_location?.airline || ""}
                            </Typography.Text>
                          ) : (
                            ""
                          )}

                          {!!originator?.system_location?.locationCode ? (
                            <Typography.Text>
                              /{originator?.system_location?.locationCode || ""}
                            </Typography.Text>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <p>Origator Detail</p>
                      )}
                    </Fragment>
                  }
                  dividerBottom
                />
                <RowList
                  linkTo={{
                    pathname: `${pathname}/itinerary`,
                    state: itinerary,
                  }}
                  title={"Itinerary "}
                  content={<div>{itinerary?.length || 0} Itinerary</div>}
                  dividerBottom
                />

                <RowList
                  linkTo={{
                    pathname: `${pathname}/contact`,
                    state: contact_information,
                  }}
                  title={"Contact "}
                  content={
                    <div>{contact_information?.length || 0} Contact Info</div>
                  }
                  dividerBottom
                />

                <RowList
                  linkTo={{
                    pathname: `${pathname}/osi`,
                    state: osi,
                  }}
                  title={"OSI "}
                  content={<div>{osi?.length} Other Service Information</div>}
                  dividerBottom
                />

                <RowList
                  linkTo={{
                    pathname: `${pathname}/ssr`,
                    state: ssr,
                  }}
                  title={"SSR "}
                  content={
                    <div>
                      {Object.keys(ssr || {})?.length} Special Service Request
                    </div>
                  }
                  // dividerBottom
                />
              </Card>
            </Col>
          </Row>
        );
      }}
    />
  );
}
