import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import BasePage from "../../components/BasePage";
import TelexDetail from "../../../../content/interactive/pages/components/details/telex";
import { Card, Col, Divider, Row, Typography } from "antd";
import RowList from "../../components/RowList";
import BaseDetailPage from "../../components/BaseDetailPage";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { log } from "util";
import { useFontSize } from "../../../../hooks/useDimension";
import { getDomHeader, getHeaders } from "../../library";

export default function Detail() {
  const {id} = useParams()
  const { pathname, state } = useLocation();
  const fontSize=useFontSize();


  const memoizeStateLocation = useMemo(()=>{
    return state
  }, [pathname])

  console.log(memoizeStateLocation);
  
  const { _id, origin, destination, headers,message, processedAt, } = memoizeStateLocation  || {
    origin: "",
    destination: "",
    processedAt: "",
  };

  const { unh } = headers || { unh: "" };
  const { msgIdentifier, statusOfTransfer } = unh || {
    msgIdentifier: "",
    statusOfTransfer: "",
  };
  const { msgType, msgVersionNumber, msgReleaseNumber } = msgIdentifier || {
    msgTyp: "",
    msgVersionNumber: "",
    msgReleaseNumber: "",
  };
  const { seqOfTransfer } = statusOfTransfer || { seqOfTransfer: "" };
  return (
    // <BasePage goBack title="Telex">
    <BaseDetailPage
      dataSource={memoizeStateLocation}
      renderContent={() => {
        return (
          <Card>
            <Row>
              <Col span={24}>
                <div>
                  <Typography.Title className="base-text-color" style={{fontSize: fontSize.lg}}>
                    {origin} - {destination}
                  </Typography.Title>
                  <Typography.Text className="text-sub-title">
                  {`${getHeaders(headers) || getDomHeader(message) || "-"} `}
                  </Typography.Text>
                </div>
                {!!msgType || !!msgVersionNumber || msgReleaseNumber ? (
                  <Typography.Text className="text-sub-title" level={5}>{`${
                    msgType || ""
                  } v.${msgVersionNumber}.${msgReleaseNumber}`}</Typography.Text>
                ) : (
                  ""
                )}
              </Col>
              <Col span={24} className="text-right">
                
                {!!processedAt ? (
                  <Typography.Text className="text-sub-title">
                    {moment(processedAt).format("DD-MMM-YYYY HH:mm")}
                  </Typography.Text>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Divider className="my-4" />
            <div className="p-2" >
            
                  {!!message && !!message?.length ? <TelexDetail data={memoizeStateLocation} /> : <p>No Result Message</p>}
            </div>
          </Card>
        );
      }}
    />

    // </BasePage>
  );
}
