import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { IoMdPeople } from 'react-icons/io';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Table from '../lists/table';
import { ImMan, ImWoman } from 'react-icons/im';
import { FaBaby } from 'react-icons/fa';
import Flags from '../../../../../components/flags';
import { useFetch } from '../../../../../hooks/useFetch';
export default ({pnr_id, isDomestic, pax_id}) => {
    const [data, loading] = useFetch('api/v1/passengers/domestic/detail/pnr_id/'+pnr_id);
    const [sortedData, prlExists]=useMemo(()=>{
        if(!data)return [[], false];
        if(!Array.isArray(data))return [[], false];
        const [exist]=data.filter(({smi})=>smi==='PRL');
        const sorted=data.sort((a, b)=>`${a.passenger_name}`.localeCompare(`${b.passenger_name}`));
        return [sorted, exist];
    },[data])
    const pdata=useMemo(()=>{
        return sortedData.filter(({_id})=>_id!==pax_id)
    }, [sortedData])
    const getSmi = (smi)=>{
        switch (smi) {
            case 'PNL':
            case 'ADL':
                if(prlExists)return "No-Show";
                return "Booked";
            case 'PRL':
                return 'Boarding'
            default:
                return '-';
        }
    }
    const [,getLan]=useLanguage();
    const renderGender=(gender, pax_type)=>{
        if(pax_type!=='IN'){
            switch (gender?.toLowerCase()) {
                case 'female':
                    return <ImWoman size={24} color="#ff6e87" />
                case 'male':
                    return <ImMan size={24} color="blue"/>
                default:
                    return <ImMan size={24} color="blue"/>
            }
        }
        return <FaBaby size={24} color="green" />
    }
    return (
        <Panel
            headerLeft={<IoMdPeople size={24}/>}
            headerRight={<Typography.Text>{getLan('accompany_with')}</Typography.Text>}
        >
            <Table 
                data={pdata || []}
                pagination={false}
                filterField={['passenger_name', 'doc_number']}
                url={"/passengers/domestic/detail"}
                columns={[
                {
                    title:'passenger_name',
                    dataIndex:'passenger_name',       
                    render:(val, rec)=>{
                        const {passenger_type} = rec;
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'start'}}>
                                <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                    {!!passenger_type && passenger_type.substring(0,1)}
                                </span>
                                <span>{val}</span>
                            </div>
                        )
                    }             
                },                
                {
                    title:'gender',
                    dataIndex:'gender',
                    render:(val, rec)=>renderGender(val, rec?.pax_type)
                },
                {
                    title:'class',
                    dataIndex:'class',
                },
                {
                    title:'origin',
                    dataIndex:'origin',
                },
                {
                    title:'dest',
                    dataIndex:'dest',
                },
                {
                    title:'ticket_number',
                    dataIndex:'ticket_number',
                },
                {
                    title:'status',
                    dataIndex:'smi',
                    render:(val)=>getSmi(val)
                },
            ]}
            />
        </Panel>
    )
}