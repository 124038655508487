import FlightSchedule from '../pages/flight_schedule';
import FlightDetail from '../pages/flight_schedule/detail';
import PnrFsPage from '../pages/flight_schedule/pnr';
import PaxFsPage from '../pages/flight_schedule/pax';
import FlightMatch from '../pages/flight_schedule/detail_matched';
const routes = [
    {
        path:'/flight/schedule/:direction',
        exact:true,
        Component:FlightSchedule
    },
    {
        path:'/flight/schedule/match/:id',
        exact:true,
        Component:FlightMatch
    },
    {
        path:'/flight/schedule/:direction/:id',
        exact:true,
        Component:FlightDetail
    },
    {
        path:'/flight/schedule/:direction/pnr/:id',
        exact:true,
        Component:PnrFsPage
    },
    {
        path:'/flight/schedule/:direction/passengers/:id',
        exact:true,
        Component:PaxFsPage
    },
]

export default routes;