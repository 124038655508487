import { Avatar, Card, Col, Row, Typography } from "antd";
import moment from "moment";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Flags from "../../../../components/flags";
import { useFontSize } from "../../../../hooks/useDimension";
import Badge from "../../components/Badge";
import RowList from "../../components/RowList";
import { getFormatedTime, getLogoAirline } from "../../library";

export default function Schedule({ data }) {

  const fontSize = useFontSize()
  const {
    _id,
    flight_number,
    acreg,
    actype,
    dest,
    arrival_date,
    flight_date,
    flight_rule_affected,
    airline_id,
    is_arrival,
    is_internasional,
    customs_messages,
    origin,
    localAta,
    localAtd,
    localSta,
    localStd,
    apis_info,
    sta,
    std,
    insert_from,
    status,
    watch_flag,
  } = data || {
    _id: "",
    flight_number: "",
    acreg: "",
    arrival_date: "",
    airline_id: null,
    customs_messages: null,
    actype: "",
    dest: "",
    apis_info: {},
    localAta: 0,
    localAtd: 0,
    localSta: 0,
    localStd: 0,
    flight_date: "",
    flight_rule_affected: [],
    is_arrival: false,
    is_internasional: false,
    origin: "",
    sta: "",
    std: "",
    insert_from: "",
    status: "",
    watch_flag:''
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}  className='p-2'>
          <Card className="bg-base-theme " style={{height: '100%'}}>
            {/* <div style={{height: '100%'}}> */}

            <Row gutter={[8,32]}>
              <Col span={12}>
                <Typography.Title className="text-white" style={{fontSize:fontSize.lg}}>
                  {!!is_arrival ? "Arrival" : "Departure"}
                </Typography.Title>
              </Col>
              <Col span={12} className="text-right">
                <Typography.Text className="text-white ">
                  {is_arrival ? arrival_date : flight_date}
                </Typography.Text>
              </Col>
        

              <Col span={6} className='text-center'>
                <Avatar
                  alt={airline_id?.code}
                  size="large"
                  style={{
                    alignSelf: "center",
                    backgroundColor: "white",
                    width: "6rem",
                    height: "6rem",
                  }}
                  className={"avatar-contain mb-4"}
                  src={`${getLogoAirline(airline_id?.code)}`}
                />
                {!!flight_rule_affected?.length ? 
                <Fragment>
                  {flight_rule_affected.map((item,idx)=>(
                    <Badge key={idx}  block color={"error"} text={item.rule_name?.toUpperCase().replace('_', ' ')} />
                  ))}
                </Fragment>
                : ""}

              </Col>
              <Col span={18}>
                {/* <RowList
              // title={}
              content={ */}
                <div className="text-right">
                  <Typography.Title className="text-white" style={{fontSize:fontSize.lg}}>
                    {flight_number}
                  </Typography.Title>
                  <div className="rowslist-inline-flex " >
                    <Typography.Text className="text-white" style={{fontSize:fontSize.md}}>
                      {airline_id?.name}
                    </Typography.Text>
                    <Flags
                      country_code={airline_id?.country}
                      // withCountry
                      countryWidth
                      // countryFormat={"alpha3"}
                    />
                  </div>
                  <p className="text-white" style={{fontSize:fontSize.md}}>
                    {!!acreg && !!actype
                      ? `${acreg}(${actype})`
                      : "Aircraft Not Registered"}
                  </p>
                </div>
                {/* }
              // dividerBottom
            /> */}
            

            
              </Col>
            </Row>
            {/* </div> */}

          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className='p-2'>
          <Card style={{height: '100%' }}>
         
            <RowList
              title={ `STD` }
              content={`${ moment(flight_date).format('DD/MMM/YY')} ${getFormatedTime({
                localAt: localAtd,
                localSt: localStd,
                st: std,
              })} LT`}
              dividerBottom
            />
            <RowList
              title={`STA`}
              content={`${moment(arrival_date).format('DD/MMM/YY')} ${getFormatedTime({
                localAt: localAta,
                localSt: localSta,
                st: sta,
              })} LT`}
              dividerBottom
            />
            <RowList
              title={"Total Passengers"}
              content={apis_info?.total_passengers || 0}
              dividerBottom
            />
            <RowList
              title={"Total Crew"}
              content={apis_info?.total_crew || 0}
              dividerBottom
            />
            <RowList title={"Source"} content={insert_from || "Telex"} />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
