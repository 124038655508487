import { FilterOutlined } from "@ant-design/icons";
import { Avatar, Button, List, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { useMemo, useState } from "react";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { useFetch } from "../../../../../hooks/useFetch";
import Badge from "../../../components/Badge";
import BaseListPage from "../../../components/BaseListPage";
import {
  getColorStatusTelex,
  getDomHeader,
  getHeaders,
  onLimitChar,
} from "../../../library";
import ModalFilterTelex from "../ModalFilterTelex";
import TelexList from "../TelexList";

export default function TelexInbox() {
  const [search, setSearch] = useState({
    Origin: "",
    destination: "",
    subSmi: "",
    status: 10,
    message: "",
    telexDate: [moment(), moment().add(1, "day")],
  });

  const formatedSearch = useMemo(() => {
    const { telexDate, status, ...rest } = search;
    const [date1, date2] = telexDate;
    const telex_date = [
      date1.format("DD-MMM-YYYY"),
      date2.format("DD-MMM-YYYY"),
    ];
    const s = status === 10 ? undefined : status;

    return { ...rest, telex_date, status: s };
  }, [search]);

  const [isModalFilter, setIsModalFilter] = useState(false);
  const handleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  return (
    <BaseListPage
      fixedPagination
      paginationUrl={`api/v1/telex/pagination`}
      searchApiProps={formatedSearch}
      datePickerChange={(val, valStr) => {
        setSearch({ ...search, telexDate: val });
      }}
      FilterComponent={() => {
        return (
          <Fragment>
            <Button
              style={{ width: "100%" }}
              type="primary"
              icon={<FilterOutlined />}
              size={24}
              onClick={handleModal}
            />
            {isModalFilter && (
              <ModalFilterTelex
                state={search}
                setState={setSearch}
                handleSearch={(value) => {
                  setSearch({
                    ...search,
                    ...value,
                    timestamp: moment().unix(),
                  });
                  setIsModalFilter(!isModalFilter);
                }}
                // title={direction}
                open={isModalFilter}
                handleModal={handleModal}
              />
            )}
          </Fragment>
        );
      }}
      // searchProps={['origin', 'destination']}
      // datas={stateLocation}
      renderItem={(item) => {
        const {
          _id,
          origin,
          destination,
          headers,
          processedAt,
          subSmi,
          status,
          message,
        } = item || {
          origin: "",
          destination: "",
          processedAt: "",
        };
        const { unh } = headers || { unh: "" };
        const { msgIdentifier, statusOfTransfer } = unh || {
          msgIdentifier: "",
          statusOfTransfer: "",
        };
        const { msgType, msgVersionNumber, msgReleaseNumber } =
          msgIdentifier || {
            msgTyp: "",
            msgVersionNumber: "",
            msgReleaseNumber: "",
          };
        const { seqOfTransfer } = statusOfTransfer || { seqOfTransfer: "" };
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  // className={"avatar-contain"}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: getColorStatusTelex(status),
                  }}
                  icon={<IoMdMail size={20} />}
                />
              }
              title={
                <Link to={{ pathname: `/telex/detail/${_id}`, state: item }}>
                  <div className="cursor-pointer">
                    <Typography.Title level={5}>
                      {origin} - {destination}
                    </Typography.Title>
                  </div>
                </Link>
              }
              description={`${
                onLimitChar(getHeaders(headers) || getDomHeader(message) || "-", 16)
              } `}
            />
            <div>
              {!!subSmi || !!msgVersionNumber || msgReleaseNumber ? (
                <Badge
                  color={"def"}
                  text={`${
                    subSmi || ""
                  } v.${msgVersionNumber}.${msgReleaseNumber}`}
                />
              ) : (
                ""
              )}

              {!!processedAt ? (
                <Typography.Text className="text-sub-title">
                  {moment(processedAt).format("DD-MMM-YYYY HH:mm")}
                </Typography.Text>
              ) : (
                ""
              )}
            </div>
          </List.Item>
        );
      }}
    />
  );
}
