import { Card } from 'antd';
import React from 'react'
import { useLocation } from 'react-router-dom';

export default function Osi() {
  const { state } = useLocation();

  return (
    <Card>
      <ul>
        {!!state &&
          !!state.length &&
          state.map((item, idx) => <li key={idx} style={{wordWrap: 'break-word'}}>{item}</li>)}
      </ul>
    </Card>
  )
}
