import { green } from '@ant-design/colors';
import { PrinterFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { Fragment } from 'react';
import CFG from '../../../../../../config/env';
import { useMemo } from 'react';
import RCTPrint from 'react-to-print';

const { BaseUrl } = CFG;

export default ({data})=>{
   
    return (
        <Fragment>
            <div style={{display:'flex', flexDirection:'row', gap:16, alignItems:'center'}}>
                <Typography.Text>LNSW</Typography.Text>
                <Button 
                    type='ghost' 
                    style={{color:green[5], border:`1px solid ${green[6]}`, borderRadius:99}} 
                    icon={<PrinterFilled />} 
                    onClick={()=>{
                        window.open('/docs/clearance/'+data._id, '__blank');
                    }}
                />
            </div>
        </Fragment>
    )
}