import { Avatar, Tabs, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Flags from '../../../../../../components/flags';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import Row from '../../category/row';
import AccompanyWith from '../../category/accompany_with';
import FlightHistory from '../../category/citizen_history_flight_inter';
import VesselHistory from '../../category/citizen_history_vessel_inter';
import { ImMan, ImWoman } from 'react-icons/im';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { GetAPI } from '../../../../../../redux';
import MalePict from '../../../../../../assets/media/male.png';
import FemalePict from '../../../../../../assets/media/female.png';
import CFG from '../../../../../../config/env';
import { FaPlane, FaShip, FaHotel } from 'react-icons/fa';
import {GiPassport, GiSecurityGate } from 'react-icons/gi';
import {GrVisa} from 'react-icons/gr';
import { Fragment } from 'react';
import Perlintasan from './perlintasan';
import Paspor from './paspor';
import Panel from '../../panel/panel';
import Permit from './permit';
import Visa from './visa';
import { IoIosPeople } from 'react-icons/io';
import DualCitizenship from '../../category/dual_citizen';
const {internalDetail} = CFG;

export default ({data})=>{
    const calcAge=(dob)=>{
        let m = moment(dob, 'DD-MMM-YYYY');
        if(!m.isValid()){
            m=moment(dob, 'DDMMMYY');
        }
        if(m>moment()){
            m.subtract(100, 'years');
        }
        return [Math.floor(moment.duration(moment().diff(m)).asYears()), m.format('DD-MMM-YYYY')];
    }

    const calcExpiration=(expire)=>{
        let m = moment(expire, 'DD-MMM-YYYY');
        if(!m.isValid()){
            m=moment(expire, 'DDMMMYY');
        }
        
        return [moment.duration(m.diff(moment())).asMonths(), m.format('DD-MMM-YYYY')];
    }

    const [_id, fullname, dob, createdAt, gender, id_expiry, issued_id_country, jenis_doc, nationality, no_id, age, expire_time] = useMemo(()=>{
        if(!data)return [false, false, false, false, false, false,  false,  false, false, false, false]
        const {_id, fullname, dob, createdAt, gender, id_expiry, issued_id_country, jenis_doc, nationality, no_id } = data;
        const [age, birthday]=calcAge(dob);
        const [months, expiration] = calcExpiration(id_expiry);
        return [_id, fullname, birthday, createdAt, gender, expiration, issued_id_country, jenis_doc, nationality, no_id, age, months];
    },[data]);
    const [,getLan] = useLanguage();
    const [internal_detail, setInternalDetail]=useState({face:'', izintinggal:[], paspor:[], visa:[], perlintasan:[]});
    const dispatch=useDispatch();
    const {userdata:{level}} = useSelector(state=>state.auth);
    useEffect(()=>{
        dispatch(GetAPI({url:`api/v1/citizen/internal/detail/${_id}`})).then((payload)=>{
            // console.log({payload});
            if(!!payload?.payload){
                const {data:{data}} = payload.payload;
                const {face, izintinggal, paspor, visa, perlintasan} = data;
                setInternalDetail({face, izintinggal, paspor, visa, perlintasan});
            }
        })
    }, [_id]);

    const renderGender=(gender)=>{        
        switch (gender.toLowerCase()) {
            case 'female':
            case 'f':
                return <ImWoman size={24} color="#ff6e87" />
            case 'm':
            case 'male':
                return <ImMan size={24} color="blue"/>
            default:
                return <ImMan size={24} color="blue"/>
        }
    }

    const getAvatar=(gender)=>{        
        switch (gender.toLowerCase()) {
            case 'female':
            case 'f':
                return FemalePict
            case 'm':
            case 'male':
                return MalePict
            default:
                return MalePict
        }
    }
    const getJenisDoc=(jenis_doc)=>{
        return jenis_doc==='P'?'P':jenis_doc.toLowerCase()==='passport'?'P':jenis_doc
    }
    const [tabActive, setTabActive]=useState('1');
    const TabLabel=({Caption, Icon})=>{
        return <span style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center'}}> {Icon && <Icon size={24} />} {Caption} </span>
    }
    return data && (
        <div style={{display:'flex', flexDirection:'row', gap:32, padding:16, flexWrap:'wrap'}}>
            <div style={{display:'flex', flexDirection:'row', rowGap:16, gap:4, padding:8, width:'100%', justifyContent:'space-between', background:'white'}}>
                <Avatar 
                    src={!!internal_detail?.face?`data:image/jpeg;base64,${internal_detail.face}`:getAvatar(gender)}
                    size={'large'}
                    style={{margin:8, width:120, height:120}}
                />
                <div style={{width:`calc(((100% - 120px)/2) - 8px)`, display:'flex', flexDirection:'column'}}>
                    <Row last={true} name={'citizen_name'} value={fullname} />                  
                    <Row last={true} name={'gender'} value={
                        <div style={{display:'flex', flexDirection:'row last={true}', gap:4}}>
                            {renderGender(gender)}
                            <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                {numeral(age).format('00')}
                            </span>
                        </div>
                    } />                  
                    <Row last={true} name={'dob'} value={dob} />                  
                    <Row last={true} name={'nationality'} value={<Flags country_code={nationality} withCountry />} />                  
                    {/* <Row last={true} name={'created_at'} value={moment(createdAt).format('DD-MMM-YYYY HH:mm:ss')} last />                   */}
                </div>
                <div style={{width:`calc(((100% - 120px)/2) - 8px)`, display:'flex', flexDirection:'column'}}>
                    <Row last={true} name={'doc_type'} value={getJenisDoc(jenis_doc)} />                  
                    <Row last={true} name={'doc_number'} value={no_id} />                  
                    <Row last={true} name={'doc_country'} value={<Flags country_code={issued_id_country} withCountry />} />                  
                    <Row last={true} name={'doc_expiry'} value={`${id_expiry} (${Math.floor(expire_time)} Months)`} />                  
                </div>
            </div>
            <Tabs 
                activeKey={tabActive}
                onChange={(key)=>setTabActive(key)}
                style={{flexGrow:1}}
                type='card'
            >
                <Tabs.TabPane key='1' tab={<TabLabel Caption={getLan('flight')} Icon={FaPlane} />}>
                    <FlightHistory _id={_id} />
                </Tabs.TabPane>
                <Tabs.TabPane key='2' tab={<TabLabel Caption={getLan('vessel')} Icon={FaShip} />}>
                    <VesselHistory _id={_id} />
                </Tabs.TabPane>
                {
                    internalDetail && (
                        <Fragment>
                            <Tabs.TabPane key='3' tab={<TabLabel Caption={getLan('passport')} Icon={GiPassport} />}>
                               <Panel
                                    headerLeft={<GiPassport size={24}/>}
                                    headerRight={<Typography.Text>{getLan('passport')}</Typography.Text>}
                                >
                                    <Paspor data={internal_detail.paspor} />    
                                </Panel>
                            </Tabs.TabPane>
                            <Tabs.TabPane key='4' tab={<TabLabel Caption={getLan('perlintasan')} Icon={GiSecurityGate} />}>
                                <Panel
                                    headerLeft={<GiSecurityGate size={24}/>}
                                    headerRight={<Typography.Text>{getLan('perlintasan]')}</Typography.Text>}
                                >
                                    <Perlintasan data={internal_detail.perlintasan} />
                                </Panel>
                            </Tabs.TabPane>
                            <Tabs.TabPane key='5' tab={<TabLabel Caption={getLan('visa')} Icon={GrVisa} />}>
                                <Panel
                                    headerLeft={<GrVisa size={24}/>}
                                    headerRight={<Typography.Text>{getLan('visa')}</Typography.Text>}
                                >
                                    <Visa data={internal_detail.visa} />
                                </Panel>
                            </Tabs.TabPane>
                            <Tabs.TabPane key='6' tab={<TabLabel Caption={getLan('izin_tinggal')} Icon={FaHotel} />}>
                                <Panel
                                    headerLeft={<FaHotel size={24}/>}
                                    headerRight={<Typography.Text>{getLan('izin_tinggal')}</Typography.Text>}
                                >
                                    <Permit data={internal_detail.izintinggal} />
                                </Panel>
                            </Tabs.TabPane>
                        </Fragment>
                    )
                }
                {
                    ((level & 0x1f00) >0) && (
                        <Tabs.TabPane key="7" tab={<TabLabel Caption={getLan('dual_citizenship')} Icon={IoIosPeople} />}>
                            <DualCitizenship _id={_id}/>
                        </Tabs.TabPane>
                    )
                }

            </Tabs>
        </div>
    )
}