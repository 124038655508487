export default  {
    home:'Beranda',
    name:"Nama",
    master:'Master',
    airline:'Maskapai',
    airport:'Bandara',
    aircraft:"Pesawat",
    aircraft_reg:'Registrasi Pesawat',
    passengers:'Penumpang',
    citizen:'Penumpang',
    list:'Daftar Penumpang',
    matched:'Cocok',    
    watchlist:'Pantauan',
    rules:'Pengaturan',
    users:'Pengguna',
    telex:'Telex',
    telex_in:"Masuk",
    telex_failed:"Gagal Proses",
    flight:'Penerbangan',
    flight_master:'Master',
    flight_schedule:"Jadwal Penerbangan",
    flight_schedule_departure:"Keberangkatan",
    flight_schedule_arrival:"Kedatangan",
    vessel:'Kapal Laut',
    vessel_departure:'Keberangkatan',
    vessel_arrival:'Kedatangan',
    vesseldeparture:'Keberangkatan',
    vesselarrival:'Kedatangan',
    search:'Pencarian',
    flight_date:'Tanggal Penerbangan',
    flight_number:'Nomor Penerbangan',
    others:'Lain-lain',
    bot:'Bot',
    whatsapp:"Whatsapp",
    log:"Riwayat Pemakaian",
    dashboard:"Dasbor",
    inbound:'Kedatangan',
    outbound:'Keberangkatan',
    transliteration:'Alih Aksara',
    no_aircraft:'Registrasi pesawat tidak ditemukan',
    iata_code:'Kode IATA',
    icao_code:'Kode ICAO',
    country:'Negara',
    country_code:"Kode Negara",
    origin:'Asal',
    dest:"Tujuan",
    city:"Kota",
    timezone:"Zona Waktu",
    about:'Tentang Program Ini',
    std:"STD",
    sta:'STA',
    source:'Sumber Data',
    loading:"Memuat...",
    pnr:'Kode Booking',
    booking_date:'Waktu Booking',
    detail:'Detil',
    originator:"Sumber Asal",
    system_location:'Lokasi Sistem',
    travel_agent_iata_number:"Agen Perjalanan#",
    currency:'Mata Uang',
    language:"Bahasa",
    osi:"Informasi Layanan Lainnya (OSI)",
    ssr:"Permintaan Layanan Khusus (SSR)",
    not_found:'Tidak Ditemukan',
    itinerary:'Riwayat Perjalanan',
    additional_ticketing_info:'Tambahan Informasi Ticketing',
    comming_soon:"Segera hadir",
    ctcm:"Kontak Telepon Genggam",
    ctce:"Kontak Surel",
    contact_information:'Informasi Kontak',
    total_pax:"Jumlah Penumpang",
    underconstruction:'Menu ini masih dalam tahap pengembangan',
    history:"Riwayat",
    telex_type:"Tipe Telex",
    created_at:"Waktu Masuk",
    notes:"Catatan",
    created_by:"Pembuat",
    processed_at:"Waktu Proses",
    status:"Status",
    telex_date:"Tanggal",
    smi:"SMI",
    reff:'Referensi',
    passenger_name:"Nama Penumpang",
    dob:"Tgl Lahir",
    gender:"J/K",
    nationality:"Kewarganegaraan",
    doc_type:"Tipe Dokumen",
    doc_country:"Negara Dokumen",
    doc_number:"Nomor Paspor",
    passenger_type:"Tipe Penumpang",
    doc_expiry:"Masa Berlaku",
    accompany_with:'Rekan Seperjalanan',
    age:'Usia',
    copy:"Salin",
    copy_original:"Salin Pesan Asli",
    frscheduler:"FR Scheduler",
    ap1:"Angkasa Pura 1",
    ap2:"Angkasa Pura 2",
    search_menu:'Cari Menu',
    ferry_key:'ID Ferry',
    schedule_id:'ID Jadwal',
    operator_id:"ID Operator",
    schedule_date:"Tanggal",
    schedule_time:"Waktu",
    destination:"Tujuan",
    created_at:"Waktu Pesan Masuk",
    updated_at:"Waktu Pesan Pembaruan",
    vessel_passengers:'Daftar Penumpang',
    seat_type:"Tipe Kursi",
    ticket_number:"Nomor Tiket",
    boarding_number:"Nomor Bording",
    flight_schedule_domestic:'Domestik',
    queue:"Antrian Proses",
    content_length:"Panjang Karakter",
    flight_domestic:'Penerbangan Domestik',
    flight_international:"Penerbangan Internasional",
    error:"Pesan Error",
    id:"ID",
    remarks:"Catatan",
    class:'Kelas',
    dom_inbound:'Penerbangan Sebelumnya',
    dom_outbound:'Penerbangan Setelahnya',
    male:'Laki-laki',
    female:'Perempuan',
    vessel_passenger:"Penumpang Kapal",
    pax_type:"Tipe Penumpang",
    issuing_place:"Diterbitkan Di",
    occupation:"Pekerjaan",
    db:"Basis Data",
    flight_arrival:"Kedatangan - Penerbangan",
    flight_departure:"Keberangkatan - Penerbangan ",
    vessel_arr:"Kedatangan - Kapal",
    vessel_dep:"Keberangkatan - Kapal",
    citizen_name:"Nama",
    search_any:"Pencarian(Nama atau Nomor Paspor)",
    flight_history:"Riwayat Penerbangan",
    domestic:"Domestik",
    copy_error:"Salin Pesan Error",
    copy_data:"Salin Data",
    copy_telex:"Salin Telex",
    delete_process:'Hapus',
    reprocess:"Proses Ulang",
    vessel_history:"Riwayat Perjalanan Kapal",
    pax_by_age:"Penumpang Berdasarkan Usia",
    pax_by_nat:"Penumpang Warga Negara",
    pax_by_org:"Penumpang Berdasarkan Asal Keberangkatan",
    pax_by_dst:"Penumpang Berdasarkan Daerah Tujuan",
    total_pax:"Jumlah Penumpang",
    total_crew:"Jumlah Kru",
    data_not_found:"Data Tidak Ditemukan",
    process_status:"Status Pemroses",
    process_logs:"Riwayat Pemroses",
    process_time:'Jumlah Proses',
    process_name:"Nama Pemroses",
    last_active:"Waktu Terakhir Aktif",
    command:"Perintah",
    processing_time:"Waktu(ms)",
    process_id:"Proses ID",
    bot_telex:"Bot Telex",
    passport:"Paspor",
    visa:"Visa",
    carrier:"Pengangkut",
    monthly:"Bulanan",
    daily:"Harian",
    all:'Semua',
    change_password:"Ganti Kata Sandi",
    current_password:"Kata Sandi Sekarang",
    new_password:"Kata Sandi Baru",
    confirm_password:"Kata Sandi Konfirmasi",
    save:"Simpan",
    curr_passw_message:'Mohon masukan kata sandi saat ini!',
    new_passw_message:'Mohon masukan kata sandi baru!',
    conf_passw_message:"Mohon masukan konfirmasi kata sandi",
    passw_not_match:"Kata sandi baru dan konfirmasi tidak sama",
    seat_number:'Kursi',
    passport_min:'Paspor Minimal(Bulan)',
    trafficking_param_girl:"Jumlah Wanita",
    trafficking_param_age:"Umur Wanita",
    picture_list:"Daftar Gambar",
    rule_type:"Tipe",
    time:"Waktu",
    ages:"Usia",
    expiration:"Masa Berlaku",
    first_age:"Usia Awal",
    last_age:"Usia Akhir",
    min_value:'Jumlah Minimal',
    max_value:'Jumlah Maksimal',
    values:'Jumlah',
    screen_name:"Nama Pengaturan",
    db_name:"Fungsi",
    station:"Stasion",
    poi:"Pemantauan",
    passenger_or_passport:"Nama Penumpang/Paspor",
    seaport:"Pelabuhan",
    date:"Tanggal",
    rule_name:"Nama Pengaturan",
    total:"Total",
    passport_number:'Nomor Paspor',
    msg_type:"Tipe Pesan",
    pnrapi:"PNRGOV APIS Monitoring",
    time_missing:"Waktu Terlewat(Min)",
    domestic_pax:'Penumpang Domestic',
    izin_tinggal:'Izin Tinggal',
    perlintasan:'Perlintasan',
    arah:'Arah',
    number:"Nomor Pengangkut",
    kode_tpi:"Kode TPI",
    nama_tpi:"Nama TPI",
    melintas:"Melintas",
    next_flight:"Penerbangan Selanjutnya",
    vessel_name:"Nama Kapal",
    dual_citizenship:'Kewarganegaraan Ganda',
}