import { Card, Col, Divider, Row, Tabs, Typography } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import RowList from "../../components/RowList";
import { AppVersion, getUpdateLogs } from "../../../../config/appVersion";
import { useFetch } from "../../../../hooks/useFetch";
import { useFontSize } from "../../../../hooks/useDimension";

export default function About() {
  const fontSize = useFontSize();
  const { language } = useSelector((state) => state.apps);
  const historyFe = getUpdateLogs();
  const [beVersion] = useFetch("api/v1/version");
  const { version: beCurrentVersion, history: historyBe } = beVersion;

  const TabItems = [
    {
      key: "History FE",
      label: "Front End",
      children:
        Array.isArray(historyFe) &&
        historyFe.map((u, idx) => {
          const { version, update } = u;
          const updates = update[language.toLowerCase()];
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 8,
                padding: "8px 16px",
              }}
              key={idx}
            >
              <Typography.Text strong>{version}</Typography.Text>
              <ul style={{ paddingInline: 20 }}>
                {updates.map((up, iiix) => (
                  <li key={iiix}>{up}</li>
                ))}
              </ul>
            </div>
          );
        }),
    },
    {
      key: "History BE",
      label: "Back End",
      children: historyBe?.map((u, idx) => {
        const { version, update } = u;
        const updates = update[language.toLowerCase()];
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 8,
              padding: "8px 16px",
            }}
            key={idx}
          >
            <Typography.Text strong>{version}</Typography.Text>
            <ul style={{ paddingInline: 20 }}>
              {Array.isArray(updates) &&
                updates.map((up, iiix) => <li key={iiix}>{up}</li>)}
            </ul>
          </div>
        );
      }),
    },
  ];
  return (
    <Fragment>
      <Card className="bg-base-theme">
        <Typography.Title style={{ color: "white" }}>
          Passengers Analysis Unit
        </Typography.Title>
        <Typography.Text style={{ color: "white" }}>
          Apps for Pax Screening <br /> By. Direktorat Jendral Imigrasi{" "}
        </Typography.Text>

        <Typography className="mt-4" style={{ color: "white" }}>
          Current Version
        </Typography>
        <Divider className="my-2" style={{ borderColor: "white" }} />
        <div className="p-2">
          <RowList
            title={<div style={{ color: "white" }}>Front End</div>}
            content={<div style={{ color: "white" }}>{AppVersion}</div>}
          />
          <RowList
            title={<div style={{ color: "white" }}>Back End</div>}
            content={<div style={{ color: "white" }}>{beCurrentVersion}</div>}
          />
        </div>
      </Card>
      <div className=" mt-4" >
        <Typography.Title className="p-4" style={{ fontSize: fontSize.lg }}>
          History Update
        </Typography.Title>
        <Card>

        <Tabs>
          {TabItems.map((item, idx) => (
            <Tabs.TabPane tab={item.label} key={idx}>
              {item.children}
            </Tabs.TabPane>
          ))}
          {/* <Tabs.TabPane tab="Flight Radar" key="item-1">
          <FlightRadar />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Telex Logs" key="item-2">
          <TelexLogs />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Process Status" key="item-3">
          <ProccessStatus />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Process Logs" key="item-4">
          <ProccessLogs />
        </Tabs.TabPane> */}
        </Tabs>
        </Card>
      </div>
    </Fragment>
  );
}
