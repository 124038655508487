import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { GiHarborDock, GiSailboat } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Row from './row';
import Flags from '../../../../../components/flags';

export default ({data}) =>{
    const { schedule_id, schedule_date, _id, sta, std,  schedule_time, ferry_key, origin, destination, source, 
        createdAt, updatedAt, operator_id, is_arrival, origin_id, destination_id } = data;
    const [,getLan]=useLanguage();
    const direction=is_arrival?'arrival':'departure';
    const url=`/vessel/${direction}/${_id}`;
    const schedule=is_arrival?sta:std;
    const Station=({station_id, station_name})=>{
        return station_id?.name && (
            <div style={{display:'flex', flexDirection:'row', gap:8}}>
                <Flags countryWidth={30} country_code={station_id?.country} withCountry countryFormat={'alpha3'}  />
                -
                <span>
                    {station_id?.name?.toUpperCase()}
                </span>
            </div>
        ) || station_name;
    }
    return (
        <Panel
            headerLeft={is_arrival?<GiHarborDock size={24} />:<GiSailboat size={24} />}
            headerRight={<Typography.Text>{getLan('vessel_'+direction)}</Typography.Text>}
        >
            <Row name={'schedule_id'} value={<Link to={url}>{schedule_id}</Link>} />
            <Row name={'operator_id'} value={operator_id} />
            <Row name={'ferry_key'} value={ferry_key} />
            <Row name={'schedule_date'} value={`${schedule}`} />
            <Row name={'origin'} value={<Station station_id={origin_id} station_name={origin} />} />
            <Row name={'destination'} value={<Station station_id={destination_id} station_name={destination} />} />
            <Row name={'created_at'} value={moment(createdAt).format('DD-MMM-YYYY HH:mm:ss')} />
            <Row name={'updated_at'} value={moment(updatedAt).format('DD-MMM-YYYY HH:mm:ss')} />
            <Row last name={'source'} value={source || "-"} />
        </Panel>
    )
}