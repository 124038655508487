import {  Form, Input, InputNumber, Select } from 'antd';
import React, { useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import { useLanguage } from '../../../../hooks/useLanguage';
import Page from '../components/pages/form';
import { MdOutlineRuleFolder } from 'react-icons/md';
import AutoComplete from '../../../../components/autocomplete/Online';

export default () => {
    const [,getLan]=useLanguage();
    const [model, setModel]=useState({name:'', rule_type:'passenger', nationality:'', origin:null, dest:null,
        gender:'', first_age:0, last_age:0, expired_date:0, min_value:0, max_value:0
    });
    return (
        <Page 
            title={'rules'}
            icon={MdOutlineRuleFolder} 
            bgColor={"#ffffff88"}
            // labelCol={{span:4}}
            model={model}
            setModel={setModel}
            url={"api/v1/rules"}
            callbackSuccess="/passengers/rules"            
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'rules'
                    },
                ]
            }
        >
            <div style={{display:'flex', flexDirection:'row', gap:32}}>
                <div style={{display:'flex', flexDirection:'column', width:'40%', rowGap:8}}>
                    <Form.Item label={getLan('name')} name="name" required>
                        <Input autoFocus autoComplete='off' />
                    </Form.Item>
                    <Form.Item label={getLan('rule_type')} name="rule_type" required>
                        <Select>
                            <Select.Option value="passenger">Passenger</Select.Option>
                            <Select.Option value="passport">Passport</Select.Option>
                            <Select.Option value="pnr">PNR</Select.Option>
                            <Select.Option value="flight">Flight</Select.Option>
                        </Select>
                    </Form.Item>                    
                </div>
                <div style={{display:'flex', flexDirection:'column', width:'30%', rowGap:8}}>
                    <Form.Item label={getLan('nationality')} name="nationality">
                        <Input autoComplete='off' />
                    </Form.Item>
                    <Form.Item label={getLan('gender')} name="gender">
                        <Select>
                            <Select.Option value="female">Female</Select.Option>
                            <Select.Option value="male">Male</Select.Option>
                        </Select>
                    </Form.Item>   
                    <Form.Item label={getLan('origin')} name="origin">
                        <AutoComplete 
                            getKey={(opt)=>opt._id}
                            getSelectedOptions={(opt, val)=>opt._id===val._id}
                            getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                            uri="api/v1/airport"
                            onChange={(val)=>setModel({...model, origin:val})}
                            value={model.origin}
                        />
                    </Form.Item>
                    <Form.Item label={getLan('dest')} name="dest">
                        <AutoComplete 
                            getKey={(opt)=>opt._id}
                            getSelectedOptions={(opt, val)=>opt._id===val._id}
                            getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                            uri="api/v1/airport"
                            onChange={(val)=>setModel({...model, dest:val})}
                            value={model.dest}
                        />
                    </Form.Item>                    
                </div>
                <div style={{display:'flex', flexDirection:'column', flexGrow:1, rowGap:8}}>
                    <Form.Item label={getLan('first_age')} name="first_age">
                        <InputNumber  style={{width:'100%'}} />
                    </Form.Item>
                    <Form.Item label={getLan('last_age')} name="last_age">
                        <InputNumber  style={{width:'100%'}} />
                    </Form.Item>
                    <Form.Item label={getLan('min_value')} name="min_value">
                        <InputNumber  style={{width:'100%'}} />
                    </Form.Item>
                    <Form.Item label={getLan('max_value')} name="max_value">
                        <InputNumber  style={{width:'100%'}} />
                    </Form.Item>
                </div>
            </div>
        </Page>
    )
}