import { Divider, Typography } from 'antd'
import React, { Fragment, useEffect} from 'react'
import { useSelector } from 'react-redux';
import CONFIG from '../../config/env';
import { useLanguage } from '../../hooks/useLanguage';
import {getLanguage} from '../../languages/utils';
const {Title} = CONFIG;

export default ({ title, children})=>{
    useEffect(()=>{
        document.title=`${Title} (${title})`;
    }, [])
    const {language} = useSelector(state=>state.apps);
    const [Lang] = useLanguage(language);
    return (
        <Fragment>
            <Typography.Title level={4}>{getLanguage(`${title}`.toLowerCase(), Lang)}</Typography.Title>
            <Divider style={{marginBlock:16}} />
            <div style={{display:'flex', flexDirection:'column'}}>
                {children}
            </div>
        </Fragment>
    )
}