import React from 'react'
// import useMediaQuery from 'use-media-antd-query';
// import SmallLogin from './smallLogin';
import LargeLogin from './largeLogin';

export default ({apps}) => {
    // const col = useMediaQuery();
    // const size=useMemo(()=>{
    //     if(['xs', 'sm', 'md'].indexOf(col)>=0)return 'small';
    //     return 'large';
    // }, [col])
    return <LargeLogin apps={apps}/>
}