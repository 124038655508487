import React, { useEffect, useMemo, useState } from 'react';
import { useAos } from '../../../../../hooks/useAos';
import { useFetch } from '../../../../../hooks/useFetch';
import Page from './page';      
import {Table} from 'antd';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Scrollbars from 'react-custom-scrollbars';
import { useRef } from 'react';

export default ({title, icon, breadcrumbs, url, searchComponent, searchValue, columns, onRowClick, onRowMiddleClick}) => {
    const [page, setPage]=useState(1);
    const [perPage, setPerPage]=useState(10);
    const [srcVal, setSrcVal]=useState(false);
    const timeout=useRef(null);
    const [defUrl, setDefUrl]=useState(false);
    useEffect(()=>{
        // if(!srcVal)srcVal;
        if(!!srcVal){
            if(!!timeout.current)clearTimeout(timeout.current);
            timeout.current=setTimeout(()=>{
                const u=`${url}/pagination?search=${JSON.stringify(srcVal)}&page=${page}&perPage=${perPage}`;
                setDefUrl(u)
            }, 100);
        }
    }, [url, srcVal, page, perPage]);
    useEffect(()=>{
        setSrcVal(searchValue);
        setPage(1);
    }, [searchValue]);
    const aos=useAos();
    const [,getLang]=useLanguage();
    const [rows, loading]=useFetch(defUrl);
    const cols=useMemo(()=>{
        return !!columns && Array.isArray(columns) && columns.map((({title, ...rest})=>({...rest, title:getLang(title)}))) || [];
    },[columns]);

    const [data,,subTotal]=useMemo(()=>{
        if(!rows)return [[], 0, 0];
        const {data:d, total:t, subTotal:st} = rows;
        return [d, t, st];
    }, [rows]);

    return (
        <Page title={title} icon={icon} breadcrumbs={breadcrumbs}>
            <Scrollbars>    
                <div style={{display:'flex', flexDirection:'row', height:'100%', paddingBlock:8, rowGap:8, gap:8, flexWrap:'wrap'}}>
                        <div data-aos={aos}>
                            {searchComponent}
                        </div>
                        <div style={{flexGrow:1, background:'#ffffff88', height:'100%', minWidth:'50%', padding:24}}>
                            <Scrollbars>
                                <Table 
                                    style={{paddingInline:16}}
                                    columns={cols}
                                    dataSource={data || []}
                                    size="small"
                                    loading={loading}                        
                                    bordered 
                                    rowKey={(r)=>r._id}
                                    pagination={{
                                        current:page, pageSize:perPage, total:subTotal,
                                        position:['topLeft'],
                                        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        onChange:(pp, ps)=>{
                                            setPage(pp);
                                            setPerPage(ps);
                                        } 
                                        
                                    }}
                                    rowClassName={typeof onRowClick==='function' && "row-pointer"}
                                    onRow={(row, idx)=>{
                                        return {
                                            onClick:()=>{
                                                typeof onRowClick==='function' && onRowClick(row);
                                            },
                                        }
                                    }}
                                />
                            </Scrollbars>
                        </div>
                </div>
            </Scrollbars>
        </Page>
    )

}