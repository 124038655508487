import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name',
            compact:true
        },
        {
            selector:(row)=>row?.kanwil_id?.name ,
            name:"Kanwil",
            id:'kanwil_id',
            compact:true
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'created_at'
        },
        {
            selector:(row)=>row?.createdBy?.username || '-',
            name:"CreatedBy",
            id:'created_by'
        },
    ]
    return (
        <TablePage
            title={"Master Kanim"}
            url="api/v1/kanim"
            actionPosition={'first'}
            createPath="/master/kanim/create"
            editPath={"/master/kanim/edit"}
            columns={columns}            
        />
    )
}