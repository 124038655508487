import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import Page from '../components/pages/paging';
import Search from './search';
import { MdQueue } from 'react-icons/md';

export default () => {
    const Icon=MdQueue;
    const [searchValue, setSearchValue]=useState({ msg_type:'', timestamp:moment().unix(), date:moment()});    
    const uri=`api/v1/message/checker`;
    const formatedSearchVal=useMemo(()=>{
        const { msg_type, date, ...others} = searchValue;
        const s=!msg_type?undefined:msg_type;
        return {...others, msg_type:s, date:date?.format('DD-MMM-YYYY') || undefined}
    }, [searchValue]);
    // const handleNeedRefresh=(resp)=>{
    //     setSearchValue({...searchValue, refresh:moment().unix()})
    // }
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            columns={[
                {
                    title:'flight_number',
                    dataIndex:"flight_schedule_id",
                    ellipsis:true,
                    render:(val, row)=>val?.flight_number
                },
                {
                    title:'flight_date',
                    dataIndex:"flight_schedule_id",
                    ellipsis:true,
                    render:(val, row)=>val?.flight_date
                },
                {
                    title:'msg_type',
                    dataIndex:"msg_type",
                    ellipsis:true,
                    width:'120px'
                },              
                {
                    title:'time_missing',
                    dataIndex:"time_missing",
                    ellipsis:true,
                },              
                {
                    render:(val, row)=>row.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm'),
                    title:'created_at'
                },
            ]}
            title={`pnrapi`} 
            icon={Icon} 
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'pnrapi'
                    },
                ]
            }
        />
    )
}