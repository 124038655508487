import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "../login";
import DashboardPages from "./pages";
import { setApps, initMe } from "../../redux";
import SplashPage from "../../splash";
import { createUseStyles, ThemeProvider } from "react-jss";
import { theme } from "./theme";
import "./styles.module.css";

const UserPage = ({ userdata }) => {
  console.log({ userdata });
  return (
    <BrowserRouter basename="/appsv2">
      <Switch>
        <Route exact path="/">
          {(!!userdata && <Redirect to={"/home"} />) || (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/login">
          {(!!userdata && (userdata.level & 0x1fff) > 0 && (
            <Redirect to={"/home"} />
          )) || <LoginPage apps={"Appsv2"} />}
        </Route>
        <Route path="/*">
          {(!!userdata && (userdata.level & 0x1fff) > 0 && (
            <DashboardPages idx={new Date().getTime()} />
          )) || <Redirect to="/login" /> || <Redirect to="/login" />}
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default () => {
  const { userdata, initComplete } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setApps("head-office")); // set applikasi
    dispatch(initMe()); // cek token validasi
  }, [initMe, setApps]);
  if (!initComplete) return <SplashPage />;
  return (
    <ThemeProvider theme={theme}>
      <div className="root">
        <UserPage userdata={userdata} />
      </div>
    </ThemeProvider>
  );
};
