import { blue } from "@ant-design/colors";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { useFetch } from "../../../../../../hooks/useFetch";
import RowList from "../../../../components/RowList";


export default function FlightRadar() {
  const [data] = useFetch("api/v1/bot/fr_scheduler");
  return (
    <div className="p-3">
        <RowList title={<div style={{fontWeight: "bold", color:blue[6]}}>AIRPORT</div>} content={<div style={{fontWeight: "bold", color:blue[6]}}>LAST REQUEST</div>} dividerBottom />

      {Object.keys(data || {}).map((item, idx) => (
        <RowList
          key={idx}
          title={item}
          content={moment.unix(data[item]).format("DD/MMM/YY HH:mm")}
          dividerBottom={idx < Object.keys(data || {}).length}
        />
      ))}
    </div>
  );
}
