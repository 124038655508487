import { Avatar, Typography } from 'antd';
import React, { useMemo } from 'react';
import { IoIosPeople, IoIosPerson, IoMdPeople } from 'react-icons/io';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Table from '../lists/table';
import { useFetch } from '../../../../../hooks/useFetch';
import { GiSailboat } from 'react-icons/gi';
import moment from 'moment';
import { Link } from 'react-router-dom';
import WatchFlag from './watchflag';

export default ({_id,}) => {
    const [data, loading] = useFetch('api/v1/dual_citizen/detail/citizen_id/'+_id);

    const [,getLan]=useLanguage();
    return (
        <Panel
            headerLeft={<IoIosPeople size={24}/>}
            headerRight={<Typography.Text>{getLan('dual_citizenship')}</Typography.Text>}
        >
            <Table 
                data={(data && data[0]?.match_ids) || []}
                pagination={false}
                filterField={['passenger_name', 'doc_number']}
                // url="/vessel/passengers/detail"
                columns={[
                {
                    title:'name',
                    dataIndex:'_id',  
                    width:'200px',     
                    render:(val, {_id, fullname})=><Link to={"/passengers/citizen/detail/"+_id}>{fullname}</Link>
                },
                {
                    title:'passport',
                    dataIndex:'no_id',  
                    render:(val)=>val
                },                                
                {
                    title:'nationality',
                    dataIndex:'nationality',       
                    render:(val)=>val
                },
                {
                    title:'dob',
                    dataIndex:'dob',       
                    render:(val)=>val
                },
            ]}
            />
        </Panel>
    )
}