export default {
    home:'Home',
    name:"Name",
    master:'Master',
    airline:'Airline',
    airport:'Airport',
    aircraft:"Aircraft",
    aircraft_reg:'Aircraft Registration',
    passengers:'Passengers',
    citizen:'Citizen',
    list:'Lists',
    matched:'Matched',
    watchlist:'Watchlist',
    rules:'Rules',
    telex:'Telex',
    telex_in:"Inbox",
    telex_failed:"Failing Parse",
    users:'Users',
    flight:'Flight',
    flight_master:'Master',
    flight_schedule:"Schedule",
    flight_schedule_departure:"Departure",
    flight_schedule_arrival:"Arrival",
    vessel:'Vessel',
    vessel_departure:'Departure',
    vessel_arrival:'Arrival',
    vesseldeparture:'Departure',
    vesselarrival:'Arrival',
    search:'Search',
    flight_date:'Flight Date',
    flight_number:'Flight Number',
    others:'Other',
    bot:'Bot',
    whatsapp:"Whatsapp",
    log:"Log",
    dashboard:"Dashboard",
    inbound:'Inbound',
    outbound:'Outbound',
    transliteration:'Transliterasi',
    no_aircraft:'No aircraft registration found',
    iata_code:'IATA Code',
    icao_code:'ICAO Code',
    country:'Country',
    country_code:"Country Code",
    origin:'Origin',
    dest:"Destination",
    city:"City",
    timezone:"Time Zone",
    about:'About',
    std:"STD",
    sta:'STA',
    source:'Source',
    loading:"Loading...",
    pnr:'PNR',
    booking_date:'Booking Date',
    detail:'Detail',
    originator:"Originator",
    created_by:"Created By",
    notes:"Notes",
    system_location:'System Location',
    travel_agent_iata_number:"Travel Agent#",
    currency:'Currency',
    language:"Language",
    not_found:'Not Found',
    osi:"Other Service Information (OSI)",
    ssr:"Special Service Request (SSR)",
    additional_ticketing_info:'Additional Ticketing Info',
    itinerary:'Itinerary',
    comming_soon:"Comming Soon",
    ctcm:"Mobile Contact",
    total_pax:"Total Pax",
    contact_information:'Contact Information',
    ctce:"Email Contact",
    history:"History",
    underconstruction:'This Menu is Underconstruction',
    telex_type:"Telex Type",
    created_at:"Created At",
    processed_at:"Processed At",
    status:"Status",
    telex_date:"Date",
    smi:"SMI",
    reff:'Reference',
    passenger_name:"Fullname",
    dob:"Birthday",
    gender:"Gender",
    nationality:"Nationality",
    doc_type:"Doc Type",
    doc_country:"Doc Country",
    doc_number:"Doc Number",
    doc_expiry:"Doc Expiration",
    passenger_type:"Passenger Type",
    age:'Age',
    accompany_with:'Accompany With',
    copy:"Copy",
    copy_original:"Copy Original",
    frscheduler:"FR Scheduler",
    ap1:"Angkasa Pura 1",
    ap2:"Angkasa Pura 2",
    search_menu:'Search Menu',
    ferry_key:'Ferry Key',
    schedule_id:'Schedule ID',
    schedule_date:"Schedule Date",
    operator_id:"ID Operator",
    schedule_time:"Schedule Time",
    destination:"Destination",
    created_at:"Created At",
    vessel_passengers:'Passengers List',
    updated_at:"Updated At",
    seat_type:"Seat Type",
    ticket_number:"Ticket Number",
    boarding_number:"Boarding Number",
    queue:"Process Queue",
    flight_schedule_domestic:'Domestic',
    content_length:"Content Length",
    flight_domestic:'Domestic Flight',
    error:"Error Message",
    id:"ID",
    remarks:"Remarks",
    class:'Flight Class',
    dom_inbound:'Previous Flight',
    dom_outbound:'Next Flight',
    male:'Male',
    female:'Female',
    vessel_passenger:"Vessel Pax",
    pax_type:"Pax Type",
    issuing_place:"Issuing Place",
    occupation:"Occupation",
    flight_international:"Flight International",
    citizen_name:"Name",
    search_any:"Search",
    domestic:"Domestic",
    copy_error:"Copy Error",
    copy_data:"Copy Data",
    copy_telex:"Copy Telex",
    delete_process:'Delete',
    reprocess:"Reprocess",
    vessel_history:"Vessel History",
    pax_by_age:"Passengers Based on Ages",
    pax_by_nat:"Passengers Based on Nationality",
    pax_by_org:"Passengers Based on Origin",
    pax_by_dst:"Passengers Based on Destination",
    total_pax:"Total Passengers",
    total_crew:"Total Crew",
    data_not_found:"Data Not Found",
    process_status:"Process Status",
    process_logs:"Process Logs",
    process_time:'Process Times',
    process_name:"Process Name",
    last_active:"Last Active",
    command:"Command",
    processing_time:"Time(ms)",
    process_id:"Process ID",
    bot_telex:"Bot Telex",
    passport:"Passport",
    visa:'Visa',
    carrier:"Carrier",
    monthly:"Monthly",
    all:'All',
    daily:"Daily",
    change_password:"Change Password",
    current_password:"Old Password",
    new_password:"New Password",
    confirm_password:"Confirm New Password",
    save:"Save",
    curr_passw_message:'Please input current password!',
    new_passw_message:'Please input new password',
    conf_passw_message:"Please input confirm password",
    passw_not_match:"New password and confirm password not match",
    seat_number:'Seat',
    passport_min:'Passport Min(Months)',
    trafficking_param_girl:"Minimum Female",
    trafficking_param_age:"Max Age Female",
    picture_list:"Picture List",
    rule_type:"Type",
    time:"Time",
    ages:"Ages",
    expiration:"Expiration",
    first_age:"First Age",
    last_age:"Last Age",
    min_value:"Min Value",
    max_value:"Max Value",
    values:'Values',
    poi:"POI",
    passenger_or_passport:"Nama Penumpang atau Paspor",
    seaport:"Seaport",
    date:"Date",
    rule_name:"Rule Name",
    total:"Total",
    passport_number:'Passport Number',
    msg_type:"Message Type",
    pnrapi:"PNRGOV APIS Monitoring",
    time_missing:"Missing Time(Min)",
    izin_tinggal:'Stay Permit',
    perlintasan:'Crossing',
    arah:'Direction',
    number:"Loader Number",
    kode_tpi:"TPI Code",
    nama_tpi:"TPI Name",
    melintas:"Crossing",
    next_flight:"Next Flights",
    vessel_name:"Ship Name",
    dual_citizenship:'Dual Citizenship',
}