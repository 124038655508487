import { MdOutlineQueue } from "react-icons/md";
import { RiInboxLine } from "react-icons/ri";

export default [
    {
        caption:'telex_in',
        category:'telex',
        group:1, 
        Icon:RiInboxLine,
        linkTo:'/telex/inbox'
    },
    {
        caption:'queue',
        category:'telex',
        group:1, 
        Icon:MdOutlineQueue,
        linkTo:'/telex/queue'
    },
]