import { Typography } from 'antd';
import React, {useMemo} from 'react';
import { useLanguage } from '../../../../../../../hooks/useLanguage';
import Airline from '../../../category/airline';
import Airport from '../../../category/airport';
import Row from '../../../category/row';
import Telex from '../../../category/telex';
import Panel from '../../../panel/panel';
export default ({ data }) => {
    const [airline_id, origin_id, dest_id, flight_number, flight_date, _id,
        aircraft_registration_id, is_arrival,
        is_international] = useMemo(() => {
            if (!data) return [false, false, false, false, false, false, false];
            const { airline_id, origin_id, dest_id, flight_number, flight_date, _id, aircraft_registration_id, is_arrival, is_international } = data;
            return [airline_id, origin_id, dest_id, flight_number, flight_date, _id, aircraft_registration_id, is_arrival, is_international];
        }, [data]);

    const [, getLan] = useLanguage();
    const reg = aircraft_registration_id && `${aircraft_registration_id?.aircraft_registration} (${aircraft_registration_id?.aircraft_type_txt})` || '';
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 32, padding: 16, flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, flexGrow: 1 }}>
                <Panel
                    headerLeft={<Typography.Text style={{ fontSize: '1.6rem' }}>{getLan('flight_schedule')}</Typography.Text>}
                >
                    <Row size={'medium'} name={"flight_number"} value={flight_number} />
                    <Row size={'medium'} name={"flight_date"} value={flight_date} />
                    <Row size={'medium'} name={"aircraft_reg"} value={reg || getLan("no_aircraft")} />
                    <Row size={'medium'} name={"STD"} value={data?.std + ' LT'} />
                    <Row size={'medium'} name={"STA"} value={data?.sta + ' LT'} />
                    <Row last={true} size={'medium'} name={"source"} value={data?.insert_from || "Telex"} />
                </Panel>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'start' }}>
                    {airline_id && <Airline airline_id={airline_id} />}
                    {origin_id && <Airport data={origin_id} direction="origin" />}
                    {dest_id && <Airport data={dest_id} direction="dest" />}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, flexGrow: 1 }}>
                <Telex data={_id} is_arrival={is_arrival} is_international={is_international} width="100%" />
            </div>
        </div>
    )
}