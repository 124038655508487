import React from 'react';
import { GiHouse, GiRobotHelmet } from 'react-icons/gi';
import Detail from '../../components/pages/detail';
import FRComponent from './pages/fr';
export default () => {
    return (
        <Detail 
            title={`frscheduler`} 
            icon={GiRobotHelmet} 
            id={'fr_scheduler'}
            url={`api/v1/bot`}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'bot'
                    },
                    {
                        item:'frscheduler'
                    },
                ]
            }
            DetailComponent={FRComponent}
        />
    )
}