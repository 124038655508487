import { Form, Input, InputNumber, Typography } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Offline'
import {useSelector} from 'react-redux'
import moment from 'moment-timezone';
const DefModel={
    code:'',
    tpi_code:'',
    name:'',
    kota:'',
    country:'',
    timezone:'',
    unix_offset:0,
}

export default ()=>{
    const [model, setModel]=useState(DefModel);
    const tzs=useMemo(()=>moment.tz.names(), []);

    return (
        <FormPage 
            url={'api/v1/seaport'}
            callbackPath={'/master/seaport'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{span:8}}
            labelCol={{span:3}}
            title={"Seaport"}            
            emptyModel={DefModel}
            onEditFormEvent={(val)=>{
                const {alternate_names} = val;
                if(!alternate_names)return val;
                return {...val, alternate_names:alternate_names.join(', ')};
            }}
            onSaveEvent={(val)=>{
                const {alternate_names} = val;
                if(!alternate_names) return val;
                const names=alternate_names.split(',').map((n)=>n.trim());
                return {...val, alternate_names:names};
            }}
        >
            <Form.Item name={'code'} label="Code" rules={[{required:true}]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item name={'tpi_code'} label="TPI Code" >
                <Input  />
            </Form.Item>
            <Form.Item name={'name'} label="Name" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'alternate_names'} label="Alternate Name" extra={"Separate with comma (,)"}>
                <Input />
            </Form.Item>
            <Form.Item name={'kota'} label="City">
                <Input  />
            </Form.Item>
            <Form.Item name={'country'} label="Country" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'timezone'} label="Timezone" rules={[{required:true}]}>
                <AutoComplete 
                    data={tzs}
                    value={model.timezone}
                    getKey={(tz)=>tz}
                    getSelectedOptions={(opt, val)=>opt===val}
                    getValues={(opt)=>opt}
                    onChange={(val)=>{
                        const offset=moment().tz(val)._offset;
                        const unix_offset=(offset && offset * 60)||0;
                        setModel({...model, timezone:val, unix_offset})
                    }}
                />
            </Form.Item>
        </FormPage>
    )
}