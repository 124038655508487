import { Avatar, List, Typography } from "antd";
import moment from "moment";
import React from "react";
import { MdOutlineAirplaneTicket } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Badge from "../../components/Badge";
import BaseListPage from "../../components/BaseListPage";

export default function PnrList({stateLocation}) {
  return (
    <BaseListPage
      datas={stateLocation}
      fixedPagination
      searchProps={["pnr"]}
      renderItem={(item) => {
        const { _id, pnr, booking_date, flight_date, flight_number, total_pax } =
          item || {
            _id:'',
            pnr: "",
            booking_date: "",
            flight_date: "",
            flight_number: "",
          };
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  icon={<MdOutlineAirplaneTicket />}
                />
              }
              title={
                <Link to={`/pnr/detail/${_id}`}>
                  <div>
                    <Typography.Title level={5}>
                      {pnr?.toUpperCase()}
                    </Typography.Title>
                  </div>
                </Link>
              }
              description={
                <div>
                  <p className="mb-1">
                    Book{" "}
                    {booking_date}
                  </p>
                  <p className="mb-1">
                    Flight {flight_number} / {flight_date}
                  </p>
                </div>
              }
            />

            <div>
              <Badge color={"error"} text={`${total_pax} pax`} />
            </div>
          </List.Item>
        );
      }}
    />
  );
}
