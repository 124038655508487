import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ClearancePage from './clearance';

export default () => {
    return (
        <BrowserRouter basename="/docs"  >
            <Switch>
                <Route path="/clearance/:id">
                    <ClearancePage />
                </Route>                
            </Switch>
        </BrowserRouter>
    )
}