import { Button, Typography } from "antd";
import React from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useHistory } from "react-router-dom";

export default function TitleBasePage({ title, goBack }) {
  const history = useHistory();

  return (
    <div>
      {!!goBack && (
        <Button
          style={{ marginRight: 8 }}
          type="link"
          icon={<MdOutlineArrowBackIos color={"white"} size={20} />}
          onClick={() => history.goBack()}
        />
      )}
      <Typography.Text
        className="basepage-title-text uppercase-first-char"
        style={{ color: "white" }}
      >
        {title}
      </Typography.Text>
    </div>
  );
}
