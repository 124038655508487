import { Form, Input } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
const DefModel = {
    name: '',
    type: 'Feature'
}
const { TextArea } = Input;

export default () => {
    const [model, setModel] = useState(DefModel);

    return (
        <FormPage
            url={'api/v1/kanwil'}
            callbackPath={'/master/kanwil'}
            formValid={false}
            model={{
                ...model,
                coordinate: JSON.stringify(model.coordinate),
                coordinate_bound: JSON.stringify(model.coordinate_bound),
                geometry: JSON.stringify(model.geometry)
            }}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"Kanwil"}
            emptyModel={DefModel}
            onSaveEvent={(row) => {
                const { coordinate, coordinate_bound, geometry } = row;
                return { ...row, coordinate: JSON.parse(coordinate), coordinate_bound: JSON.parse(coordinate_bound), geometry: JSON.parse(geometry) }
            }}
        >
            <Form.Item name={'name'} label="Name" rules={[{ required: true }]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item name={'region_number'} label="Nomor Urut Wilayah" >
                <Input value={`${model.region_number}`} />
            </Form.Item>
            <Form.Item name={'type'} label="Type Geo" >
                <Input value={`${model.type}`} />
            </Form.Item>
            <Form.Item name={'coordinate'} label="Coordinate" rules={[{ required: true }]}>
                <TextArea
                    style={{
                        height: 120,
                    }}
                    value={model.coordinate}
                />
            </Form.Item>
            <Form.Item name={'coordinate_bound'} label="Coordinate Bound" rules={[{ required: true }]}>
                <TextArea
                    style={{
                        height: 120,
                    }}
                    value={model.coordinate_bound}
                />
            </Form.Item>
            <Form.Item name={'geometry'} label="Geometry" rules={[{ required: true }]}>
                <TextArea
                    style={{
                        height: 200,
                    }}
                    value={model.geometry}
                />
            </Form.Item>
        </FormPage>
    )
}