import { geekblue } from "@ant-design/colors";
import { Avatar, Button, Col, List, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { FaPassport } from "react-icons/fa";
import { RiShipFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Flags from "../../../../../components/flags";
import Badge from "../../../components/Badge";
import BaseListPage from "../../../components/BaseListPage";

export default function VesselHistory({ dataSource }) {
  return (
    <BaseListPage
      datas={dataSource}
      renderItem={(item) => {
        const { _id, boarding_number, watch_flag, vessel_id } = item || {
          _id: "",
          operator_id: "",
          passenger_name: "",
          boarding_number: "",
          dob: "",
          doc_country: "",
          nationality: "",
          doc_number: "",
          pnr_id: "",
          gender: "",
          pnr: "",
          doc_expiration: "",
          doc_expiry: "",
          doc_nationality: "",
          watch_flag: "",
          age: "",
          pax_type: "",
          unclode_id: "",
          vessel_id: "",
        };
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  alt={""}
                  size="large"
                  style={{
                    backgroundColor: geekblue[7],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className={"avatar-contain"}
                  icon={<RiShipFill />}
                />
              }
              title={
                <div>
                  <Link
                    to={`${
                      !!vessel_id?.is_arrival
                        ? `/vessel/arrival/${vessel_id?._id}`
                        : `/vessel/departure/${vessel_id?._id}`
                    }`}
                  >
                    <Typography.Title level={5}>
                      {moment(vessel_id?.schedule_date).format("DD-MMM-YYYY")}{" "}
                      {`${
                        !!vessel_id?.operator_id
                          ? `(${vessel_id?.operator_id})`
                          : ""
                      }`}
                    </Typography.Title>
                  </Link>
                </div>
              }
              description={
                <div>
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <ul>
                        <li>boarding : {boarding_number}</li>
                        <li>
                          <div className="rowslist-inline-flex">
                            Origin : {vessel_id?.origin}{" "}
                            <Flags
                              country_code={vessel_id?.origin_id?.country}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="rowslist-inline-flex">
                            Dest : {vessel_id?.destination}{" "}
                            <Flags
                              country_code={vessel_id?.destination_id?.country}
                            />
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        className="mt-4"
                        style={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent:'flex-end',
                          textAlign: "right",
                          gap: 32,
                        }}
                      >
                        {!!watch_flag ? (
                          <div>
                            <Badge color={"error"} text={"WATCHLIST"} />
                          </div>
                        ) : (
                          ""
                        )}

                        <Link to={`/vessel/passengers/detail/${_id}`}>
                          <Button type="link" className="mt-2">
                            Passenger Detail
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            />
          </List.Item>
        );
      }}
    />
  );
}
