import { LineChart, AreaChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = require('./summery.json')

const Recharts = () => {
    return (
        <div style={{ display: 'flex' }}>
            <div>
                <p>Ages</p>
                <AreaChart
                    width={400}
                    height={300}
                    data={data.data.ages}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="age" type="category" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="female" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Area type="monotone" dataKey="male" stroke="#82ca9d" />
                </AreaChart>

                <p>National</p>
                <LineChart
                    width={400}
                    height={300}
                    data={data.data.nations}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="nationality" type="category" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="female" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="male" stroke="#82ca9d" />
                </LineChart>
            </div>

            <div>
                <p>Origin</p>
                <LineChart
                    width={400}
                    height={300}
                    data={data.data.origin}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="code" type="category" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="female" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="male" stroke="#82ca9d" />
                </LineChart>

                <p>Destination</p>
                <LineChart
                    width={400}
                    height={300}
                    data={data.data.dest}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="code" type="category" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="female" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="male" stroke="#82ca9d" />
                </LineChart>
            </div>
        </div>
    )
};
export default Recharts;