import React from 'react';
import Table from '../../lists/table';
import moment from 'moment';

export default ({data}) => {
    console.log({data});
    return (
        <Table 
                data={data || []}
                pagination={false}
                filterField={['dokim_no', 'nama_satker', 'nomor_paspor', 'penjamin']}
                columns={[
                    {
                        title:'indeks',
                        dataIndex:'indeks',  
                    }, 
                    {
                        title:'dokim_no',
                        dataIndex:'dokim_no',  
                    }, 
                    {
                        title:'no_permohonan',
                        dataIndex:'no_permohonan',  
                    },
                    {
                        title:'tanggal_permohonan',
                        dataIndex:'tanggal_permohonan',       
                        render:(val)=>!!val?moment(val).format('DD-MMM-YYYY'):'-'
                    },
                    {
                        title:'dokim_mulai',
                        dataIndex:'dokim_mulai',       
                        render:(val)=>!!val?moment(val).format('DD-MMM-YYYY'):'-'
                    },
                    {
                        title:'dokim_akhir',
                        dataIndex:'dokim_akhir',       
                        render:(val)=>!!val?moment(val).format('DD-MMM-YYYY'):'-'
                    },
                    {
                        title:'nama_negara',
                        dataIndex:'nama_negara',       
                    },
                    {
                        title:'gender',
                        dataIndex:'jenis_kelamin',  
                    }, 
                    {
                        title:'nama_satker',
                        dataIndex:'nama_satker',  
                    },                
                    {
                        title:'passport',
                        dataIndex:'nomor_paspor',  
                    },
                    {
                        title:'penjamin',
                        dataIndex:'penjamin',  
                    },
                    {
                        title:'status',
                        dataIndex:'status',  
                    },
            ]}
        />
    )
}