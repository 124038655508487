import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'

export default () => { 
    return (
        <TablePage 
            title={'Customer Transaction'}            
            url="api/v1/customer/transaction"
            columns={[]}
        />
    )
}