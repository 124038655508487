import { Typography } from 'antd';
import React from 'react';
import { RiInformationLine } from 'react-icons/ri';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
export default ({osi, width}) => {
    const [,getLan] = useLanguage();
    return (
        <Panel
            headerLeft={<RiInformationLine size={24} />}
            headerRight={<Typography.Text>{getLan('osi')}</Typography.Text>}
        >
            <ul>
                {
                    osi.map((msg, idx)=><li key={idx}>{msg}</li>)
                }
            </ul>
        </Panel>
    )
}