import { green } from "@ant-design/colors";
import { Avatar, Button, Col, List, Row, Typography } from "antd";
import React from "react";
import { IoMdMail } from "react-icons/io";
import { MdPeopleAlt } from "react-icons/md";
import { RiWheelchairLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import Flags from "../../../../../components/flags";
import Badge from "../../../components/Badge";
import BaseListPage from "../../../components/BaseListPage";
import { getLogoAirline } from "../../../library";

export default function FlightHistory({ dataSource }) {
  return (
    <BaseListPage
      datas={dataSource}
      renderItem={(item) => {
        const {
          _id,
          flight_date,
          dest,
          origin,
          pnr_id,
          is_arrival,
          flight_schedule_id,
          pnr,
          flight_number,
          watch_flag,
        } = item || {
          _id: "",
          flight_date: "",
          flight_number: "",
          dest: "",
          is_arrival: "",
          origin: "",
          pnr_id: "",
          flight_schedule_id: "",
          pnr: "",
          watch_flag: "",
        };
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  alt={flight_schedule_id?.airline_id?.code}
                  size="def"
                  //   style={{ alignSelf: "center" }}
                  className={"avatar-contain"}
                  src={`${getLogoAirline(
                    flight_schedule_id?.airline_id?.code
                  )}`}
                />
              }
              title={
                <div>
                  <Link
                    to={`${
                      !!is_arrival
                        ? `/flight/schedule/arrival/${flight_schedule_id?._id}`
                        : `/flight/schedule/departure/${flight_schedule_id?._id}`
                    }`}
                  >
                    <Typography.Title level={5}>
                      {flight_date}{" "}
                      {`${!!flight_number ? `(${flight_number})` : ""}`}
                    </Typography.Title>
                  </Link>
                </div>
              }
              description={
                <div>
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div>
                        {" "}
                        PNR :{" "}
                        <Link to={`/pnr/detail/${pnr_id?._id}`}>{pnr}</Link>
                      </div>
                      <div style={{display:'flex', flexDirection:'column'}}>

                      <div className="rowslist-inline-flex">
                        {" "}
                        Origin : {origin}{" "}
                        <Flags
                          country_code={flight_schedule_id?.origin_id?.country}
                        />
                      </div>
                      <div className="rowslist-inline-flex">
                        {" "}
                        Dest : {dest}{" "}
                        <Flags
                          country_code={flight_schedule_id?.dest_id?.country}
                        />
                      </div>
                      </div>
                      {/* <ul>
                    <li>
                      PNR : <Link to={`/pnr/detail/${pnr_id?._id}`}>{pnr}</Link>
                    </li>
                    <li  ><div className="rowslist-inline-flex">Origin : {origin} <Flags country_code={flight_schedule_id?.origin_id?.country} /></div></li>
                    <li ><div className="rowslist-inline-flex">Dest : {dest} <Flags country_code={flight_schedule_id?.dest_id?.country}/></div></li>
                  </ul> */}
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} >
                      <div
                      className="mt-4"
                        style={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent:'flex-end',
                          textAlign:'right',
                          gap: 32,
                          
                        }}
                      >
                        {!!watch_flag ? (
                          <div>

                            <Badge color={"error"} text={"WATCHLIST"} />
                          </div>
                        ) : (
                          ""
                        )}
                        <Link to={`/passengers/detail/${_id}`}>
                          <Button type="link" >
                            Passenger Detail
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            />
          </List.Item>
        );
      }}
    />
  );
}
