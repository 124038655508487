import { FilterOutlined } from "@ant-design/icons";
import { Col, Typography, Row, Divider, Button } from "antd";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useState } from "react";
import { useFontSize } from "../../../../../../hooks/useDimension";
import CardListPage from "../../../../components/CardListPage";
import RowList from "../../../../components/RowList";
import { getBadgeStatus, getServiceStatus } from "../../../../library";
import ModalFilterLogs from "./ModalFilterLogs";

export default function ProcessLogs() {
  const fontSize = useFontSize();
  const [search, setSearch] = useState({
    process_name: "",
    command: "",
  });
  const [isModalFilter, setIsModalFilter] = useState(false);
  const handleModal = () => {
    setIsModalFilter(!isModalFilter);
  };
  return (
    <CardListPage
      paginationUrl={"api/v1/bot/process/logs/pagination"}
      searchApiProps={search}
      fixedPagination
      FilterComponent={() => {
        return (
          <div className="text-right" style={{ padding: "4px" }}>
            <Button
              //   style={{ width: 42 }}
              type="primary"
              icon={<FilterOutlined />}
              size={24}
              onClick={handleModal}
            >
              Filter
            </Button>
            {isModalFilter && (
              <ModalFilterLogs
                state={search}
                setState={setSearch}
                handleSearch={(value) => {
                  setSearch({
                    ...search,
                    ...value,
                    timestamp: moment().unix(),
                  });
                  setIsModalFilter(!isModalFilter);
                }}
                // title={direction}
                open={isModalFilter}
                handleModal={handleModal}
              />
            )}
          </div>
        );
      }}
      CardContent={({ item }) => {
        const { buffer_id, command, process_name, process_time, log } =
          item || {
            buffer_id: "",
            command: "",
            process_name: "",
            process_time: "",
            log: "",
          };
        return (
          <div>
            <Row>
              <Col span={18}>
                <Typography.Title
                  style={{ fontSize: fontSize.lg, fontWeight: "bold" }}
                >
                  {process_name}
                </Typography.Title>
              </Col>
              <Col span={6}>{getServiceStatus(log)}</Col>
            </Row>
            <Divider className="my-1" />
            <div className="p-2">
              <RowList title={"Command"} content={command} dividerBottom />
              <RowList title={"Buffer id"} content={buffer_id} dividerBottom />

              <RowList
                title={"Process Time"}
                content={`${numeral(process_time).format("0,000")} ms`}
              />
            </div>
          </div>
        );
      }}
    />
  );
}
