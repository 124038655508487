import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import LoginPage from '../login';
import AppPages from './app';
import { setApps, initMe } from '../../redux';
import SplashPage from '../../splash';



const UserPage = ({ userdata, }) => {
    return (
        <BrowserRouter basename='/apps'>
            <Switch>
                <Route exact path="/">
                    {(!!userdata && <Redirect to={'/home'} />) || <Redirect to="/login" />}
                </Route>
                <Route exact path="/login">
                    {(!!userdata && <Redirect to={'/home'} />) || <LoginPage apps={"apps"} />}
                </Route>
                <Route path="/*">
                    {(!!userdata && <AppPages idx={new Date().getTime()} />) || <Redirect to="/login" />}
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default () => {
    const { userdata, initComplete } = useSelector(state => state.auth)
    const dispatch = useDispatch();



    useEffect(() => {

        dispatch(setApps('head-office')); // set applikasi
        dispatch(initMe()); // cek token validasi        
    }, [initMe, setApps,])
    if (!initComplete) return <SplashPage />
    return <UserPage userdata={userdata} />
}