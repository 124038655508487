import moment from 'moment';
import numeral from 'numeral';
import React, { useState } from 'react';
import { GiHouse, GiLog } from 'react-icons/gi';
import Page from '../../components/pages/paging';
import Search from './search';

// const ColumnsInternational=

export default () => {
    const Icon = GiLog;
    const [searchValue, setSearchValue] = useState({
        process_name: '', command: ''
    });

    return (
        <Page
            url={'api/v1/bot/process/logs'}
            searchValue={searchValue}
            columns={[
                {
                    title:"process_name",
                    dataIndex:'process_name'
                },
                {
                    title:"command",
                    dataIndex:'command'
                },
                {
                    title:"status",
                    dataIndex:'log',
                    width:90
                },
                {
                    title:"process_id",
                    dataIndex:'buffer_id',
                    ellipsis:true
                },
                {
                    title:"processing_time",
                    dataIndex:'process_time',
                    align:"right",
                    width:90,
                    render:(val)=>numeral(val).format('0,000')
                },
                {
                    title:"created_at",
                    dataIndex:'createdAt',
                    render:(val)=>moment(val).format('DD-MMM-YYYY HH:mm')
                }

            ]}
            title={`process_logs`}
            icon={Icon}
            searchComponent={
                <Search
                    onSearchCallback={(value) => {
                        setSearchValue({ ...value, timestamp: moment().unix() });
                    }}
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href: '/home',
                        icon: <GiHouse color='white' />,
                        item: 'home'
                    },
                    {
                        item: 'bot'
                    },
                    {
                        item: 'process_logs'
                    }
                ]
            }
        />
    )
}