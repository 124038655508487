import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { IoMdPeople } from 'react-icons/io';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Table from '../lists/table';
import { ImMan, ImWoman } from 'react-icons/im';
import { FaBaby } from 'react-icons/fa';
import Flags from '../../../../../components/flags';
import { useFetch } from '../../../../../hooks/useFetch';
export default ({pnr_id, isDomestic, pax_id}) => {
    const [data, loading] = useFetch('api/v1/passengers'+(!!isDomestic?'/domestic':'')+'/detail/pnr_id/'+pnr_id);
    const pdata=useMemo(()=>{
        if(!data)return [];
        if(!Array.isArray(data))return [];
        return data.filter(({_id})=>_id!==pax_id)
    }, [data])
    const [,getLan]=useLanguage();
    const renderGender=(gender, pax_type)=>{
        if(pax_type!=='IN'){
            switch (gender?.toLowerCase()) {
                case 'female':
                    return <ImWoman size={24} color="#ff6e87" />
                case 'male':
                    return <ImMan size={24} color="blue"/>
                default:
                    return <ImMan size={24} color="blue"/>
            }
        }
        return <FaBaby size={24} color="green" />
    }
    const getPaxType=(pax_type)=>{
        switch (pax_type.toLowerCase()) {
            case 'in-transit passenger':
                return "T"
            case 'passenger':
                return "P";
            case "In-Transit Crew Member":
                return 'T';
            case "Crew Member":
                return "C";
            default:
                return "P";
        }
    }
    return (
        <Panel
            headerLeft={<IoMdPeople size={24}/>}
            headerRight={<Typography.Text>{getLan('accompany_with')}</Typography.Text>}
        >
            <Table 
                data={pdata || []}
                pagination={false}
                filterField={['passenger_name', 'doc_number']}
                url={"/passengers"+(!!isDomestic?'/domestic':'')+"/detail"}
                columns={[
                {
                    title:'passenger_name',
                    dataIndex:'passenger_name',    
                    width:"150px",
                    ellipsis:true,   
                    render:(val, rec)=>{
                        const {passenger_type} = rec;
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'start', width:120}}>
                                <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                    {getPaxType(passenger_type)}
                                </span>
                                <Typography.Text title={val} ellipsis >{val}</Typography.Text>
                            </div>
                        )
                    }             
                },                
                {
                    title:'gender',
                    width:"80px",
                    dataIndex:'gender',
                    render:(val, rec)=>(
                        <div style={{display:'flex', flexDirection:'row', gap:4}}>
                            {renderGender(val, rec?.pax_type)}
                            ({rec.age})    
                        </div>
                    )
                },
                {
                    title:'nationality',
                    width:"120px",
                    dataIndex:'doc_nationality',
                    render:(val, rec)=>!!val && <Flags withCountry countryFormat={'alpha3'} country_code={val} /> || '-'                     
                },
                {
                    title:'doc_country',
                    width:"120px",
                    dataIndex:'doc_country',
                    render:(val, rec)=>!!val && <Flags countryFormat={'alpha3'} withCountry country_code={val} /> || '-'                     
                },   
            ]}
            />
        </Panel>
    )
}