import React from 'react';
import {HiOutlineHome} from 'react-icons/hi'
import { IoMdAirplane } from 'react-icons/io';
import { MdHomeFilled } from 'react-icons/md';
import {Link} from 'react-router-dom';
import {getLanguage} from '../../../languages/utils';

const SIZE=20;
export default (languages)=> [
    {
        key:'/home',
        icon:<MdHomeFilled size={SIZE} />,
        label:<Link to={'/home'}>{getLanguage('home', languages)}</Link>,
        level:0x1fff
    },
    {
        key:'/flight',
        icon:<IoMdAirplane size={SIZE}  />,
        label:<Link to={'/flight'}>{getLanguage('flight', languages)}</Link>,
        level:0x1fff
    },
]