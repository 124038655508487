import React from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiHouse } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/passengers/pnr_dom';
import { RiTicketLine } from 'react-icons/ri';
export default () => {
    const {id} = useParams();
    
    return (
        <Detail 
            title={`pnr`} 
            icon={RiTicketLine} 
            id={id}
            url={`api/v1/passengers/domestic/detail/pnr_id`}
            getBreadcumbValue={(data)=>data[0]?.pnr}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'pnr'
                    },                    
                    {
                        item:'passengers'
                    },
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{id}}
        />
    )
}