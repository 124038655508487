import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import HotKeys from 'react-hot-keys'
import { useLanguage } from '../hooks/useLanguage';
import CFG from '../config/env';
import { useSelector } from 'react-redux';
const {BgColor} = CFG;

const MenuButton=({menu, index, selectedIndex, onSelectedMenu, onClick, onHover})=>{
    const {category, caption, Icon, linkTo} = menu;
    const selected=useMemo(()=>{
        return index===selectedIndex;
    }, [selectedIndex, index])
    return (
        <div style={{border:`1px solid ${BgColor}`, padding:8, display:'flex', flexDirection:'row', gap:16, 
            background:selected?BgColor:'white', color:selected?'white':BgColor, cursor:'pointer'
        }} onMouseEnter={()=>onHover && typeof onHover==='function' && onHover(index)} 
            onClick={()=>onClick && typeof onClick==='function' && onClick(index)}>
            <Icon size={20} color={selected?'white':BgColor} />
            <div style={{display:"flex", flexDirection:'row', gap:8}} >
                <Typography.Text style={{color:selected?'white':BgColor}}>{category} / </Typography.Text>
                <Typography.Text style={{color:selected?'white':BgColor}}>{caption}</Typography.Text>
            </div>
        </div>  
    )
}

export default ({menu})=>{
    const history=useHistory();
    const [vis, setVis]=useState(false);
    const [,getLang]=useLanguage();
    const handleSearch=()=>{
        setVis(true);
    }
    const [selIndex, setSelIndex]=useState(0);
    const {userdata:{level}} = useSelector(state=>state.auth);
    const [txt, setTxt]=useState('');
    const translatedMenu=useMemo(()=>{
        return menu.filter(m=>!!m).map(({caption, category, ...m})=>({...m, category:getLang(category), caption:getLang(caption)}))
    }, [menu, vis])
    const filteredMenu=useMemo(()=>{
        if(!txt)return [...translatedMenu].slice(0, 5);
        const filtered=translatedMenu.filter(({caption, category, level:mLevel})=>{
            if((level & mLevel)>0){
                const catFound=`${category}`?.toLowerCase().indexOf(txt?.toLowerCase())>=0;
                const capFound=`${caption}`?.toLowerCase().indexOf(txt?.toLowerCase())>=0;
                return catFound || capFound;
            }
            return false;
        });

        return filtered;
    }, [translatedMenu, txt, level]);
    useEffect(()=>{
        if(!vis)setTxt('');
    }, [vis])
    useEffect(()=>{
        setSelIndex(0);
    }, [txt])
    return (
        <React.Fragment>
            <HotKeys
                keyName='up'
                onKeyDown={()=>{
                    const selUp=selIndex-1;
                    if(selUp>=0){
                        setSelIndex(selUp)
                    }
                }}
            />
            <HotKeys
                keyName='down'
                onKeyDown={()=>{
                    const selUp=selIndex+1;
                    if(selUp<filteredMenu.length){
                        setSelIndex(selUp)
                    }
                }}
            />
            <HotKeys
                keyName='enter'
                onKeyDown={()=>{
                    if(vis){
                        const sel=filteredMenu[selIndex];
                        if(!!sel) {
                            history.push(sel.linkTo);
                            setVis(false);
                        }
                    }
                }}
            />
            <HotKeys 
                keyName='alt+s'
                onKeyDown={handleSearch}
                filter={()=>true}
            >
                <Button 
                    type='link'
                    icon={<SearchOutlined />}
                    onClick={handleSearch}
                    style={{color:'white', fontSize:'1.0rem'}}
                >
                    {getLang('search_menu')} (ALT+S)
                </Button>
            </HotKeys>
            <Modal
                visible={vis}
                onCancel={()=>setVis(false)}
                centered
                destroyOnClose
                closable={false}
                title={null}
                footer={null}
            >
                <div style={{display:'flex', flexDirection:'column', rowGap:8}}>
                    <Input.Search 
                        placeholder={getLang('search_menu')}
                        autoFocus
                        value={txt}
                        onChange={(e)=>setTxt(e.target.value)}
                    />
                    {
                        filteredMenu && Array.isArray(filteredMenu) && filteredMenu.map((m, idx)=>{
                            return (
                                <MenuButton 
                                    onClick={()=>{
                                        history.push(m.linkTo);
                                        setVis(false);
                                    }} 
                                    index={idx} 
                                    key={idx} 
                                    menu={m} 
                                    selectedIndex={selIndex} 
                                    onHover={(index)=>setSelIndex(index)} />
                            )
                        })
                    }
                </div>
            </Modal>
            
        </React.Fragment>
    )
}