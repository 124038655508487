import { Button, Typography } from 'antd';
import moment from 'moment';
import {PrinterFilled} from '@ant-design/icons';
import React from 'react';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import Row from '../../category/row';
import Panel from '../../panel/panel';
import { GiHarborDock, GiSailboat } from 'react-icons/gi';
import Vessel_pax from '../../category/vessel_pax';
import Flags from '../../../../../../components/flags';
import { RiPrinterLine } from 'react-icons/ri';
import { green } from '@ant-design/colors';
import ClearanceBtn from './clearanceBtn';
import { useCallback } from 'react';
import { useState } from 'react';
export default ({data, direction})=>{
    const { schedule_id, std, _id, sta, is_arrival, ferry_key, origin, destination, destination_id, origin_id,
        source, createdAt, updatedAt, operator_id, vessel_name } = data;
    const [,getLan] = useLanguage();
    const format=direction==='arrival'?'M/D/YYYY':"DD/MM/YYYY";
    const Station=({station_id, station_name})=>{
        return station_id?.name && (
            <div style={{display:'flex', flexDirection:'row', gap:8}}>
                <Flags countryWidth={30} country_code={station_id?.country} withCountry countryFormat={'alpha3'}  />
                -
                <span>
                    {station_id?.name?.toUpperCase()}
                </span>
            </div>
        ) || station_name;
    }
    const [passengers, setPassengers]=useState([]);
    const paxCallback=useCallback((paxes)=>{
        setPassengers(paxes);
    }, [])
    return (
        <div style={{display:'flex', flexDirection:'column', gap:32, padding:16, flexWrap:'wrap'}}>
            <div style={{display:'flex', flexDirection:'column', rowGap:16, width:'50%'}}>
                <Panel
                    headerLeft={direction==='arrival'?<GiHarborDock size={24} />:<GiSailboat size={24} />}
                    headerRight={<Typography.Text>{getLan('vessel_'+direction)}</Typography.Text>}
                >
                    <Row name={'operator_id'} value={operator_id} />
                    <Row name={'ferry_key'} value={ferry_key} />
                    <Row name={'vessel_name'} value={vessel_name} />
                    <Row name={'schedule_id'} value={schedule_id} />
                    <Row name={'std'} value={`${std} LT`} />
                    <Row name={'sta'} value={`${sta} LT`} />
                    <Row name={'origin'} value={<Station station_id={origin_id} station_name={origin}/>} />
                    <Row name={'destination'} value={<Station station_id={destination_id} station_name={destination} />} />
                    <Row name={'created_at'} value={moment(createdAt).format('DD-MMM-YYYY HH:mm:ss')} />
                    <Row name={'updated_at'} value={moment(updatedAt).format('DD-MMM-YYYY HH:mm:ss')} />
                    <Row last name={'source'} valueEl={()=>{
                        if(source!=="LNSW"){
                            return source || "-";
                        }
                        return <ClearanceBtn data={data} passengers={passengers} />
                    }} />
                </Panel>
            </div>
            <div style={{display:'flex', flexDirection:'column', rowGap:8, flexGrow:1}}>
                {_id && <Vessel_pax vessel_id={_id} format={format} onPaxReceives={paxCallback} />}
            </div>
        </div>
    )
}