import { Button, Table, Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { useLanguage } from '../../../../../../../hooks/useLanguage';
import Airline from '../../../category/airline';
import Airport from '../../../category/airport';
import Row from '../../../category/row';
import Telex from '../../../category/telex';
import Panel from '../../../panel/panel';
import SumInter from '../../../category/summary_inter';

import { ReloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { GetAPI } from '../../../../../../../redux';

const FlightRule=({rules})=>{
    const [, getLan] = useLanguage();
    return (
        <Table 
            columns={[
                {
                    title:getLan('rule_name'),
                    dataIndex:'rule_name',
                },
                {
                    title:getLan('nationality'),
                    dataIndex:'nationality',
                },
                {
                    title:getLan('total'),
                    dataIndex:'total'
                }
            ]}
            dataSource={rules}
        />
    )
}

export default ({ data }) => {
    const [airline_id, origin_id, dest_id, flight_number, flight_date, _id, 
        aircraft_registration_id, is_arrival, is_international, total_pax, total_crew, flight_rule, aircraft_type] = useMemo(() => {
        if (!data) return [false, false, false, false, false, false, false, 0, 0, []];
        const { airline_id, origin_id, dest_id, flight_number, flight_date, _id, aircraft_registration_id, 
            is_arrival, is_international, apis_info, flight_rule_affected, actype } = data;
        const {total_passengers, total_crew} = apis_info || {total_passengers:0, total_crew:0};
        return [airline_id, origin_id, dest_id, flight_number, flight_date, _id, aircraft_registration_id, 
            is_arrival, is_international, total_passengers || 0, total_crew||0, flight_rule_affected || [], actype];
    }, [data]);

    const dispatch=useDispatch();

    const handleRestart=()=>{
        dispatch(GetAPI({url:'api/v1/flight/restart/'+_id}))
    }

    const [, getLan] = useLanguage();
    const reg = !!aircraft_registration_id && `${aircraft_registration_id?.aircraft_registration} (${aircraft_registration_id?.aircraft_type_txt || aircraft_type})` || '';
    // const {} = useSelector(state=>state.Auth);
    return !!data && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 32, padding: 16, flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, maxWidth:'50%' }}>
                <Panel
                    headerLeft={<Typography.Text style={{ fontSize: '1.6rem' }}>{getLan('flight_schedule')}</Typography.Text>}
                    headerRight={<Button type='text' onClick={handleRestart} icon={<ReloadOutlined />} />}
                >
                    <Row size={'medium'} name={"flight_number"} value={flight_number} />
                    <Row size={'medium'} name={"flight_date"} value={flight_date} />
                    <Row size={'medium'} name={"aircraft_reg"} value={reg || getLan("no_aircraft")} />
                    <Row size={'medium'} name={"STD"} value={data?.std + ' LT'} />
                    <Row size={'medium'} name={"STA"} value={data?.sta + ' LT'} />
                    <Row size={'medium'} name={"total_pax"} value={total_pax} />
                    <Row size={'medium'} name={"total_crew"} value={total_crew} />
                    <Row last={true} size={'medium'} name={"source"} value={data?.insert_from || "Telex"} />
                </Panel>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'start', flexWrap:'wrap' }}>
                    {airline_id && <Airline airline_id={airline_id} />}
                    {origin_id && <Airport data={origin_id} direction="origin" />}
                    {dest_id && <Airport data={dest_id} direction="dest" />}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, flexGrow: 1 }}>
                {
                    flight_rule && <FlightRule rules={flight_rule} />
                }
                <SumInter _id={_id}/>
                <Telex data={_id} is_arrival={is_arrival} is_international={is_international} />
            </div>
        </div>
    )
}