import React from 'react'
import { SiderLayout } from '../../../components/layouts/sider';
import Menus from './menus'
import { green } from '@ant-design/colors'
import { useSelector } from 'react-redux';
import { Routing } from './routing'
import { Route } from 'react-router-dom';
// import {loadAirlines, loadAirports} from '../../../redux'
import { Switch } from 'react-router-dom';
import { useLocHref } from '../../../hooks/useLocHref';
import { useMenu } from '../../../hooks/useMenu';
import { useNavs } from '../../../hooks/useNavs';
import CONFIG from '../../../config/env';
import DashboardLayout from '../components/DashboardLayout';
import NotFoundPage from '../components/NotFoundPage';
const { Title } = CONFIG;
const TheRouter = () => {
    const { userdata: { level } } = useSelector(state => state.auth);
    const routes = Routing.filter(r => (r.level & level) > 0);

    return (
        <Switch>
            {
                routes.map((route, idx) => (
                    <Route key={idx} path={route.to} exact={route.isExact !== false} render={props => {
                        return !!route.component && route.component
                    }} />
                ))
            }
            <Route path={'/*'}>
                <NotFoundPage />
            </Route>
        </Switch>
    )
}

export default ({ idx }) => {
    // const dispatch = useDispatch();
    // useEffect(()=>{
    //     // dispatch(loadAirlines());
    //     // dispatch(loadAirports());
    // }, []);
    const { userdata: { level } } = useSelector(state => state.auth);
    const key = useLocHref('dashboard');
    const { keys, menus } = useMenu(Menus, level);
    useNavs(keys, key);
    return (
        <DashboardLayout
            menus={menus}
        >
            <TheRouter />
        </DashboardLayout>
    )
}