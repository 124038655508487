import {
  AutoComplete,
  Avatar,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import Zoom from "chartjs-plugin-zoom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { blue, cyan, geekblue, magenta } from "@ant-design/colors";
import { Bar, Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { useFontSize } from "../../../../hooks/useDimension";
import { MdFlight } from "react-icons/md";
import { RiWomenLine } from "react-icons/ri";
import { IoIosFemale, IoIosMale } from "react-icons/io";
import { IoFemale, IoMale } from "react-icons/io5";
import { FaShip } from "react-icons/fa";
import numeral from "numeral";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Filler,
  Tooltip,
  Legend,
  Zoom
);

export default function PassengersSummary() {
  const fontSize = useFontSize();
  const { passengersTrip } = useParams();
  const [date, setDate] = useState(moment());
  const [timestamp, setTimestamp] = useState(moment(new Date()).unix());
  const [filter, setFilter] = useState({});
  const [filterByLabel, setFilterByLabel] = useState("");
  const [sortBy, setSortBy] = useState("ages");

  const formatedDate = useMemo(() => {
    return moment(date).format("DD-MMM-YYYY");
  }, [date]);

  const defUrl = useMemo(() => {
    return `api/v1/summary/dashboard/${passengersTrip}/${formatedDate}?filters=${JSON.stringify(
      [filter]
    )}&${timestamp}`;
  }, [timestamp, formatedDate, passengersTrip, filter]);

  const [summInbound] = useFetch(defUrl);

  const { ages, carrier, dest, flight, nations, origin, vessel } =
    summInbound || {
      ages: [],
      carrier: [],
      dest: [],
      flight: [],
      nations: [],
      origin: [],
      vessel: [],
    };

  const [PassengerFlight, PassengerVessel] = useMemo(() => {
    const [flightDet] = flight;
    const [vesselDet] = vessel;
    return [flightDet, vesselDet]
  }, [summInbound, filter]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "top",
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },

    pan: {
      enabled: true,
      mode: "x",
      speed: 10,
    },
  };

  const getLabel = (sortBy) => {
    switch (sortBy) {
      case "ages":
        return "age";
      case "carrier":
        return "code";
      case "dest":
        return "code";
      case "nations":
        return "nationality";
      case "origin":
        return "code";

      default:
        break;
    }
  };

  const sortedData = useMemo(() => {
    return !!summInbound && summInbound[sortBy];
  }, [summInbound, sortBy]);

  const labels = useMemo(() => {
    if (!!sortedData) {
      if (!!filterByLabel) {
        return [filterByLabel];
      }
      return sortedData?.map((item, idx) => `${item[`${getLabel(sortBy)}`]}`);
    }
    return false;

    return (
      !!sortedData &&
      sortedData?.map((item, idx) => `${item[`${getLabel(sortBy)}`]}`)
    );
  }, [sortedData, filterByLabel]);

  const filteredData = useMemo(() => {
    return !!filterByLabel
      ? sortedData?.filter(
          (el) => el[`${getLabel(sortBy)}`]?.toString() === filterByLabel
        )
      : sortedData;
  }, [filterByLabel, sortedData]);

  const dataChart = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Female",
        data: !!filteredData && filteredData?.map((item) => item?.female),
        borderColor: geekblue[4],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        fill: true,
        label: "Male",
        data: !!filteredData && filteredData?.map((item) => item?.male),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div>
      <div className="p-4">
        <Typography.Title className="base-text-color" style={{ fontSize: fontSize.lg }}>
          {passengersTrip} passengers
        </Typography.Title>
        <Divider className="my-2" />
        <Row className="home-filter" style={{ justifyContent: "flex-end" }}>
          <Col xs={10} sm={8} md={6} lg={6}>
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MMM-YYYY"}
              value={date}
              onChange={(val, valStr) => {
                setDate(val);
              }}
              size="large"
            />
          </Col>
        </Row>
        {/* <Row className="my-4">
         
        </Row> */}
        <Row className="my-4" gutter={[16,16]}>
        <Col xs={24} sm={12} md={12} >
          <div style={{padding: 16}} className="bg-base-theme">

            <div
              className="mb-2"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Avatar
                style={{
                  width: "4rem",
                  height: "4rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: cyan[1],
                }}
                icon={<MdFlight color={cyan[7]} size={"2rem"} />}
              />
            </div>
            <Typography.Title
              style={{
                fontSize: fontSize.md,
                // fontWeight: "bold",
                color: "white",
                textAlign: "center",
              }}
            >
              Flight Passengers
            </Typography.Title>
            {/* <Divider className="my-4"  style={{borderColor: "white"}} /> */}
            <Row className="mt-4" gutter={[16, 16]}>
              <Col
                span={12}
                className="text-center"
                style={{ color: geekblue[5] }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    //   color: "white",
                    justifyContent: "center",
                    gap: 8,
                    backgroundColor: geekblue[1],
                    borderRadius: 4,
                    padding: 4,
                    width: "100%",
                  }}
                >
                  <IoMale size={20} />
                  <Typography.Title
                    style={{
                      fontSize: fontSize.md,
                      textAlign: "center",
                      color: geekblue[6],
                      fontWeight: "bold",
                      marginBottom: 0,
                    }}
                  >
                    {numeral(PassengerFlight?.male).format("0,000")}
                  </Typography.Title>
                </div>
              </Col>
              {/* <Divider style={{margin: "0 8px", borderColor: "white"}} type="vertical" /> */}
              <Col
                span={12}
                className="text-center"
                style={{ color: magenta[5] }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // color: "white",
                    gap: 8,
                    backgroundColor: magenta[1],
                    borderRadius: 4,
                    padding: 4,
                    width: "100%",
                  }}
                >
                  <IoFemale size={20} />
                  <Typography.Title
                    style={{
                      fontSize: fontSize.md,
                      color: magenta[5],
                      fontWeight: "bold",
                      marginBottom: 0,
                    }}
                  >
                    {numeral(PassengerFlight?.female).format("0,000")}
                  </Typography.Title>
                </div>
              </Col>
            </Row>
          </div>
          </Col>
          <Col xs={24} sm={12} md={12} >
            <div style={{padding:16}} className="bg-base-theme">

            <div
              className="mb-2"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Avatar
                style={{
                  width: "4rem",
                  height: "4rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: blue[1],
                }}
                icon={<FaShip color={blue[7]} size={"2rem"} />}
              />
            </div>
            <Typography.Title
              style={{
                fontSize: fontSize.md,
                // fontWeight: "bold",
                color: "white",
                textAlign: "center",
              }}
            >
              Vessel Passengers
            </Typography.Title>
            {/* <Divider className="my-4"  style={{borderColor: "white"}} /> */}
            <Row className="mt-4" gutter={[16, 16]}>
              <Col
                span={12}
                className="text-center"
                style={{ color: geekblue[5] }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    //   color: "white",
                    justifyContent: "center",
                    gap: 8,
                    backgroundColor: geekblue[1],
                    borderRadius: 4,
                    padding: 4,
                    width: "100%",
                  }}
                >
                  <IoMale size={20} />
                  <Typography.Title
                    style={{
                      fontSize: fontSize.md,
                      textAlign: "center",
                      color: geekblue[6],
                      fontWeight: "bold",
                      marginBottom: 0,
                    }}
                  >
                    {numeral(PassengerVessel?.male).format("0,000")}
                  </Typography.Title>
                </div>
              </Col>
              {/* <Divider style={{margin: "0 8px", borderColor: "white"}} type="vertical" /> */}
              <Col
                span={12}
                className="text-center"
                style={{ color: magenta[5] }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // color: "white",
                    gap: 8,
                    backgroundColor: magenta[1],
                    borderRadius: 4,
                    padding: 4,
                    width: "100%",
                  }}
                >
                  <IoFemale size={20} />
                  <Typography.Title
                    style={{
                      fontSize: fontSize.md,
                      color: magenta[5],
                      fontWeight: "bold",
                      marginBottom: 0,
                    }}
                  >
                    {numeral(PassengerVessel?.female).format("0,000")}
                  </Typography.Title>
                </div>
              </Col>
            </Row>
            </div>
          </Col>
        </Row>
        <Row className="my-4" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card
              // title={"Inbound"}
              style={{ height: "100%" }}
              extra={
                <div className="rowslist-inline-flex">
                  <div>
                    {!!labels.length && (
                      // <AutoComplete
                      // allowClear
                      //   placeholder={`Select ${sortBy}`}
                      //   style={{ width: 100 }}
                      //   options={labels?.map((item) => {
                      //     return  {label: item, value: item} ;
                      //   })}
                      //   onSelect={(val) => setFilterByLabel(val)}
                      //   // onChange={(val) => console.log('onChange', val)}
                      //   onClear={(val) => setFilterByLabel('')}
                      // />

                      <Select
                        style={{ width: 120 }}
                        value={filterByLabel}
                        onChange={(val) => {
                          // setSortBy(val)
                          if(!val) setFilter({})
                          else setFilter({ name: getLabel(sortBy), value: val})
                          
                          setFilterByLabel(val);
                        }}
                      >
                        <Select.Option value={""}>All</Select.Option>
                        {labels.map((item, idx) => (
                          <Select.Option key={idx} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </div>
                  <div>
                    <Select
                      style={{ width: 120 }}
                      value={sortBy}
                      onChange={(val) => {
                        setSortBy(val);
                        setFilter({})
                        setFilterByLabel("");
                      }}
                    >
                      <Select.Option value="ages">age</Select.Option>
                      <Select.Option value="carrier">carrier</Select.Option>
                      <Select.Option value="dest">destination</Select.Option>
                      <Select.Option value="origin">origin</Select.Option>
                      <Select.Option value="nations">Nationality</Select.Option>
                    </Select>
                  </div>
                </div>
              }
            >
              {!!filterByLabel ? (
                <Bar data={dataChart} options={options} />
              ) : (
                <Line data={dataChart} options={options} />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
