
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SubDetailPage from "../../../components/SubDetailPage";
import PassengersFlight from "../../flight/passenger/PassengersFlight";
import Contact from "./Contact";
import Itinerary from "./Itinerary";
import Originator from "./Originator";
import Osi from "./Osi";
import Ssr from "./Ssr";


export default () => {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();

  const components = {
    passengers:PassengersFlight,
    originator:Originator,
    contact:Contact,
    itinerary:Itinerary,
    osi:Osi,
    ssr:Ssr
  };

  return (
    // <BasePage goBack title={`PNR ${subdetail}`}>
      <SubDetailPage
        basePath={pathname}
        subComponents={components}
        keyComponents={subdetail}
      />
    // </BasePage>
  );
};
