import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiHouse } from 'react-icons/gi';
import { RiInboxLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import Page from '../../components/pages/paging';
import Search from './search';
import TelexBtn from '../../components/category/modal/telex_row_buttons';
const getStatusTxt=(status)=>{
    switch (status) {
        case 0:
            return "Pending";
        case 1:
            return "Success";
        case 2:
            return "Error";
        case 3:
            return "Ignored";
        case 14:
            return "Limited";
        default:
            break;
    }
}

const getHeaders=(headers)=>{
    if(!headers)return false;
    if(!headers?.unh)return false;
    const { msgSubsetIdentification, msgRefNumber } = headers.unh;
    if(!msgSubsetIdentification)return false;
    const {msgSubsetVersionNumber, msgSubsetIdentification:subs} = msgSubsetIdentification;
    return `${msgRefNumber} ${subs}${msgSubsetVersionNumber||''}`;
}

const getDomHeader=(message)=>{
    const [,header]=message;
    return header;
}

export default () => {
    const Icon=RiInboxLine;
    const [searchValue, setSearchValue]=useState({telex_date:[moment(), moment().add(1, 'day')], origin:'', destination:'', subSmi:'', status:10});    
    const uri=`api/v1/telex`;
    const formatedSearchVal=useMemo(()=>{
        const {telex_date, status, ...others} = searchValue;
        const [from, to] = telex_date;
        const s=status===10?undefined:status;
        return {...others, telex_date:[from.format('DD-MMM-YYYY'), to.format('DD-MMM-YYYY')], status:s}
    }, [searchValue]);
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            columns={[
                {
                    render:(val, row)=> <TelexBtn telex_data={row} />,
                    title:'corid',
                    width:'100px'
                },
                {
                    render:(val, row)=>row.subSmi,
                    title:'telex_type',
                    width:'100px'
                },
                {
                    render:(val, row)=> getHeaders(row.headers) || getDomHeader(row.message),
                    title:"reff",
                    grow:2
                },
                {
                    render:(val, row)=>row.origin,
                    title:'origin'
                },
                {
                    render:(val, row)=>row.destination.join(', '),
                    title:'dest'
                },
                {
                    render:(val, row)=>getStatusTxt(row.status),
                    title:'status',
                    width:'100px'
                },
                {
                    render:(val, row)=>row.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm'),
                    title:'created_at'
                },
                {
                    render:(val, row)=>row.processedAt && moment(row.processedAt).format('DD-MMM-YYYY HH:mm'),
                    title:'processed_at'
                },
            ]}
            title={`telex_in`} 
            icon={Icon} 
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'telex'
                    },
                    {
                        item:'telex_in'
                    }
                ]
            }
        />
    )
}