import { Col, Typography, Row, Divider } from "antd";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useFontSize } from "../../../../../../hooks/useDimension";
import CardListPage from "../../../../components/CardListPage";
import RowList from "../../../../components/RowList";
import { getServiceStatus } from "../../../../library";

export default function ProcessStatus() {
  const fontSize = useFontSize();
  return (
    <CardListPage
      url={"api/v1/bot/process/status"}
      fixedPagination
      CardContent={({ item }) => {
        const { createdAt, last_active, process_name, process_times, status } =
          item || {
            createdAt: "",
            last_active: "",
            process_name: "",
            process_times: "",
            status: "",
          };
        return (
          <div>
            <Row>
              <Col span={18}>
                <Typography.Title
                  style={{ fontSize: fontSize.lg, fontWeight: "bold" }}
                >
                  {process_name}
                </Typography.Title>
              </Col>
              <Col span={6}>{getServiceStatus(status)}</Col>
            </Row>
            <Divider className="my-1" />
            <div className="p-2">
              <RowList
                title={"Process Time"}
                content={`${numeral(process_times).format("0,000")} ms`}
              />
              <RowList
                title={"Last Active"}
                content={moment
                  .duration(moment(last_active).diff(moment()))
                  .humanize(true)}
              />
            </div>
          </div>
        );
      }}
    />
  );
}
