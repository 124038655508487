import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useLanguage } from '../../../../../hooks/useLanguage';
import AutoComplete from '../../../../../components/autocomplete/Online';
import CFG from '../../../../../config/env';
const {withDomestic} = CFG;
export default ({onSearchCallback, initValue}) => {
    const [state, setState]=useState(initValue);
    const [,getLang] = useLanguage();
    return (
        <div style={{padding:12, background:'#ffffff88', display:'flex', flexDirection:'column', overflow:'auto', minWidth:280}}>
            <Typography.Text strong>{getLang('search')}</Typography.Text>
                <Form 
                    layout='vertical'
                    initialValues={state}
                    onFinish={onSearchCallback}
                    autoComplete="off"                    
                >                    
                    <Form.Item
                        label={getLang('process_name')}
                        name="process_name"
                    >
                        <Input autoFocus/>
                    </Form.Item>
                    <Form.Item
                        label={getLang('command')}
                        name="command"
                    >
                        <Input/>
                    </Form.Item>
                    <Button block type='primary' htmlType='submit'>Search</Button>
                </Form>
        </div>    
    )
}