import { blue } from "@ant-design/colors";
import { Avatar, Card, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { MdPeople } from "react-icons/md";
import { Link, useLocation, useParams } from "react-router-dom";
import Flags from "../../../../../components/flags";
import Badge from "../../../components/Badge";
import BaseDetailPage from "../../../components/BaseDetailPage";
import RowList from "../../../components/RowList";
import { renderGender, getPaxType, getPaxTypePassenger } from "../../../library";

export default function Detail() {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();
  return (
    <BaseDetailPage
      url={`api/v1/vessel/passenger/detail/${id}`}
      renderContent={(data) => {
        const {
          passenger_name,
          age,
          boarding_number,
          citizen_id,
          dob,
          doc_expiration,
          doc_number,
          doc_type,
          gender,
          nationality,
          pax_type,
          ticket_number,
          unclode_id,
          vessel_id,
          watch_flag,
        } = data || {
          passenger_name: "",
          age: "",
          boarding_number: "",
          citizen_id: "",
          dob: "",
          doc_expiration: "",
          doc_number: "",
          doc_type: "",
          gender: "",
          nationality: "",
          pax_type: "",
          ticket_number: "",
          unclode_id: "",
          vessel_id: "",
          watch_flag: "",
        };

        const {
          _id,
          is_arrival,
          schedule_date,
          schedule_id,
          ferry_key,
          operator_id,
        } = vessel_id || {
          _id: "",
          is_arrival: "",
          schedule_date: "",
          schedule_id: "",
          ferry_key: "",
          operator_id: "",
        };
        return (
          <Row gutter={[8, 8]} className="my-4">
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card style={{ height: "100%" }}>
                <Row gutter={[8, 8]}>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      alt={""}
                      size="large"
                      style={{
                        backgroundColor: blue[6],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "6rem",
                        height: "6rem",
                      }}
                      className={"avatar-contain"}
                      icon={<MdPeople size={"3rem"} />}
                      // src={`${getLogoAirline(airline_id?.code)}`}
                    />
                    {!!watch_flag ? (
                      <Badge color={"error"} text={"WATCHLIST"} />
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={24} className="text-center">
                    <Link to={`/passengers/citizen/detail/${citizen_id}`}>
                      <Typography.Title level={4} style={{ color: blue[4] }}>
                        {passenger_name} {renderGender(gender)}
                      </Typography.Title>
                    </Link>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 24,
                      }}
                    >
                      <Typography.Text className="text-sub-title">
                        {" "}
                        {moment(dob).format("DD-MMM-YYYY")} ({age} year)
                      </Typography.Text>
                      <Flags
                        country_code={unclode_id}
                        withCountry
                        countryFormat={"alpha3"}
                        countryWidth
                      />
                      {/* {!!passenger_type ? (
                        <Badge
                          color={
                            passenger_type?.toLowerCase() === "passenger"
                              ? "def"
                              : "success"
                          }
                          text={passenger_type}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                    {/* <div> */}

                    {/* </div> */}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <Card>
                    <RowList
                      title={"Pax Type"}
                      content={getPaxTypePassenger(pax_type)}
                      dividerBottom
                    />
                    <RowList title={"Ticket"} content={ticket_number} />
                  </Card>
                </Col>
                <Col span={24}>
                  <Card style={{ height: "100%" }}>
                    <RowList
                      linkTo={"#"}
                      title={"Passport"}
                      content={
                        <div
                          className="rowslist-inline-flex"
                          style={{ flexWrap: "nowrap" }}
                        >
                          {`${doc_number} ${
                            !!doc_expiration
                              ? `(${moment(doc_expiration).format(
                                  "DD-MMM-YYYY"
                                )})`
                              : ""
                          }`}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Flags
                              className="text-right"
                              country_code={nationality}
                              withCountry
                              countryFormat={"alpha3"}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Card>
                <RowList
                  linkTo={
                    !!is_arrival
                      ? `/vessel/${
                          !!is_arrival ? "arrival" : "departure"
                        }/${_id}`
                      : "#"
                  }
                  title={"Vessel"}
                  content={
                    <div className="rowslist-inline-flex">
                      <p style={{ marginBottom: 0 }}>
                        {ferry_key} /{" "}
                        {moment(schedule_date).format("DD-MMM-YYYY")}
                        / {operator_id}
                      </p>
                    </div>
                  }
                />
              </Card>
            </Col>
          </Row>
        );
      }}
    />
  );
}
