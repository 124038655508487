// const BaseUrl='https://cargo.saranawarehouse.co.id/service/';
const BaseUrl='/service/';
const Title='Direktorat Jenderal Imigrasi';
const BgColor='#11375c';
const withDomestic=false;
const defaultOriginDom={_id:'60a3d868045fd93f38efc29a', code:"CGK", kota:'Jakarta'};
const backgroundGradient="rgba(44,113,146,1)";
const internalDetail = true;

export default {
    BaseUrl,
    Title,
    BgColor,
    withDomestic,
    defaultOriginDom,
    backgroundGradient,
    internalDetail
}
