import { Typography } from 'antd';
import React from 'react';
import { MdOutlineContactPhone } from 'react-icons/md';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
export default ({data, width}) => {
    const [,getLan] = useLanguage();
    return (
        <Panel
            headerLeft={<MdOutlineContactPhone size={24} />}
            headerRight={<Typography.Text>{getLan('contact_information')}</Typography.Text>}
        >
            <div style={{display:'flex', flexDirection:"column", rowGap:4, paddingInline:6, position:'relative', inset:0}}>
                <ul>
                    {
                        !!data && Array.isArray(data) && data.map((c, idx)=>{
                            const {addressDetails} = c;
                            return (
                                <li key={idx}>{addressDetails?.freeText}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </Panel>
    )
}