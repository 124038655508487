import React from 'react'
import BaseListPage from '../../../components/BaseListPage'
import CitizenList from '../../citizen/CitizenList'
import Passengerlist from '../../flight/passenger/Passengerlist'

export default function ListSearch({dataSource}) {
  return (
    <BaseListPage 
    
        datas={dataSource}
        renderItem={(item)=>{
            return <CitizenList dataSource={item} />
        }}
    />
  )
}
