import React from 'react';
import { GiHouse, GiInfo } from 'react-icons/gi';
import Page from '../../components/pages/detail';
import About from './about';

export default () => {
    return (
        <Page
            title={"about"}
            icon={GiInfo}
            bgColor={'#ffffff88'}
            DetailComponent={About}
            id="version"
            url={'api/v1'}            
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'about'
                    }
                ]
            }
        />
    )
}