import { HiHome } from "react-icons/hi";
import {
  MdInfo,
  MdMail,
  MdOutlineAirplanemodeActive,
  MdPeopleAlt,
} from "react-icons/md";
import { RiComputerFill, RiHome2Line, RiShipFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getLanguage } from "../../../languages/utils";

const SIZE = 20;
export default (languages) => [
  {
    key: "/dasboard",
    icon: <HiHome size={SIZE} />,
    label: <Link to={"#"}>{getLanguage("Dashboard", languages)}</Link>,
    level: 0x1fff,
    children: [
      {
        key: "/home",
        // icon: <HiHome size={SIZE} />,
        label: <Link to={"/home"}>{getLanguage("home", languages)}</Link>,
        level: 0x1fff,
      },
      {
        key: "/dashboard/inbound",
        // icon: <HiHome size={SIZE} />,
        label: (
          <Link to={"/dashboard/inbound"}>
            {getLanguage("Inbond", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/dashboard/outbound",
        // icon: <HiHome size={SIZE} />,
        label: (
          <Link to={"/dashboard/outbound"}>
            {getLanguage("Outbond", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
    ],
  },
  {
    key: "/flight",
    icon: <MdOutlineAirplanemodeActive size={SIZE} />,
    label: <Link to={"#"}>{getLanguage("Flight", languages)}</Link>,
    level: 0x1fff,
    children: [
      // {
      //   key: "/flight/schedule/domestic",
      //   // icon: <RiHome2Line size={SIZE} />,
      //   label: (
      //     <Link to={"/flight/schedule/domestic"}>
      //       {getLanguage("Domestic", languages)}
      //     </Link>
      //   ),
      //   level: 0x1fff,
      // },
      {
        key: "/flight/schedule/arrival",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/flight/schedule/arrival"}>
            {getLanguage("Arrival", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/flight/schedule/departure",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/flight/schedule/departure"}>
            {getLanguage("Departure", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
    ],
  },
  {
    key: "/vessel",
    icon: <RiShipFill size={SIZE} />,
    label: <Link to={"#"}>{getLanguage("Vessel", languages)}</Link>,
    level: 0x1fff,
    children: [
      {
        key: "/vessel/arrival",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/vessel/arrival"}>
            {getLanguage("Arrival", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/vessel/departure",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/vessel/departure"}>
            {getLanguage("Departure", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
    ],
  },
  {
    key: "/passenger",
    icon: <MdPeopleAlt size={SIZE} />,
    label: <Link to={"#"}>{getLanguage("POI", languages)}</Link>,
    level: 0x1fff,
    children: [
      {
        key: "/passengers/matched",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/passengers/matched"}>
            {getLanguage("Matched", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/passenger/watchlist",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/passengers/watchlist"}>
            {getLanguage("Watchlist", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/passengers/rules",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/passengers/rules"}>
            {getLanguage("Rules", languages)}
          </Link>
        ),
        level: 0x1fff,
      },
    ],
  },
  {
    key: "/telex",
    icon: <MdMail size={SIZE} />,
    label: <Link to={"#"}>{getLanguage("Telex", languages)}</Link>,
    level: 0x1fff,
    children: [
      {
        key: "/telex/inbox",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/telex/inbox"}>{getLanguage("Inbox", languages)}</Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/telex/queue",
        // icon: <RiHome2Line size={SIZE} />,
        label: (
          <Link to={"/telex/queue"}>{getLanguage("Queue", languages)}</Link>
        ),
        level: 0x1fff,
      },
    ],
  },
  {
    key: "/monitoring",
    icon: <RiComputerFill size={SIZE} />,
    label: <Link to={"#"}>{getLanguage("Monitoring", languages)}</Link>,
    level: 0x1fff,
    children: [
      {
        key: "/monitoring/bot",
        label: (
          <Link to={"/monitoring/bot"}>{getLanguage("Bot", languages)}</Link>
        ),
        level: 0x1fff,
      },
      {
        key: "/monitoring/checker",
        label: (
          <Link to={"/monitoring/checker"}>{getLanguage("PNRGOV/APIS", languages)}</Link>
        ),
        level: 0x1fff,
      },
    ],
  },
  {
    key: "/about",
    icon: <MdInfo size={SIZE} />,
    label: <Link to={"/about"}>{getLanguage("About", languages)}</Link>,
    level: 0x1fff,
  },
];
