import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import DashboardPages from './pages/index'
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SplashPage from '../../splash';
import { initMe, setApps } from "../../redux"
import Login from "./auth/Login"
import './assets/less/mobile.less'


export const UserPageMobile = ({ userdata }) => {
    return (
        <BrowserRouter basename='/m'>
            <Switch>
                <Route exact path="/">
                    {(!!userdata && <Redirect to={'/home'} />) || <Redirect to="/login" />}
                </Route>
                <Route exact path="/login">
                    {(!!userdata  && <Redirect to={'/home'} />) || <Login apps={"Apps"} />}
                </Route>
                <Route path="/*">
                    {(!!userdata  && <DashboardPages idx={new Date().getTime()} /> || <Redirect to="/login" />)}
                </Route>
            </Switch>
        </BrowserRouter>
    )
}


export default () => {
    const { userdata, initComplete } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setApps('head-office')); // set applikasi
        dispatch(initMe()); // cek token validasi        
    }, [initMe, setApps,])
    if (!initComplete) return <SplashPage />
    return <UserPageMobile userdata={userdata} />
}