import { Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useMemo, useState } from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiHouse } from 'react-icons/gi';
import {MdAirlineSeatReclineNormal} from 'react-icons/md';
import { IoIosPeople } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import Page from '../components/pages/paging';
import Search from './search';

const ColumnsInternational=[
    {
        title:'name',
        dataIndex:'passenger_name',
        width:'320px',
        render:(val, {_id})=><Link to={`/passengers/detail/${_id}`}>{val}</Link>
    },
    {
        title:'doc_number',
        dataIndex:'doc_number',
        width:'120px',
    },
    {
        title:'flight_number',
        dataIndex:'flight_number',
        width:'150px',
        render:(val, row)=>(
            <div style={{display:'flex', flexDirection:'row', gap:4}}>
                <Typography.Text>
                    {val}
                </Typography.Text>
                {
                    !!row?.reference?.seat_number && (
                        <Fragment>
                            <MdAirlineSeatReclineNormal size={18} />
                            <Typography.Text>
                                {row.reference.seat_number}
                            </Typography.Text>
                        </Fragment>
                    )
                }
            </div>
        )
    },
    {
        title:'flight_date',
        dataIndex:'flight_date',
        width:'120px',
    },
    {
        title:'origin',
        dataIndex:'origin_id',
        render:(val, row)=>`${val?.code} - ${val?.kota||'Unknown'}`
    },
    {
        title:'dest',
        dataIndex:'dest_id',
        render:(val)=>`${val?.code} - ${val?.kota||'Unknown'}`
    },
]
const ColumnsDomestic=[
    {
        title:'name',
        dataIndex:'passenger_name',
        width:'320px',
        render:(val, {_id})=><Link to={`/passengers/domestic/detail/${_id}`}>{val}</Link>
    },
    {
        title:'flight_number',
        dataIndex:'flight_number',
        width:'120px',
    },
    {
        title:'flight_date',
        dataIndex:'flight_date_str',
        width:'120px',
    },
    {
        title:'origin',
        dataIndex:'origin_id',
        render:(val, row)=>`${val?.code} - ${val?.kota||'Unknown'}`
    },
    {
        title:'dest',
        dataIndex:'dest_id',
        render:(val)=>`${val?.code} - ${val?.kota||'Unknown'}`
    },
]
const ColumnsVessel=[
    {
        title:'name',
        dataIndex:'passenger_name',
        width:'320px',
        render:(val, {_id})=><Link to={`/vessel/passengers/detail/${_id}`}>{val}</Link>
    },
    {
        title:'doc_number',
        dataIndex:'doc_number',
        width:'120px',
    },
    {
        title:'schedule_id',
        dataIndex:'vessel_id',
        width:'120px',
        render:(val)=>val?.schedule_id
    },
    {
        title:'schedule_time',
        dataIndex:'vessel_id',
        width:'220px',
        render:(val)=>val?.schedule_date && moment(val?.schedule_date+' '+val?.schedule_time, 'YYYYMMDD HH:mm').format('DD-MMM-YYYY HH:mm')
    },
    {
        title:'origin',
        dataIndex:'vessel_id',
        render:(val)=>val?.origin
    },
    {
        title:'dest',
        dataIndex:'vessel_id',
        render:(val)=>val?.destination
    },
]

export default () => {
    const Icon=IoIosPeople;
    const [searchValue, setSearchValue]=useState({
        flight_date:null, 
        db:'flight', passenger_name:'', doc_number:'', origin:null, dest:null        
    });    
    
    const [uri, formatedSearchVal, dabase]=useMemo(()=>{
        const {flight_date, db, ...others} = searchValue;
        const url=`api/v1/passengers_list/${db}`
        if(!flight_date)return [url, {...others, db}, db];
        const [from, to] = flight_date;
        return [url, {...others, db, flight_date:[from.format('DD-MMM-YYYY'), to.format('DD-MMM-YYYY')]}, db];
    }, [searchValue]);
    
    const Col=useMemo(()=>{
        switch (dabase) {
            case 'flight':
                return ColumnsInternational;
            case 'flight_domestic':
                return ColumnsDomestic;
            case 'vessel':
                return ColumnsVessel;
            default:
                return ColumnsInternational;
        }
    }, [dabase]);
    const history=useHistory();
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            columns={Col}
            title={`list`} 
            icon={Icon} 
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'list'
                    }
                ]
            }
        />
    )
}