import moment from 'moment';
import React, { useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import { MdOutlineRuleFolder } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Table from '../components/pages/paging';
import SearchComp from './search';

const Columns=[
    {
        title:'name',
        dataIndex:'name',
        render:(val, {_id})=><Link to={`/passengers/rules/edit/${_id}`}>{val}</Link>
    },
    {
        title:'rule_type',
        dataIndex:'rule_type',
    },
    {
        title:'origin',
        dataIndex:'origin',
        render:(val)=>val?.code
    },
    {
        title:'dest',
        dataIndex:'dest',
        render:(val)=>val?.code
    },
    {
        title:'nationality',
        dataIndex:'nationality',
    },
    {
        title:'gender',
        dataIndex:'gender',
    },
    {
        title:'ages',
        dataIndex:'first_age',
        render:(v, rec)=>rec.last_age > 0 && `${v} - ${rec?.last_age}` 
    },
    {
        title:'values',
        dataIndex:'min_value',
        render:(v, rec)=>`${v} - ${rec.max_value}` 
    },
    {
        title:'created_by',
        dataIndex:'createdBy',
        render:(val)=>val?.username
    },
    {
        title:'time',
        dataIndex:'createdAt',
        render:(val)=>moment(val).format('DD-MMM-YYYY HH:mm')
    },
];

export default () => {
    const [search, setSearch] = useState({name:''});
    return (
        <Table 
            url={'api/v1/rules'}
            searchValue={search}
            title={'rules'}
            icon={MdOutlineRuleFolder}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'rules'
                    }
                ]
            }
            columns={Columns}
            searchComponent={
                <SearchComp 
                    onSearchCallback={(searchValue)=>{
                        setSearch({...searchValue, timestamp:moment().unix()});
                    }}
                />
            }
        />
    )
}