import { Dropdown, Typography, Menu as MenuComp } from 'antd';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CFG from '../../../config/env';
import { useLanguage } from '../../../hooks/useLanguage';
// import Menu, {Categories} from '../menu';
import { LightenDarkenColor } from '../utils';
import './menu.css';
import { useMenu } from './useMenu';
// const { BgColor } = CFG;

const Overlay=({subMenu, background, color})=>{
    const [,getLan]=useLanguage();
    const items=useMemo(()=>{
        return subMenu.map((m, idx)=>{
            const {caption, Icon, childs, linkTo} = m;
            const children=childs && childs.map((r)=>{
                const {caption:cp, Icon, linkTo} = r;
                return {label:<Link to={linkTo}>{getLan(cp)}</Link>, icon:<Icon size={16}/>, key:`${caption}__${cp}__${idx}`};
            })
            const menu={icon:<Icon size={16}/>}
            return !!children && {...menu, label:getLan(caption), children, key:`${caption}__idx` } || {...menu, label:<Link to={linkTo}>{getLan(caption)}</Link>, key:`${caption}__idx`};
        })
    }, [subMenu]);
    return (
        <div style={{background}}>
            <MenuComp mode='vertical' items={items} subMenuCloseDelay={0.1} /> 
        </div>
    )
}

export default ({category, color, background}) => {
    // const [MainMenu, SubMenu]=useMemo(()=>{
    //     const [m] = Categories.filter(({caption})=>caption.toLowerCase()===category.toLowerCase());
    //     const menus= Menu.filter(mn=>!!mn).filter(({category:c})=>c?.toLowerCase()===category?.toLowerCase());
    //     return [m, menus];
    // }, [category]);
    const [,MainMenu, SubMenu]=useMenu(category);
    const [hover, setHover]=useState(false);
    const bgColor=useMemo(()=>{
        return !hover?background:LightenDarkenColor(background,10)
    }, [hover, background])
    const [,getLan]=useLanguage();
    return !!MainMenu && (
        <Dropdown 
            className='menu-wrapper' 
            trigger={['click', 'hover']} 
            overlay={<Overlay subMenu={SubMenu} />} 
            placement='bottomLeft' 
            overlayClassName='overlay-wrapper'
        >
            <div 
                onMouseEnter={()=>setHover(true)}
                onMouseLeave={()=>setHover(false)}
                style={{display:'flex', background:bgColor, flexDirection:'row', gap:4, justifyContent:'center', alignItems:'center', cursor:'pointer' }}
            >
                {!!MainMenu?.Icon && <MainMenu.Icon size={'1rem'} color={color||'white'}/>}
                <Typography.Text style={{color, fontSize:'0.8rem'}}>{getLan(MainMenu.caption)}</Typography.Text>
            </div>
        </Dropdown>
    )
}

export const SingleMenu=({color, background, Icon, linkTo, caption})=>{
    const [hover, setHover] = useState(false);
    const bgColor=useMemo(()=>{
        return !hover?background:LightenDarkenColor(background,10);
    }, [hover, background])
    const [,getLan]=useLanguage();
    return (
        <div 
            style={{lineHeight:0, background:bgColor, height:20, display:'flex', paddingInline:4}} 
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
        > 
            <Link 
                style={{color, fontSize:'0.8rem', display:'flex', flexDirection:'row', gap:4, alignItems:'center'}} 
                to={linkTo}
            >
                <Icon style={{fontSize:'0.8rem'}} color="white" />
                {getLan(caption)}
            </Link>
        </div>
    )
}