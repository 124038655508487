import { Typography } from 'antd';
import React from 'react';
import { Fragment } from 'react';
import { useMemo } from 'react';
import { GiPencil } from 'react-icons/gi';
// import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
// import Row from './row';

export default ({data}) =>{
    const remarks=useMemo(()=>{
        if(!data)return [];
        const result=[];
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const val = data[key];
                const values=Array.isArray(val)?val:[val];
                result.push({name:key, values});
            }
        }
        return result;
    },[data])
    const [,getLan]=useLanguage();
    return (
        <Panel
            headerLeft={<GiPencil size={24} />}
            headerRight={<Typography.Text>{getLan('remarks')}</Typography.Text>}
        >
            <div style={{paddingInline:8, paddingBlock:4}}>
                <ul>
                    {
                        remarks.map(({name, values}, idx)=>{
                            return (
                                <li key={idx}>
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        <span>{name}</span>
                                        {
                                            Array.isArray(values) && (
                                                <ul>
                                                    {
                                                        !!values && Array.isArray(values) && values.map((val, iix)=>{
                                                            return (<li key={`${idx}___${iix}`}>
                                                                <Typography.Text ellipsis>
                                                                    {name==='CTCE'?val.split('//').join('@'):val}
                                                                </Typography.Text>
                                                            </li>)
                                                        })
                                                    }
                                                </ul>
                                            )|| (
                                                <span>{`${values}`}</span>
                                            )
                                        }
                                    </div>
                                </li>      
                            ) 
                        })
                    }
                </ul>
            </div>
        </Panel>
    )
}