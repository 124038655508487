import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useListMenu from '../menu';

export const useMenu = (category) =>{
    const [Menu, Categories] = useListMenu();
    const [result, setResult]=useState([]);
    const [subMenu, setSubMenu]=useState([]);
    const [mainMenu, setMainMenu]=useState(false);
    const {userdata} = useSelector(state=>state.auth);
    useEffect(()=>{
        const filteredMenu = Menu.filter((mn)=>{
            if(mn?.level && mn.level>0){
                return (mn.level & userdata.level) > 0;
            }
            return true;
        })
        setResult(filteredMenu);
    }, [userdata, Menu]);

    useEffect(()=>{
        const [m] = Categories.filter(({caption})=>caption.toLowerCase()===category.toLowerCase());
        const menus= result.filter(mn=>!!mn).filter(({category:c})=>c?.toLowerCase()===category?.toLowerCase());
        // console.log(category, menus, m, result, Categories)
        // return [m, menus];
        setMainMenu(m);
        setSubMenu(menus);
    }, [category, result, Categories]);
    // console.log({mainMenu, subMenu})
    return [result, mainMenu, subMenu];
}