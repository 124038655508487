import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'

export default () => { 
    return (
        <TablePage 
            title={'Customer Tarif'}            
            url="api/v1/customer/tarif"            
            editPath={'/customer/rates/edit'}
            createPath={'/customer/rates/create'}
            columns={[]}
        />
    )
}