import { blue, green, grey, red } from "@ant-design/colors";
import {
  DownloadOutlined,
  FileExcelOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Typography,
  Input,
  DatePicker,
  Pagination,
} from "antd";
import Search from "antd/lib/transfer/search";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { onSearchByPropsObj } from "../library";
import NoResultData from "./NoResultData";
import TitleBasePage from "./TitleBasePage";

// const { Search } = Input;

export default function BasePage({
  children,
  datas,
  title,
  goBack,
  paginationUrl,
  url,
  searchApiProps,
  searchProps,
  sortDependency,
  getData,
  datePickerChange,
  FilterComponent,
  onSortData,
  onExportExcel,
  fixedPagination,
}) {
  
  const [dates, setDates] = useState([
    moment(new Date()),
    moment(new Date()).add(1, "day"),
  ]);
  const [isNotValidDate, setIsNotValidDate] = useState(false);

  const memoDates = useMemo(() => {
    return dates;
  }, [dates]);

  const [search, setSearch] = useState("");
  const [timestamp, setTimestamp] = useState(moment(new Date()).unix());
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tempDataPagination, setTempDataPagination] = useState([]);

  // const page = useMemo(() => {
  //   return currentPage;
  // }, [currentPage]);

  // const perPage = useMemo(() => {
  //   return sizePerPage;
  // }, [sizePerPage]);

  const paginationUri = useMemo(() => {
    return (
      !!paginationUrl &&
      `${paginationUrl}?search=${JSON.stringify({
        ...searchApiProps,
        timestamp,
      })}&page=${page}&perPage=${perPage}`
    );
  }, [page, perPage, searchApiProps]);

  const defUrl = useMemo(() => {
    const urlPagination = !!paginationUrl
      ? paginationUri
      : `${url}?search=${JSON.stringify({ ...searchApiProps, timestamp })}`;
    return !!url || !!paginationUrl ? urlPagination : false;
  }, [url, paginationUri, searchApiProps]);

  const [fetched, loading] = useFetch(defUrl);

  // useEffect(() => {
  //   if (!!paginationUrl && !!fetched && page === 1) {
  //     setTempDataPagination(fetched?.data);
  //   } else if (!!paginationUrl && page > 1) {
  //     setTempDataPagination((state) => [...state, ...fetched?.data]);
  //   }
  //   // return ()=> setTempDataPagination([])
  // }, [fetched, searchApiProps, page]);

  const data = useMemo(() => {
    const responseFetched = !!fetched?.data ? fetched?.data : fetched;
    return !!datas?.length ? datas : responseFetched;
  }, [datas, fetched]);


  const searchData = useMemo(() => {
    if (!!searchProps?.length && !!data?.length) {
      return onSearchByPropsObj({
        arrayData: data,
        arrayPropsStr: searchProps,
        searchStr: search,
      });
    } else {
      return data;
    }
  }, [data, search]);

  // const [firstIndex, lastIndex] = useMemo(() => {
  //   const first = !!infinitScroll ? 0 : (page - 1) * perPage;
  //   const last = !!infinitScroll ? first + perPage * page : first + perPage;
  //   return [first, last];
  // }, [page, perPage, data]);

  const sortedData = useMemo(() => {
    return (!!onSortData && onSortData(searchData)) || searchData;
  }, [searchData, sortDependency]);

  const firstIndex = (page - 1) * perPage;
  const lastIndex = firstIndex + perPage;

  const chunkedData = useMemo(() => {
    const paginatedData = !!paginationUrl && !!sortedData?.length && sortedData;
    const sdata =
      (!!sortedData && sortedData?.slice(firstIndex, lastIndex)) || sortedData;
    return !!paginationUrl ? paginatedData : sdata;
  }, [sortedData, page, perPage]);

  const totalData = useMemo(() => {
    return !!fetched?.subTotal ? fetched?.subTotal : sortedData?.length;
  }, [fetched, sortedData]);

  const resetCurrentPage = (page = 1, perPage = 10) => {
    setPage(page);
    setPerPage(perPage);
  };

  useEffect(() => {
    !paginationUrl && !!loading && resetCurrentPage()
    !!data && !!getData && typeof getData === "function" && getData(chunkedData);
  }, [chunkedData, loading]);

  

  // const isLoadmore = () => {
  //   if (!!fetched?.total && totalData - chunkedData?.length !== 0) {
  //     return true;
  //   } else if (sortedData?.length - chunkedData?.length !== 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const isPagination = () => !loading && totalData - chunkedData?.length > 0
  const fixedPaginationStyle = {
    position: "fixed",
    bottom: 0,
    zIndex: 98,
    backgroundColor: "white",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    padding: '24px 0'
    
  }

  return (
    <div style={{ position: "relative"}}>
      <div
        className={`${
          !!title ? "basepage-title bg-base-theme" : "basepage-no-title"
        }`}
      >
        {!!title && <TitleBasePage title={title} goBack={goBack} />}

        <Row
          // gutter={[8, 8]}
          className={`${
            !!searchProps?.length || !!datePickerChange ? "p-2" : ""
          }`}
        >
          {!!searchProps?.length && (
            <Col
              xs={{ span: 24, order: 3 }}
              sm={{ span: 24, order: 3 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              className=" p-1"
            >
              <Search
                style={{ minWidth: 200 }}
                placeholder={` ${searchProps.toString().replace("_", " ")} `}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
                allowClear
                enterButton
              />
            </Col>
          )}

          {!!datePickerChange && (
            <Col
              id="filter"
              xs={{ span: !!FilterComponent ? 20 : 24, order: 1 }}
              sm={{ span: !!FilterComponent ? 21 : 24, order: 1 }}
              md={{ span: !!FilterComponent ? 10 : 12, order: 2 }}
              lg={{ span: !!FilterComponent ? 10 : 12, order: 2 }}
              className=" p-1"

            >
              <DatePicker.RangePicker
                format={"DD-MMM-YYYY"}
                status={!!isNotValidDate && "error"}
                allowClear={false}
                getPopupContainer={() => document.getElementById("filter")}
                value={memoDates}
                onOpenChange={(open) => {
                  !!open &&  setIsNotValidDate(false);
                  
                }}
                style={{ width: "100%" }}
                onCalendarChange={(val, valString, info) => {
                  const { range } = info;
                  if (range === "end") {
                    const [from, to] = val;
                    const diff = moment.duration(to.diff(from)).days();
                    if (diff > 7) {
                      setIsNotValidDate(true);
                      // throw new Error("Cannot more than 7 days");
                      return false;
                    }
                    // return true;
                    setIsNotValidDate(false);
                    datePickerChange(val, valString);
                    setDates(val);
                  }
                }}
                // onChange={(val, valString) => {

                // }}
              />
              {!!isNotValidDate && <Typography.Text style={{color: red[4]}}>Cannot more than 7 days </Typography.Text>}
              
            </Col>
          )}

          {!!onExportExcel && (
            <Col
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ order: 3 }}
              lg={{ order: 3 }}
              className=" p-1"

            >
              {/* {!!onExportExcel && (
                <Col xs={{ order: 2 }}
                sm={{ order: 2 }}
                md={{ order: 3 }}
                lg={{ order: 3 }}> */}
              {/* <div style={{flexGrow: 1}}>
                  {!!FilterComponent && FilterComponent()}
                </div> */}
              {!!onExportExcel && (
                <div>
                  <Button
                    onClick={() => onExportExcel(sortedData)}
                    style={{ background: green[8] }}
                    icon={<FileExcelOutlined style={{ color: "white" }} />}
                    type="text"
                  />
                </div>
              )}
              {/* </Col>
              )} */}
            </Col>
          )}

          {!!FilterComponent && (
            <Col
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ order: 3 }}
              lg={{ order: 3 }}
              className=" p-1"

              style={{ flexGrow: 1 }}
            >
              {/* {!!onExportExcel && (
                <Col xs={{ order: 2 }}
                sm={{ order: 2 }}
                md={{ order: 3 }}
                lg={{ order: 3 }}> */}
              {/* <div > */}
              {!!FilterComponent && FilterComponent({ resetCurrentPage })}
              {/* </div> */}
              {/* {!!onExportExcel && (
                <div>
                    <Button
                    onClick={() => onExportExcel(sortedData)}
                    style={{ background: green[8], borderRadius: 99 }}
                    icon={<FileExcelOutlined style={{ color: "white" }} />}
                    type="text"
                  />
                </div>

                  )} */}
              {/* </Col>
              )} */}
            </Col>
          )}
        </Row>
      </div>

      {!loading ? (
        !!chunkedData && chunkedData?.length !== 0 ? (
        // <Scrollbars style={{minHeight: "calc(100vh - 250px)"}}>
          
          <Fragment>
            <div style={{marginBottom: !!isPagination() && !!fixedPagination ? 40: 4}}>{children}</div>
            {/* {page < 5 ? (
              <Fragment>
                {isLoadmore() && (
                  <div className="my-3 text-center">
                    <Button
                      type="link"
                      onClick={() => {
                        setPage((current) => current + 1);
                      }}
                    >
                      load more ...
                    </Button>
                  </div>
                )}
              </Fragment>
            ) : (
              <p className="text-center">
                use search or filter to spesific data
              </p>
            )} */}

          </Fragment>
        // </Scrollbars>

        ) : (
          <NoResultData />
        )
      ) : (
        <p>loading ...</p>
      )}

      {isPagination() && (
        <div
          style={!!fixedPagination ? fixedPaginationStyle : {  display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width:'100%',
          padding: '8px 0'}}
        >
          <div>
            <Pagination
              className="section-pagination"
              defaultCurrent={1}
              current={page}
              total={totalData}
              onChange={(pg, sz) => {
                console.log(pg, sz);
                setPerPage(sz);
                setPage(pg || 1);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
