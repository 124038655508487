import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "Name",
            id: 'station_name',
            compact: true
        },
        {
            selector: (row) => row?.code,
            name: "Code",
            id: 'code',
            compact: true
        },

        {
            selector: (row) => row?.station_id?.name,
            name: "Station Name",
            id: 'station_code',
            compact: true
        },
        {
            selector: (row) => row?.type_tpi,
            name: "Type",
            id: 'type_tpi',
            compact: true
        },
        {
            selector: (row) => row?.kanim_id?.name,
            name: "Kanim",
            id: 'kanim_id',
            compact: true
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
        {
            selector: (row) => row?.createdBy?.username || '-',
            name: "CreatedBy",
            id: 'created_by'
        },
    ]
    return (
        <TablePage
            title={"Master TPI"}
            url="api/v1/tpi"
            actionPosition={'first'}
            createPath="/master/tpi/create"
            editPath={"/master/tpi/edit"}
            columns={columns}
        />
    )
}