import { blue, magenta } from "@ant-design/colors";
import { Avatar, List, Typography } from "antd";
import moment from "moment";
import React from "react";
import { MdPeopleOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Flags from "../../../../components/flags";
import Badge from "../../components/Badge";
import { getTypeDocument, renderGender } from "../../library";

export default function CitizenList({ dataSource }) {
  const {
    _id,
    dob,
    fullname,
    gender,
    id_expiration,
    jenis_doc,
    issued_id_country,
    nationality,
    no_id,
  } = dataSource || {
    _id: "",
    dob: "",
    jenis_doc: "",
    fullname: "",
    gender: "",
    id_expiration: "",
    issued_id_country: "",
    no_id: "",
  };
  return (
    <Link to={`/passengers/citizen/detail/${_id}`}>
      <List.Item>
        <List.Item.Meta
          avatar={
            <Avatar
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                backgroundColor: `${
                  gender?.toLowerCase() === "m" ||
                  gender?.toLowerCase() === "male"
                    ? blue[4]
                    : magenta[4]
                }`,
              }}
              icon={<MdPeopleOutline />}
            />
          }
          title={
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                marginBottom: 0,
                gap: 8,
              }}
            >
              <Typography.Title level={5}>
                {fullname?.toUpperCase()}
              </Typography.Title>
              {renderGender(gender)}
            </div>
          }
          description={
            <div>
              <p className="mb-1">{moment(dob).format("DD-MMM-YYYY")} </p>
              {/* <p className="mb-1">
            PNR : {pnr} / {pnr_id?.total_pax} pax{" "}
          </p> */}
              <div className="rowslist-inline-flex">
                <p className="mb-1">
                  {no_id} / {moment(id_expiration).format("DD-MMM-YYYY")}
                </p>
                {!!issued_id_country &&
                !!nationality &&
                issued_id_country !== nationality ? (
                  <Flags
                    country_code={issued_id_country}
                    // withCountry
                    countryWidth={50}
                    // countryFormat={"alpha3"}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          }
        />

        <div>
          <div className="mb-4">
            <Badge color={"def"} text={getTypeDocument(jenis_doc)} />
          </div>
          <Flags
            country_code={nationality}
            withCountry
            countryWidth={50}
            countryFormat={"alpha3"}
          />
          {/* {watch_flag !== 0 ? (
        <div className="mt-4">
          <Badge color={"error"} text={"WATCHLIST"} />
        </div>
      ) : (
        ""
      )} */}
        </div>
      </List.Item>
    </Link>
  );
}
