import AsyncSelect from 'react-select/async';
import React, { useCallback, useEffect, useRef, useState} from 'react';
import {GetAPI} from '../../redux';
import { useDispatch } from 'react-redux';

export default ({getValues, onChange,value, getSelectedOptions, getKey, uri, width})=>{
    const mapper=(row, idx)=>{
        const value=(!!getValues && getValues(row)) || row;
        const key=(!!getKey && getKey(row)) || idx;
        return ({label:value, value:value, key, row});
    }
    const dispatch=useDispatch();
    const loadData=async(val, uri)=>{
        const url=`${uri}?page=1&perPage=10&search=${val}`;
        const resp = await dispatch(GetAPI({url}));
        console.log({resp});
        return resp.payload.data?.data;
    }
    const [defaultOptions, setDefaultOpt]=useState([]);

    useEffect(()=>{
        loadData('',uri).then(data => {
            if(!!data){
                setDefaultOpt(data);
            }
        })
    },[]);
    const timer=useRef(false);
    const loadOptions=useCallback((val)=>{
        if(timer.current) clearTimeout(timer.current)
        return new Promise(res => {
            const p = new Promise((resolve)=>{            
                timer.current=setTimeout(()=>{
                    loadData(val, uri).then((result)=>{
                        resolve(result)
                    })
                },300)
            });
            p.then(result=>res(result));
        })
    },[mapper, uri]);

    return (
        <AsyncSelect 
            cacheOptions={true}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions||[]}
            style={{width}}
            value={value}
            isClearable
            getOptionLabel={(opt)=>getValues(opt)}
            getOptionValue={(opt)=>getValues(opt)}
            onChange={onChange}
        />
    )
}