import React from 'react';

export default ({bgColor, headerBgColor, headerLeft, headerMiddle, headerRight, data, Item, children, overflow }) => {
    const background = bgColor || '#ffffffaa';
    const headerBackground = headerBgColor || '#ffffffcc';
    return (
        <div style={{display:'flex', flexDirection:'column', background, width:'100%', borderRadius:8, 
            overflow:overflow||'hidden'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', padding:8,
                gap:8, background:headerBackground}}>
                {
                    headerLeft || <div />
                }
                {
                    headerMiddle || <div />
                }
                {
                    headerRight || <div />
                }
            </div>
            {
                data && Array.isArray(data) && data.map((row, idx)=> <Item key={idx} row={row}/>)
            }
            {
                !data && children
            }
        </div>
    )
}