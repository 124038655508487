import { Typography } from 'antd';
import React from 'react';
import {FlightItem, PaxItem, PaxItemDomestic} from './items';

export default ({data, database}) => {
    const renderFlight=()=>{
        return !!data && Array.isArray(data) && data.map((row, idx)=>{            
            return (
                <div style={{width:'100%', paddingBlock:8, paddingInline:16}}>
                    <FlightItem flight_record={row} key={idx} />
                </div>
            )
        })
    }

    const renderPax=()=>{
        return !!data && Array.isArray(data) && data.map((row, idx)=>{
            return (
                <PaxItem passenger={row} key={idx} />
            )
        })
    }

    const renderPaxDom=()=>{
        return !!data && Array.isArray(data) && data.map((row, idx)=>{
            return (
                <PaxItemDomestic passenger={row} key={idx} />
            )
        })
    }

    const renderPaxes=()=>{
        return (            
            <div style={{display:'flex', flexDirection:'row', gap:16, flexWrap:'wrap', rowGap:16, justifyContent:'center', padding:16}}>
                {renderPax()}
            </div>
        )
    }

    const noDataFound=()=>{
        return (
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:16}}>
                <Typography>Data Not Found</Typography>
            </div>
        )
    }

    const renderDomestic=()=>{
        return (            
            <div style={{display:'flex', flexDirection:'row', gap:16, flexWrap:'wrap', rowGap:16, justifyContent:'center', padding:16}}>
                {renderPaxDom()}
            </div>
        )
    }

    const renderData=()=>{
        switch (database) {
            case 'flight_number':
                return renderFlight();
            case 'pax':
                return renderPaxes();
            case 'domestic':
                return renderDomestic();
            default:
                return null;
        }
    }

    return (!!data && Array.isArray(data) && data.length===0 && noDataFound()) || ( renderData());
    
}