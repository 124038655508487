import React, { useEffect, useMemo } from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import Page from '../components/pages/page';

export default ({children, id, timestamp, title, icon, bcumb, url, onDataSuccess, filterName, filterValue, filters})=>{
    const uri=useMemo(()=>{
        const filter=`filters=${JSON.stringify(filters)}&`;
        return !!id?url+'/'+id+'?'+filter+'timestamp='+timestamp:url+'?'+filter+'timestamp='+timestamp;
    }, [url, id, timestamp, filters])

    const [data] = useFetch(uri, false);
    useEffect(()=>{
        !!onDataSuccess && typeof onDataSuccess === 'function' && onDataSuccess(data);
    }, [data]);
    
    return (
        <Page title={title} icon={icon} breadcrumbs={bcumb}>
            <div style={{display:'flex', flexDirection:'column', padding:8, background:'#ffffff88', width:'100%', height:'100%'}}>                
                { children }
            </div>
        </Page>
    )
}