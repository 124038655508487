import React from "react";
import { Card } from "antd";
import { useLocation } from "react-router-dom";
import RowList from "../../../components/RowList";

export default function Originator() {
  const { state } = useLocation();
  const {country,currency,origin,system_location,travel_agent_iata_number} = state || {country: '',currency:'',origin:'',system_location:null,travel_agent_iata_number:null}
  return (
    <Card>
      <RowList title={"Origin"} content={origin?.code} dividerBottom/>
      <RowList title={"System Location"} content={`${system_location?.airline|| ''} / ${system_location?.locationCode || ''}`} dividerBottom />
      <RowList title={"Travel Agen"} content={travel_agent_iata_number} dividerBottom/>
      <RowList title={"Currency"} content={currency} dividerBottom/>
      <RowList title={"Language"} content={country}/>
    </Card>
  );
}
