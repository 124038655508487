import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import AutoComplete from '../../../../components/autocomplete/Online';
import {RiArrowLeftFill, RiSearchLine} from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useFocus } from '../../../../hooks/useFocus';
export default ({onSearchCallback, initValue, direction}) => {
    const [state, setState]=useState(initValue);
    // const [width, setWidth]=useState(265);
    const [colapsed, setColapsed]=useState(true);
    const [,getLang] = useLanguage();
    const {userdata} = useSelector(state=>state.auth);
    const defInterval=localStorage.getItem('fl_interval');
    const [auto, setAuto]=useState(defInterval || '60');
    const inval=useRef();
    const origin_active= direction==='arrival' || (userdata.level & 0x1ff0) > 0;
    const dest_active= direction==='departure' || (userdata.level & 0x1ff0) > 0;
    const focus = useFocus();
    const [refresh, setRefresh]=useState(0);
    useEffect(()=>{
        if(auto>0){
            typeof onSearchCallback==='function' && onSearchCallback(state);
        }
    }, [auto, refresh])

    useEffect(()=>{
        if(!!focus){
            const dt=moment().subtract(4, 'hours');
            const dt2=moment().add(4, 'hours');
            setState({...state, flight_date:[dt.startOf('date'), dt2.startOf('date').add(1, 'day').startOf('date'),], timestamp:moment().unix()})
            setRefresh(refresh+1);
        };
        if(!!inval.current)clearInterval(inval.current);
        
        inval.current=setInterval(()=>{
            if(!!focus){
                const dt=moment().subtract(4, 'hours');
                const dt2=moment().add(4, 'hours');
                setState({...state, flight_date:[dt.startOf('date'), dt2.startOf('date').add(1, 'day').startOf('date')], timestamp:moment().unix()})
                setRefresh(refresh+1);
            }
        }, parseInt(auto) * 1000);
        return ()=>{
            clearInterval(inval.current);
        }   
    }, [auto, focus]);

    return (
        <div style={{padding:12, background:'#ffffff88', display:'flex', flexDirection:'column', rowGap:8, width:colapsed?undefined:265}}>
            {
                !colapsed && (
                    <Fragment>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <Typography.Text strong>{getLang('search')}</Typography.Text> 
                            <Button icon={<RiArrowLeftFill />} onClick={()=>setColapsed(true)} />                            
                        </div>
                        <Form 
                            layout='vertical'
                            initialValues={state}
                            onFinish={onSearchCallback}
                            autoComplete="off"
                        >
                            <Form.Item
                                label={getLang("flight_date")}
                                name="flight_date"
                                style={{marginBottom:4}}
                                rules={[{
                                    validator:async(rule, value)=>{
                                        // console.log({rule, value})
                                        const [from, to]=value;
                                        const diff=moment.duration(to.diff(from)).days();
                                        if(diff>7){
                                            throw new Error('Cannot more than 7 days');
                                        }
                                        return true;
                                    }
                                }]}
                            >
                                <DatePicker.RangePicker 
                                    showTime={false}
                                    value={state.flight_date}
                                    format={'DD-MMM-YYYY'}
                                    onChange={(val, strVal)=>{
                                        setState({...state, flight_date:val});
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={getLang('airline')}
                                style={{marginBottom:4}}
                                name="airline"
                            >
                                <AutoComplete 
                                    getKey={(opt)=>opt._id}
                                    getSelectedOptions={(opt, val)=>opt._id===val._id}
                                    getValues={(val)=>`${val.code || val.icao} - ${val.name}`}
                                    uri="api/v1/airlines"
                                    onChange={(val)=>setState({...state, airline:val})}
                                    value={state.airline}
                                />
                            </Form.Item>
                            { 
                                origin_active && (
                                    <Form.Item
                                        label={getLang('origin')}
                                        style={{marginBottom:4}}
                                        name="origin"
                                    >
                                        <AutoComplete 
                                            getKey={(opt)=>opt._id}
                                            getSelectedOptions={(opt, val)=>opt._id===val._id}
                                            getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                                            uri="api/v1/airport"
                                            onChange={(val)=>setState({...state, origin:val})}
                                            value={state.origin}
                                        />
                                    </Form.Item>
                                )
                            }
                            {
                                dest_active && (
                                        <Form.Item
                                            label={getLang('dest')}
                                            style={{marginBottom:4}}
                                            name="dest"
                                        >
                                            <AutoComplete 
                                                getKey={(opt)=>opt._id}
                                                getSelectedOptions={(opt, val)=>opt._id===val._id}
                                                getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                                                uri="api/v1/airport"
                                                onChange={(val)=>setState({...state, dest:val})}
                                                value={state.dest}
                                            />
                                        </Form.Item>
                                    )
                            }
                            <Form.Item
                                label={getLang('flight_number')}
                                style={{marginBottom:4}}
                                name="flight_number"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item 
                                label="Interval" >
                                <Select value={auto} 
                                    placeholder={'Interval'}
                                    onChange={(val)=>{
                                        setAuto(val)
                                        localStorage.setItem('fl_interval', val);
                                    }}>
                                    <Select.Option value="0">Manual</Select.Option>
                                    <Select.Option value="15">15</Select.Option>
                                    <Select.Option value="30">30</Select.Option>
                                    <Select.Option value="60">60</Select.Option>
                                </Select>
                            </Form.Item>
                            <Button block type='primary' htmlType='submit'>Search</Button>
                        </Form>
                    </Fragment>
                ) || (
                    <Button icon={<RiSearchLine />} onClick={()=>setColapsed(false)} />
                )
            }
        </div>    
    )
}