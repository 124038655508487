import React from 'react';
import { useFetch } from '../../../../../hooks/useFetch';
import { Tooltip, Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import { blue, magenta } from '@ant-design/colors';
import { Typography } from 'antd';
import { useMemo } from 'react';
import Panel from '../panel/panel';
import {TooltipAge, TooltipAirport, TooltipCountry} from './charts/tooltip';
import LegendFormatter from './charts/legend';
import { useLanguage } from '../../../../../hooks/useLanguage';

export default ({_id})=>{
    const url=`api/v1/summary/flight_schedule/international/${_id}`;
    const [data] = useFetch(url);
    const [ages, nations, origin, destination, wni, wna] = useMemo(()=>{
        if(!data)return [false, false, false, false];
        const {ages, nations, origin, destination} = data;
        const [wni, wna]=Array.isArray(nations) && nations.reduce((acc, val)=>{
            let [a, i] = acc;
            const {male, female, _id} = val;
            const total=parseInt(male) + parseInt(female);
            if(_id?.nationality==='IDN'){
                return [a + total, i];
            }
            return [a, i + total];
        }, [0,0]) || [0,0]; 
        return [ages, nations, origin, destination, wni, wna];
    },[data]);
    const [,getLan] = useLanguage();
    const ageData=useMemo(()=>{
        if(!ages)return [];        
        return Array.isArray(ages) && ages.sort((a, b)=>a._id.age - b._id.age).map(({_id, ...rest})=>({age:_id.age, ...rest}));
    }, [ages]);
    const natData=useMemo(()=>{
        if(!nations)return [];        
        return Array.isArray(nations) && nations.sort((a, b)=>(b.female+b.male) - (a.male+a.female)).map(({_id, ...rest})=>({nationality:_id.nationality, ...rest}));
    }, [nations]);
    const orgData=useMemo(()=>{
        if(!origin)return [];        
        return Array.isArray(origin) && origin.sort((a, b)=>(b.female+b.male) - (a.male+a.female)).map(({_id, ...rest})=>({origin:_id.code, ...rest, airport:_id}));
    }, [origin]);
    const dstData=useMemo(()=>{
        if(!destination)return [];        
        return Array.isArray(destination) && destination.sort((a, b)=>(b.female+b.male) - (a.male+a.female)).map(({_id, ...rest})=>({dest:_id.code, ...rest, airport:_id}));
    }, [destination]);
    return ageData.length>0 && (
        <div style={{display:'flex', flexDirection:'column', rowGap:16}}>
            <Panel
                headerRight={<Typography.Text>{getLan('pax_by_age')}</Typography.Text>}
            >
                <div style={{paddingBlock:16, paddingRight:24}}>
                    <ResponsiveContainer width={'95%'} height={240} >
                        <BarChart
                            data={ageData}           
                        >
                            <YAxis  />
                            <XAxis dataKey={"age"} type="category" />
                            <Tooltip content={<TooltipAge />} />
                            <Legend formatter={LegendFormatter} />
                            <Bar stackId={'a'} dataKey={"male"} stroke={blue[4]} fill={blue[5]} />
                            <Bar stackId={'a'} dataKey={"female"} stroke={ magenta[4]} fill={magenta[5]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Panel>
            <Panel
                headerRight={<Typography.Text>{getLan('pax_by_nat')}</Typography.Text>}
                headerLeft={<Typography.Text>WNI : {wni} WNA : {wna}</Typography.Text>}
            >
                <div style={{paddingBlock:16, paddingRight:24}}>
                    <ResponsiveContainer width={'95%'} height={240} >
                        <BarChart
                            data={natData}           
                        >
                            <YAxis  />
                            <XAxis dataKey={"nationality"} type="category" />
                            <Tooltip content={TooltipCountry} />
                            <Legend formatter={LegendFormatter} />
                            <Bar stackId={'a'} dataKey={"male"} stroke={blue[4]} fill={blue[5]} />
                            <Bar stackId={'a'} dataKey={"female"} stroke={ magenta[4]} fill={magenta[5]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Panel>
            <Panel
                headerRight={<Typography.Text>{getLan('pax_by_org')}</Typography.Text>}
            >
                <div style={{paddingBlock:16, paddingRight:24}}>
                    <ResponsiveContainer width={'95%'} height={240} >
                        <BarChart
                            data={orgData}           
                        >
                            <YAxis  />
                            <XAxis dataKey={"origin"} type="category" />
                            <Tooltip content={TooltipAirport} />
                            <Legend formatter={LegendFormatter} />
                            <Bar stackId={'a'} dataKey={"male"} stroke={blue[4]} fill={blue[5]} />
                            <Bar stackId={'a'} dataKey={"female"} stroke={ magenta[4]} fill={magenta[5]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Panel>
            <Panel
                headerRight={<Typography.Text>{getLan('pax_by_dst')}</Typography.Text>}
            >
                <div style={{paddingBlock:16, paddingRight:24}}>
                    <ResponsiveContainer width={'95%'} height={240} >
                        <BarChart
                            data={dstData}           
                        >
                            <YAxis  />
                            <XAxis dataKey={"dest"} type="category" />
                            <Tooltip content={TooltipAirport} />
                            <Legend formatter={LegendFormatter} />
                            <Bar stackId={'a'} dataKey={"male"} stroke={blue[4]} fill={blue[5]} />
                            <Bar stackId={'a'} dataKey={"female"} stroke={ magenta[4]} fill={magenta[5]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Panel>
        </div>
    )
}