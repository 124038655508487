import { FilterOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useMemo } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Flags from "../../../../components/flags";
import CardListPage from "../../components/CardListPage";
import { getFormatedTime, getLogoAirline } from "../../library";
import ModalFilterFlight from "./ModalFilterFlight";
import PaxMessage from "./PaxMessage";
import CFG from "../../../../config/env";
import PaxDomesticMsg from "./PaxDomesticMsg";
import { red } from "@ant-design/colors";
import { Link } from "react-router-dom";
import { useFontSize } from "../../../../hooks/useDimension";
import CardListFlight from "./CardListFlight";

const { withDomestic, defaultOriginDom } = CFG;

export default function Flight() {
  const { direction } = useParams();
  const { pathname } = useLocation();
  const fontSize = useFontSize();
  const url = useMemo(() => {
    const byRoute =
      !!direction && direction === "domestic"
        ? `api/v1/flight_schedule/${direction}/inquiry`
        : `api/v1/flight_schedule/international/${direction}/inquiry`;
    return direction === "matched"
      ? `api/v1/flight_schedule/international/any/detail`
      : byRoute;
  }, [direction]);

  const [searchApi, setSearchApi] = useState({
    flight_number: "",
    airline: "",
    origin: "",
    dest: "",
    flightDate: [moment(new Date()), moment(new Date()).add(1, "days")],
  });
  const [isModalFilter, setIsModalFilter] = useState(false);
  const handleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const formatedSearch = useMemo(() => {
    const { flightDate, ...rest } = searchApi;
    const [day1, day2] = flightDate || ["", ""];
    return {
      ...rest,
      // flightDate,
      from: moment(day1).format("DD-MMM-YYYY"),
      to: moment(day2).format("DD-MMM-YYYY"),
    };
  }, [searchApi, direction]);

  useEffect(() => {
    !!direction && direction === "domestic"
      ? setSearchApi({ ...searchApi, origin: defaultOriginDom })
      : setSearchApi({ ...searchApi });
  }, [direction]);

  return (
    <CardListPage
      title={`Flight ${direction}`}
      url={url}
      searchApiProps={formatedSearch}
      searchProps={["flight_number", "dest", "origin"]}
      onSortData={(data) => {
        if (!!data && Array.isArray(data)) {
          const currentTime = moment().unix();
          const mData = data.map((row, idx) => {
            const scheduleTime =
              direction === "arrival" ? row.unixSta : row.unixStd;
            const diff = Math.abs(currentTime - (scheduleTime || 0));
            return { ...row, number: idx + 1, diff };
          });
          const sorted = mData.sort((a, b) => a.diff - b.diff);
          return sorted;
        }
        return [];
      }}
      datePickerChange={(val, valStr) => {
        setSearchApi({ ...searchApi, flightDate: val });
      }}
      fixedPagination
      FilterComponent={() => {
        return (
          <Fragment>
            <Button
              style={{ width: "100%" }}
              type="primary"
              icon={<FilterOutlined />}
              size={24}
              onClick={handleModal}
            />
            {isModalFilter && (
              <ModalFilterFlight
                state={searchApi}
                setState={setSearchApi}
                handleSearch={(value) => {
                  setSearchApi({
                    ...searchApi,
                    ...value,
                    timestamp: moment().unix(),
                    airline_id: value?.airline?._id,
                  });
                  setIsModalFilter(!isModalFilter);
                }}
                title={direction}
                open={isModalFilter}
                handleModal={handleModal}
              />
            )}
          </Fragment>
        );
      }}
      CardContent={({ item }) => {
        return (
          <CardListFlight dataSource={item} />
        );
      }}
    ></CardListPage>
  );
}
