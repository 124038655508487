import React from 'react';
import Tabs from '../../tabs';
import Detail from './list_domestic';

export default ({data, id, direction}) => {
    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            <Tabs 
                selTab={1}
                tabs={[
                    {
                        id:0,
                        caption:'detail',
                        href:`/pnr/detail/${id}`,
                    },                    
                    {
                        id:1, 
                        caption:'passengers',
                        href:`/pnr/passengers/domestic/${id}`,
                    }
                ]}
            />
            <Detail data={data}  />
        </div>
    )
}