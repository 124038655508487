import React from 'react';
import { GiHarborDock, GiHouse, GiSailboat } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/vessel';
export default () => {
    const {direction, id} = useParams();
    
    const Icon=direction==='arrival'?GiHarborDock:GiSailboat;
    return (
        <Detail 
            title={`vessel_${direction}`} 
            icon={Icon} 
            id={id}
            url={`api/v1/vessel/${direction}/detail`}
            getBreadcumbValue={({schedule_id, operator_id})=>`${operator_id} ${schedule_id}`}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'vessel'
                    },
                    {
                        item:'vessel_'+direction,
                        href:'/vessel/'+direction
                    }
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{direction, id}}
        />
    )
}