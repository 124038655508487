import React from "react";
import { SiderLayout } from "../components/layouts/sider";
import Menus from "./menu";
import { green } from "@ant-design/colors";
import { useSelector } from "react-redux";
import { Routing } from "./routing";
import { Route } from "react-router-dom";
// import {loadAirlines, loadAirports} from '../../../redux'
import { Switch } from "react-router-dom";
import { useLocHref } from "../../../hooks/useLocHref";
import { useMenu } from "../../../hooks/useMenu";
import { useNavs } from "../../../hooks/useNavs";
import CONFIG from "../../../config/env";
import { createUseStyles } from "react-jss";
const { Title } = CONFIG;

const useStyles = createUseStyles((theme) => ({
  root: {
    "& .ant-layout-sider": {
      background: theme.colorPrimary,
    },
    "& .ant-layout-content": {
      background: theme.bgContent,
    },
    "& .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub":
      {
        background: theme.colorPrimary,
      },
    "& .ant-menu.ant-menu-dark": {
      "& .ant-menu-item-icon": {
        margin: "8px 0px",
        // color: theme.colorPrimary,
      },
      "& .ant-menu-item-selected": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        backgroundColor: theme.bgContent,
        "& .ant-menu-item-icon": {
          color: theme.colorPrimary,
        },
        "& .ant-menu-title-content a": {
          color: theme.colorPrimary,
        },
      },
    },
  },
}));

const TheRouter = () => {
  const {
    userdata: { level },
  } = useSelector((state) => state.auth);
  const routes = Routing.filter((r) => (r.level & level) > 0);

  return (
    <Switch>
      {routes.map((route, idx) => (
        <Route
          key={idx}
          path={route.to}
          exact={route.isExact !== false}
          render={(props) => {
            return !!route.component && route.component;
          }}
        />
      ))}
      <Route path={"/*"}>
        <div>Page Not Found</div>
      </Route>
    </Switch>
  );
};

export default ({ idx }) => {
  // const dispatch = useDispatch();
  // useEffect(()=>{
  //     // dispatch(loadAirlines());
  //     // dispatch(loadAirports());
  // }, []);
  const classes = useStyles();
  const {
    userdata: { level },
  } = useSelector((state) => state.auth);
  const key = useLocHref("dashboard");
  const { keys, menus } = useMenu(Menus, level);
  useNavs(keys, key);
  return (
    <div >
      <SiderLayout
        menus={menus}
        headerBgColor={"white"}
        // headerColor={"white"}
        title={`${Title}`}
      >
        <TheRouter />
      </SiderLayout>
    </div>
  );
};
