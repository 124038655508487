import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SubDetailPage from "../../../components/SubDetailPage";
import PassengersVessel from "../passenger/PassengersVessel";

export default () => {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();

  const components = {
    passengers: PassengersVessel,
  };

  return (
    <SubDetailPage
      basePath={pathname}
      subComponents={components}
      keyComponents={subdetail}
    />
  );
};
