import { Card, Col, Row, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Flags from "../../../../components/flags";
import { useFetch } from "../../../../hooks/useFetch";
import DetailPage from "../../components/BaseDetailPage";
import RowList from "../../components/RowList";
import Schedule from "./Schedule";
import BaseDetailPage from "../../components/BaseDetailPage";
import { onLimitChar } from "../../library";

export default function Detail() {
  const { id, subdetail } = useParams();
  const { pathname } = useLocation();
  // const [detail, loadinDetailg] = useFetch(
  //   `api/v1/flight_schedule/international/arrival/detail/${id}`
  // ) || { detail: {} };

  const [detailData, setDetailData] = useState(null);

  const [summaryUrl, telexUrl, pnrUrl, passengersUrl] = useMemo(() => {
    if (!!detailData) {
      const summary = `api/v1/summary/flight_schedule/international/${id}`;
      const telex = `api/v1/flight_schedule/international/arrival/telexes/${id}`;
      const pnr = `api/v1/pnr/detail/flight_schedules_id/${id}`;
      const passengers = `api/v1/passengers/detail/flight_schedule_id/${id}`;
      return [summary, telex, pnr, passengers];
    } else {
      return [false, false, false, false];
    }
  }, [detailData]);

  
  const [summary, loadingSummary] = useFetch(summaryUrl);
  const [telex, loadingTelex] = useFetch(telexUrl);
  const [pnr, loadingPnr] = useFetch(pnrUrl);
  const [passengers, loadingPassenger] = useFetch(passengersUrl);
  return (
    <BaseDetailPage
      url={`api/v1/flight_schedule/international/arrival/detail/${id}`}
      getData={(data) => {
        setDetailData(data);
      }}
      renderContent={(data) => {
        const { airline_id, origin_id, dest_id, flight_rule_affected } =
        data || {
            airline_id: null,
            origin_id: null,
            dest_id: null,
            flight_rule_affected: [],
          };
        const { name, country } = airline_id || { name: "", country: "" };
        const {
          name: originName,
          code: originCode,
          country: originCountry,
        } = origin_id || { originName: "", originCode: "", originCountry: "" };
        const {
          name: destName,
          code: destCode,
          country: destCountry,
        } = dest_id || { destName: "", destCode: "", destCountry: "" };
        return (
          <Row style={{ gap: 16 }}>
            <Col span={24}>
              <Schedule data={detailData} />
            </Col>
            <Col span={24}>
              <Card>
                <RowList
                  linkTo={{ pathname: `${pathname}/origin`, state: origin_id }}
                  title={"Origin"}
                  content={
                    <div className="rowslist-inline-flex">
                      <Typography.Text>{`${
                        !!originName && !!originCode
                          ? `${onLimitChar(originName, 16)} (${originCode})`
                          : "-"
                      }`}</Typography.Text>
                      <Flags
                        country_code={originCountry}
                        withCountry
                        countryFormat={"alpha3"}
                      />
                    </div>
                  }
                  dividerBottom
                />
                <RowList
                  linkTo={{
                    pathname: `${pathname}/destination`,
                    state: dest_id,
                  }}
                  title={"Destination"}
                  content={
                    <div className="rowslist-inline-flex">
                      <Typography.Text>{`${
                        !!destName && !!destCode
                          ? `${onLimitChar(destName, 16)} (${destCode})`
                          : "-"
                      }`}</Typography.Text>
                      <Flags
                        country_code={destCountry}
                        withCountry
                        countryFormat={"alpha3"}
                      />
                    </div>
                  }
                  dividerBottom
                />
                <RowList
                  linkTo={{
                    pathname: `${pathname}/rule`,
                    state: flight_rule_affected,
                  }}
                  title={"Rule"}
                  content={`${flight_rule_affected.length} rules filtered`}
                  dividerBottom
                />
                <RowList
                  linkTo={{ pathname: `${pathname}/telex`, state: telex }}
                  title={"Telex"}
                  content={`${telex?.length || 0} telex`}
                  dividerBottom
                />
                <RowList
                  linkTo={{ pathname: `${pathname}/pnr`, state: pnr }}
                  title={"Pnr"}
                  content={`${pnr?.length || 0} pnr`}
                  dividerBottom
                />
                <RowList
                  linkTo={{
                    pathname: `${pathname}/passengers`,
                    state: passengers,
                  }}
                  title={"Pasenger List"}
                  content={`${passengers?.length || 0} Passengers`}
                  // dividerBottom
                />
              </Card>
            </Col>
          </Row>
        );
      }}
    ></BaseDetailPage>
  );
}
