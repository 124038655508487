import { Card } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import Flags from "../../../../../components/flags";
import RowList from "../../../components/RowList";

export default function RouteFlight({ stateLocation }) {
  const { name, code, kota, country, icao_code } = stateLocation || {
    name: "",
    code: "",
    kota: "",
    country: "",
    icao_code: "",
  };

  return (
    <Card>
      <RowList title={"Name"} content={name} dividerBottom />
      <RowList title={"City"} content={kota} dividerBottom />
      <RowList title={"IATA code"} content={code} dividerBottom />
      <RowList title={"ICAO code"} content={icao_code} dividerBottom />
      <RowList
        title={"Country"}
        content={
          <div className="rowslist-inline-flex">
            <Flags
              country_code={country}
              withCountry
              countryWidth
              countryFormat={"alpha3"}
            />
          </div>
        }
      />
    </Card>
  );
}
