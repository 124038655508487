import Flight from "./flight";
import FlightDetail from "./flight/Detail";
import FlightSubDetail from "./flight/subdetail";
import Home from "./home";
import PnrDetail from './pnr/Detail'
import PnrSubDetail from './pnr/subdetail'
import PassengersDetail from './flight/passenger/Detail'
import PassengersSubDetail from './flight/passenger/subdetail'
import CitizenDetail from './citizen/Detail'
import CitizenSubDetail from './citizen/subdetail'
import TelexDetail from './telexes/Detail'
import Vessel from "./vessel";
import VesselDetail from "./vessel/Detail";
import VesselSubDetail from "./vessel/subdetail";
import PassengersMatched from "./poi/Matched";
import PassengersWatchlist from "./poi/watchlist/Watchlist"
import PassengersWatchlistForm from "./poi/watchlist/form"
import PassengersRules from "./poi/rules"
import PassengersRulesForm from './poi/rules/form'
import VesselPassengersDetail from "./vessel/passenger/Detail";
import PassengersSummary from "./dashboard";
import SearchPage from "./search";
import TelexInbox from "./telexes/inbox";
import TelexQueue from "./telexes/queue";
import BotMonitoring from './monitoring/bot'
import CheckerMonitoring from './monitoring/checker'
import About from "./about";
// import VesselPassengersSubDetail from "./vessel/passenger/subdetail";


export const Routing = [
    {
        to: '/home',
        level: 0x1fff,
        component: <Home />
    },
    {
        to: '/search',
        level: 0x1fff,
        component: <SearchPage />
    },
    {
        to: '/dashboard/:passengersTrip',
        level: 0x1fff,
        component: <PassengersSummary />
    },
    // {
    //     to: '/dashboard/outbond',
    //     level: 0x1fff,
    //     component: <Outbond />
    // },
    {
        to: '/flight/schedule/:direction',
        level: 0x1fff,
        component: <Flight />
    },
    {
        to: '/flight/schedule/:direction/:id',
        level: 0x1fff,
        component: <FlightDetail />
    },
    {
        to: '/flight/schedule/:direction/:id/:subdetail',
        level: 0x1fff,
        component: <FlightSubDetail />
    },
    {
        to: '/pnr/detail/:id',
        level: 0x1fff,
        component: <PnrDetail />
    },
    {
        to: '/pnr/detail/:id/:subdetail',
        level: 0x1fff,
        component: <PnrSubDetail />
    },
    {
        to: '/passengers/detail/:id',
        level: 0x1fff,
        component: <PassengersDetail />
    },
    {
        to: '/passengers/detail/:id/:subdetail',
        level: 0x1fff,
        component: <PassengersSubDetail />
    },
    {
        to: '/passengers/citizen/detail/:id',
        level: 0x1fff,
        component: <CitizenDetail />
    },
    {
        to: '/vessel/passengers/detail/:id',
        level: 0x1fff,
        component: <VesselPassengersDetail />
    },
    {
        to: '/passengers/citizen/detail/:id/:subdetail',
        level: 0x1fff,
        component: <CitizenSubDetail />
    },
    {
        to: '/telex/detail/:id',
        level: 0x1fff,
        component: <TelexDetail />
    },
    {
        to: '/vessel/:direction',
        level: 0x1fff,
        component: <Vessel />
    },
    {
        to: '/vessel/:direction/:id',
        level: 0x1fff,
        component: <VesselDetail />
    },
    {
        to: '/vessel/:direction/:id/:subdetail',
        level: 0x1fff,
        component: <VesselSubDetail />
    },
    
    {
        to: '/telex/inbox',
        level: 0x1fff,
        component: <TelexInbox />
    },
    {
        to: '/telex/queue',
        level: 0x1fff,
        component: <TelexQueue />
    },
    {
        to: '/passengers/matched',
        level: 0x1fff,
        component: <PassengersMatched />
    },
    {
        to: '/passengers/watchlist',
        level: 0x1fff,
        component: <PassengersWatchlist />
    },
    {
        to: '/passengers/rules',
        level: 0x1fff,
        component: <PassengersRules />
    },
    {
        to: '/passengers/rules/create',
        level: 0x1fff,
        component: <PassengersRulesForm />
    },
    {
        to: '/passengers/rules/edit/:id',
        level: 0x1fff,
        component: <PassengersRulesForm />
    },
    {
        to: '/passengers/watchlist/create',
        level: 0x1fff,
        component: <PassengersWatchlistForm />
    },
    {
        to: '/passengers/watchlist/edit/:id',
        level: 0x1fff,
        component: <PassengersWatchlistForm />
    },
    {
        to: '/monitoring/bot',
        level: 0x1fff,
        component: <BotMonitoring />
    },
    {
        to: '/monitoring/checker',
        level: 0x1fff,
        component: <CheckerMonitoring />
        
    },
    {
        to: '/about',
        level: 0x1fff,
        component: <About />
    },


]