import React from 'react';
import { GiHouse } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/citizen';
import { IoIosPeople } from 'react-icons/io';
export default () => {
    const {direction, id} = useParams();
    
    const Icon=IoIosPeople;
    return (
        <Detail 
            title={`citizen`} 
            icon={Icon} 
            id={id}
            url={`api/v1/citizen/detail`}
            getBreadcumbValue={({fullname})=>fullname}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'citizen'
                    },
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{direction, id}}
        />
    )
}