import { Form, Input, Select, Switch } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Online'
import { useSelector } from 'react-redux'
import { Fragment } from 'react';
import { GiHarborDock, GiCommercialAirplane } from 'react-icons/gi';

const DefModel = {
    username: '',
    password: '',
    name: '',
    email: '',
    level: 1,
    phone: '',
    airport_id: null,
    seaport_id: null,
    kanwil_id: null,
    kanim_id: null,
    tpi_id: null,
    is_airport: true,
}

export default () => {
    const [model, setModel] = useState(DefModel);
    const { levels } = useSelector(state => state.apps);
    const { userdata: { level } } = useSelector(state => state.auth);
    const lvls = useMemo(() => {
        return levels.filter((l) => l.level <= level);
    }, [levels, level])
    return (
        <FormPage
            url={'api/v1/users'}
            callbackPath={'/master/users'}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Airline"}
            emptyModel={DefModel}
            onEditFormEvent={(val) => {
                const { airport_id } = val;
                const is_airport = !!airport_id;
                return { ...val, is_airport };
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '40%', rowGap: 8 }}>
                    <Form.Item name={'username'} label="Username" rules={[{ required: true }]} >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item name={'name'} label="Name" >
                        <Input />
                    </Form.Item>
                    <Form.Item name={'password'} label="Password" rules={[{ required: !!model?.isCreate }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name={'email'} label="Email">
                        <Input />
                    </Form.Item>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '40%', rowGap: 8 }}>
                    <Form.Item name={'level'} label="Role">
                        <Select onChange={(val) => setModel({ ...model, level: val })}>
                            {
                                lvls.map((level, idx) => (
                                    <Select.Option key={idx} value={level.level}>
                                        {
                                            level.value
                                        }
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    {
                        (model.level & 0x1) > 0 ? (
                            <Fragment>
                                <Form.Item name={'tpi_id'} label="TPI">
                                    <AutoComplete
                                        getKey={(opt) => opt._id}
                                        getSelectedOptions={(opt, val) => opt._id === val._id}
                                        getValues={(val) => `${val.kanim_id?.name} - ${val?.name}`}
                                        uri="api/v1/tpi"
                                        onChange={(val) => setModel({ ...model, tpi_id: val })}
                                        value={model.tpi_id}
                                    />
                                </Form.Item>
                            </Fragment>
                        ) : (model.level & 0x2) > 0 ?
                            (
                                <Fragment>
                                    <Form.Item name={'kanim_id'} label="Kantor Imigrasi">
                                        <AutoComplete
                                            getKey={(opt) => opt._id}
                                            getSelectedOptions={(opt, val) => opt._id === val._id}
                                            getValues={(val) => `${val?.name}`}
                                            uri="api/v1/kanim"
                                            onChange={(val) => setModel({ ...model, kanim_id: val })}
                                            value={model.kanim_id}
                                        />
                                    </Form.Item>
                                </Fragment>
                            ) : (model.level & 0x8) > 0 ? (
                                <Fragment>
                                    <Form.Item name={'kanwil_id'} label="Kantor Wilayah">
                                        <AutoComplete
                                            getKey={(opt) => opt._id}
                                            getSelectedOptions={(opt, val) => opt._id === val._id}
                                            getValues={(val) => `${val?.name}`}
                                            uri="api/v1/kanwil"
                                            onChange={(val) => setModel({ ...model, kanwil_id: val })}
                                            value={model.kanwil_id}
                                        />
                                    </Form.Item>
                                </Fragment>
                            ) : (model.level & 0x3) > 0 && (
                                <Fragment>
                                    <Form.Item label="Station Type">
                                        <Switch
                                            size='default'
                                            unCheckedChildren={<GiHarborDock size={14} />}
                                            checkedChildren={<GiCommercialAirplane size={14} />}
                                            defaultChecked
                                            checked={model.is_airport}
                                            onChange={(checked) => {
                                                setModel({ ...model, is_airport: checked })
                                            }}
                                        />
                                    </Form.Item>
                                    {
                                        model.is_airport && (
                                            <Form.Item name={'airport_id'} label="Airport">
                                                <AutoComplete
                                                    getKey={(opt) => opt._id}
                                                    getSelectedOptions={(opt, val) => opt._id === val._id}
                                                    getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
                                                    uri="api/v1/airport/domestic"
                                                    onChange={(val) => setModel({ ...model, airport_id: val, seaport_id: null })}
                                                    value={model.airport_id}
                                                />
                                            </Form.Item>
                                        ) || (
                                            <Form.Item name={'seaport_id'} label="Seaport">
                                                <AutoComplete
                                                    getKey={(opt) => opt._id}
                                                    getSelectedOptions={(opt, val) => opt._id === val._id}
                                                    getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
                                                    uri="api/v1/seaport/domestic"
                                                    onChange={(val) => setModel({ ...model, airport_id: null, seaport_id: val })}
                                                    value={model.seaport_id}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                </Fragment>

                            )
                    }
                </div>

            </div>
        </FormPage>
    )
}