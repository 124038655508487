import { grey } from '@ant-design/colors';
import React, { useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useAos } from '../../../../../hooks/useAos';
import useWindowDimensions from '../../../../../hooks/useDimension';
import { useFetch } from '../../../../../hooks/useFetch';
import Lists from '../lists/lists';
import Page from './page';      

export default ({title, icon, breadcrumbs, url, searchComponent, searchValue, renderItem, onDataEvent, OtherComponent, filterValues}) => {
    const defUrl=useMemo(()=>{
        const u=`${url}?search=${JSON.stringify(searchValue)}`;
        return u;
    }, [url, searchValue]);
    const aos=useAos();
    const [data, loading]=useFetch(defUrl);
    const sortedData=useMemo(()=>{
        if(typeof onDataEvent!=='function')return data;
        return onDataEvent(data);
    }, [data])
    const {width} = useWindowDimensions();
    return (
        <Page title={title} icon={icon} breadcrumbs={breadcrumbs}>
            <Scrollbars>
                <div style={{display:'flex', flexDirection:'row', height:'100%', paddingBlock:8, gap:8, flexWrap:'wrap'}}>
                    <div data-aos={aos}>
                        {searchComponent}
                    </div>
                    <div style={{minWidth:'50%', background:'#ffffff88', height:'100%', flexGrow:1}}>
                        <Scrollbars>
                            <Lists 
                                OtherComponent={OtherComponent}
                                data={sortedData}
                                filterValues={filterValues}
                                renderItem={renderItem}
                            />
                        </Scrollbars>
                    </div>
                </div>
            </Scrollbars>
        </Page>
    )
}