import React from 'react'
import { Route, Redirect, Switch } from "react-router-dom"

import Routes from './routes';
import NotFound from './routes/page404';
export default () => {
    return(
        <Switch>
            {
                Routes.map((rt, idx)=>{
                    const {path, exact, Component, redirect, cache} = rt;
                    return (
                        <Route key={idx} path={path} exact={exact}>
                            {
                                (!!redirect && <Redirect to={redirect} />) 
                                    ||
                                <Component path={path} />
                            }
                        </Route>
                    ) 
                })
            } 
            <Route path={'/*'}>
                <NotFound />
            </Route>
        </Switch>
    )
}