import TelexInboxPage from '../pages/telex/inbox';
import TelexQueuePage from '../pages/telex/queue';

export default [
    {
        path:'/telex/inbox',
        exact:true,
        Component:TelexInboxPage
    },
    {
        path:'/telex/queue',
        exact:true,
        Component:TelexQueuePage
    },
]