import React from 'react'
import { log } from 'util';
import CardListPage from '../../../components/CardListPage';
import CardListFlight from '../../flight/CardListFlight';

export default function CardListSearch({dataSource}) {

  return (
    <CardListPage
        datas={dataSource}
        CardContent={({item}) => {
          // const {
          //   _id,
          //   flight_number,
          //   acreg,
          //   actype,
          //   dest,
          //   arrival_date,
          //   flight_date,
          //   flight_rule_affected,
          //   airline_id,
          //   is_arrival,
          //   is_internasional,
          //   customs_messages,
          //   origin,
          //   localAta,
          //   localAtd,
          //   localSta,
          //   localStd,
          //   sta,
          //   std,
          //   status,
          // } = item || {
          //   _id: "",
          //   flight_number: "",
          //   acreg: "",
          //   arrival_date: "",
          //   airline_id: null,
          //   customs_messages: null,
          //   actype: "",
          //   dest: "",
          //   localAta: 0,
          //   localAtd: 0,
          //   localSta: 0,
          //   localStd: 0,
          //   flight_date: "",
          //   flight_rule_affected: [],
          //   is_arrival: false,
          //   is_internasional: false,
          //   origin: "",
          //   sta: "",
          //   std: "",
          //   status: "",
          // };
          return (
            <CardListFlight dataSource={item} />
            // <Fragment>
            //   <Link to={`/flight/schedule/${is_arrival? 'arrival':'departure'}/${_id}`}>
            //     <Row>
            //       <Col span={6}>
            //         {/* {flight_rule_affected.length > 0 && <Badge color={'error'} text={`Rule ${flight_rule_affected.length}`} />} */}

            //         <Typography.Title
            //           style={{
            //             color: `${
            //               !!flight_rule_affected?.length ? red[6] : ""
            //             }`,
            //             fontSize: fontSize.lg,
            //           }}
            //         >
            //           {flight_number}
            //         </Typography.Title>
            //       </Col>
            //       <Col span={18}>
            //         <Row gutter={8} justify="end">
            //           <Col className="text-right" span={20}>
            //             <div
            //               style={{
            //                 display: "inline-flex",
            //                 gap: 4,
            //                 alignItems: "center",
            //               }}
            //             >
            //               <Flags country_code={airline_id?.country} />
            //               <Typography.Title
            //                 level={5}
            //                 style={{ marginBottom: 0, fontSize: fontSize.md }}
            //               >
            //                 {airline_id?.name || ""}
            //               </Typography.Title>
            //             </div>
            //             <div>
            //               <Typography.Text className="text-sub-title" level={5}>
            //                 {!!is_arrival ? arrival_date : flight_date}
            //               </Typography.Text>
            //             </div>
            //           </Col>
            //           <Col xs={4} sm={4} md={4} lg={4}>
            //             <Avatar
            //               alt={airline_id?.code}
            //               size="large"
            //               style={{ alignSelf: "center" }}
            //               className={"avatar-contain"}
            //               src={`${getLogoAirline(airline_id?.code)}`}
            //             />
            //           </Col>
            //         </Row>
            //       </Col>
            //     </Row>
            //     <Divider className="my-2" />
            //     <Row gutter={[8, 8]}>
            //       <Col xs={5} sm={4} className="text-center">
            //         <Typography.Title level={5} className="Text-bold">
            //           {/* {is_arrival
            //           ? getFormatedTime({
            //               localAt: localAtd,
            //               localSt: localStd,
            //               st: std,
            //             })
            //           : getFormatedTime({
            //               localAt: localAta,
            //               localSt: localSta,
            //               st: sta,
            //             })} */}
            //           {getFormatedTime({
            //             localAt: localAtd,
            //             localSt: localStd,
            //             st: std,
            //           })}
            //         </Typography.Title>
            //         <Typography.Text className="text-sub-title">
            //           {origin}
            //         </Typography.Text>
            //       </Col>
            //       <Col xs={14} sm={16} className="text-center">
            //         <Divider dashed className="my-1">
            //           {status || "Not Scheduled"}
            //         </Divider>
            //         <Typography.Text className="text-sub-title">
            //           {!!acreg && !!actype
            //             ? `${acreg}(${actype})`
            //             : "Aircraft Not Registered"}
            //         </Typography.Text>
            //       </Col>
            //       <Col xs={5} sm={4} className="text-center">
            //         <Typography.Title level={5} className="Text-bold">
            //           {getFormatedTime({
            //             localAt: localAta,
            //             localSt: localSta,
            //             st: sta,
            //           })}
            //           {/* {is_arrival
            //           ? getFormatedTime({
            //               localAt: localAta,
            //               localSt: localSta,
            //               st: sta,
            //             })
            //           : getFormatedTime({
            //               localAt: localAtd,
            //               localSt: localStd,
            //               st: std,
            //             })} */}
            //         </Typography.Title>
            //         <Typography.Text className="text-sub-title">
            //           {dest}
            //         </Typography.Text>
            //       </Col>
            //     </Row>
            //     <Divider className="my-4" />
            //       <PaxMessage id={_id} />
            //   </Link>
            // </Fragment>
          );
        }}
      />
  )
}
