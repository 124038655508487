import Xls from 'exceljs';
import {saveAs} from 'file-saver';

const ApisColumns=['name', 'dob', 'gender', 'nationality', 'passport_number', 'notes'];

export const parseWatchlist=async(file)=>{
    
    // const wb=readExcel(file);
    const wb=new Xls.Workbook();
    await wb.xlsx.load(file);
    const data=[]; 
    const sheet=wb.worksheets[0];
    // console.log({sheet, wb})
    for (let iii = 0; iii < sheet.rowCount; iii++) {
        if(iii===0)continue;
        let obj={};
        for (let iv = 0; iv < ApisColumns.length; iv++) {
            const col = ApisColumns[iv];
            obj[col]=sheet.getCell(`${iii+1}`, `${iv+1}`).value;
        }
        data.push(obj);
    }   
    console.log(data);
    return data;   
}

export const downloadWatchlistTemplate=async()=>{
    const wb=new Xls.Workbook();
    wb.title="Template Watchlist";
    wb.subject="Template Watchlist";
    wb.creator="ESI - EDIFly Solusi Indonesia ~~~HK~~~"
    wb.created=new Date();
    const sheet=wb.addWorksheet();
    for (let iii = 0; iii < ApisColumns.length; iii++) {
        const col = ApisColumns[iii];
        const cell = sheet.getCell('1', `${iii+1}`);//.value=col;        
        cell.value=col;
    }
    
    const buff=await wb.xlsx.writeBuffer();
    saveAs(new Blob([buff], {type:"application/octet-stream"}), 'TemplateWatchlist.xlsx');
    return true;
}  