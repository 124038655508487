import { Button, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
        textAlign: 'center',
        height: '100vh'
        
      }}
    >
      <div>
        <Typography.Title level={1} className='base-text-color'>404</Typography.Title>
        <Typography.Text className="text-sub-title">
          Page Not Found
        </Typography.Text>
      </div>
      <div className="my-4">
        <Link to={"/"}>
          <Button type="link">back to home</Button>
        </Link>
      </div>
    </div>
  );
}
