import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import Row from '../../category/row';
import Panel from '../../panel/panel';
import { IoMdPerson } from 'react-icons/io';
import Flags from '../../../../../../components/flags';
import { useMemo } from 'react';
import Vessel from '../../category/vessel';
import { Link } from 'react-router-dom';
import WatchFlag from '../../category/watchflag';
export default ({data})=>{
    const [,getLan]=useLanguage();
    const format=useMemo(()=>{
        if(!data)return 'DD/MM/YYYY';
        if(!data?.vessel_id)return 'DD/MM/YYYY';
        const {is_arrival} = data.vessel_id;
        return is_arrival?'M/D/YYYY':'DD/MM/YYYY';
    }, [data])
    const [expire, dob]=useMemo(()=>{
        if(!data)return [false, false]
        const {doc_expiration, dob} = data;
        let b=moment(dob, 'DD-MMM-YYYY');
        const birth=(b.isValid())?b.format('DD-MMM-YYYY'):dob;
        b=moment(doc_expiration, 'DD-MMM-YYYY');
        const expire=(b.isValid())?b.format('DD-MMM-YYYY'):doc_expiration;
        return [expire, birth]
    }, [data, format])
    // console.log({expire, dob})
    const getPaxType=(pax_type)=>{
        switch (pax_type) {
            case 'A':
                return 'Adult';
            case 'C':
                return 'Children';
            case 'IN':
            case 'I':
                return 'Infant';
            default:
                return pax_type;
        }
    }
    return (
        <div style={{display:'flex', flexDirection:'row', gap:32, padding:16, flexWrap:'wrap'}}>
            <div style={{display:'flex', flexDirection:'column', rowGap:16, minWidth:520, flexGrow:1}}>
                <Panel
                    headerLeft={<IoMdPerson size={18} />}
                    headerRight={<Typography.Text>{getLan('detail')}</Typography.Text>}
                >
                    <Row size={'medium'} name={'passenger_name'} value={
                        <Link to={`/passengers/citizen/detail/${data?.citizen_id}`}>{data?.passenger_name}</Link> 
                    } />
                    <Row size={'medium'} name={'gender'} value={data?.gender==='F'?getLan('female'):getLan('male')} />
                    <Row size={'medium'} name={'pax_type'} value={getPaxType(data?.pax_type)} />
                    <Row size={'medium'} name={'dob'} value={`${dob} (${data?.age})`} />
                    <Row size={'medium'} name={'nationality'} value={data?.nationality && <Flags country_code={data?.nationality} withCountry />} />
                    <Row size={'medium'} name={'doc_number'} value={data?.doc_number} />
                    <Row size={'medium'} name={'doc_type'} value={data?.doc_type} />
                    <Row size={'medium'} name={'doc_expiry'} value={expire} />
                    <Row size={'medium'} name={'doc_country'} value={data?.unclode_id && <Flags country_code={data?.unclode_id} withCountry />} />
                    <Row size={'medium'} name={'watchlist'}  value={<WatchFlag flag={data?.watch_flag} row={data} />} />
                    <Row size={'medium'} name={'issuing_place'} value={data?.issuing_place} />
                    <Row size={'medium'} name={'seat_type'} value={data?.seat_type} />
                    <Row size={'medium'} name={'ticket_number'} value={data?.ticket_number} />
                    <Row size={'medium'} name={'remarks'} value={data?.remark} />
                    <Row size={'medium'} name={'occupation'} last value={data?.occupation} />
                </Panel>
            </div>
            <div style={{minWidth:480}}>
                { !!data?.vessel_id && <Vessel data={data?.vessel_id} />}
            </div>
        </div>
    )
}