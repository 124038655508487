import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
import AutoComplete from '../../../../components/autocomplete/Online';
import CFG from '../../../../config/env';
const {withDomestic} = CFG;
export default ({onSearchCallback, initValue}) => {
    const [state, setState]=useState(initValue);
    const [,getLang] = useLanguage();
    return (
        <div style={{padding:12, background:'#ffffff88', display:'flex', flexDirection:'column', overflow:'auto'}}>
            <Typography.Text strong>{getLang('search')}</Typography.Text>
                <Form 
                    layout='vertical'
                    initialValues={state}
                    onFinish={onSearchCallback}
                    autoComplete="off"                    
                >                    
                    <Form.Item
                        style={{marginBottom:4}}
                        label={getLang('passenger_name')}
                        name="passenger_name"
                    >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom:4}}
                        label={getLang('db')}
                        name="db"
                    >
                        <Select 
                            onChange={(val)=>{
                                setState({...state, db:val});
                            }}
                        >
                            <Select.Option value={'flight'}>{getLang('flight_international')}</Select.Option>
                            { withDomestic &&  <Select.Option value={'flight_domestic'}>{getLang('flight_domestic')}</Select.Option> }
                            <Select.Option value={'vessel'}>{getLang('vessel')}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={getLang("schedule_date")}
                        name="flight_date"
                        style={{marginBottom:4}}
                        rules={[{
                            validator:async(rule, value)=>{
                                console.log({value})
                                if(!value)return true;
                                // console.log({rule, value})
                                const [from, to]=value;
                                const diff=moment.duration(to.diff(from)).days();
                                if(diff>7){
                                    throw new Error('Cannot more than 7 days');
                                }
                                return true;
                            }
                        }]}
                    >
                        <DatePicker.RangePicker 
                            showTime={true}
                            value={state.flight_date}
                            format={'DD-MMM-YYYY'}
                            onChange={(val, strVal)=>{
                                setState({...state, flight_date:val});
                            }}
                        />
                    </Form.Item>
                    {
                        state.db!=='vessel' && (
                            <Form.Item
                                label={getLang('origin')}
                                style={{marginBottom:4}}
                                name="origin"
                            >
                                        <AutoComplete 
                                            getKey={(opt)=>opt._id}
                                            getSelectedOptions={(opt, val)=>opt._id===val._id}
                                            getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                                            uri="api/v1/airport"
                                            onChange={(val)=>setState({...state, origin:val})}
                                            value={state.origin}
                                        />
                            </Form.Item>
                        )
                    }
                    {
                        state.db!=='vessel' && (
                            <Form.Item
                                label={getLang('dest')}
                                style={{marginBottom:4}}
                                name="dest"
                            >
                                        <AutoComplete 
                                            getKey={(opt)=>opt._id}
                                            getSelectedOptions={(opt, val)=>opt._id===val._id}
                                            getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                                            uri="api/v1/airport"
                                            onChange={(val)=>setState({...state, dest:val})}
                                            value={state.dest}
                                        />
                            </Form.Item>
                        )
                    }                    
                    <Form.Item
                        label={getLang('doc_number')}
                        name="doc_number"
                    >
                        <Input />
                    </Form.Item>
                    <Button block type='primary' htmlType='submit'>Search</Button>
                </Form>
        </div>    
    )
}