import React, { Fragment } from "react";
import { useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Collapse,
  Dropdown,
  Input,
  List,
  Menu,
  Row,
  Typography,
} from "antd";
import BaseListPage from "../../../components/BaseListPage";
import { renderRuleType } from "../../../library";
import { magenta } from "@ant-design/colors";
import RowList from "../../../components/RowList";
import { useFontSize } from "../../../../../hooks/useDimension";
import moment from "moment";
import { EllipsisOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const { Search } = Input;
const { Panel } = Collapse;

export default function Rules() {
  const fontSize = useFontSize();
  const history = useHistory();
  const [search, setSearch] = useState({
    name: "",
  });

  const OverlayMenu = ({ data }) => {
    const {_id} = data
    return (
      <Menu
        items={[
          {
            label: (
              <Button
                type="link"
                onClick={() =>
                  history.push(`/passengers/rules/edit/${_id}`)
                }
              >
                edit
              </Button>
            ),
            key: "edit_rule",
          },
        ]}
      />
    );
  };
  return (
    <BaseListPage
      paginationUrl={"api/v1/rules/pagination"}
      searchApiProps={search}
      fixedPagination
      FilterComponent={() => {
        return (
          <div className="p-2">
            <Row gutter={[8, 8]} style={{ alignItems: "center" }}>
              <Col xs={18} md={20}>
                <Search
                  placeholder="name"
                  allowClear
                  onSearch={(val) => {
                    setSearch({ ...search, name: val });
                  }}
                />
              </Col>
              <Col xs={6} md={4}>
                <Button
                  icon={<PlusCircleOutlined />}
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => {
                    history.push("/passengers/rules/create");
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </div>
        );
      }}
      renderItem={(item) => {
        const {
          rule_type,
          name,
          nationality,
          dest,
          origin,
          gender,
          first_age,
          last_age,
          min_value,
          max_value,
          createdAt,
        } = item || {
          rule_type: "",
          name: "",
          nationality: "",
          dest: "",
          origin: "",
          gender: "",
          first_age: "",
          last_age: "",
          min_value: "",
          max_value: "",
          createdAt: "",
        };
        console.log(Object.keys(item));
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: magenta[6],
                  }}
                  icon={renderRuleType(rule_type)}
                />
              }
              title={
                <Row>
                  <Col xs={20} sm={20} md={22}>
                    <Typography.Title style={{ fontSize: fontSize.md }}>
                      {name?.toUpperCase()}
                    </Typography.Title>
                  </Col>
                  <Col
                    xs={4}
                    sm={4}
                    md={2}
                    className="text-right"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div id="action-container">
                      <Dropdown
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                        overlayStyle={{
                          position: "relative",
                          zIndex: 50,
                          width: "max-content",
                        }}
                        getPopupContainer={() =>
                          document.getElementById("action-container")
                        }
                        overlay={<OverlayMenu data={item} />}
                        trigger={["click"]}
                      >
                        <Button
                          type="link
                "
                          icon={<EllipsisOutlined />}
                        />
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              }
              description={
                <Fragment>
                  <p>Create {moment(createdAt).format("DD-MMM-YYYY HH:mm")}</p>
                  <Collapse
                    className="mt-4"
                    accordion
                    expandIconPosition={"end"}
                  >
                    <Panel header="Rules" key="1">
                      <ul>
                        {!!nationality && (
                          <li>
                            {" "}
                            <RowList
                              title={"Nationality"}
                              content={nationality}
                            />{" "}
                          </li>
                        )}
                        {!!dest && (
                          <li>
                            {" "}
                            <RowList
                              title={"Destination"}
                              content={dest}
                            />{" "}
                          </li>
                        )}
                        {!!origin && (
                          <li>
                            {" "}
                            <RowList title={"Origin"} content={origin} />{" "}
                          </li>
                        )}
                        {!!gender && (
                          <li>
                            {" "}
                            <RowList title={"Gender"} content={gender} />{" "}
                          </li>
                        )}
                        <li>
                          {" "}
                          <RowList
                            title={"Age"}
                            content={
                              <div>
                                {first_age}-{last_age}
                              </div>
                            }
                          />{" "}
                        </li>
                        <li>
                          {" "}
                          <RowList
                            title={"Total"}
                            content={
                              <div>
                                {min_value}-{max_value}
                              </div>
                            }
                          />{" "}
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </Fragment>
              }
            />
          </List.Item>
        );
      }}
    />
  );
}
