
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SubDetailPage from "../../../components/SubDetailPage";
import Airline from "./Airline";
import RouteFlight from "./RouteFlight";
import Rule from "./Rule";
import TelexList from "../../telexes/TelexList.js";
import PnrList from "../../pnr/PnrList";

import PassengersFlight from "../passenger/PassengersFlight";

export default () => {
  const { id, subdetail } = useParams();
  const { pathname, state } = useLocation();

  const components = {
    airline: Airline,
    origin: RouteFlight,
    destination: RouteFlight,
    rule: Rule,
    telex:TelexList,
    pnr:PnrList,
    passengers:PassengersFlight
  };

  return (
    // <BasePage  title={`Flight ${subdetail}`}>
      <SubDetailPage
        basePath={pathname}
        subComponents={components}
        keyComponents={subdetail}
      />
    //  </BasePage>
  );
};
