import PnrDetail from '../pages/pnr/detail';
import PaxPnrPage from '../pages/pnr/pax';
import PaxDomPnrPage from '../pages/pnr/pax_dom';
export default [
    {
        path:'/pnr/detail/:id',
        exact:true,
        Component:PnrDetail
    },
    {
        path:'/pnr/passengers/:id',
        exact:true,
        Component:PaxPnrPage
    },
    {
        path:'/pnr/passengers/domestic/:id',
        exact:true,
        Component:PaxDomPnrPage
    },
]