import { Input, Pagination, Typography } from 'antd';
import numeral from 'numeral';
import React, { useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
const MAXROW=10;
export default ({data, renderItem, filterValues, noLimit, OtherComponent}) => {
    const [qry, setQry] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(MAXROW);
    const filteredData=useMemo(()=>{
        if(!data)return [];
        if(!qry)return data;
        return !!data && Array.isArray(data) && data.filter((row)=>{            
            for (let iii = 0; iii < filterValues.length; iii++) {
                const f = filterValues[iii];
                const val = row[f]; 
                if(`${val}`.toLowerCase().indexOf(qry.toLowerCase())>=0){
                    return true;
                }
            }
            return false;
        })
    }, [data, qry, filterValues]);
    const limitedData=useMemo(()=>{
        if(!!noLimit)return filteredData;
        const result=[];
        const last = (page * perPage)- 1;
        const start= (page - 1) * perPage;
        for (let iii = start; iii <= last; iii++) {
            if( iii >= filteredData.length)continue;
            const f = filteredData[iii];
            result.push(f);
        }
        return result;
    }, [filteredData, noLimit, page, perPage]);
    return (
        <div style={{display:'flex', flexDirection:'column', rowGap:16, paddingInline:16, paddingBlock:8, height:'100%'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:8, alignItems:'center'}}>
                <div style={{display:'flex', flexDirection:'column', rowGap:4}}>
                    <Typography.Text strong>
                        Viewed: {numeral(limitedData.length).format('0,000')} Filtered : {numeral(filteredData.length).format('0,000')} From Total : {numeral(data?.length || 0).format('0,000')}
                    </Typography.Text>
                    {
                        !noLimit && (
                            <Pagination 
                                total={Array.isArray(data) && data.length || 0} 
                                defaultCurrent={1} 
                                current={page}
                                simple
                                onChange={(pg, sz)=>{
                                    setPerPage(sz);
                                    setPage(pg || 1);
                            }}  />
                        )
                    }
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'end', gap:8}}>
                    <Input.Search 
                        style={{width:220, alignSelf:'end'}} 
                        value={qry} 
                        onChange={(e)=>{
                            setQry(e.target.value);
                        }} 
                    />
                    {OtherComponent}
                </div>
            </div>
            <Scrollbars>
                <div style={{display:'flex', flexDirection:'column', rowGap:8, paddingInline:16}}>
                    {
                        !!limitedData && Array.isArray(limitedData) && limitedData.map(renderItem)
                    }
                </div>
            </Scrollbars>            
        </div>
    )
}