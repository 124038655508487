import { Col, Row } from "antd";
import numeral from "numeral";
import React, { Fragment, useMemo } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import Badge from "../../components/Badge";

export default function PaxMessange({ id }) {
  const url = useMemo(() => {
    return `api/v1/vessel/pax_total/${id}`;
  }, [id]);

  const [paxMsg, loading] = useFetch(url);

  const { pnr, manifest, interpol, imigrasi, visa, passport, poi, crew } =
    paxMsg || {
      pnr: 0,
      manifest: 0,
      interpol: 0,
      imigrasi: 0,
      pantau: 0,
      passport: 0,
      poi: 0,
      crew: 0,
    };
  return (
    <Fragment>
      <Row gutter={[8,8]}>
        <Col span={8}>
          <Badge
            text={`MAN : ${numeral(manifest).format("000")}`}
            color={`${manifest > 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={8}>
          <Badge
            text={`PPT : ${numeral(passport).format("000")}`}
            color={`${passport === 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={8}>
          <Badge
            text={`ITP : ${numeral(interpol).format("000")}`}
            color={`${interpol === 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={8}>
          <Badge
            text={`CKL : ${numeral(imigrasi).format("000")}`}
            color={`${imigrasi === 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={8}>
          <Badge
            text={`POI : ${numeral(poi).format("000")}`}
            color={`${poi === 0 ? "success" : "error"}`}
          />
        </Col>
        <Col span={8}>
          <Badge
            text={`VIT : ${numeral(visa).format("000")}`}
            color={`${visa === 0 ? "success" : "error"}`}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
