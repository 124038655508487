import React from 'react';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import {HiOutlineTicket} from 'react-icons/hi'
import { GiHouse } from 'react-icons/gi';
import DetailComp from '../components/details/pnr/detail';

export default () => {
    const {id} = useParams();

    return (
        <Detail 
            url={`api/v1/pnr/detail`}
            title="pnr"
            icon={HiOutlineTicket}
            getBreadcumbValue={({pnr})=>pnr}
            id={id}
            DetailComponent={DetailComp}
            additionalData={{id}}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'pnr'
                    },
                    
                ]
            }
        />
    )
}