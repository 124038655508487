import { Col, Row } from "antd";
import React, { Fragment, useEffect, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useFetch } from "../../../hooks/useFetch";

export default function BaseDetailPage({
  children,
  dataSource,
  url,
  onDataEvent,
  getData,
  renderContent,
}) {
  const defUrl = useMemo(() => {
    return !!url && url;
  }, [url]);

  const [fetchedData, loading] = useFetch(defUrl);

  const currenData = useMemo(() => {
    const data = !!dataSource ? dataSource : fetchedData;
    return !!data && !!Object.keys(data).length ? data : false;
  }, [dataSource, fetchedData]);

  const sortedData = useMemo(() => {
    if (typeof onDataEvent !== "function") return currenData;
    return onDataEvent(currenData);
  }, [currenData]);

  useEffect(() => {
    if (!!getData && typeof getData === "function") {
      getData(sortedData);
    }
  }, [sortedData]);

  return (
    <div>
      {!loading ? (
        !!sortedData ? (
          <Fragment>{renderContent(sortedData)}</Fragment>
        ) : (
          <p>No Result Data</p>
        )
      ) : (
        <p>loading ...</p>
      )}
    </div>
  );
}
