import { Button,  DatePicker,  Form, Select, Typography } from 'antd';
import React, { useState } from 'react';
import { useLanguage } from '../../../../hooks/useLanguage';
export default ({onSearchCallback, initValue}) => {
    const [state, setState]=useState(initValue);
    const [,getLang] = useLanguage();
    return (
        <div style={{padding:12,  width:320,background:'#ffffff88', display:'flex', flexDirection:'column', overflow:'auto'}}>
            <Typography.Text strong>{getLang('search')}</Typography.Text>
                <Form 
                    layout='vertical'
                    initialValues={state}
                    onFinish={onSearchCallback}
                    autoComplete="off"
                    
                >
                    <Form.Item
                        style={{marginBottom:16}}
                        label={getLang('date')}
                        name="date"
                    >
                        <DatePicker 
                            style={{width:'100%'}}
                            showTime={false}
                            format={'DD-MMM-YYYY'}
                        />
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom:16}}
                        label={getLang('msg_type')}
                        name="msg_type"
                    >
                        <Select>
                            <Select.Option value={''}>ALL</Select.Option>
                            <Select.Option value={'PNRGOV'}>PNRGOV</Select.Option>
                            <Select.Option value={'APIS'}>APIS</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button block type='primary' htmlType='submit'>Search</Button>
                </Form>
        </div>    
    )
}