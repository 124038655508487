import { Avatar, Typography } from 'antd';
import React, { useMemo } from 'react';
import { IoIosPerson, IoMdPeople } from 'react-icons/io';
import { useLanguage } from '../../../../../hooks/useLanguage';
import Panel from '../panel/panel';
import Table from '../lists/table';
import { useFetch } from '../../../../../hooks/useFetch';
import { GiSailboat } from 'react-icons/gi';
import moment from 'moment';
import { Link } from 'react-router-dom';
import WatchFlag from './watchflag';

export default ({_id,}) => {
    const [data, loading] = useFetch('api/v1/vessel_pax/detail/citizen_id/'+_id);

    const [,getLan]=useLanguage();
    return (
        <Panel
            headerLeft={<GiSailboat size={24}/>}
            headerRight={<Typography.Text>{getLan('vessel_history')}</Typography.Text>}
        >
            <Table 
                data={data || []}
                pagination={false}
                filterField={['passenger_name', 'doc_number']}
                // url="/vessel/passengers/detail"
                columns={[
                {
                    title:'action',
                    dataIndex:'_id',  
                    width:'80px',     
                    render:(val)=><Link to={"/vessel/passengers/detail/"+val}><IoIosPerson size={24} /></Link>
                },
                {
                    title:'operator_id',
                    dataIndex:'vessel_id',  
                    width:'140px',     
                    render:(val)=>val?.ferry_key
                },                
                {
                    title:'schedule_id',
                    dataIndex:'vessel_id',       
                    width:'150px',     
                    render:(val)=>{
                        if(!val)return '-';
                        const {_id, is_arrival} = val;
                        const direction=is_arrival?'arrival':'departure';
                        return <Link to={`/vessel/${direction}/${_id}`}>{val?.schedule_id}</Link>
                    }
                },
                {
                    title:'schedule_time',
                    dataIndex:'vessel_id',       
                    render:(val)=>!!val && (val.is_arrival?val.sta:val.std)
                },
                {
                    title:'origin',
                    width:'120px',     
                    dataIndex:'vessel_id',       
                    render:(val)=>val?.origin.toUpperCase()
                },
                {
                    title:'dest',
                    width:'120px',     
                    dataIndex:'vessel_id',       
                    render:(val)=>val?.destination.toUpperCase()
                },
                {
                    title:'status',
                    width:'40px',     
                    dataIndex:'watch_flag',       
                    render:(val, rec)=><WatchFlag flag={val} row={rec} />
                },
            ]}
            />
        </Panel>
    )
}