import { Typography } from 'antd';
import React from 'react';
import { MdBuild } from 'react-icons/md';
import { useLanguage } from '../../../hooks/useLanguage';

export default () => {
    const [,getLan]=useLanguage();
    return (
        <div style={{display:'flex', flexDirection:'column', padding:8, background:'#ffffff88', height:'100%', 
            justifyContent:'center', alignItems:'center'}}>
            <div className='underconstruction'>
                <Typography.Text className='header'>{getLan('comming_soon')}</Typography.Text>
                <MdBuild size={56} color="black" style={{border:'1px solid black', padding:8, borderRadius:99}} />
                <Typography.Text>{getLan('underconstruction')}</Typography.Text>
            </div>
        </div>
    )
}