import { Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import { useLanguage } from '../../../../../../hooks/useLanguage';

export default ({title, children, Trigger, data, width}) => {
    const [open, setOpen]=useState(false);
    const [,getLang] = useLanguage();
    return (
        <Fragment>
            <Trigger onClick={()=>setOpen(true)} data={data} />
            <Modal 
                footer={null}
                visible={open}
                onCancel={()=>setOpen(false)}
                title={getLang(title)}                
                width={width}
                closable
                destroyOnClose           
                centered
            >
                {
                    children
                }
            </Modal>
        </Fragment>
    )
}